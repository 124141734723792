<!-- eslint-disable array-callback-return -->
<!-- eslint-disable array-callback-return -->
<!-- eslint-disable array-callback-return -->
<template>
  <div class="company-detail flex-col">
    <img
      style="position: sticky; width: 25px; left: 98%; cursor: pointer; top: 0"
      @click="close"
      src="@/assets/image/close.png"
      alt=""
    />
    <div class="company-container" v-loading="loading">
      <div v-show="coreAdv" class="honorlist-bg flex-row">
        <div class="core-content">
          <p class="title flex-row">
            <span><span class="sub-before"></span>核心优势</span
            ><img
              @click="coreAdv = false"
              style="width: 30px; height: 30px"
              src="@/assets/image/close.png"
            />
          </p>
          <hr style="margin: 15px auto" />
          <div class="show">
            <div v-for="(i, index) in advantage" :key="index">
              <p
                style="
                  font-size: 16px;
                  font-weight: bold;
                  margin: 20px 0 10px;
                  color: #ff6600;
                "
              >
                <span
                  style="
                    color: white;
                    padding: 5px 10px;
                    background: #ff6600;
                    border-radius: 6px;
                    margin-right: 10px;
                  "
                  >{{ index + 1 }}/{{ advantage.length }}</span
                >{{ i.category }}
              </p>
              <p
                style="
                  font-size: 14px;
                  line-height: 24px;
                  text-indent: 40px;
                  color: #666;
                "
              >
                {{ i.content }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-show="honorlist" class="honorlist-bg flex-row">
        <div class="honor-content">
          <div class="flex-row honor-top">
            <span>企业亮点</span
            ><img
              @click="honorlist = false"
              src="@/assets/image/close.png"
              alt=""
            />
          </div>
          <hr style="margin: 20px auto" />
          <div
            v-if="tags.highlights && tags.highlights.length"
            class="flex-row"
            style="border-bottom: 1px dashed #d1d1d1; padding-bottom: 20px"
          >
            <img
              style="height: 108px"
              src="@/assets/image/company/highlight.png"
            />
            <div class="flex-row highlight-list">
              <p v-for="(i, index) in tags.highlights" :key="index">
                · {{ i }}
              </p>
            </div>
          </div>
          <div
            class="flex-row"
            v-if="tags.ranks && tags.ranks.length"
            style="margin-top: 20px"
          >
            <img
              style="height: 108px"
              src="@/assets/image/company/honor.png"
            />
            <div class="flex-row honor-list">
              <p v-for="(i,index) in tags.ranks" :key="index">· {{ i }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-row head">
        <div class="head-left flex-row">
          <div class="left-info">
            <div class="flex-row">
              <div
                class="logo"
                :style="!banner.logo ? 'background:#7db3d9' : ''"
              >
                <img v-if="banner.logo" :src="banner.logo" />
                <span
                  style="
                    vertical-align: top;
                    font-size: 34px;
                    display: inline-block;
                    color: white;
                  "
                  v-else-if="companyInfo.companyName"
                  >{{ companyInfo.companyName.slice(0, 1) }}</span
                >
              </div>
              <div class="flex-col base-info">
                <p class="company-name">
                  {{ companyInfo.companyName }}
                  <el-popover
                    v-if="banner.level"
                    placement="bottom-start"
                    title=""
                    width="400"
                    trigger="hover"
                    content="评级是基于多维度数据，从组织背景、经营现状、信用评级、创新能力、发展态势几大维度综合计算的企业得分，是了解企业实力的直观方式。评级分为A1/A2/B1/B2/B3/B4/C1/C2; A1最高C2最低。"
                  >
                    <img
                      style="vertical-align: text-bottom"
                      slot="reference"
                      :src="
                        require('@/assets/image/company/' +
                          banner.level +
                          '.png')
                      "
                    />
                  </el-popover>
                </p>
                <p class="company-status">
                  <span v-if="companyInfo.regStatus">{{
                    companyInfo.regStatus
                  }}</span
                  ><span v-if="security.secuCode"
                    >{{ security.secuMarketText }}｜{{ security.secuAbbr
                    }}{{ security.secuCode }}</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="head-right">
          <p class="title">
            联系方式<span class="contact-type"
              >（共有<span>{{
                unlockInfo.fixedPhone || unlockInfo.mobile || unlockInfo.email
                  ? unlockInfo.fixedPhone -
                    0 +
                    (unlockInfo.mobile - 0) +
                    (unlockInfo.email - 0)
                  : 0
              }}</span
              >条联系方式）</span
            >
          </p>
          <div class="lock flex-row">
            <div v-if="lock === 0" class="unlock" @click="unlockContact">
              <img src="@/assets/image/company/lock.png" alt="" />立即解锁
            </div>
            <div v-else class="unlock" @click="unlockContact">立即查看</div>
          </div>
        </div>
      </div>
      <div class="navbar flex-row">
        <span
          @click="chooseNav(i.name)"
          v-for="(i, index) in navList"
          :key="index"
          :class="currentNav === i.name ? 'active' : ''"
          ><img
            :src="
              currentNav === i.name
                ? require('@/assets/image/company/' + i.img + '-active.png')
                : require('@/assets/image/company/' + i.img + '.png')
            "
            alt=""
          />{{ i.name }}</span
        >
      </div>
      <!-- 切换内容 -->
      <!-- 企业概览 -->
      <div v-show="currentNav === '企业概览'" class="contentOne flex-row">
        <div class="contentOne-center" style="max-width: 75%">
          <div>
            <p class="title flex-row">
              <span><span class="sub-before"></span>企业信息</span
              ><span
                v-if="advantage.length"
                class="core"
                @click="coreAdv = true"
                ><img
                  src="@/assets/image/company/core.png"
                  alt=""
                />核心优势</span
              >
            </p>
            <p class="intro">{{ summary.summary }}</p>
            <br />
            <div id="companyRelation" style="margin: 0 auto"></div>
            <br />
            <div
              class="flex-row honor-bar"
              v-if="tags.ranks && tags.ranks.length && tags.ranks.length > 5"
            >
              <span
                class="flex-row"
                v-for="(i, index) in tags.ranks && tags.ranks.slice(0, 5)"
                :key="index"
                >{{ i }}▼</span
              >
              <span @click="showHighlights" class="more"
                >更多 <img src="@/assets/image/company/arrow-down.png" alt=""
              /></span>
            </div>
            <br />
            <div class="highlight">
              <el-popover
                v-for="(i, index) in tags.technology"
                :key="index"
                placement="top-start"
                title=""
                width="300"
                trigger="hover"
                :content="i.remark"
              >
                <span slot="reference">{{ i.dictTitle }}▼</span>
              </el-popover>
              <span
                v-for="(i, index) in tags.products"
                :key="'product' + index"
                >{{ i }}</span
              >
              <span
                v-for="(i, index) in tags.highlights"
                :key="'highlight' + index"
                >{{ i }}</span
              >
              <span v-for="(i, index) in tags.selftags" :key="'self' + index">{{
                i
              }}</span>
              <span
                v-for="(i, index) in tags.industries"
                :key="'industry' + index"
                >{{ i }}</span
              >
            </div>
          </div>
        </div>
        <div class="contentOne-right">
          <div v-if="similarCompanyVoList.length">
            <p class="title"><span class="sub-before"></span>同类公司</p>
            <hr style="margin: 15px auto" />
            <div
              v-for="(i, index) in similarCompanyVoList"
              :key="index"
              class="same-company flex-row"
            >
              <div
                class="logo"
                :style="
                  !i.companyIcon
                    ? 'background:' + moreColors[Math.floor(Math.random() * 4)]
                    : ''
                "
              >
                <img v-if="i.companyIcon" :src="i.companyIcon" />
                <span
                  style="
                    vertical-align: top;
                    font-size: 14px;
                    display: inline-block;
                    color: white;
                    font-size: 14px;
                  "
                  v-else
                  >{{ i.companyName.slice(0, 1) }}</span
                >
              </div>
              {{ i.companyName }}
            </div>
          </div>
          <div v-if="downStream.length || upStream.length">
            <p class="title nav-list">
              <span
                style="cursor: pointer"
                @click="updown = 'down'"
                :class="updown === 'down' ? 'nav-active' : ''"
                >客户</span
              ><span
                style="cursor: pointer"
                @click="updown = 'up'"
                :class="updown === 'up' ? 'nav-active' : ''"
                >供应商</span
              >
            </p>
            <hr style="margin-bottom: 15px" />
            <div v-show="updown === 'down'">
              <div
                v-for="(i, index) in downStream"
                :key="index"
                class="same-company flex-row"
              >
                <div
                  class="logo"
                  :style="
                    !i.companyIcon
                      ? 'background:' +
                        moreColors[Math.floor(Math.random() * 4)]
                      : ''
                  "
                >
                  <img v-if="i.companyIcon" :src="i.companyIcon" />
                  <span
                    style="
                      vertical-align: top;
                      font-size: 14px;
                      display: inline-block;
                      color: white;
                      font-size: 14px;
                    "
                    v-else
                    >{{ i.name.slice(0, 1) }}</span
                  >
                </div>
                {{ i.name }}
              </div>
            </div>
            <div v-show="updown === 'up'">
              <div
                v-for="(i, index) in upStream"
                :key="index"
                class="same-company flex-row"
              >
                <div
                  class="logo"
                  :style="
                    !i.companyIcon
                      ? 'background:' +
                        moreColors[Math.floor(Math.random() * 4)]
                      : ''
                  "
                >
                  <img v-if="i.companyIcon" :src="i.companyIcon" />
                  <span
                    style="
                      vertical-align: top;
                      font-size: 14px;
                      display: inline-block;
                      color: white;
                      font-size: 14px;
                    "
                    v-else
                    >{{ i.name.slice(0, 1) }}</span
                  >
                </div>
                {{ i.name }}
              </div>
            </div>
          </div>
        </div>
        <div v-show="executiveDetailDialog" class="executiveDialog flex-row">
          <div class="executiveDetail">
            <img
              @click="executiveDetailDialog = false"
              class="close"
              src="@/assets/image/close.png"
              alt=""
            />
            <p class="title">简介</p>
            <hr style="margin: 15px auto" />
            <div class="flex-row intro">
              <div class="flex-row" v-if="executiveDetail.name">
                <div
                  class="logo"
                  :style="
                    !executiveDetail.pic
                      ? 'background:' +
                        moreColors[Math.floor(Math.random() * 4)]
                      : ''
                  "
                >
                  <img v-if="executiveDetail.pic" :src="executiveDetail.pic" />
                  <span
                    style="
                      vertical-align: top;
                      font-size: 14px;
                      display: inline-block;
                      color: white;
                      font-size: 30px;
                    "
                    v-else
                    >{{ executiveDetail.name.slice(0, 1) }}</span
                  >
                </div>
                <div class="executive-base">
                  <p>{{ executiveDetail.name }}</p>
                  <p>{{ executiveDetail.position }}</p>
                </div>
              </div>
              <div class="executive-more">
                <p>
                  职位：<span>{{ executiveDetail.position }}</span>
                </p>
                <p>
                  学历：<span>{{ executiveDetail.education }}</span>
                </p>
                <p>
                  任职日期：<span>{{ executiveDetail.duration }}</span>
                </p>
                <p>
                  出生年月：<span>{{ executiveDetail.date }}</span>
                </p>
              </div>
            </div>
            <p class="sketch">
              {{ executiveDetail.profile }}
            </p>
          </div>
        </div>
      </div>
      <!-- 个股概况 -->
      <div v-show="currentNav === '个股概况'" sty class="contentSix flex-row">
        <div class="contentSix-left">
          <div>
            <p class="title">
              <span class="sub-before"></span>股票详情
              <span>
                &emsp;&emsp;
                <span
                  v-if="security.secuMarket === 1 && stockDetail.pc"
                  style="
                    display: inline-block;
                    line-height: 30px;
                    text-align: center;
                    padding: 0 20px;
                    height: 30px;
                    background: #f5f5f5;
                    border-radius: 4px;
                    border: 1px solid #eaeaea;
                    font-size: 14px;
                    margin-left: 100px;
                  "
                  >价格：<span
                    :style="
                      stockDetail.pc > 0 ? 'color:#ff0000' : 'color:#1B9D4B'
                    "
                    >{{ stockDetail.p }}</span
                  >
                  &emsp;涨跌幅：<span
                    :style="
                      stockDetail.pc > 0 ? 'color:#ff0000' : 'color:#1B9D4B'
                    "
                    >{{
                      (stockDetail.pc > 0 ? "↑ " : "↓ ") + stockDetail.pc + "%"
                    }}</span
                  ></span
                >
                <span
                  v-if="security.secuMarket === 2 && stockDetail2.ChangePercent"
                  style="
                    display: inline-block;
                    line-height: 30px;
                    text-align: center;
                    padding: 0 20px;
                    height: 30px;
                    background: #f5f5f5;
                    border-radius: 4px;
                    border: 1px solid #eaeaea;
                    font-size: 14px;
                    margin-left: 100px;
                  "
                  >价格：<span
                    :style="
                      stockDetail2.ChangePercent > 0
                        ? 'color:#ff0000'
                        : 'color:#1B9D4B'
                    "
                    >{{ stockDetail2.Close }}</span
                  >
                  &emsp;涨跌幅：<span
                    :style="
                      stockDetail2.ChangePercent > 0
                        ? 'color:#ff0000'
                        : 'color:#1B9D4B'
                    "
                    >{{
                      (stockDetail2.ChangePercent > 0 ? "↑ " : "↓ ") +
                      stockDetail2.ChangePercent +
                      "%"
                    }}</span
                  ></span
                >
              </span>
            </p>
            <hr style="margin: 15px auto" />
            <div>
              <div class="stock-detail" v-if="stockDetail.o">
                今开：<span
                  :style="
                    stockDetail.o > stockDetail.yc
                      ? 'color:#ff0000'
                      : 'color:#1B9D4B'
                  "
                  >{{ stockDetail.o && stockDetail.o.toFixed(2) }}</span
                >&emsp;最高：<span style="color: #ff0000">{{
                  stockDetail.h && stockDetail.h.toFixed(2)
                }}</span
                >&emsp;最低：<span style="color: #1b9d4b">{{
                  stockDetail.l && stockDetail.l.toFixed(2)
                }}</span
                >&emsp;成交量：<span
                  >{{ (stockDetail.v / 10000).toFixed(2) }}万手</span
                >&emsp;总市值：<span
                  >{{ (stockDetail.sz / 100000000).toFixed(2) }}亿</span
                >&emsp;流通市值：<span
                  >{{ (stockDetail.lt / 100000000).toFixed(2) }}亿</span
                >&emsp;市盈：<span>{{
                  stockDetail.pe && stockDetail.pe.toFixed(2)
                }}</span
                >&emsp;市净：<span>{{
                  stockDetail.sjl && stockDetail.sjl.toFixed(2)
                }}</span>
              </div>
              <div class="stock-detail" v-if="stockDetail2.Open">
                今开：<span
                  :style="
                    stockDetail2.Open > stockDetail2.PreviousClose
                      ? 'color:#ff0000'
                      : 'color:#1B9D4B'
                  "
                  >{{
                    stockDetail2.Open && (stockDetail2.Open - 0).toFixed(2)
                  }}</span
                >&emsp;最高：<span style="color: #ff0000">{{
                  stockDetail2.High && (stockDetail2.High - 0).toFixed(2)
                }}</span
                >&emsp;最低：<span style="color: #1b9d4b">{{
                  stockDetail2.Low && (stockDetail2.Low - 0).toFixed(2)
                }}</span
                >&emsp;成交量：<span
                  >{{ (stockDetail2.Volume / 10000).toFixed(2) }}万手</span
                >&emsp;总市值：<span
                  >{{
                    (stockDetail2.MarketValue / 100000000).toFixed(2)
                  }}亿</span
                >&emsp;流通市值：<span
                  >{{
                    (stockDetail2.FlowCapitalValue / 100000000).toFixed(2)
                  }}亿</span
                >&emsp;市盈：<span>{{
                  stockDetail2.PERation &&
                  (stockDetail2.PERation - 0).toFixed(2)
                }}</span
                >&emsp;市净：<span>{{
                  stockDetail2.PB && (stockDetail2.PB - 0).toFixed(2)
                }}</span>
              </div>
              <div class="stock">
                <span
                  @click="currentStock = index"
                  :style="
                    currentStock === index
                      ? 'background: #15659E;color:white'
                      : ''
                  "
                  v-for="(i, index) in stockList"
                  :key="index"
                  >{{ i }}</span
                >
              </div>
              <div
                id="shares"
                style="width: 100%; height: 540px; margin-top: 0"
              ></div>
            </div>
          </div>
          <div>
            <p class="title"><span class="sub-before"></span>财务指标</p>
            <hr style="margin: 15px auto" />
            <div class="target-head flex-row">
              <span v-for="(i, index) in targetNum" :key="index">{{
                i.date
              }}</span>
            </div>
            <div class="num flex-row">
              <span>净利润同比（%）</span>
              <div class="flex-row">
                <span v-for="(i, index) in targetNum" :key="index">{{
                  i.a
                }}</span>
              </div>
            </div>
            <div class="num flex-row">
              <span>营收同比率（%）</span>
              <div class="flex-row">
                <span v-for="(i, index) in targetNum" :key="index">{{
                  i.b
                }}</span>
              </div>
            </div>
            <div class="num flex-row">
              <span>ROE（%）</span>
              <div class="flex-row">
                <span v-for="(i, index) in targetNum" :key="index">{{
                  i.c
                }}</span>
              </div>
            </div>
            <div class="num flex-row">
              <span>毛利率（%）</span>
              <div class="flex-row">
                <span v-for="(i, index) in targetNum" :key="index">{{
                  i.d
                }}</span>
              </div>
            </div>
            <div class="num-title">利润表(亿元)</div>
            <div class="num flex-row">
              <span>总营收</span>
              <div class="flex-row">
                <span v-for="(i, index) in targetNum" :key="index">{{
                  i.e
                }}</span>
              </div>
            </div>
            <div class="num flex-row">
              <span>总利润</span>
              <div class="flex-row">
                <span v-for="(i, index) in targetNum" :key="index">{{
                  i.f
                }}</span>
              </div>
            </div>
            <div class="num flex-row">
              <span>净利润</span>
              <div class="flex-row">
                <span v-for="(i, index) in targetNum" :key="index">{{
                  i.g
                }}</span>
              </div>
            </div>
            <div class="num-title">资产负债表(亿元)</div>
            <div class="num flex-row">
              <span>总资产</span>
              <div class="flex-row">
                <span v-for="(i, index) in targetNum" :key="index">{{
                  i.h
                }}</span>
              </div>
            </div>
            <div class="num flex-row">
              <span>总负债</span>
              <div class="flex-row">
                <span v-for="(i, index) in targetNum" :key="index">{{
                  i.i
                }}</span>
              </div>
            </div>
            <div class="num flex-row">
              <span>股东权益合计</span>
              <div class="flex-row">
                <span v-for="(i, index) in targetNum" :key="index">{{
                  i.j
                }}</span>
              </div>
            </div>
            <div class="num-title">现金流量表(亿元)</div>
            <div class="num flex-row">
              <span>营业性现金流</span>
              <div class="flex-row">
                <span v-for="(i, index) in targetNum" :key="index">{{
                  i.m
                }}</span>
              </div>
            </div>
            <div class="num flex-row">
              <span>投资性现金流</span>
              <div class="flex-row">
                <span v-for="(i, index) in targetNum" :key="index">{{
                  i.n
                }}</span>
              </div>
            </div>
            <div class="num flex-row">
              <span>融资性现金流</span>
              <div class="flex-row">
                <span v-for="(i, index) in targetNum" :key="index">{{
                  i.o
                }}</span>
              </div>
            </div>
            <div class="num flex-row">
              <span>净现金流</span>
              <div class="flex-row">
                <span v-for="(i, index) in targetNum" :key="index">{{
                  i.p
                }}</span>
              </div>
            </div>
          </div>
          <div>
            <p class="title"><span class="sub-before"></span>主营收入按地区</p>
            <hr style="margin: 15px auto" />
            <p class="date">
              <span
                :class="reportDate === i.date ? 'choosed' : ''"
                @click="chooseAreaDate(i, index)"
                v-for="(i, index) in areaAllData"
                :key="index"
                >{{ i.date }}</span
              >
            </p>
            <el-table :data="areaNum" style="width: 100%">
              <el-table-column prop="area" label="主营构成"> </el-table-column>
              <el-table-column
                prop="mainBusinessIncome"
                align="center"
                label="主营收入(亿元)"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.mainBusinessIncome
                      ? scope.row.mainBusinessIncome &&
                        (scope.row.mainBusinessIncome / 100000000).toFixed(2)
                      : "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column
                prop="mbiRatio"
                align="center"
                label="收入比例(%)"
              >
                <template slot-scope="scope">
                  {{ scope.row.mbiRatio ? scope.row.mbiRatio : "-" }}
                </template>
              </el-table-column>
              <el-table-column
                prop="mainBusinessCost"
                align="center"
                label="主营成本(亿元)"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.mainBusinessCost
                      ? scope.row.mainBusinessCost &&
                        (scope.row.mainBusinessCost / 100000000).toFixed(2)
                      : "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column
                prop="mbcRatio"
                align="center"
                label="成本比例(%)"
              >
                <template slot-scope="scope">
                  {{ scope.row.mbcRatio ? scope.row.mbcRatio : "-" }}
                </template>
              </el-table-column>
              <el-table-column
                prop="mainBusinessRpofit"
                align="center"
                label="主营利润(亿元)"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.mainBusinessRpofit
                      ? scope.row.mainBusinessRpofit &&
                        (scope.row.mainBusinessRpofit / 100000000).toFixed(2)
                      : "-"
                  }}
                </template>
              </el-table-column>
              <el-table-column
                prop="mbrRatio"
                align="center"
                label="利润比例(%)"
              >
                <template slot-scope="scope">
                  {{ scope.row.mbrRatio ? scope.row.mbrRatio : "-" }}
                </template>
              </el-table-column>
              <el-table-column
                prop="grossRpofitRatio"
                align="center"
                label="毛利率(%)"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.grossRpofitRatio
                      ? scope.row.grossRpofitRatio
                      : "-"
                  }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="contentSix-right">
          <div>
            <p class="title"><span class="sub-before"></span>指标情况</p>
            <hr style="margin: 15px auto" />
            <div class="target">
              <span
                v-for="(i, index) in targets"
                :key="index"
                @click="ability = index"
                :class="index === ability ? 'ability' : ''"
                >{{ i }}</span
              >
            </div>
            <div v-show="ability === 0">
              <div
                id="growChart"
                style="width: 500px; height: 300px; margin-top: 20px"
              ></div>
              <div
                id="netProfitChart"
                style="width: 500px; height: 300px; margin-top: 20px"
              ></div>
            </div>
            <div v-show="ability === 1">
              <div
                id="grossMarginChart"
                style="width: 500px; height: 300px; margin-top: 20px"
              ></div>
              <div
                id="roeChart"
                style="width: 500px; height: 300px; margin-top: 20px"
              ></div>
              <div
                id="opmAnpmChart"
                style="width: 500px; height: 300px; margin-top: 20px"
              ></div>
            </div>
            <div v-show="ability === 2">
              <div
                id="alrChart"
                style="width: 500px; height: 300px; margin-top: 20px"
              ></div>
              <div
                id="cirChart"
                style="width: 500px; height: 300px; margin-top: 20px"
              ></div>
            </div>
            <div v-show="ability === 3">
              <div
                id="tatChart"
                style="width: 500px; height: 300px; margin-top: 20px"
              ></div>
            </div>
            <div v-show="ability === 4">
              <div
                id="ocfChart"
                style="width: 500px; height: 300px; margin-top: 20px"
              ></div>
              <div
                id="ncfChart"
                style="width: 500px; height: 300px; margin-top: 20px"
              ></div>
            </div>
          </div>
          <div>
            <p class="title">
              <span class="sub-before"></span>主营业务按地区<span
                style="color: #333333; float: right; font-weight: normal"
                >{{ reportDate }}</span
              >
            </p>
            <hr style="margin: 15px auto" />
            <div id="areaBusiness" style="width: 500px; height: 300px"></div>
          </div>
          <div>
            <p class="title"><span class="sub-before"></span>主营产品&业务</p>
            <hr style="margin: 15px auto" />
            <div class="product" v-if="banner.mainProduct">
              <p
                v-for="(i, index) in banner.mainProduct.split(';')"
                :key="index"
              >
                {{ i }}
              </p>
            </div>
            <div style="text-align: center" v-else>
              <img src="@/assets/image/company/collecting.png" alt="" />
              <p style="font-size: 14px">相关数据整理中</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 基本信息 -->
      <div v-show="currentNav === '基本信息'" class="contentTwo flex-row">
        <div class="contentTwo-left">
          <div>
            <p class="title"><span class="sub-before"></span>公司概况</p>
            <hr style="margin: 15px auto" />
            <div class="info flex-row">
              <div>
                <p><span>法定代表人</span>{{ companyInfo.legal }}</p>
                <p><span>曾用名</span>{{ companyInfo.usedName }}</p>
                <p><span>成立日期</span>{{ companyInfo.establishmentDate }}</p>
                <p>
                  <span>营业期限</span>{{ companyInfo.startDate }} -
                  {{ companyInfo.closeDate }}
                </p>
                <p><span>经营状态</span>{{ companyInfo.regStatus }}</p>
                <p><span>注册资本</span>{{ companyInfo.regCapital }}</p>
                <p><span>人员规模</span>{{ companyInfo.employeesNum }}</p>
              </div>
              <div>
                <p><span>统一社会信用代码</span>{{ companyInfo.creditCode }}</p>
                <p><span>组织机构代码证</span>{{ companyInfo.creditCode }}</p>
                <p><span>注册号</span>{{ companyInfo.applicationNum }}</p>
                <p><span>行业</span>{{ companyInfo.industryName }}</p>
                <p><span>公司类型</span>{{ companyInfo.companyTypeName }}</p>
                <p><span>实缴资本</span>{{ companyInfo.actCapital }}</p>
                <p><span>区域</span>{{ companyInfo.province }}</p>
              </div>
            </div>
            <p style="font-size: 16px">
              <span style="color: #999">注册地址</span>&emsp;&emsp;{{
                companyInfo.regAddr
              }}
            </p>
            <p style="font-size: 16px; margin-top: 10px; line-height: 28px">
              <span style="color: #999">经营范围</span>&emsp;&emsp;{{
                companyInfo.businessMajor
              }}
            </p>
          </div>
          <div v-if="shareholder && shareholder.length">
            <p class="title"><span class="sub-before"></span>股东信息</p>
            <hr style="margin: 15px auto" />
            <el-table :data="shareholder" style="width: 100%">
              <el-table-column type="index" width="100"> </el-table-column>
              <el-table-column prop="shName" label="股东"> </el-table-column>
              <el-table-column prop="investSum" label="投资额(万元)">
              </el-table-column>
              <el-table-column prop="equityRatio" label="出资比例(%)">
              </el-table-column>
              <el-table-column prop="investTime" label="认缴出资日期">
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change="handleHolderChange"
              layout="prev, pager, next"
              :total="shareholderTotal"
            >
            </el-pagination>
          </div>
          <div v-if="major && major.length">
            <p class="title"><span class="sub-before"></span>主要人员</p>
            <hr style="margin: 15px auto" />
            <div class="flex-row major">
              <div v-for="(i, index) in major" :key="index" class="flex-row">
                <div
                  class="logo"
                  :style="
                    !i.leaderPic
                      ? 'background:' +
                        moreColors[Math.floor(Math.random() * 4)]
                      : ''
                  "
                >
                  <img v-if="i.leaderPic" :src="i.leaderPic" />
                  <span
                    style="
                      vertical-align: top;
                      font-size: 14px;
                      display: inline-block;
                      color: white;
                      font-size: 24px;
                    "
                    v-else
                    >{{ i.leaderName.slice(0, 1) }}</span
                  >
                </div>
                <div class="member flex-col">
                  <p>{{ i.leaderName }}</p>
                  <p>{{ i.postName }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="controller && controller.length">
            <p class="title"><span class="sub-before"></span>疑似控制人</p>
            <hr style="margin: 15px auto" />
            <div class="controller-head">
              <span>序号</span><span>实际控制人</span>
              <div>实际控制人链路</div>
            </div>
            <div
              class="controller-content flex-row"
              v-for="(i, index) in controller"
              :key="index"
            >
              <span>{{ index + 1 }}</span
              ><span>{{ i.entityName }}</span>
              <div class="flex-row">
                <div class="path" v-for="(j, idx) in i.path" :key="idx">
                  <p v-if="idx % 2 === 0">{{ j }}</p>
                  <span v-else>{{ j }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="shareholderDate && shareholderDate.length">
            <p class="title"><span class="sub-before"></span>公告披露股东</p>
            <hr style="margin: 15px auto" />
            <p class="shareholderDate">
              <span
                @click="chooseDate(index)"
                :class="currentDate === index ? 'active' : ''"
                v-for="(i, index) in shareholderDate"
                :key="index"
                >{{ i }}</span
              >
            </p>
            <el-table :data="disclosureShareholders" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="shName" width="300" label="股东名称">
              </el-table-column>
              <el-table-column prop="shType" label="股份类型">
              </el-table-column>
              <el-table-column prop="holderNum" label="持股数（万股）">
              </el-table-column>
              <el-table-column prop="holderNumRatio" label="持股比例">
              </el-table-column>
              <el-table-column prop="change1" label="持股变化（万股）">
              </el-table-column>
              <el-table-column prop="changeRatio" label="增减比例">
              </el-table-column>
            </el-table>
          </div>
          <div v-if="executive && executive.length">
            <p class="title"><span class="sub-before"></span>公告披露高管</p>
            <hr style="margin: 15px auto" />
            <el-table
              :data="
                executive.slice((executivePage - 1) * 10, executivePage * 10)
              "
              style="width: 100%"
            >
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="name" label="姓名"> </el-table-column>
              <el-table-column prop="gender" label="性别"> </el-table-column>
              <el-table-column prop="position" label="职务"> </el-table-column>
              <el-table-column prop="education" label="学历"> </el-table-column>
              <el-table-column prop="age" label="年龄"> </el-table-column>
              <el-table-column prop="salary" label="薪酬(万元)">
              </el-table-column>
              <el-table-column prop="holderNum" label="持股数(万股)">
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change="handleExecutiveChange"
              layout="prev, pager, next"
              :total="executive.length"
            >
            </el-pagination>
          </div>
          <div v-if="executive && executive.length">
            <p class="title"><span class="sub-before"></span>核心团队</p>
            <hr style="margin: 15px auto" />
            <el-table
              :data="executive.slice((corePage - 1) * 10, corePage * 10)"
              style="width: 100%"
            >
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="name" label="姓名"> </el-table-column>
              <el-table-column prop="position" label="职位"> </el-table-column>
              <!-- <el-table-column prop="professional" label="职称">
                </el-table-column> -->
              <el-table-column prop="education" label="学历"> </el-table-column>
              <el-table-column prop="duration" label="任职日期">
              </el-table-column>
              <el-table-column prop="date" label="出生年月"> </el-table-column>
              <el-table-column
                show-overflow-tooltip
                prop="profile"
                label="简介"
              >
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change="handleCoreChange"
              layout="prev, pager, next"
              :total="executive.length"
            >
            </el-pagination>
          </div>
          <!-- <div v-if="investment.length">
              <p class="title">
                <span class="sub-before"></span>对外投资<span class="num">{{ investmentTotal }}</span>
              </p>
              <hr style="margin:15px auto" />
              <el-table
                :data="investment"
                style="width: 100%"
              >
                <el-table-column type="index" width="40"> </el-table-column>
                <el-table-column prop="relatedPartyName" label="被投资公司名称">
                </el-table-column>
                <el-table-column prop="relatedCompanyType" label="公司类型">
                </el-table-column>
                <el-table-column prop="relatedType" label="关联类型">
                </el-table-column>
                <el-table-column prop="legalPersonRepr" label="法定代表人">
                </el-table-column>
                <el-table-column prop="regCapital" label="注册资本">
                </el-table-column>
                <el-table-column prop="linkDetails" label="查看链路">
                </el-table-column>
              </el-table>
              <el-pagination
                @current-change="handleInvestChange"
                layout="prev, pager, next"
                :total="investmentTotal"
              >
              </el-pagination>
            </div> -->
          <div v-if="directInvestment && directInvestment.length">
            <p class="title">
              <span class="sub-before"></span>直接对外投资<span class="num">{{
                directInvestmentTotal
              }}</span>
            </p>
            <hr style="margin: 15px auto" />
            <el-table :data="directInvestment" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="name" label="被投资公司名称">
              </el-table-column>
              <el-table-column prop="legalPersonName" label="法定代表人">
              </el-table-column>
              <el-table-column prop="amount" label="投资数额(万元)">
              </el-table-column>
              <el-table-column prop="percent" label="投资比例(%)">
              </el-table-column>
              <el-table-column prop="regStatus" label="经营状态">
                <template slot-scope="scope">
                  <span
                    style="color: green; width: 100px"
                    v-if="
                      scope.row.regStatus === '存续' ||
                      scope.row.regStatus === '在业' ||
                      scope.row.regStatus === '在营' ||
                      scope.row.regStatus === '开业'
                    "
                    >{{ scope.row.regStatus }}</span
                  >
                  <span style="color: red; width: 100px" v-else>{{
                    scope.row.regStatus
                  }}</span>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change="handleDirectInvestChange"
              layout="prev, pager, next"
              :total="directInvestmentTotal"
            >
            </el-pagination>
          </div>
          <div v-if="record && record.length">
            <p class="title">
              <span class="sub-before"></span>变更记录<span class="num">{{
                recordTotal
              }}</span>
            </p>
            <hr style="margin: 15px auto" />
            <el-table
              :data="record"
              style="width: 100%"
              :cell-style="cellStyle"
            >
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="changeDate" label="变更日期">
              </el-table-column>
              <el-table-column prop="changeNote" label="变更项目">
              </el-table-column>
              <el-table-column
                prop="beforeChange"
                show-overflow-tooltip
                label="变更前"
              >
              </el-table-column>
              <el-table-column
                prop="afterChange"
                show-overflow-tooltip
                label="变更后"
              >
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change="handleChangeChange"
              layout="prev, pager, next"
              :total="recordTotal"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <!-- 重点关注 -->
      <div v-show="currentNav === '重点关注'" class="contentThree flex-row">
        <div class="contentThree-left">
          <div v-if="courtNotice.length">
            <p class="title">
              <span class="sub-before"></span>法院公告<span class="num">{{
                courtNoticeTotal
              }}</span>
            </p>
            <hr style="margin: 15px auto" />
            <el-table :data="courtNotice" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column
                prop="partyName"
                show-overflow-tooltip
                label="当事人"
              >
              </el-table-column>
              <el-table-column prop="announcementType" label="公告类型">
              </el-table-column>
              <el-table-column prop="courtName" label="公告法庭">
              </el-table-column>
              <el-table-column prop="publDate" label="公示日期">
              </el-table-column>
              <!-- <el-table-column label="详情" width="60">
                  <template slot-scope="scope">
                    <el-button
                      @click="showDetail(scope.row)"
                      type="text"
                      size="small"
                      >详情</el-button
                    >
                  </template>
                </el-table-column> -->
            </el-table>
            <el-pagination
              @current-change="handleCourtChange"
              layout="prev, pager, next"
              :total="courtNoticeTotal"
            >
            </el-pagination>
          </div>
          <div v-if="holdCourtNotice.length">
            <p class="title">
              <span class="sub-before"></span>开庭公告<span class="num">{{
                holdCourtNoticeTotal
              }}</span>
            </p>
            <hr style="margin: 15px auto" />
            <el-table :data="holdCourtNotice" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <!-- <el-table-column prop="caseNumber" label="案号"> </el-table-column> -->
              <el-table-column prop="lawfulDay" label="开庭时间">
              </el-table-column>
              <el-table-column
                prop="plaintiff"
                show-overflow-tooltip
                label="上诉人"
              >
              </el-table-column>
              <el-table-column
                prop="defendant"
                show-overflow-tooltip
                label="被上诉人"
              >
              </el-table-column>
              <el-table-column prop="subjectMatter" label="案由">
              </el-table-column>
              <!-- <el-table-column label="详情" width="60">
                  <template slot-scope="scope">
                    <el-button
                      @click="showDetail(scope.row)"
                      type="text"
                      size="small"
                      >详情</el-button
                    >
                  </template>
                </el-table-column> -->
            </el-table>
            <el-pagination
              @current-change="handleSessionChange"
              layout="prev, pager, next"
              :total="holdCourtNoticeTotal"
            >
            </el-pagination>
          </div>
          <div v-if="courtInformation.length">
            <p class="title">
              <span class="sub-before"></span>庭审信息<span class="num">{{
                courtInformationTotal
              }}</span>
            </p>
            <hr style="margin: 15px auto" />
            <el-table :data="courtInformation" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="caseNumber" label="案号">
              </el-table-column>
              <el-table-column
                prop="plaintiff"
                show-overflow-tooltip
                label="上诉人(原告)"
              >
              </el-table-column>
              <el-table-column
                prop="defendant"
                show-overflow-tooltip
                label="被上诉人(被告)"
              >
              </el-table-column>
              <el-table-column prop="courtName" label="庭审地点">
              </el-table-column>
              <el-table-column prop="subjectMatter" label="案由">
              </el-table-column>
              <el-table-column prop="lawfulDay" label="开庭时间">
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change="handleInformationChange"
              layout="prev, pager, next"
              :total="courtInformationTotal"
            >
            </el-pagination>
          </div>
          <div v-if="executed.length">
            <p class="title">
              <span class="sub-before"></span>失信被执行人<span class="num">{{
                executedTotal
              }}</span>
            </p>
            <hr style="margin: 15px auto" />
            <el-table :data="executed" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="caseNumber" label="案号">
              </el-table-column>
              <el-table-column prop="courtName" label="执行法院">
              </el-table-column>
              <el-table-column prop="performance" label="履行情况">
              </el-table-column>
              <el-table-column prop="executeNumber" label="执行依据文号">
              </el-table-column>
              <el-table-column prop="filingTime" label="立案时间">
              </el-table-column>
              <el-table-column prop="infoPublDate" label="发布时间">
              </el-table-column>
              <!-- <el-table-column label="详情" width="60">
                  <template slot-scope="scope">
                    <el-button
                      @click="showDetail(scope.row)"
                      type="text"
                      size="small"
                      >详情</el-button
                    >
                  </template>
                </el-table-column> -->
            </el-table>
            <el-pagination
              @current-change="handleExecutedChange"
              layout="prev, pager, next"
              :total="executedTotal"
            >
            </el-pagination>
          </div>
          <div v-if="sanction.length">
            <p class="title">
              <span class="sub-before"></span>行政处罚<span class="num">{{
                sanctionTotal
              }}</span>
            </p>
            <hr style="margin: 15px auto" />
            <el-table :data="sanction" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="adminPenalCode" label="决定文书号">
              </el-table-column>
              <el-table-column prop="illegalType" label="违法行为类型">
              </el-table-column>
              <el-table-column
                prop="adminPenalContent"
                show-overflow-tooltip
                label="处罚内容"
              >
              </el-table-column>
              <el-table-column prop="adminPenalJudgeOrg" label="决定机关">
              </el-table-column>
              <el-table-column prop="adminPenalDate" label="决定时间">
              </el-table-column>
              <!-- <el-table-column label="详情" width="60">
                  <template slot-scope="scope">
                    <el-button
                      @click="showDetail(scope.row)"
                      type="text"
                      size="small"
                      >详情</el-button
                    >
                  </template>
                </el-table-column> -->
            </el-table>
            <el-pagination
              @current-change="handleSanctionChange"
              layout="prev, pager, next"
              :total="sanctionTotal"
            >
            </el-pagination>
          </div>
          <div v-if="litigation.length">
            <p class="title">
              <span class="sub-before"></span>法律诉讼<span class="num">{{
                litigationTotal
              }}</span>
            </p>
            <hr style="margin: 15px auto" />
            <el-table :data="litigation" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="refereeDate" label="日期">
              </el-table-column>
              <el-table-column prop="docType" label="文书类型">
              </el-table-column>
              <el-table-column prop="subjectMatter" label="案由">
              </el-table-column>
              <el-table-column prop="caseType" label="案件身份">
              </el-table-column>
              <!-- <el-table-column label="详情" width="60">
                  <template slot-scope="scope">
                    <el-button
                      @click="showDetail(scope.row)"
                      type="text"
                      size="small"
                      >详情</el-button
                    >
                  </template>
                </el-table-column> -->
            </el-table>
            <el-pagination
              @current-change="handleLawsuitChange"
              layout="prev, pager, next"
              :total="litigationTotal"
            >
            </el-pagination>
          </div>
          <div v-if="executee.length">
            <p class="title">
              <span class="sub-before"></span>被执行人信息<span class="num">{{
                executeeTotal
              }}</span>
            </p>
            <hr style="margin: 15px auto" />
            <el-table :data="executee" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="caseNumber" label="案号">
              </el-table-column>
              <el-table-column prop="courtName" label="执行法院">
              </el-table-column>
              <el-table-column prop="executeTarget" label="执行标的">
              </el-table-column>
              <el-table-column prop="filingTime" label="日期">
              </el-table-column>
              <!-- <el-table-column label="详情" width="60">
                  <template slot-scope="scope">
                    <el-button
                      @click="showDetail(scope.row)"
                      type="text"
                      size="small"
                      >详情</el-button
                    >
                  </template>
                </el-table-column> -->
            </el-table>
            <el-pagination
              @current-change="handleExecuteeChange"
              layout="prev, pager, next"
              :total="executeeTotal"
            >
            </el-pagination>
          </div>
          <div
            v-if="
              !courtNotice.length &&
              !holdCourtNotice.length &&
              !courtInformation.length &&
              !executed.length &&
              !sanction.length &&
              !litigation.length &&
              !executee.length
            "
            class="flex-col"
            style="
              justify-content: center;
              align-items: center;
              height: 58vh;
              font-size: 16px;
            "
          >
            <img src="@/assets/image/no-data.png" alt="" />
            <p>暂无相关数据</p>
          </div>
        </div>
      </div>
      <!-- 知识产权 -->
      <div v-show="currentNav === '知识产权'" class="contentFour flex-row">
        <div class="contentFour-left">
          <div v-if="patent.length">
            <p class="title">
              <span class="sub-before"></span>专利<span class="num">{{
                patentTotal
              }}</span>
            </p>
            <hr style="margin: 15px auto" />
            <el-table :data="patent" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="title" label="专利名称"> </el-table-column>
              <el-table-column prop="pubNumber" label="申请公布号">
              </el-table-column>
              <el-table-column prop="patType" label="专利类型">
              </el-table-column>
              <el-table-column prop="lprs" label="法律状态"> </el-table-column>
              <el-table-column prop="pubDate" label="申请公布日">
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change="handlePatentChange"
              layout="prev, pager, next"
              :total="patentTotal"
            >
            </el-pagination>
          </div>
          <div v-if="trademark.length">
            <p class="title">
              <span class="sub-before"></span>商标<span class="num">{{
                trademarkTotal
              }}</span>
            </p>
            <hr style="margin: 15px auto" />
            <el-table :data="trademark" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="img" label="商标图片">
                <template slot-scope="scope">
                  <el-popover placement="right" trigger="hover">
                    <img :src="scope.row.tmPic" style="max-width: 500px" />
                    <img
                      slot="reference"
                      :src="scope.row.tmPic"
                      style="width: 50px; height: 50px"
                    />
                  </el-popover>
                </template>
              </el-table-column>
              <el-table-column prop="tmName" label="商标名称">
              </el-table-column>
              <!-- <el-table-column prop="regNo" label="注册号"> </el-table-column> -->
              <el-table-column prop="status" label="状态"> </el-table-column>
              <el-table-column prop="intCls" label="分类"> </el-table-column>
              <el-table-column prop="appDate" label="申请日期">
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change="handleTrademarksChange"
              layout="prev, pager, next"
              :total="trademarkTotal"
            >
            </el-pagination>
          </div>
          <div v-if="copyright.length">
            <p class="title">
              <span class="sub-before"></span>作品著作权<span class="num">{{
                copyrightTotal
              }}</span>
            </p>
            <hr style="margin: 15px auto" />
            <el-table :data="copyright" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="name" label="著作名称"> </el-table-column>
              <el-table-column prop="regNum" label="登记号"> </el-table-column>
              <el-table-column prop="type" label="登记类别"> </el-table-column>
              <el-table-column prop="finishTime" label="创作完成日期">
              </el-table-column>
              <el-table-column prop="firstPublishTime" label="首次发布日期">
              </el-table-column>
              <el-table-column prop="regTime" label="登记日期">
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change="handleCopyrightChange"
              layout="prev, pager, next"
              :total="copyrightTotal"
            >
            </el-pagination>
          </div>
          <div v-if="certificate.length">
            <p class="title">
              <span class="sub-before"></span>资质证书<span class="num">{{
                certificateTotal
              }}</span>
            </p>
            <hr style="margin: 15px auto" />
            <el-table :data="certificate" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="certificatesName" label="证书名称">
              </el-table-column>
              <el-table-column prop="certificatesNo" label="证书编号">
              </el-table-column>
              <el-table-column prop="certificatesType" label="证书类型">
              </el-table-column>
              <el-table-column prop="issuesUnit" label="发证机构">
              </el-table-column>
              <el-table-column prop="validStart" label="发证日期">
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change="handleCertificateChange"
              layout="prev, pager, next"
              :total="certificateTotal"
            >
            </el-pagination>
          </div>
          <div v-if="website.length">
            <p class="title">
              <span class="sub-before"></span>网站<span class="num">{{
                websiteTotal
              }}</span>
            </p>
            <hr style="margin: 15px auto" />
            <el-table :data="website" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="webName" label="网站名称">
              </el-table-column>

              <el-table-column prop="siteUrl" label="网址">
                <template slot-scope="scope">
                  <el-button
                    @click="toWebsite(scope.row.siteUrl)"
                    type="text"
                    size="small"
                    >{{ scope.row.siteUrl }}</el-button
                  >
                </template>
              </el-table-column>
              <el-table-column prop="icpNumber" label="网站备案/许可证号">
              </el-table-column>
              <el-table-column prop="reviewTime" label="审核日期">
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change="handleWebsiteChange"
              layout="prev, pager, next"
              :total="websiteTotal"
            >
            </el-pagination>
          </div>
          <div v-if="software.length">
            <p class="title">
              <span class="sub-before"></span>软件著作权<span class="num">{{
                softwareTotal
              }}</span>
            </p>
            <hr style="margin: 15px auto" />
            <el-table :data="software" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="regName" label="软件名称">
              </el-table-column>
              <el-table-column prop="regNum" label="登记号"> </el-table-column>
              <el-table-column prop="regAbb" label="软件简称">
              </el-table-column>
              <el-table-column prop="version" label="版本号"> </el-table-column>
              <el-table-column prop="publishTime" label="登记批准日期">
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change="handleSoftwareChange"
              layout="prev, pager, next"
              :total="softwareTotal"
            >
            </el-pagination>
          </div>
          <div
            v-if="
              !patent.length &&
              !trademark.length &&
              !copyright.length &&
              !certificate.length &&
              !website.length &&
              !software.length
            "
            class="flex-col"
            style="
              justify-content: center;
              align-items: center;
              height: 58vh;
              font-size: 16px;
            "
          >
            <img src="@/assets/image/no-data.png" alt="" />
            <p>暂无相关数据</p>
          </div>
        </div>
      </div>
      <!-- 经营状况 -->
      <div v-show="currentNav === '经营状况'" class="contentFive flex-row">
        <div class="contentFive-left">
          <div v-if="financing.length">
            <p class="title">
              <span class="sub-before"></span>融资记录<span class="num">{{
                financingTotal
              }}</span>
            </p>
            <hr style="margin: 15px auto" />
            <el-table :data="financing" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="round" label="轮次"> </el-table-column>
              <el-table-column prop="reportDate" label="披露时间">
              </el-table-column>
              <el-table-column prop="inverstors" label="投资方">
              </el-table-column>
              <el-table-column prop="amount" label="交易金额">
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change="handleFinancingChange"
              layout="prev, pager, next"
              :total="financingTotal"
            >
            </el-pagination>
          </div>
          <div v-if="supplyChain.length">
            <p class="title">
              <span class="sub-before"></span>供应链信息<span class="num">{{
                supplyChainTotal
              }}</span>
            </p>
            <hr style="margin: 15px auto" />
            <el-table :data="supplyChain" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="year" label="年度"> </el-table-column>
              <el-table-column prop="scRelationship" label="供应链关系">
              </el-table-column>
              <el-table-column prop="scChiName" label="公司名称">
              </el-table-column>
              <el-table-column prop="scType" label="公司类型">
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change="handleSupplyChainChange"
              layout="prev, pager, next"
              :total="supplyChainTotal"
            >
            </el-pagination>
          </div>
          <div v-if="buildProject.length">
            <p class="title">
              <span class="sub-before"></span>重大在建项目<span class="num">{{
                buildProjectTotal
              }}</span>
            </p>
            <hr style="margin: 15px auto" />
            <el-table :data="buildProject" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="projectName" label="项目名称">
              </el-table-column>
              <el-table-column prop="progress" label="进展阶段">
              </el-table-column>
              <el-table-column prop="amount" label="总投资额(万元)">
              </el-table-column>
              <el-table-column prop="state" label="地区"> </el-table-column>
              <el-table-column prop="infoPublDate" label="发布日期">
              </el-table-column>
              <!-- <el-table-column label="详情" width="60">
                  <template slot-scope="scope">
                    <el-button
                      @click="showDetail(scope.row)"
                      type="text"
                      size="small"
                      >详情</el-button
                    >
                  </template>
                </el-table-column> -->
            </el-table>
            <el-pagination
              @current-change="handleBuildProjectChange"
              layout="prev, pager, next"
              :total="buildProjectTotal"
            >
            </el-pagination>
          </div>
          <div v-if="investProject.length">
            <p class="title">
              <span class="sub-before"></span>重大投资项目<span class="num">{{
                investProjectTotal
              }}</span>
            </p>
            <hr style="margin: 15px auto" />
            <el-table :data="investProject" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="projectName" label="项目名称">
              </el-table-column>
              <el-table-column prop="declaringUnit" label="申报单位或法人单位">
              </el-table-column>
              <el-table-column prop="approvalItems" label="审批事项">
              </el-table-column>
              <el-table-column prop="approvalResult" label="审批结果">
              </el-table-column>
              <el-table-column prop="approvalTime" label="审批日期">
              </el-table-column>
              <!-- <el-table-column label="详情" width="60">
                  <template slot-scope="scope">
                    <el-button
                      @click="showDetail(scope.row)"
                      type="text"
                      size="small"
                      >详情</el-button
                    >
                  </template>
                </el-table-column> -->
            </el-table>
            <el-pagination
              @current-change="handleInvestProjectChange"
              layout="prev, pager, next"
              :total="investProjectTotal"
            >
            </el-pagination>
          </div>
          <div v-if="licence.length">
            <p class="title">
              <span class="sub-before"></span>行政许可<span class="num">{{
                licenceTotal
              }}</span>
            </p>
            <hr style="margin: 15px auto" />
            <el-table :data="licence" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="name" label="许可文件名称">
              </el-table-column>
              <el-table-column prop="number" label="许可书文编号">
              </el-table-column>
              <el-table-column prop="startDate" label="有效期自">
              </el-table-column>
              <el-table-column prop="endDate" label="有效期至">
              </el-table-column>
              <el-table-column prop="department" label="许可机关">
              </el-table-column>
              <!-- <el-table-column label="详情" width="60">
                  <template slot-scope="scope">
                    <el-button
                      @click="showDetail(scope.row)"
                      type="text"
                      size="small"
                      >详情</el-button
                    >
                  </template>
                </el-table-column> -->
            </el-table>
            <el-pagination
              @current-change="handleLicenceChange"
              layout="prev, pager, next"
              :total="licenceTotal"
            >
            </el-pagination>
          </div>
          <div v-if="product.length">
            <p class="title">
              <span class="sub-before"></span>产品信息<span class="num">{{
                productTotal
              }}</span>
            </p>
            <hr style="margin: 15px auto" />
            <el-table :data="product" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="logo" label="商标图片">
                <template slot-scope="scope">
                  <div
                    class="logo"
                    :style="
                      !scope.row.logo
                        ? 'background:' +
                          moreColors[Math.floor(Math.random() * 4)]
                        : ''
                    "
                  >
                    <img v-if="scope.row.logo" :src="scope.row.logo" />
                    <span
                      style="
                        vertical-align: top;
                        font-size: 34px;
                        display: inline-block;
                        color: white;
                        font-size: 24px;
                      "
                      v-else-if="scope.row.productName"
                      >{{ scope.row.productName.slice(0, 1) }}</span
                    >
                  </div>
                  <!-- <img
                      slot="reference"
                      :src="scope.row.logo"
                      style="width: 50px;height: 50px;object-fit:contain"
                    /> -->
                </template>
              </el-table-column>
              <el-table-column prop="productName" label="产品名称">
              </el-table-column>
              <!-- <el-table-column
                  prop="productAbbr"
                  label="产品简称"
                ></el-table-column> -->
              <!-- <el-table-column
                  prop="productType"
                  label="产品分类"
                ></el-table-column> -->
              <el-table-column prop="domain" label="领域"> </el-table-column>
              <el-table-column prop="yewu" label="业务"> </el-table-column>
              <!-- <el-table-column label="详情" width="60">
                  <template slot-scope="scope">
                    <el-button
                      @click="showDetail(scope.row)"
                      type="text"
                      size="small"
                      >详情</el-button
                    >
                  </template>
                </el-table-column> -->
            </el-table>
            <el-pagination
              @current-change="handleProductChange"
              layout="prev, pager, next"
              :total="productTotal"
            >
            </el-pagination>
          </div>
          <div v-if="competitor.length">
            <p class="title">
              <span class="sub-before"></span>竞品信息<span class="num">{{
                competitorTotal
              }}</span>
            </p>
            <hr style="margin: 15px auto" />
            <el-table :data="competitor" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="icon" label="商标图片">
                <template slot-scope="scope">
                  <div
                    class="logo"
                    :style="
                      !scope.row.icon
                        ? 'background:' +
                          moreColors[Math.floor(Math.random() * 4)]
                        : ''
                    "
                  >
                    <img v-if="scope.row.icon" :src="scope.row.icon" />
                    <span
                      style="
                        vertical-align: top;
                        font-size: 34px;
                        display: inline-block;
                        color: white;
                        font-size: 24px;
                      "
                      v-else-if="scope.row.productName"
                      >{{ scope.row.productName.slice(0, 1) }}</span
                    >
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="productName" label="产品名称">
              </el-table-column>
              <!-- <el-table-column
                  prop="productAbbr"
                  label="产品简称"
                ></el-table-column> -->
              <el-table-column
                prop="competCompany"
                label="所属公司"
              ></el-table-column>
              <!-- <el-table-column label="详情" width="60">
                  <template slot-scope="scope">
                    <el-button
                      @click="showDetail(scope.row)"
                      type="text"
                      size="small"
                      >详情</el-button
                    >
                  </template>
                </el-table-column> -->
            </el-table>
            <el-pagination
              @current-change="handleCompetitorChange"
              layout="prev, pager, next"
              :total="competitorTotal"
            >
            </el-pagination>
          </div>
          <div
            v-if="
              !financing.length &&
              !supplyChain.length &&
              !buildProject.length &&
              !investProject.length &&
              !licence.length &&
              !product.length &&
              !competitor.length
            "
            class="flex-col"
            style="
              justify-content: center;
              align-items: center;
              height: 58vh;
              font-size: 16px;
            "
          >
            <img src="@/assets/image/no-data.png" alt="" />
            <p>暂无相关数据</p>
          </div>
        </div>
      </div>
      <!-- 数据解读 -->
      <div v-show="currentNav === '数据解读'" class="contentSeven flex-row">
        <div class="contentSeven-left">
          <div>
            <p
              v-for="(i, index) in leftNav"
              :style="
                leftList[i.value] === true || (is201 && i.value === 'relationMap')
                  ? ''
                  : 'color:#ababab;cursor:not-allowed'
              "
              :class="leftIndex === index ? 'active' : ''"
              :key="index"
              @click="dataChoose(index, i.value)"
            >
              <span>{{ i.name }}</span>
            </p>
          </div>
        </div>
        <div class="contentSeven-right" v-if="leftIndex === 0">
          <div>
            <div style="height: 600px" id="actualChart"></div>
          </div>
          <div>
            <p class="title"><span class="sub-before"></span>实际控制人链路</p>
            <hr style="margin: 15px auto" />
            <div class="controller-head">
              <span>序号</span><span>实际控制人名称</span>
              <div>实际控制人链路</div>
            </div>
            <div
              class="controller-content flex-row"
              v-for="(i, index) in controller"
              :key="index"
            >
              <span>{{ index + 1 }}</span
              ><span>{{ i.entityName }}</span>
              <div class="flex-row">
                <div class="path" v-for="(j, idx) in i.path" :key="idx">
                  <p v-if="idx % 2 === 0">{{ j }}</p>
                  <span v-else>{{ j }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="contentSeven-right" v-if="leftIndex === 1">
          <div>
            <div style="height: 600px" id="investChart"></div>
          </div>
          <div>
            <p class="title"><span class="sub-before"></span>对外下属投资方</p>
            <hr style="margin: 15px auto" />
            <el-table :data="investTable" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="relatedPartyName" label="被投资公司名称">
              </el-table-column>
              <el-table-column prop="relatedCompanyType" label="公司类型">
              </el-table-column>
              <el-table-column prop="relatedType" label="关联类型">
              </el-table-column>
              <el-table-column prop="legalPersonRepr" label="法定代表人">
              </el-table-column>
              <el-table-column prop="regCapital" label="注册资本(万元)">
              </el-table-column>
              <el-table-column prop="currency" label="币种"> </el-table-column>
            </el-table>
            <el-pagination
              @current-change="handleInvestTableChange"
              layout="prev, pager, next"
              :total="investTableTotal"
            >
            </el-pagination>
          </div>
        </div>
        <div class="contentSeven-right" v-if="leftIndex === 2">
          <div>
            <div style="height: 600px" id="chainChart"></div>
          </div>
          <div>
            <p class="title">
              <span class="sub-before"></span>供应链关系方列表
              <el-select
                size="mini"
                style="width: 100px"
                v-model="chainYear"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in chainYears"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <el-input
                size="mini"
                style="width: 200px"
                clearable
                v-model="keyword"
                placeholder="请输入关键字"
              ></el-input>
              <el-button
                @click="queryCustomer"
                type="primary"
                size="mini"
                icon="el-icon-search"
              ></el-button>
            </p>
            <hr style="margin: 15px auto" />
            <div class="relation">
              <span
                @click="relationIndex = 0"
                :class="relationIndex === 0 ? 'active' : ''"
                >供应商列表</span
              ><span
                @click="relationIndex = 1"
                :class="relationIndex === 1 ? 'active' : ''"
                >销售客户列表</span
              >
            </div>
            <el-table :data="customer" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="year" label="年度"> </el-table-column>
              <el-table-column prop="scRelationship" label="供应链关系">
              </el-table-column>
              <el-table-column prop="scChiName" label="公司名称">
              </el-table-column>
              <el-table-column prop="scType" label="公司类型">
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change="handleCustomerChange"
              layout="prev, pager, next"
              :total="investTableTotal"
            >
            </el-pagination>
          </div>
        </div>
        <div class="contentSeven-right" v-if="leftIndex === 3">
          <div class="flex-row">
            <div style="height: 600px; flex: 1" id="mapChart"></div>
            <div
              style="height: 600px; flex: 0 0 40%"
              id="mapRelationChart"
            ></div>
          </div>
          <div>
            <p class="title"><span class="sub-before"></span>分支机构</p>
            <hr style="margin: 15px auto" />
            <el-table :data="branchTable" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="name" label="分支机构名称">
              </el-table-column>
              <el-table-column prop="estiblishTime" label="成立日期">
              </el-table-column>
              <el-table-column prop="legalPersonName" label="法人">
              </el-table-column>
              <el-table-column prop="regStatus" label="经营状态">
              </el-table-column>
              <el-table-column prop="province" label="省份"> </el-table-column>
            </el-table>
            <el-pagination
              @current-change="handleBranchChange"
              layout="prev, pager, next"
              :total="branchTableTotal"
            >
            </el-pagination>
          </div>
        </div>
        <div class="contentSeven-right" v-if="leftIndex === 4">
          <div class="flex-row">
            <div style="height: 600px; flex: 1" id="lawsuitChart"></div>
            <div
              style="height: 600px; flex: 0 0 40%"
              id="lawsuitRoundChart"
            ></div>
          </div>
          <div>
            <p class="title"><span class="sub-before"></span>诉讼处罚</p>
            <hr style="margin: 15px auto" />
            <el-table :data="courtLawSuitTable" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="publicTime" label="公布时间">
              </el-table-column>
              <el-table-column prop="publicType" label="公告类型">
              </el-table-column>
              <el-table-column prop="defendants" label="被告/当事人">
              </el-table-column>
              <el-table-column prop="plaintiffs" label="原告/上诉方">
              </el-table-column>
              <el-table-column prop="caseNo" label="案号"> </el-table-column>
              <el-table-column prop="caseReason" label="案由">
              </el-table-column>
            </el-table>
            <el-pagination
              @current-change="handleLawSuitChange"
              layout="prev, pager, next"
              :total="lawSuitTableTotal"
            >
            </el-pagination>
          </div>
        </div>
        <div class="contentSeven-right" v-if="leftIndex === 5">
          <div>
            <div style="height: 600px" id="disputeChart"></div>
          </div>
          <div>
            <p class="title">
              <span class="sub-before"></span>纠纷对象前十统计
            </p>
            <hr style="margin: 15px auto" />
            <el-table :data="courtLawSuitTableTop10" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="publicTime" label="公布时间">
              </el-table-column>
              <el-table-column prop="publicType" label="公告类型">
              </el-table-column>
              <el-table-column prop="defendants" label="被告/当事人">
              </el-table-column>
              <el-table-column prop="plaintiffs" label="原告/上诉方">
              </el-table-column>
              <el-table-column prop="caseNo" label="案号"> </el-table-column>
              <el-table-column prop="caseReason" label="案由">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="contentSeven-right" v-if="leftIndex === 6">
          <div>
            <div style="height: 600px" id="briefChart"></div>
          </div>
          <div>
            <p class="title"><span class="sub-before"></span>案由前十统计</p>
            <hr style="margin: 15px auto" />
            <el-table :data="courtLawSuitTableTop10" style="width: 100%">
              <el-table-column type="index" width="40"> </el-table-column>
              <el-table-column prop="publicTime" label="公布时间">
              </el-table-column>
              <el-table-column prop="publicType" label="公告类型">
              </el-table-column>
              <el-table-column prop="defendants" label="被告/当事人">
              </el-table-column>
              <el-table-column prop="plaintiffs" label="原告/上诉方">
              </el-table-column>
              <el-table-column prop="caseNo" label="案号"> </el-table-column>
              <el-table-column prop="caseReason" label="案由">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div
          class="contentSeven-right"
          v-if="leftIndex === 7"
          v-loading="relationLoading"
          :body="true"
        >
          <div
            style="height: 66vh; overflow: hidden"
            id="relationMapChart"
          ></div>
          <img class="legend" src="@/assets/image/company/legend.png" alt="" />
          <el-tooltip
            class="item"
            effect="dark"
            content="全屏"
            placement="right"
          >
            <img
              class="full-btn"
              width="24"
              @click="openfullScreen"
              src="@/assets/image/company/big-screen.png"
              alt=""
            />
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="重置"
            placement="right"
          >
            <img
              class="reset-btn"
              width="32"
              @click="reset"
              src="@/assets/image/company/reset.png"
              alt=""
            />
          </el-tooltip>
          <div class="filter-window">
            <!-- <div class="flex-row"><span class="filter-label">投资关系：</span><div class="filter-button"><span @click="invest=''" :style="invest==''?'color:#417dff;border:1px solid #417dff':''">全部</span><span @click="invest='直接投资'" :style="invest=='直接投资'?'color:#417dff;border:1px solid #417dff':''">直接投资</span><span @click="invest='股东投资'" :style="invest=='股东投资'?'color:#417dff;border:1px solid #417dff':''">股东投资</span><span @click="invest='法人投资'" :style="invest=='法人投资'?'color:#417dff;border:1px solid #417dff':''">法人投资</span><span @click="invest='董监高投资'" :style="invest=='董监高投资'?'color:#417dff;border:1px solid #417dff':''">董监高投资</span></div></div>
                <div class="flex-row"><span class="filter-label">任职情况：</span><div class="filter-button"><span @click="post=''" :style="post==''?'color:#417dff;border:1px solid #417dff':''">全部</span><span @click="post='法人任职'" :style="post=='法人任职'?'color:#417dff;border:1px solid #417dff':''">法人任职</span><span @click="post='董监高任职'" :style="post=='董监高任职'?'color:#417dff;border:1px solid #417dff':''">董监高任职</span></div></div>
                <div class="flex-row"><span class="filter-label">持股：</span><el-slider style="width:100%" :marks="marks" @change="sliderChange" v-model="holdShares"></el-slider></div>
                <br/> -->
            <div class="flex-row">
              <el-input @input="inputChange" v-model="filterCompany"></el-input
              ><el-button
                style="flex: 0 0 70px; margin-left: 10px"
                type="primary"
                @click="focusCompany"
                >聚焦</el-button
              >
            </div>
            <div class="filter-list">
              <p
                @click="filterCompany = i"
                v-for="(i, index) in filterList"
                :key="index"
              >
                {{ index + 1 }}、{{ i }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact-bg flex-row" v-show="contactDialog">
      <div class="contact-dialog flex-col">
        <img
          @click="contactDialog = false"
          class="close"
          src="@/assets/image/close.png"
          alt=""
        />
        <p class="title"><span class="sub-before"></span>联系方式</p>
        <hr style="margin: 15px auto" />
        <div
          v-if="
            unlockInfo.fixedPhone -
              0 +
              (unlockInfo.mobile - 0) +
              (unlockInfo.email - 0) >
            0
          "
        >
          <p class="contact-company">{{ companyInfo.companyName }}</p>
          <p class="contact-way">
            联系方式共{{
              unlockInfo.fixedPhone -
              0 +
              (unlockInfo.mobile - 0) +
              (unlockInfo.email - 0)
            }}条
          </p>
          <div class="flex-row contact-type">
            <div class="flex-col">
              <img src="@/assets/image/company/contact-phone.png" alt="" />
              <p>电话{{ unlockInfo.fixedPhone }}</p>
            </div>
            <div class="flex-col">
              <img src="@/assets/image/company/contact-mobile.png" alt="" />
              <p>手机{{ unlockInfo.mobile }}</p>
            </div>
            <div class="flex-col">
              <img src="@/assets/image/company/contact-email.png" alt="" />
              <p>邮箱{{ unlockInfo.email }}</p>
            </div>
          </div>
          <div class="qrcode">
            <img src="@/assets/image/company/yuanshang.jpg" alt="" />
          </div>
          <p class="scan">微信扫一扫查看联系方式</p>
        </div>
        <div v-else class="flex-col no-way">
          <img width="250" src="@/assets/image/company/no-way.png" alt="" />
          <br />
          <p>暂无相关联系方式</p>
        </div>
      </div>
    </div>
    <div class="full-screen" v-show="fullScreen" v-loading="bigRelationLoading">
      <el-tooltip class="item" effect="dark" content="重置" placement="right">
        <img
          class="reset-btn"
          width="32"
          @click="reset"
          src="@/assets/image/company/reset.png"
          alt=""
        />
      </el-tooltip>
      <img @click="closeFullScreen" src="@/assets/image/close.png" alt="" />
      <img class="legend" src="@/assets/image/company/legend.png" alt="" />
      <div class="filter-window">
        <div class="flex-row">
          <el-input @input="inputChange" v-model="filterCompany"></el-input
          ><el-button
            style="flex: 0 0 70px; margin-left: 10px"
            type="primary"
            @click="focusCompany"
            >聚焦</el-button
          >
        </div>
        <div class="filter-list">
          <p
            @click="filterCompany = i"
            v-for="(i, index) in filterList"
            :key="index"
          >
            {{ index + 1 }}、{{ i }}
          </p>
        </div>
      </div>
      <div id="bigRelationMapChart" style="height: 100vh"></div>
    </div>
  </div>
</template>
<script>
import '@/assets/css/company.css'
// import "@/assets/js/go.js";
// import "@/assets/js/double.js";
let relationChart = null
let bigRelationChart = null
export default {
  name: 'CompanyInfo',
  props: {
    companyCode: Number
  },
  data () {
    return {
      moreColors: ['#7db3d9', '#bc9edd', '#84cab0', '#d1ae9d'],
      loading: false,
      fullDialog: false,
      contactDialog: false,
      companyInfo: {},
      security: {},
      advantage: [],
      intellectualPropertyVo: {},
      summary: {},
      tags: {},
      banner: {},
      honorlist: false,
      coreAdv: false,
      navList: [
        { name: '企业概览', img: 'survey' },
        { name: '个股概况', img: 'shares' },
        { name: '基本信息', img: 'info' },
        { name: '重点关注', img: 'follow' },
        { name: '知识产权', img: 'science' },
        { name: '经营状况', img: 'operation' },
        { name: '数据解读', img: 'data' }
      ],
      currentNav: '企业概览',
      stockDetail: {}, // A股详情
      stockDetail2: {}, // 三板详情
      stockList: ['分时', '日K线', '周K线', '月K线'],
      stockData: [],
      currentStock: 0,
      moreAdv: true,
      updown: 'down',
      upStream: [],
      downStream: [],
      // 高管详情弹窗
      executiveDetailDialog: false,
      executiveDetail: {},
      more: true,
      lock: 0,
      unlockInfo: {},
      // 头部右侧联系企业
      phone: [],
      phoneIdList: [],
      people: ['高管人员', '对外投资', '股东', '投资机构'],
      shareholderList: [{ name: '股东', children: [] }],
      outboundInvestment: [{ name: '对外投资', children: [] }],
      manager: [{ name: '高管人员', children: [] }],
      relation: [],
      //   同类公司
      ability: 0,
      targets: ['成长能力', '盈利能力', '偿债能力', '营运能力', '现金流能力'],
      similarCompanyVoList: [],
      //   高管人员/公告披露高管/核心团队
      targetNum: [],
      reportDate: '',
      areaNum: [],
      areaAllData: [],
      executive: [],
      executivePage: 1,
      corePage: 1,
      //   股东信息
      shareholder: [],
      shareholderTotal: 0,
      shareholderPage: 1,
      //   主要人员
      major: [],
      // 疑似控制人
      controller: [],
      //   公告披露股东-日期选择
      shareholderDate: [],
      currentDate: 0,
      //   公告披露股东
      disclosureShareholders: [],
      allShareholders: [],
      //   对外投资
      investment: [],
      investmentTotal: 0,
      investmentPage: 1,
      // 直接对外投资
      directInvestment: [],
      directInvestmentTotal: 0,
      directInvestmentPage: 1,
      //   变更记录
      record: [],
      recordTotal: 0,
      recordPage: 1,
      //   法院公告
      courtNotice: [],
      courtNoticeTotal: 0,
      courtNoticePage: 1,
      //   开庭公告
      holdCourtNotice: [],
      holdCourtNoticeTotal: 0,
      holdcourtNoticePage: 1,
      //   庭审信息
      courtInformation: [],
      courtInformationTotal: 0,
      courtInformationPage: 1,
      //   失信被执行人
      executed: [],
      executedTotal: 0,
      executedPage: 1,
      //   行政处罚
      sanction: [],
      sanctionTotal: 0,
      sanctionPage: 1,
      //   法律诉讼
      litigation: [],
      litigationTotal: 0,
      litigationPage: 1,
      //   被执行人信息
      executee: [],
      executeeTotal: 0,
      executeePage: 1,
      //   专利
      patent: [],
      patentTotal: 0,
      patentPage: 1,
      //   商标
      trademark: [],
      trademarkTotal: 0,
      trademarksPage: 1,
      //   作品著作权
      copyright: [],
      copyrightTotal: 0,
      copyrightPage: 1,
      //   资质证书
      certificate: [],
      certificateTotal: 0,
      certificatePage: 1,
      //   网站
      website: [],
      websiteTotal: 0,
      websitePage: 1,
      // 软件著作权
      software: [],
      softwareTotal: 0,
      softwarePage: 1,
      //   融资记录
      financing: [],
      financingTotal: 0,
      financingPage: 1,
      //   供应链信息
      supplyChain: [],
      supplyChainTotal: 0,
      supplyChainPage: 1,
      //   重大在建项目
      buildProject: [],
      buildProjectTotal: 0,
      buildProjectPage: 1,
      //   重大投资项目
      investProject: [],
      investProjectTotal: 0,
      investProjectPage: 1,
      //   行政许可
      licence: [],
      licenceTotal: 0,
      licencePage: 1,
      //   产品信息
      product: [],
      productTotal: 0,
      productPage: 1,
      //   竞品信息
      competitor: [],
      competitorTotal: 0,
      competitorPage: 1,
      clickedNav: [],
      // leftNav:['总览图','公司实际控制人','股东及其实际控制人','对外下属投资方','海外投资子公司','合资合作方','股东所投其他公司','关联自然人','历史已退出关联方','借贷担保关系方','供应链关系方','股权质押关系方','法律诉讼关系方','资产租赁托管关系方','股权托管关系方','资金管理方','可能关联方'],
      leftNav: [
        { name: '公司实际控制人', value: 'actualControl' },
        { name: '对外下属投资方', value: 'equityPenetrate' },
        { name: '供应链关系方', value: 'supplyChain' },
        { name: '分支机构', value: 'branch' },
        { name: '诉讼处罚', value: 'courtLawSuit' },
        { name: '纠纷对象前十统计', value: 'courtLawSuit' },
        { name: '案由前十统计', value: 'courtLawSuit' },
        { name: '关系图谱', value: 'relationMap' }
      ],
      leftIndex: -1,
      relationIndex: 0,
      investTable: [], // 对外下属投资方
      investTableTotal: 0,
      investTablePage: 1,
      customer: [],
      customerTotal: 0,
      customerPage: 1,
      chainYears: [],
      supplyYears: [],
      saleYears: [],
      chainYear: '',
      keyword: '',
      relationType: '供应',
      courtLawSuit: {}, // 诉讼三接口图数据集合
      courtLawSuitTable: [], // 诉讼三接口表
      lawSuitTableTotal: 0,
      lawSuitPage: 1,
      courtLawSuitTableTop10: [], // 诉讼三接口表取前十条
      branchTable: [],
      branchTableTotal: 0,
      branchPage: 1,
      branchAnalysisVos: [], // 分支机构右侧全部关系
      allBranchData: [], // 所有分支机构数据
      branchMapData: [], // 分支机构地区
      provincePoint: {
        上海: [121.490317, 31.222771],
        河北: [114.502461, 38.045474],
        山西: [112.549248, 37.857014],
        内蒙古: [111.670801, 40.818311],
        辽宁: [123.429096, 41.796767],
        吉林: [125.3245, 43.886841],
        黑龙江: [126.642464, 45.756967],
        江苏: [118.767413, 32.041544],
        浙江: [120.153576, 30.287459],
        安徽: [117.283042, 31.86119],
        福建: [119.306239, 26.075302],
        江西: [115.892151, 28.676493],
        山东: [117.000923, 36.675807],
        河南: [113.665412, 34.757975],
        湖北: [114.298572, 30.584355],
        湖南: [112.982279, 28.19409],
        广东: [113.280637, 23.125178],
        广西: [108.320004, 22.82402],
        海南: [110.33119, 20.031971],
        四川: [104.080989, 30.657689],
        贵州: [106.713478, 26.578343],
        云南: [102.712251, 25.040609],
        西藏: [91.132212, 29.660361],
        陕西: [108.948024, 34.263161],
        甘肃: [103.823557, 36.058039],
        青海: [101.778916, 36.623178],
        宁夏: [106.278179, 38.46637],
        新疆: [87.617733, 43.792818],
        北京: [116.418757, 39.917544],
        天津: [117.195907, 39.118327],
        重庆: [108.380246, 30.807807],
        香港: [114.1543731, 22.28198083],
        澳门: [113.5528956, 22.20787],
        台湾: [121.509062, 25.044332]
      },
      leftList: [],
      timer: null,
      relationLoading: false,
      initRelationData: {
        // 原始数据
      },
      relationData: {}, // 处理数据
      holdShares: 0,
      invest: '',
      post: '',
      filterCompany: '',
      allList: [],
      filterList: [],
      needWatchInvest: false,
      needWatchPost: false,
      marks: {
        0: '0%',
        100: '100%'
      },
      bigRelationLoading: false,
      fullScreen: false,
      is201: true
    }
  },
  created () {
    // this.companyCode=13478
  },
  mounted () {
    this.init()
    this.getMajor()
    // this.getBaseInfo();
    // this.getOperation();
    // this.getKnowledge();
    // this.getAttention();
    this.$once('hook:beforeDestroy', () => {
      clearInterval(this.timer)
    })
  },
  watch: {
    // 关系图谱-投资关系
    invest (n, o) {
      if (n !== o) {
        this.post = ''
        this.relationData = JSON.parse(JSON.stringify(this.initRelationData))
        switch (n) {
          case '':
            this.relationMapChart()
            break
          case '直接投资':
            this.relationData.links.forEach((i) => {
              if (
                i.source !== this.companyInfo.companyName ||
                i.name !== '参股'
              ) {
                i.name = ''
                i.target = ''
                i.source = ''
              }
            })
            this.relationMapChart(this.companyInfo.companyName)
            break
          case '股东投资':
            this.handleRelationData('参股', '参股')
            break
          case '法人投资':
            this.handleRelationData('法人', '参股')
            break
          case '董监高投资':
            this.handleRelationData('董事', '参股')
            break
          default:
            break
        }
      }
    },
    // 关系图谱-任职情况
    post (n, o) {
      if (n !== o) {
        this.invest = ''
        this.relationData = JSON.parse(JSON.stringify(this.initRelationData))
        switch (n) {
          case '':
            this.relationMapChart()
            break
          case '法人任职':
            this.handleRelationData1('法人', '董事')
            break
          case '董监高任职':
            this.handleRelationData1('董事', '董事')
            break
          default:
            break
        }
      }
    },
    relationIndex (n, o) {
      if (n === 0) {
        this.chainYears = this.supplyYears
        this.chainYear = ''
        this.relationType = '供应'
        this.queryCustomer()
      } else if (n === 1) {
        this.chainYears = this.saleYears
        this.chainYear = ''
        this.relationType = '销售'
        this.queryCustomer()
      }
    },
    currentStock (n, o) {
      if (n !== o) {
        if (n === 0) {
          this.curKChart()
        } else {
          this.kChart()
        }
      }
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    // 关闭弹窗
    close () {
      this.$emit('sendMsg', 0)
    },
    // 企业图
    companyRelation () {
      const chart = this.Echarts.init(
        document.getElementById('companyRelation')
      )
      const children = JSON.parse(
        JSON.stringify(this.summary.chainRel)
          .replace(/child/g, 'children')
          .replace(/title/g, 'name')
      )
      let number = 0 // 二级行业数量（判断图高度）
      children.forEach((i, index) => {
        if (!i.name) {
          children.splice(index, 1)
        }
      })
      children.forEach((i) => {
        i.post = 1
        i.children.forEach((j) => {
          number++
          j.status = i.status
          j.level = 2
        })
      })
      const data = [
        {
          name:
            this.companyInfo.companyName.length > 12
              ? this.companyInfo.companyName.slice(
                0,
                this.companyInfo.companyName.length / 2
              ) +
                '\n' +
                this.companyInfo.companyName.slice(
                  this.companyInfo.companyName.length / 2
                )
              : this.companyInfo.companyName,
          symbolSize: 210,
          symbol:
            'image://' + require('@/assets/image/company/company-bg.png'),
          label: {
            show: true,
            position: 'inside',
            borderWidth: 0,
            textStyle: {
              color: 'white',
              fontSize: 18,
              padding: 20
            },
            backgroundColor: 'transparent',
            formatter: (param) => {
              return param.name
              // if(param.name.length>8&&param.name.length<16){
              //   return param.name.slice(0,param.name.length/2)+'\n'+param.name.slice(param.name.length/2,param.name.length)
              // }else if(param.name.length>16){
              //   return param.name.slice(0,param.name.length/3)+'\n'+param.name.slice(param.name.length/3,param.name.length/3*2)+'\n'+param.name.slice(param.name.length/3*2)
              // }
            }
          },
          children: children
        }
      ]
      const option = {}
      if (data[0].children.length <= 10) {
        option.series = [
          {
            type: 'tree',
            left: '20%',
            width: '40%',
            right: '20%',
            top: 0,
            bottom: 0,
            orient: 'LR',
            symbolSize: [80, 20],
            symbol: 'roundRect',
            edgeShape: 'polyline',
            initialTreeDepth: 2,
            itemStyle: {
              color: 'transparent',
              borderColor: 'transparent'
            },
            expandAndCollapse: false,
            animationDuration: 550,
            animationDurationUpdate: 750,
            // 线条的颜色
            lineStyle: {
              normal: {
                color: '#42ACEA',
                width: 1
              }
            },
            label: {
              show: true,
              position: 'inside',
              normal: {
                fontSize: 16,
                backgroundColor: '#fff',
                borderColor: '#42ACEA',
                borderWidth: 1,
                borderRadius: 15,
                color: '#42ACEA',
                padding: [5, 20]
              }
            },
            leaves: {
              label: {
                position: 'inside',
                color: '#42ACEA',
                backgroundColor: 'white'
              }
            },
            data: data
          }
        ]
      } else {
        const childs = data[0].children
        const data1 = JSON.parse(JSON.stringify(data))
        const data2 = JSON.parse(JSON.stringify(data))
        data1[0].children = childs.slice(0, childs.length / 2)
        data2[0].children = childs.slice(childs.length / 2)
        data2[0].symbolSize = 0
        data2[0].label.show = false
        option.series = [
          {
            type: 'tree',
            left: '50%',
            width: '40%',
            right: '10%',
            top: 140,
            bottom: 0,
            orient: 'LR',
            symbolSize: [80, 20],
            symbol: 'roundRect',
            edgeShape: 'polyline',
            initialTreeDepth: 2,
            itemStyle: {
              color: 'transparent',
              borderColor: 'transparent'
            },
            expandAndCollapse: false,
            animationDuration: 550,
            animationDurationUpdate: 750,
            // 线条的颜色
            lineStyle: {
              normal: {
                color: '#42ACEA',
                width: 1
              }
            },
            label: {
              show: true,
              position: 'inside',
              normal: {
                fontSize: 16,
                backgroundColor: '#fff',
                borderColor: '#42ACEA',
                borderWidth: 1,
                borderRadius: 15,
                color: '#42ACEA',
                padding: [5, 20]
              }
            },
            leaves: {
              label: {
                position: 'inside',
                color: '#42ACEA',
                backgroundColor: 'white'
              }
            },
            data: data1
          },
          {
            type: 'tree',
            left: '10%',
            width: '40%',
            right: '45%',
            orient: 'RL',
            bottom: 0,
            top: 0,
            symbolSize: [80, 20],
            symbol: 'roundRect',
            edgeShape: 'polyline',
            initialTreeDepth: 2,
            itemStyle: {
              color: 'transparent',
              borderColor: 'transparent'
            },
            expandAndCollapse: false,
            animationDuration: 550,
            animationDurationUpdate: 750,
            // 线条的颜色
            lineStyle: {
              normal: {
                color: '#42ACEA',
                width: 1
              }
            },
            label: {
              show: true,
              position: 'inside',
              normal: {
                fontSize: 16,
                backgroundColor: '#fff',
                borderColor: '#42ACEA',
                borderWidth: 1,
                borderRadius: 15,
                color: '#42ACEA',
                padding: [5, 20]
              }
            },
            leaves: {
              label: {
                position: 'inside',
                color: '#42ACEA',
                backgroundColor: 'white'
              }
            },
            data: data2
          }
        ]
      }
      chart.setOption(option)
      chart.getDom().style.height = 200 + number * 45 + 'px'
      chart.resize()
    },
    // 亮点标签全部展示
    showHighlights () {
      this.honorlist = true
    },
    // 选择tab
    chooseNav (i) {
      this.currentNav = i
      switch (true) {
        case i === '个股概况' && this.clickedNav.indexOf('个股概况') === -1:
          this.getFinancial()
          this.financeAnalysis()
          setTimeout(() => {
            this.curKChart()
          }, 1000)
          if (this.security && this.security.secuCode) {
            this.getStockData()
          }
          this.clickedNav.push('个股概况')
          break
        case i === '基本信息' && this.clickedNav.indexOf('基本信息') === -1:
          this.getBaseInfo()
          this.clickedNav.push('基本信息')
          break
        case i === '重点关注' && this.clickedNav.indexOf('重点关注') === -1:
          this.getAttention()
          this.clickedNav.push('重点关注')
          break
        case i === '知识产权' && this.clickedNav.indexOf('知识产权') === -1:
          this.getKnowledge()
          this.clickedNav.push('知识产权')
          break
        case i === '经营状况' && this.clickedNav.indexOf('经营状况') === -1:
          this.getOperation()
          this.clickedNav.push('经营状况')
          break
        case i === '数据解读' && this.clickedNav.indexOf('数据解读') === -1:
          this.getDataAnalysis()
          this.checkList()
          this.clickedNav.push('数据解读')
          break
        default:
          break
      }
    },
    // 数据解读左侧选择
    dataChoose (index, value) {
      // if(value!=7){
      //   relationChart&&relationChart.clear()
      //   bigRelationChart&&bigRelationChart.clear()
      // }
      if (
        (value !== 'relationMap' && !this.leftList[value]) ||
        (value === 'relationMap' && !this.is201)
      ) {
        return
      }
      this.leftIndex = index
      switch (index) {
        case 0:
          // 实际控制人
          setTimeout(() => {
            this.queryActual()
          }, 100)
          break
        case 1:
          // 对外下属投资方
          this.queryInvestTable()
          setTimeout(() => {
            this.queryInvest()
          }, 100)
          break
        case 2:
          this.getChainYears()
          this.queryCustomer()
          setTimeout(() => {
            this.queryChain()
          }, 100)
          break
        case 3:
          this.queryBranchTable()
          setTimeout(() => {
            this.queryBranch()
          }, 100)
          break
        case 4:
          setTimeout(() => {
            this.lawsuitChart()
            this.lawsuitRoundChart()
          }, 100)
          break
        case 5:
          setTimeout(() => {
            this.disputeChart()
          }, 100)
          break
        case 6:
          setTimeout(() => {
            this.briefChart()
          }, 100)
          break
        case 7:
          this.relationLoading = true
          this.filterCompany = ''
          this.getRelationData()
          break
        default:
          break
      }
    },
    defaultPic (e) {
      e.leaderPic =
        'https://bigscreenvideo.oss-cn-shanghai.aliyuncs.comhttps://zcjrys.chinaindustria.com/api/jrys/72072bf079225184c3ca802f3cab473.png'
    },

    init () {
      this.getCompanyInfo()
      this.intellectualCount()
      this.getShLeaderInfo()
      this.getUpdownList()
      this.lockStatus()
    },
    // 获取公司信息
    getCompanyInfo () {
      this.loading = true
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/base/info/${this.companyCode}`
      ).then((res) => {
        this.companyInfo = res.data.data.survey
        this.similarCompanyVoList = res.data.data.similar
        this.summary = res.data.data.summary
        this.tags = res.data.data.tags
        this.banner = res.data.data.banner
        this.security = res.data.data.security
        this.advantage = res.data.data.advantage
        // 当不是A股或者3板时隐藏个股概况
        if (this.security.secuMarket !== 1 && this.security.secuMarket !== 2) {
          this.navList = [
            { name: '企业概览', img: 'survey' },
            { name: '基本信息', img: 'info' },
            { name: '重点关注', img: 'follow' },
            { name: '知识产权', img: 'science' },
            { name: '经营状况', img: 'operation' },
            { name: '数据解读', img: 'data' }
          ]
        }
        this.loading = false
        this.companyRelation()
      })
    },
    // 上下游
    getUpdownList () {
      this.Axios.post(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/base/updownstream/${this.companyCode}`,
        {}
      ).then((res) => {
        if (res.data.code === 200) {
          this.upStream = res.data.data.supplyList
          this.downStream = res.data.data.saleList
          if (this.downStream.length) {
            this.updown = 'down'
          } else if (this.upStream.length) {
            this.updown = 'up'
          }
        }
      })
    },
    // 获取专利，商标，网站备案，作品著作权，软件著作权，资质证书的数量
    intellectualCount () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/base/intellectual/${this.companyCode}`,
        {}
      ).then((res) => {
        this.intellectualPropertyVo = res.data.data
      })
    },
    getStockData () {
      if (this.security.secuMarket === 1) {
        this.Axios.get(
          `https://ig507.com/data/time/real/${this.security.secuCode}?licence=E516BF2E-29A7-1278-5E47-3E411DBC28A8`,
          {
            transformRequest: [
              function (data, headers) {
                // 这个请求不需要请求头(在此处删除，上方设置的请求头。)不删除请求不通
                if (headers.authorization) {
                  delete headers.authorization
                  delete headers.userId
                }
                return data
              }
            ]
          }
        ).then((res) => {
          this.stockDetail = res.data
          this.Axios.get(
            `https://ig507.com/data/time/f10/info/${this.security.secuCode}?licence=E516BF2E-29A7-1278-5E47-3E411DBC28A8`,
            {
              transformRequest: [
                function (data, headers) {
                  // 这个请求不需要请求头(在此处删除，上方设置的请求头。)不删除请求不通
                  if (headers.authorization) {
                    delete headers.authorization
                    delete headers.userId
                  }
                  return data
                }
              ]
            }
          ).then((res) => {
            this.stockDetail.market =
              res.data.market.slice(0, 2) === '上海' ? 1 : 0
          })
        })
      } else {
        this.Axios.get(
          `https://xinsanban.eastmoney.com/api/QuoteCenter/stock/getstockquote?code=${this.security.secuCode}`,
          {
            transformRequest: [
              function (data, headers) {
                // 这个请求不需要请求头(在此处删除，上方设置的请求头。)不删除请求不通
                if (headers.authorization) {
                  delete headers.authorization
                  delete headers.userId
                }
                return data
              }
            ]
          }
        ).then((res) => {
          this.stockDetail2 = res.data.result[0]
        })
      }
      setTimeout(() => {
        this.curStockMoney()
      }, 3000)
      this.timer = setInterval(() => {
        this.curStockMoney()
      }, 30000)
    },
    // 股票当日分时
    curKChart () {
      const gridDateData = [
        '09:30',
        '09:31',
        '09:32',
        '09:33',
        '09:34',
        '09:35',
        '09:36',
        '09:37',
        '09:38',
        '09:39',
        '09:40',
        '09:41',
        '09:42',
        '09:43',
        '09:44',
        '09:45',
        '09:46',
        '09:47',
        '09:48',
        '09:49',
        '09:50',
        '09:51',
        '09:52',
        '09:53',
        '09:54',
        '09:55',
        '09:56',
        '09:57',
        '09:58',
        '09:59',
        '10:00',
        '10:01',
        '10:02',
        '10:03',
        '10:04',
        '10:05',
        '10:06',
        '10:07',
        '10:08',
        '10:09',
        '10:10',
        '10:11',
        '10:12',
        '10:13',
        '10:14',
        '10:15',
        '10:16',
        '10:17',
        '10:18',
        '10:19',
        '10:20',
        '10:21',
        '10:22',
        '10:23',
        '10:24',
        '10:25',
        '10:26',
        '10:27',
        '10:28',
        '10:29',
        '10:30',
        '10:31',
        '10:32',
        '10:33',
        '10:34',
        '10:35',
        '10:36',
        '10:37',
        '10:38',
        '10:39',
        '10:40',
        '10:41',
        '10:42',
        '10:43',
        '10:44',
        '10:45',
        '10:46',
        '10:47',
        '10:48',
        '10:49',
        '10:50',
        '10:51',
        '10:52',
        '10:53',
        '10:54',
        '10:55',
        '10:56',
        '10:57',
        '10:58',
        '10:59',
        '11:00',
        '11:01',
        '11:02',
        '11:03',
        '11:04',
        '11:05',
        '11:06',
        '11:07',
        '11:08',
        '11:09',
        '11:10',
        '11:11',
        '11:12',
        '11:13',
        '11:14',
        '11:15',
        '11:16',
        '11:17',
        '11:18',
        '11:19',
        '11:20',
        '11:21',
        '11:22',
        '11:23',
        '11:24',
        '11:25',
        '11:26',
        '11:27',
        '11:28',
        '11:29',
        '11:30',
        '13:01',
        '13:02',
        '13:03',
        '13:04',
        '13:05',
        '13:06',
        '13:07',
        '13:08',
        '13:09',
        '13:10',
        '13:11',
        '13:12',
        '13:13',
        '13:14',
        '13:15',
        '13:16',
        '13:17',
        '13:18',
        '13:19',
        '13:20',
        '13:21',
        '13:22',
        '13:23',
        '13:24',
        '13:25',
        '13:26',
        '13:27',
        '13:28',
        '13:29',
        '13:30',
        '13:31',
        '13:32',
        '13:33',
        '13:34',
        '13:35',
        '13:36',
        '13:37',
        '13:38',
        '13:39',
        '13:40',
        '13:41',
        '13:42',
        '13:43',
        '13:44',
        '13:45',
        '13:46',
        '13:47',
        '13:48',
        '13:49',
        '13:50',
        '13:51',
        '13:52',
        '13:53',
        '13:54',
        '13:55',
        '13:56',
        '13:57',
        '13:58',
        '13:59',
        '14:00',
        '14:01',
        '14:02',
        '14:03',
        '14:04',
        '14:05',
        '14:06',
        '14:07',
        '14:08',
        '14:09',
        '14:10',
        '14:11',
        '14:12',
        '14:13',
        '14:14',
        '14:15',
        '14:16',
        '14:17',
        '14:18',
        '14:19',
        '14:20',
        '14:21',
        '14:22',
        '14:23',
        '14:24',
        '14:25',
        '14:26',
        '14:27',
        '14:28',
        '14:29',
        '14:30',
        '14:31',
        '14:32',
        '14:33',
        '14:34',
        '14:35',
        '14:36',
        '14:37',
        '14:38',
        '14:39',
        '14:40',
        '14:41',
        '14:42',
        '14:43',
        '14:44',
        '14:45',
        '14:46',
        '14:47',
        '14:48',
        '14:49',
        '14:50',
        '14:51',
        '14:52',
        '14:53',
        '14:54',
        '14:55',
        '14:56',
        '14:57',
        '14:58',
        '14:59',
        '15:00'
      ]
      const lastPrice = this.security.secuCode
        ? this.stockDetail.yc
        : this.stockDetail2.PreviousClose
      let data = []
      const chart = this.Echarts.init(document.getElementById('shares'))
      // A股
      if (this.security.secuMarket === 1) {
        const evtSource = new EventSource(
          `https://43.push2.eastmoney.com/api/qt/stock/trends2/sse?fields1=f1,f2,f3,f4,f5,f6,f7,f8,f9,f10,f11,f12,f13&fields2=f51,f52,f53,f54,f55,f56,f57,f58&ut=fa5fd1943c7b386f172d6893dbfba10b&secid=${this.stockDetail.market}.${this.security.secuCode}&ndays=1&iscr=0`
        )
        evtSource.onmessage = (res) => {
          const that = this
          if (that.currentNav !== '个股概况' || that.currentStock !== 0) {
            evtSource.close()
          } else {
            const temp = JSON.parse(res.data).data
            if (temp && temp.trends && temp.trends.length > 1) {
              gridDateData.forEach((i, index) => {
                if (
                  temp.trends[index] &&
                  i === temp.trends[index].split(',')[0].slice(11, 16)
                ) {
                  that.stockData[index] = temp.trends[index].split(',')
                  that.stockData[index][0] = i
                } else {
                  that.stockData[index] = [
                    i,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                  ]
                }
              })
            } else if (temp && temp.trends && temp.trends.length === 1) {
              gridDateData.forEach((i, index) => {
                if (
                  temp.trends[0] &&
                  i === temp.trends[0].split(',')[0].slice(11, 16)
                ) {
                  that.stockData[index] = temp.trends[0].split(',')
                  that.stockData[index][0] = i
                }
              })
            }
            data = that.stockData
            // 第1个grid数据（折线图）
            const grid1Data = []
            // var gridDateData = [];
            // 第2个grid数据（柱状图）
            const grid2Data = []

            // 柱状图的颜色
            // 柱状图的红绿规则比较麻烦，所以本次采用的规则则是根据价格的涨跌来区分
            const vColor = []
            const UP_COLOR = '#E24528'
            const DOWN_COLOR = '#009933'
            const NORMAL_COLOR = '#33353C'
            let priceMax = 0
            let priceMin = 0
            let priceInterval = 0
            let vMax = 0
            let vMin = 0
            let vInterval = 0
            initData()
            function colorCls (num) {
              if (num > lastPrice) {
                return 'red'
              } else if (num === lastPrice) {
                return ''
              }
              return 'green'
            }
            function initData () {
              for (const i in data) {
                if (data[i] && data[i][2] !== null) {
                  if (data[i][2] > priceMax) {
                    priceMax = data[i][2]
                  }
                  if (data[i][2] < priceMin || priceMin === 0) {
                    priceMin = data[i][2]
                  }
                  // 折线图
                  grid1Data.push(data[i][2])
                  // gridDateData.push(data[i][0]);
                  if (data[i][5] - 0 > vMax) {
                    vMax = data[i][5] - 0
                  }
                  if (data[i][5] - 0 < vMin) {
                    vMin = data[i][5] - 0
                  }
                  if (i > 0) {
                    if (data[i][2] >= data[i - 1][2]) {
                      vColor.push(UP_COLOR)
                    } else {
                      vColor.push(DOWN_COLOR)
                    }
                  }
                  // 柱状图
                  grid2Data.push(data[i][5])
                } else {
                  grid1Data.push(data[i][2])
                  grid2Data.push(data[i][5])
                }
              }

              // 重新计算价格的最大最小值，以达到对称的效果
              if ((lastPrice - priceMax) * -1 > lastPrice - priceMin) {
                priceMin = lastPrice - (lastPrice - priceMax) * -1
              } else {
                priceMax = lastPrice + (lastPrice - priceMin)
              }

              priceInterval = (priceMax - lastPrice) / 4
              vInterval = vMax / 2
            }
            // echarts折线图的配置项
            const option = {
              animation: false,
              // 坐标轴指示器
              axisPointer: {
                show: true,
                // 配置线条风格为虚线风格
                lineStyle: {
                  type: 'dashed'
                },
                link: [
                  {
                    xAxisIndex: [0, 1]
                  },
                  {
                    yAxisIndex: [1]
                  }
                ]
              },
              // 悬浮框
              tooltip: {
                trigger: 'axis',
                position: function (point, params, dom, rect, size) {
                  const obj = {
                    top: 10
                  }
                  if (point[0] > size.viewSize[0] / 2) {
                    obj.left = 100
                  } else {
                    obj.right = 100
                  }

                  return obj
                },
                formatter: function (params, ticket, callback) {
                  let html = ''
                  let x, j, c
                  for (const d in params) {
                    if (
                      params[d].seriesName === '成交量1' ||
                      params[d].seriesName === '成交量2'
                    ) {
                      c = params[d]
                    }
                    if (
                      params[d].seriesName === '最新1' ||
                      params[d].seriesName === '最新2'
                    ) {
                      x = params[d]
                    }
                  }
                  if (!c.axisValue) {
                    return
                  }
                  html +=
                    '<div class="tooltips-item"><span class="name">时间</span><span class="value">' +
                    c.axisValue +
                    '</span></div>'
                  html +=
                    '<div class="tooltips-item"><span class="name">最新</span><span class="value ' +
                    colorCls(typeof x.data === 'number' ? x.data : x.data - 0) +
                    '">' +
                    (typeof x.data === 'number' ? x.data : x.data - 0) +
                    '</span></div>'
                  html +=
                    '<div class="tooltips-item"><span class="name">涨跌幅</span><span class="value ' +
                    colorCls(typeof x.data === 'number' ? x.data : x.data - 0) +
                    '">' +
                    (
                      (((typeof x.data === 'number' ? x.data : x.data - 0) -
                        lastPrice) /
                        lastPrice) *
                      100
                    ).toFixed(2) +
                    '%</span></div>'
                  html +=
                    '<div class="tooltips-item"><span class="name">涨跌额</span><span class="value ' +
                    colorCls(typeof x.data === 'number' ? x.data : x.data - 0) +
                    '">' +
                    (
                      (typeof x.data === 'number' ? x.data : x.data - 0) -
                      lastPrice
                    ).toFixed(2) +
                    '</span></div>'
                  html +=
                    '<div class="tooltips-item"><span class="name">成交量</span><span class="value">' +
                    (typeof c.data === 'number' ? c.data : c.data - 0) +
                    '手</span></div>'
                  return html
                },
                textStyle: {
                  color: '#000'
                },
                borderWidth: 1,
                borderColor: '#ECEEF2',
                backgroundColor: 'rgba(255,255,255,0.9)',
                transitionDuration: 0,
                axisPointer: {
                  animation: false,
                  type: 'cross'
                }
              },
              // grid
              grid: [
                // 第三个grid，第三个图表是在第一个图表的右方，所以要把它定位到右方
                {
                  top: 10, // 图表的外边距
                  left: 50,
                  right: 50,
                  height: 400
                },
                // 第四个grid，第四个图表是在第三个图表的下方，所以要把它定位到底部
                {
                  top: 440, // 设置上方的外边距是第三个图表的高度再加10，使用top是方便我们调整下方grid的高度
                  left: 50,
                  right: 50,
                  height: 80
                }
              ],
              // 多个图表则会存在对个x轴y轴，所以这里的配置我们也换成数组的方式
              // x轴配置，
              xAxis: [
                // 第1个grid的x轴属性
                {
                  // 告诉echarts，这个第一个grid的x轴
                  gridIndex: 0,
                  // 坐标轴是否留白
                  boundaryGap: false,
                  // x轴的刻度
                  axisTick: { show: false },
                  // x轴的刻度值
                  axisLabel: { show: false },
                  type: 'category',
                  data: gridDateData,
                  axisLine: {
                    lineStyle: {
                      color: '#ECEEF2'
                    }
                  },
                  max: 'dataMax',
                  min: 'dataMin',
                  axisPointer: {
                    show: true,
                    label: {
                      show: false
                    }
                  },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      color: '#ECEEF2',
                      type: 'solid'
                    },
                    interval: function (index, value) {
                      if (index === 0 || index === 1) {
                        return false
                      }

                      if ((index - 1) % 30 === 0) {
                        return true
                      }
                      return false
                    }
                  }
                },
                // 第2个grid的x轴属性
                {
                  // 告诉echarts，这个第一个grid的x轴
                  gridIndex: 1,
                  // 坐标轴是否留白
                  boundaryGap: false,
                  // x轴的刻度
                  axisTick: {
                    show: false
                  },
                  type: 'category',
                  max: 'dataMax',
                  min: 'dataMin',
                  data: gridDateData,
                  axisLabel: {
                    fontSize: 12,
                    show: true,
                    showMinLabel: false,
                    color: '#888'
                  },
                  axisLine: {
                    lineStyle: {
                      color: '#ECEEF2'
                    }
                  },
                  splitLine: {
                    show: true,
                    lineStyle: {
                      color: '#ECEEF2',
                      type: 'solid'
                    }
                  },
                  axisPointer: {
                    show: true,
                    type: 'line',
                    label: {
                      show: true,
                      fontSize: 10,
                      margin: 0,
                      padding: 2,
                      shadowBlur: 0,
                      color: '#33353C'
                    }
                  }
                }
              ],
              // y轴配置
              yAxis: [
                // 第1个grid的左侧y轴属性
                {
                  // 去掉刻度值旁边的指示线
                  axisTick: { show: false },
                  splitNumber: 9,
                  gridIndex: 0,
                  interval: priceInterval,
                  max: priceMax,
                  min: priceMin,
                  splitLine: {
                    lineStyle: {
                      color: '#ECEEF2',
                      type: 'solid'
                    }
                  },
                  axisLine: {
                    lineStyle: {
                      color: '#ECEEF2'
                    }
                  },
                  axisLabel: {
                    fontSize: 12,
                    margin: 0,
                    // y轴的数值向内显示
                    align: 'right',
                    formatter: function (value, index) {
                      return value.toFixed(2)
                    },
                    color: function (value, index) {
                      // 中间基准线的数值为黑色
                      if (parseFloat(value).toFixed(2) === lastPrice) {
                        return NORMAL_COLOR
                      }
                      // 上涨区域的数字为红色
                      if (value > lastPrice) {
                        return UP_COLOR
                      }
                      // 下方下跌的数值为绿色
                      if (value < lastPrice) {
                        return DOWN_COLOR
                      }
                    }
                  },
                  z: 3,
                  axisPointer: {
                    show: true,
                    type: 'line',
                    label: {
                      show: true,
                      fontSize: 10,
                      margin: -44,
                      padding: 2,
                      shadowBlur: 0,
                      color: '#33353C',
                      formatter: function (data) {
                        return data.value.toFixed(2)
                      }
                    }
                  }
                },
                // 第2个grid的y轴属性
                {
                  // 去掉刻度值旁边的指示线
                  axisTick: { show: false },
                  splitNumber: 3,
                  gridIndex: 1,
                  interval: vInterval,
                  max: vMax,
                  min: 0,
                  splitLine: {
                    lineStyle: {
                      color: '#ECEEF2',
                      type: 'solid'
                    }
                  },
                  axisLine: {
                    lineStyle: {
                      color: '#ECEEF2'
                    }
                  },
                  axisPointer: {
                    show: true,
                    type: 'line',
                    label: {
                      show: true,
                      fontSize: 12,
                      padding: 2,
                      shadowBlur: 0,
                      color: '#33353C',
                      formatter: function (data) {
                        if (data.value > 10000) {
                          return (
                            parseFloat(data.value / 10000).toFixed(2) + '万手'
                          )
                        } else {
                          return data.value.toFixed(0) + '手'
                        }
                      }
                    }
                  },
                  axisLabel: {
                    align: 'left',
                    verticalAlign: 'top',
                    // 设置显示坐标轴的数值为不显示
                    show: true,
                    fontSize: 10,
                    margin: 0,
                    showMaxLabel: true,
                    showMinLabel: false,
                    color: '#33353C',
                    formatter: function (value, index) {
                      // 格式化成月/日，只在第一个刻度显示年份
                      if (value === vMax) {
                        // 方便演示
                        if (value > 10000) {
                          value = parseFloat(value / 10000).toFixed(2) + '万手'
                        } else {
                          return value.toFixed(0) + '手'
                        }
                      }
                      return ''
                    }
                  }
                },
                // 第1个grid的右侧y轴属性
                {
                  // 去掉刻度值旁边的指示线
                  axisTick: { show: false },
                  splitNumber: 9,
                  position: 'right',
                  gridIndex: 0,
                  interval: priceInterval,
                  max: priceMax,
                  min: priceMin,
                  splitLine: {
                    lineStyle: {
                      color: '#ECEEF2',
                      type: 'solid'
                    }
                  },
                  axisLine: {
                    lineStyle: {
                      color: '#ECEEF2'
                    }
                  },
                  axisLabel: {
                    fontSize: 12,
                    margin: 0,
                    // y轴的数值向内显示
                    align: 'left',
                    formatter: function (value, index) {
                      let persent = (value - lastPrice) / lastPrice
                      persent = persent < 0 ? persent * -1 : persent
                      persent = persent * 100
                      return persent.toFixed(2) + '%'
                    },
                    color: function (value, index) {
                      // 中间基准线的数值为黑色
                      if (parseFloat(value).toFixed(2) === lastPrice) {
                        return NORMAL_COLOR
                      }
                      // 上涨区域的数字为红色
                      if (value > lastPrice) {
                        return UP_COLOR
                      }
                      // 下方下跌的数值为绿色
                      if (value < lastPrice) {
                        return DOWN_COLOR
                      }
                    }
                  },
                  z: 3,
                  axisPointer: {
                    show: true,
                    type: 'line',
                    label: {
                      show: true,
                      fontSize: 10,
                      margin: -34,
                      padding: 2,
                      shadowBlur: 0,
                      color: '#33353C',
                      formatter: function (data) {
                        let persent = (data.value - lastPrice) / lastPrice
                        persent = persent < 0 ? persent * -1 : persent
                        persent = persent * 100
                        return persent.toFixed(2) + '%'
                      }
                    }
                  }
                }
              ],
              // 数据可以通过xAxisIndex，yAxisIndex属性，来指定是哪个grid的数据
              series: [
                // 第1个图表的数据
                {
                  name: '最新2',
                  // 平滑曲线
                  smooth: true,
                  // 是否显示折线上的圆点
                  symbol: 'none',
                  // 线条颜色
                  lineStyle: {
                    color: '#0481F8',
                    width: 1
                  },
                  z: 3,
                  xAxisIndex: 0,
                  yAxisIndex: 0,
                  data: grid1Data,
                  type: 'line',
                  areaStyle: {
                    color: '#F8FAFF'
                  }
                },
                // 第2个图表的数据
                {
                  name: '成交量2',
                  xAxisIndex: 1,
                  yAxisIndex: 1,
                  barWidth: 1,
                  data: grid2Data,
                  type: 'bar',
                  itemStyle: {
                    normal: {
                      color: function (params) {
                        return vColor[params.dataIndex]
                      }
                    }
                  }
                }
              ]
            }
            chart.setOption(option, true)
            chart.resize()
          }
        }
      } else {
        // 三板
        this.Axios.get(
          `https://push2.eastmoney.com/api/qt/stock/trends2/get?end=20500000&fields1=f1%2Cf2%2Cf3%2Cf4%2Cf5%2Cf6%2Cf7%2Cf8%2Cf9%2Cf10%2Cf11%2Cf12%2Cf13&fields2=f51%2Cf52%2Cf53%2Cf54%2Cf55%2Cf56%2Cf57%2Cf58&secid=0.${this.security.secuCode}`
        ).then((res) => {
          const temp = res.data.data
          const that = this
          gridDateData.forEach((i, index) => {
            if (
              temp.trends[index + 15] &&
              i === temp.trends[index + 15].split(',')[0].slice(11, 16)
            ) {
              that.stockData[index] = temp.trends[index + 15].split(',')
              that.stockData[index][0] = i
            } else {
              that.stockData[index] = [
                i,
                null,
                null,
                null,
                null,
                null,
                null,
                null
              ]
            }
          })
          data = that.stockData
          // 第1个grid数据（折线图）
          const grid1Data = []
          // var gridDateData = [];
          // 第2个grid数据（柱状图）
          const grid2Data = []

          // 柱状图的颜色
          // 柱状图的红绿规则比较麻烦，所以本次采用的规则则是根据价格的涨跌来区分
          const vColor = []
          const UP_COLOR = '#E24528'
          const DOWN_COLOR = '#009933'
          const NORMAL_COLOR = '#33353C'
          let priceMax = 0
          let priceMin = 0
          let priceInterval = 0
          let vMax = 0
          let vMin = 0
          let vInterval = 0
          initData()
          function colorCls (num) {
            if (num > lastPrice) {
              return 'red'
            } else if (num === lastPrice) {
              return ''
            }
            return 'green'
          }
          function initData () {
            for (const i in data) {
              if (data[i] && data[i][2] !== null) {
                if (data[i][2] > priceMax) {
                  priceMax = data[i][2]
                }
                if (data[i][2] < priceMin || priceMin === 0) {
                  priceMin = data[i][2]
                }
                // 折线图
                grid1Data.push(data[i][2])
                // gridDateData.push(data[i][0]);
                if (data[i][5] - 0 > vMax) {
                  vMax = data[i][5] - 0
                }
                if (data[i][5] - 0 < vMin) {
                  vMin = data[i][5] - 0
                }
                if (i > 0) {
                  if (data[i][2] >= data[i - 1][2]) {
                    vColor.push(UP_COLOR)
                  } else {
                    vColor.push(DOWN_COLOR)
                  }
                }
                // 柱状图
                grid2Data.push(data[i][5])
              } else {
                grid1Data.push(data[i][2])
                grid2Data.push(data[i][5])
              }
            }
            // 重新计算价格的最大最小值，以达到对称的效果
            if ((lastPrice - priceMax) * -1 > lastPrice - priceMin) {
              priceMin = lastPrice - (lastPrice - priceMax) * -1
            } else {
              priceMax = lastPrice + (lastPrice - priceMin)
            }

            priceInterval = (priceMax - lastPrice) / 4
            vInterval = vMax / 2
          }
          // echarts折线图的配置项
          const option = {
            animation: false,
            // 坐标轴指示器
            axisPointer: {
              show: true,
              // 配置线条风格为虚线风格
              lineStyle: {
                type: 'dashed'
              },
              link: [
                {
                  xAxisIndex: [0, 1]
                },
                {
                  yAxisIndex: [1]
                }
              ]
            },
            // 悬浮框
            tooltip: {
              trigger: 'axis',
              position: function (point, params, dom, rect, size) {
                const obj = {
                  top: 10
                }
                if (point[0] > size.viewSize[0] / 2) {
                  obj.left = 100
                } else {
                  obj.right = 100
                }

                return obj
              },
              formatter: function (params, ticket, callback) {
                let html = ''
                let x, j, c
                for (const d in params) {
                  if (
                    params[d].seriesName === '成交量1' ||
                    params[d].seriesName === '成交量2'
                  ) {
                    c = params[d]
                  }
                  if (
                    params[d].seriesName === '最新1' ||
                    params[d].seriesName === '最新2'
                  ) {
                    x = params[d]
                  }
                }
                if (!c.axisValue) {
                  return
                }
                html +=
                  '<div class="tooltips-item"><span class="name">时间</span><span class="value">' +
                  c.axisValue +
                  '</span></div>'
                html +=
                  '<div class="tooltips-item"><span class="name">最新</span><span class="value ' +
                  colorCls(typeof x.data === 'number' ? x.data : x.data - 0) +
                  '">' +
                  (typeof x.data === 'number' ? x.data : x.data - 0) +
                  '</span></div>'
                html +=
                  '<div class="tooltips-item"><span class="name">涨跌幅</span><span class="value ' +
                  colorCls(typeof x.data === 'number' ? x.data : x.data - 0) +
                  '">' +
                  (
                    (((typeof x.data === 'number' ? x.data : x.data - 0) -
                      lastPrice) /
                      lastPrice) *
                    100
                  ).toFixed(2) +
                  '%</span></div>'
                html +=
                  '<div class="tooltips-item"><span class="name">涨跌额</span><span class="value ' +
                  colorCls(typeof x.data === 'number' ? x.data : x.data - 0) +
                  '">' +
                  (
                    (typeof x.data === 'number' ? x.data : x.data - 0) -
                    lastPrice
                  ).toFixed(2) +
                  '</span></div>'
                html +=
                  '<div class="tooltips-item"><span class="name">成交量</span><span class="value">' +
                  (typeof c.data === 'number' ? c.data : c.data - 0) +
                  '手</span></div>'
                return html
              },
              textStyle: {
                color: '#000'
              },
              borderWidth: 1,
              borderColor: '#ECEEF2',
              backgroundColor: 'rgba(255,255,255,0.9)',
              transitionDuration: 0,
              axisPointer: {
                animation: false,
                type: 'cross'
              }
            },
            // grid
            grid: [
              // 第三个grid，第三个图表是在第一个图表的右方，所以要把它定位到右方
              {
                top: 10, // 图表的外边距
                left: 50,
                right: 50,
                height: 400
              },
              // 第四个grid，第四个图表是在第三个图表的下方，所以要把它定位到底部
              {
                top: 440, // 设置上方的外边距是第三个图表的高度再加10，使用top是方便我们调整下方grid的高度
                left: 50,
                right: 50,
                height: 80
              }
            ],
            // 多个图表则会存在对个x轴y轴，所以这里的配置我们也换成数组的方式
            // x轴配置，
            xAxis: [
              // 第1个grid的x轴属性
              {
                // 告诉echarts，这个第一个grid的x轴
                gridIndex: 0,
                // 坐标轴是否留白
                boundaryGap: false,
                // x轴的刻度
                axisTick: { show: false },
                // x轴的刻度值
                axisLabel: { show: false },
                type: 'category',
                data: gridDateData,
                axisLine: {
                  lineStyle: {
                    color: '#ECEEF2'
                  }
                },
                max: 'dataMax',
                min: 'dataMin',
                axisPointer: {
                  show: true,
                  label: {
                    show: false
                  }
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: '#ECEEF2',
                    type: 'solid'
                  },
                  interval: function (index, value) {
                    if (index === 0 || index === 1) {
                      return false
                    }

                    if ((index - 1) % 30 === 0) {
                      return true
                    }
                    return false
                  }
                }
              },
              // 第2个grid的x轴属性
              {
                // 告诉echarts，这个第一个grid的x轴
                gridIndex: 1,
                // 坐标轴是否留白
                boundaryGap: false,
                // x轴的刻度
                axisTick: {
                  show: false
                },
                type: 'category',
                max: 'dataMax',
                min: 'dataMin',
                data: gridDateData,
                axisLabel: {
                  fontSize: 12,
                  show: true,
                  showMinLabel: false,
                  color: '#888'
                },
                axisLine: {
                  lineStyle: {
                    color: '#ECEEF2'
                  }
                },
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: '#ECEEF2',
                    type: 'solid'
                  }
                },
                axisPointer: {
                  show: true,
                  type: 'line',
                  label: {
                    show: true,
                    fontSize: 10,
                    margin: 0,
                    padding: 2,
                    shadowBlur: 0,
                    color: '#33353C'
                  }
                }
              }
            ],
            // y轴配置
            yAxis: [
              // 第1个grid的左侧y轴属性
              {
                // 去掉刻度值旁边的指示线
                axisTick: { show: false },
                splitNumber: 9,
                gridIndex: 0,
                interval: priceInterval,
                max: priceMax,
                min: priceMin,
                splitLine: {
                  lineStyle: {
                    color: '#ECEEF2',
                    type: 'solid'
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: '#ECEEF2'
                  }
                },
                axisLabel: {
                  fontSize: 12,
                  margin: 0,
                  // y轴的数值向内显示
                  align: 'right',
                  formatter: function (value, index) {
                    return value.toFixed(2)
                  },
                  color: function (value, index) {
                    // 中间基准线的数值为黑色
                    if (parseFloat(value).toFixed(2) === lastPrice) {
                      return NORMAL_COLOR
                    }
                    // 上涨区域的数字为红色
                    if (value > lastPrice) {
                      return UP_COLOR
                    }
                    // 下方下跌的数值为绿色
                    if (value < lastPrice) {
                      return DOWN_COLOR
                    }
                  }
                },
                z: 3,
                axisPointer: {
                  show: true,
                  type: 'line',
                  label: {
                    show: true,
                    fontSize: 10,
                    margin: -44,
                    padding: 2,
                    shadowBlur: 0,
                    color: '#33353C',
                    formatter: function (data) {
                      return data.value.toFixed(2)
                    }
                  }
                }
              },
              // 第2个grid的y轴属性
              {
                // 去掉刻度值旁边的指示线
                axisTick: { show: false },
                splitNumber: 3,
                gridIndex: 1,
                interval: vInterval,
                max: vMax,
                min: 0,
                splitLine: {
                  lineStyle: {
                    color: '#ECEEF2',
                    type: 'solid'
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: '#ECEEF2'
                  }
                },
                axisPointer: {
                  show: true,
                  type: 'line',
                  label: {
                    show: true,
                    fontSize: 12,
                    padding: 2,
                    shadowBlur: 0,
                    color: '#33353C',
                    formatter: function (data) {
                      if (data.value > 10000) {
                        return (
                          parseFloat(data.value / 10000).toFixed(2) + '万手'
                        )
                      } else {
                        return data.value.toFixed(0) + '手'
                      }
                    }
                  }
                },
                axisLabel: {
                  align: 'left',
                  verticalAlign: 'top',
                  // 设置显示坐标轴的数值为不显示
                  show: true,
                  fontSize: 10,
                  margin: 0,
                  showMaxLabel: true,
                  showMinLabel: false,
                  color: '#33353C',
                  formatter: function (value, index) {
                    // 格式化成月/日，只在第一个刻度显示年份
                    if (value === vMax) {
                      // 方便演示
                      if (value > 10000) {
                        value = parseFloat(value / 10000).toFixed(2) + '万手'
                      } else {
                        return value.toFixed(0) + '手'
                      }
                    }
                    return ''
                  }
                }
              },
              // 第1个grid的右侧y轴属性
              {
                // 去掉刻度值旁边的指示线
                axisTick: { show: false },
                splitNumber: 9,
                position: 'right',
                gridIndex: 0,
                interval: priceInterval,
                max: priceMax,
                min: priceMin,
                splitLine: {
                  lineStyle: {
                    color: '#ECEEF2',
                    type: 'solid'
                  }
                },
                axisLine: {
                  lineStyle: {
                    color: '#ECEEF2'
                  }
                },
                axisLabel: {
                  fontSize: 12,
                  margin: 0,
                  // y轴的数值向内显示
                  align: 'left',
                  formatter: function (value, index) {
                    let persent = (value - lastPrice) / lastPrice
                    persent = persent < 0 ? persent * -1 : persent
                    persent = persent * 100
                    return persent.toFixed(2) + '%'
                  },
                  color: function (value, index) {
                    // 中间基准线的数值为黑色
                    if (parseFloat(value).toFixed(2) === lastPrice) {
                      return NORMAL_COLOR
                    }
                    // 上涨区域的数字为红色
                    if (value > lastPrice) {
                      return UP_COLOR
                    }
                    // 下方下跌的数值为绿色
                    if (value < lastPrice) {
                      return DOWN_COLOR
                    }
                  }
                },
                z: 3,
                axisPointer: {
                  show: true,
                  type: 'line',
                  label: {
                    show: true,
                    fontSize: 10,
                    margin: -34,
                    padding: 2,
                    shadowBlur: 0,
                    color: '#33353C',
                    formatter: function (data) {
                      let persent = (data.value - lastPrice) / lastPrice
                      persent = persent < 0 ? persent * -1 : persent
                      persent = persent * 100
                      return persent.toFixed(2) + '%'
                    }
                  }
                }
              }
            ],
            // 数据可以通过xAxisIndex，yAxisIndex属性，来指定是哪个grid的数据
            series: [
              // 第1个图表的数据
              {
                name: '最新2',
                // 平滑曲线
                smooth: true,
                // 是否显示折线上的圆点
                symbol: 'none',
                // 线条颜色
                lineStyle: {
                  color: '#0481F8',
                  width: 1
                },
                z: 3,
                xAxisIndex: 0,
                yAxisIndex: 0,
                data: grid1Data,
                type: 'line',
                areaStyle: {
                  color: '#F8FAFF'
                }
              },
              // 第2个图表的数据
              {
                name: '成交量2',
                xAxisIndex: 1,
                yAxisIndex: 1,
                barWidth: 1,
                data: grid2Data,
                type: 'bar',
                itemStyle: {
                  normal: {
                    color: function (params) {
                      return vColor[params.dataIndex]
                    }
                  }
                }
              }
            ]
          }
          chart.setOption(option, true)
          chart.resize()
        })
      }
    },
    // 股票当前价格
    curStockMoney () {
      if (this.security.secuMarket === 1) {
        this.Axios.get(
          `https://ig507.com/data/time/real/${this.security.secuCode}?licence=E516BF2E-29A7-1278-5E47-3E411DBC28A8`,
          {
            transformRequest: [
              function (data, headers) {
                // 这个请求不需要请求头(在此处删除，上方设置的请求头。)不删除请求不通
                if (headers.authorization) {
                  delete headers.authorization
                  delete headers.userId
                }
                return data
              }
            ]
          }
        ).then((res) => {
          this.stockDetail.p = res.data.p.toFixed(2)
          this.stockDetail.pc = res.data.pc.toFixed(2)
        })
      } else {
        this.Axios.get(
          `https://xinsanban.eastmoney.com/api/QuoteCenter/stock/getstockquote?code=${this.security.secuCode}`,
          {
            transformRequest: [
              function (data, headers) {
                // 这个请求不需要请求头(在此处删除，上方设置的请求头。)不删除请求不通
                if (headers.authorization) {
                  delete headers.authorization
                  delete headers.userId
                }
                return data
              }
            ]
          }
        ).then((res) => {
          this.stockDetail2.Close =
            res.data.result[0].Close === '-'
              ? '-'
              : (res.data.result[0].Close - 0).toFixed(2)
          this.stockDetail2.ChangePercent =
            res.data.result[0].ChangePercent === '-'
              ? '-'
              : (res.data.result[0].ChangePercent - 0).toFixed(2)
        })
      }
    },
    // 股票K线图
    kChart () {
      const chart = this.Echarts.init(document.getElementById('shares'))
      let url = ''
      let startDate = 0
      if (this.security.secuMarket === 1) {
        switch (this.currentStock) {
          case 1:
            url = `https://ig507.com/data/time/history/trade/${this.security.secuCode}/Day?licence=E516BF2E-29A7-1278-5E47-3E411DBC28A8`
            startDate = 98
            break
          case 2:
            url = `https://ig507.com/data/time/history/trade/${this.security.secuCode}/Week?licence=E516BF2E-29A7-1278-5E47-3E411DBC28A8`
            startDate = 90
            break
          case 3:
            url = `https://ig507.com/data/time/history/trade/${this.security.secuCode}/Month?licence=E516BF2E-29A7-1278-5E47-3E411DBC28A8`
            startDate = 70
            break
          default:
            break
        }
      } else {
        switch (this.currentStock) {
          case 1:
            url = `https://push2his.eastmoney.com/api/qt/stock/kline/get?klt=101&beg=20100101&end=20500000&fields1=f1%2Cf2%2Cf3%2Cf4%2Cf5&fields2=f51%2Cf52%2Cf53%2Cf54%2Cf55%2Cf56%2Cf57%2Cf58%2Cf59%2Cf60&secid=0.${this.security.secuCode}&fqt=0`
            startDate = 90
            break
          case 2:
            url = `https://push2his.eastmoney.com/api/qt/stock/kline/get?klt=102&beg=20100101&end=20500000&fields1=f1%2Cf2%2Cf3%2Cf4%2Cf5&fields2=f51%2Cf52%2Cf53%2Cf54%2Cf55%2Cf56%2Cf57%2Cf58%2Cf59%2Cf60&secid=0.${this.security.secuCode}&fqt=0`
            startDate = 80
            break
          case 3:
            url = `https://push2his.eastmoney.com/api/qt/stock/kline/get?klt=103&beg=20100101&end=20500000&fields1=f1%2Cf2%2Cf3%2Cf4%2Cf5&fields2=f51%2Cf52%2Cf53%2Cf54%2Cf55%2Cf56%2Cf57%2Cf58%2Cf59%2Cf60&secid=0.${this.security.secuCode}&fqt=0`
            startDate = 0
            break
          default:
            break
        }
      }
      const upColor = '#f13e3b'
      const downColor = '#00a842'
      function splitAData (rawData) {
        // 处理A股
        const categoryData = []
        const values = []
        const volumes = []
        for (let i = 0; i < rawData.length; i++) {
          categoryData.push(rawData[i].d)
          values.push([
            rawData[i].o,
            rawData[i].c,
            rawData[i].l,
            rawData[i].h,
            rawData[i].v
          ])
          volumes.push([i, rawData[i].v, rawData[i].o > rawData[i].c ? 1 : -1])
        }
        return {
          categoryData: categoryData,
          values: values,
          volumes: volumes
        }
      }
      function splitNewData (rawData) {
        // 处理新三板
        const categoryData = []
        const values = []
        const volumes = []
        for (let i = 0; i < rawData.length; i++) {
          categoryData.push(rawData[i].split(',')[0])
          values.push([
            rawData[i].split(',')[1],
            rawData[i].split(',')[2],
            rawData[i].split(',')[4],
            rawData[i].split(',')[3],
            rawData[i].split(',')[5]
          ])
          volumes.push([
            i,
            rawData[i].split(',')[5],
            rawData[i].split(',')[1] > rawData[i].split(',')[2] ? 1 : -1
          ])
        }
        return {
          categoryData: categoryData,
          values: values,
          volumes: volumes
        }
      }
      function calculateMA (dayCount, data) {
        const result = []
        for (let i = 0, len = data.values.length; i < len; i++) {
          if (i < dayCount) {
            result.push('-')
            continue
          }
          let sum = 0
          for (let j = 0; j < dayCount; j++) {
            sum += data.values[i - j][1]
          }
          result.push(+(sum / dayCount).toFixed(3))
        }
        return result
      }
      this.Axios.get(url, {
        transformRequest: [
          function (data, headers) {
            // 这个请求不需要请求头(在此处删除，上方设置的请求头。)不删除请求不通
            if (headers.authorization) {
              delete headers.authorization
              delete headers.userId
            }
            return data
          }
        ]
      }).then((res) => {
        const data = this.security.secuCode
          ? splitAData(res.data)
          : splitNewData(res.data.data.klines)
        switch (
          this.currentStock // 数据太少处理
        ) {
          case 1:
            if (data.values.length < 1000) {
              startDate = 0
            }
            break
          case 2:
            if (data.values.length < 100) {
              startDate = 0
            }
            break
          case 3:
            if (data.values.length < 100) {
              startDate = 0
            }
            break
          default:
            break
        }
        chart.setOption(
          {
            animation: false,
            legend: {
              top: 10,
              left: 'center',
              data:
                this.currentStock === 1
                  ? ['5日K', '10日K', '20日K', '30日K']
                  : this.currentStock === 2
                    ? ['5周K', '10周K', '20周K', '30周K']
                    : this.currentStock === 3
                      ? ['5月K', '10月K', '20月K', '30月K']
                      : []
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross'
              },
              textStyle: {
                color: '#333'
              },
              backgroundColor: '#fff',
              borderWidth: 1,
              borderColor: '#ccc',
              padding: 10
            },
            axisPointer: {
              link: [
                {
                  xAxisIndex: 'all'
                }
              ],
              label: {
                backgroundColor: '#777'
              }
            },
            visualMap: {
              show: false,
              seriesIndex: 5,
              dimension: 2,
              pieces: [
                {
                  value: 1,
                  color: downColor
                },
                {
                  value: -1,
                  color: upColor
                }
              ]
            },
            grid: [
              {
                left: '5%',
                right: '5%',
                height: '50%'
              },
              {
                left: '5%',
                right: '5%',
                top: '63%',
                height: '16%'
              }
            ],
            xAxis: [
              {
                type: 'category',
                data: data.categoryData,
                scale: true,
                boundaryGap: false,
                axisLine: { onZero: false },
                splitLine: { show: false },
                min: 'dataMin',
                max: 'dataMax',
                axisPointer: {
                  z: 100
                }
              },
              {
                type: 'category',
                gridIndex: 1,
                data: data.categoryData,
                scale: true,
                boundaryGap: false,
                axisLine: { onZero: false },
                axisTick: { show: false },
                splitLine: { show: false },
                axisLabel: { show: false },
                min: 'dataMin',
                max: 'dataMax'
              }
            ],
            yAxis: [
              {
                scale: true,
                splitArea: {
                  show: true
                }
              },
              {
                scale: true,
                gridIndex: 1,
                splitNumber: 2,
                axisLabel: { show: false },
                axisLine: { show: false },
                axisTick: { show: false },
                splitLine: { show: false }
              }
            ],
            dataZoom: [
              {
                type: 'inside',
                xAxisIndex: [0, 1],
                start: startDate,
                end: 100
              },
              {
                show: true,
                xAxisIndex: [0, 1],
                type: 'slider',
                top: '85%',
                start: startDate,
                end: 100
              }
            ],
            series: [
              {
                name:
                  this.currentStock === 1
                    ? '日K'
                    : this.currentStock === 2
                      ? '周K'
                      : this.currentStock === 3
                        ? '月K'
                        : '',
                type: 'candlestick',
                data: data.values,
                itemStyle: {
                  color: upColor,
                  color0: downColor,
                  borderColor: undefined,
                  borderColor0: undefined
                },
                barMinWidth: 3,
                barMaxWidth: 20
                // barWidth:data.values.length<100?10:''
              },
              {
                name:
                  this.currentStock === 1
                    ? '5日K'
                    : this.currentStock === 2
                      ? '5周K'
                      : this.currentStock === 3
                        ? '5月K'
                        : '',
                type: 'line',
                data: calculateMA(5, data),
                smooth: true,
                lineStyle: {
                  opacity: 0.5
                }
              },
              {
                name:
                  this.currentStock === 1
                    ? '10日K'
                    : this.currentStock === 2
                      ? '10周K'
                      : this.currentStock === 3
                        ? '10月K'
                        : '',
                type: 'line',
                data: calculateMA(10, data),
                smooth: true,
                lineStyle: {
                  opacity: 0.5
                }
              },
              {
                name:
                  this.currentStock === 1
                    ? '20日K'
                    : this.currentStock === 2
                      ? '20周K'
                      : this.currentStock === 3
                        ? '20月K'
                        : '',
                type: 'line',
                data: calculateMA(20, data),
                smooth: true,
                lineStyle: {
                  opacity: 0.5
                }
              },
              {
                name:
                  this.currentStock === 1
                    ? '30日K'
                    : this.currentStock === 2
                      ? '30周K'
                      : this.currentStock === 3
                        ? '30月K'
                        : '',
                type: 'line',
                data: calculateMA(30, data),
                smooth: true,
                lineStyle: {
                  opacity: 0.5
                }
              },
              {
                name: '交易量',
                type: 'bar',
                xAxisIndex: 1,
                yAxisIndex: 1,
                barMinWidth: 3,
                barMaxWidth: 20,
                // barWidth:data.values.length<100?10:'',
                data: data.volumes
              }
            ]
          },
          true
        )
      })
    },
    // 根据企业编号获取上市企业高管信息
    getShLeaderInfo () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/shLeaderposn/getShleaderposn/${this.companyCode}?currpage=1&size=100`
      ).then((res) => {
        this.executive = res.data.data.list
      })
    },

    // 判断是否解锁
    lockStatus () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/uc/intention/company/check/${this.companyCode}`
      ).then((res) => {
        if (res.data.data) {
          this.lock = 1
          this.Axios.get(
            `https://zcjrys.chinaindustria.com/api/jrys/uc/intention/company/person/count/${this.companyCode}`
          ).then((res) => {
            this.unlockInfo = res.data.data
          })
        }
      })
    },
    // 解锁信息 2-解锁 3-委托
    unlockInformation (type) {
      const params = {
        companyNumber: this.companyCode,
        unlockStatus: type
      }
      this.Axios.post(
        'https://zcjrys.chinaindustria.com/api/jrys/uc/intention/company/save',
        params
      ).then((res) => {
        if (type === 2) {
          this.lock = 1
        }
      })
    },
    unlockContact () {
      this.unlockInformation(2)
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/uc/intention/company/person/count/${this.companyCode}`
      ).then((res) => {
        this.unlockInfo = res.data.data
        this.contactDialog = true
      })
    },
    // 主要人员
    getMajor () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/leaderposn/list/${this.companyCode}`
      ).then((res) => {
        this.major = res.data.data
      })
    },
    // 财务指标
    getFinancial () {
      this.targetNum = []
      const codeA = this.stockDetail.market === 1 ? 'SH' : 'SZ'
      const codeB = this.security.secuCode
      this.Axios.post(
        `https://zcjrys.chinaindustria.com/api/jrys/eastmoney/financeAnalysis/financialIndicators?code=${
          codeA + codeB
        }`,
        {}
      ).then((res) => {
        this.areaAllData = []
        let number = 0
        res.data.data.zygcfx.forEach((i, index) => {
          if (number === 5) return
          if (number === 0) {
            this.areaAllData.push({
              date: i.REPORT_DATE,
              num: [
                {
                  area: i.ITEM_NAME,
                  mainBusinessIncome: i.MAIN_BUSINESS_INCOME,
                  mbiRatio: i.MBI_RATIO,
                  mainBusinessCost: i.MAIN_BUSINESS_COST,
                  mbcRatio: i.MBC_RATIO,
                  mainBusinessRpofit: i.MAIN_BUSINESS_RPOFIT,
                  mbrRatio: i.MBR_RATIO,
                  grossRpofitRatio: i.GROSS_RPOFIT_RATIO
                }
              ]
            })
            number++
          } else if (i.REPORT_DATE === this.areaAllData[number - 1].date) {
            this.areaAllData[number - 1].num.push({
              area: i.ITEM_NAME,
              mainBusinessIncome: i.MAIN_BUSINESS_INCOME,
              mbiRatio: i.MBI_RATIO,
              mainBusinessCost: i.MAIN_BUSINESS_COST,
              mbcRatio: i.MBC_RATIO,
              mainBusinessRpofit: i.MAIN_BUSINESS_RPOFIT,
              mbrRatio: i.MBR_RATIO,
              grossRpofitRatio: i.GROSS_RPOFIT_RATIO
            })
          } else {
            this.areaAllData.push({
              date: i.REPORT_DATE,
              num: [
                {
                  area: i.ITEM_NAME,
                  mainBusinessIncome: i.MAIN_BUSINESS_INCOME,
                  mbiRatio: i.MBI_RATIO,
                  mainBusinessCost: i.MAIN_BUSINESS_COST,
                  mbcRatio: i.MBC_RATIO,
                  mainBusinessRpofit: i.MAIN_BUSINESS_RPOFIT,
                  mbrRatio: i.MBR_RATIO,
                  grossRpofitRatio: i.GROSS_RPOFIT_RATIO
                }
              ]
            })
            number++
          }
        })
        this.chooseAreaDate(this.areaAllData[0], 0)
      })
      this.Axios.post(
        `https://zcjrys.chinaindustria.com/api/jrys/eastmoney/financeAnalysis/financialIndicators2?code=${codeB}`
      ).then((res) => {
        for (let i = 0; i < res.data.data.length; i++) {
          this.targetNum.push({
            date: res.data.data[i].reportDate,
            a:
              res.data.data[i].netProfitAttributableToEquityHoldersYOY &&
              res.data.data[i].netProfitAttributableToEquityHoldersYOY.toFixed(
                2
              ),
            b:
              res.data.data[i].incomeYOYGrowthRate &&
              res.data.data[i].incomeYOYGrowthRate.toFixed(2),
            c:
              res.data.data[i].weightedYieldOnNetAssets &&
              res.data.data[i].weightedYieldOnNetAssets.toFixed(2),
            d:
              res.data.data[i].salesGrossMargin &&
              res.data.data[i].salesGrossMargin.toFixed(2),

            e:
              res.data.data[i].operatingIncome &&
              (res.data.data[i].operatingIncome / 100000000).toFixed(2),
            f:
              res.data.data[i].grossProfit &&
              (res.data.data[i].grossProfit / 100000000).toFixed(2),
            g:
              res.data.data[i].netProfitAttributableToEquityHolders &&
              (
                res.data.data[i].netProfitAttributableToEquityHolders /
                100000000
              ).toFixed(2),

            h:
              res.data.data[i].totalAssets &&
              (res.data.data[i].totalAssets / 10000).toFixed(2),
            i:
              res.data.data[i].totalLiabilities &&
              (res.data.data[i].totalLiabilities / 10000).toFixed(2),
            j:
              res.data.data[i].shareholdersEquityTotal &&
              (res.data.data[i].shareholdersEquityTotal / 10000).toFixed(2),
            k:
              res.data.data[i].currentAssets &&
              (res.data.data[i].currentAssets / 10000).toFixed(2),
            l:
              res.data.data[i].liquidityLiabilities &&
              (res.data.data[i].liquidityLiabilities / 10000).toFixed(2),

            m:
              res.data.data[i].netCFFromOperatingActivities &&
              (
                res.data.data[i].netCFFromOperatingActivities / 100000000
              ).toFixed(2),
            n:
              res.data.data[i].netCFFromInvestingActivities &&
              (
                res.data.data[i].netCFFromInvestingActivities / 100000000
              ).toFixed(2),
            o:
              res.data.data[i].netCFFromFinancingActivities &&
              (
                res.data.data[i].netCFFromFinancingActivities / 100000000
              ).toFixed(2),
            p:
              res.data.data[i].netCFActivities &&
              (res.data.data[i].netCFActivities / 100000000).toFixed(2)
          })
        }
      })
    },
    // 个股概况右侧echarts
    financeAnalysis () {
      const xArr = []
      const ayArr1 = []
      const ayArr2 = []
      const byArr1 = []
      const byArr2 = []
      const cyArr1 = []
      const cyArr2 = []
      const dyArr1 = []
      const dyArr2 = []
      const eyArr1 = []
      const eyArr2 = []
      const fyArr1 = []
      const fyArr2 = []
      const gyArr1 = []
      const gyArr2 = []
      const hyArr1 = []
      const hyArr2 = []
      const iyArr1 = []
      const iyArr2 = []
      const jyArr1 = []
      const jyArr2 = []
      this.Axios.post(
        `https://zcjrys.chinaindustria.com/api/jrys/eastmoney/financeAnalysis/indicatorsDetails?code=${this.security.secuCode}&companyCode=${this.companyCode}&companyName=${this.companyInfo.companyName}`
      ).then((res) => {
        for (let i = 0; i < 5; i++) {
          xArr.push(res.data.data.avg[i].reportDate)
          ayArr2.push(res.data.data.avg[i].list.incomeYOYGrowthRate.toFixed(2))
          byArr2.push(
            res.data.data.avg[
              i
            ].list.netProfitAttributableToEquityHoldersYOY.toFixed(2)
          )
          cyArr2.push(res.data.data.avg[i].list.salesGrossMargin.toFixed(2))
          dyArr2.push(
            res.data.data.avg[i].list.weightedYieldOnNetAssets.toFixed(2)
          )
          fyArr2.push(res.data.data.avg[i].list.assetLiabilityRatio.toFixed(2))
          gyArr2.push(res.data.data.avg[i].list.liquidityRatio.toFixed(2))
          hyArr2.push(
            (res.data.data.avg[i].list.inventoryTurnoverRatio * 100).toFixed(2)
          )
          iyArr2.push(
            (
              res.data.data.avg[i].list.netCFFromOperatingActivities / 100000000
            ).toFixed(2)
          )
          jyArr2.push(
            (res.data.data.avg[i].list.netCFActivities / 100000000).toFixed(2)
          )
        }
        for (let i = 0; i < 5; i++) {
          ayArr1.push(
            res.data.data.self[i].list[0].incomeYOYGrowthRate.toFixed(2)
          )
          byArr1.push(
            res.data.data.self[
              i
            ].list[0].netProfitAttributableToEquityHoldersYOY.toFixed(2)
          )
          cyArr1.push(
            res.data.data.self[i].list[0].salesGrossMargin.toFixed(2)
          )
          dyArr1.push(
            res.data.data.self[i].list[0].weightedYieldOnNetAssets.toFixed(2)
          )
          eyArr1.push(
            res.data.data.self[i].list[0].salesGrossMargin.toFixed(2)
          )
          eyArr2.push(res.data.data.self[i].list[0].salesMargin.toFixed(2))
          fyArr1.push(
            res.data.data.self[i].list[0].assetLiabilityRatio.toFixed(2)
          )
          gyArr1.push(res.data.data.self[i].list[0].liquidityRatio.toFixed(2))
          hyArr1.push(
            (
              res.data.data.self[i].list[0].inventoryTurnoverRatio * 100
            ).toFixed(2)
          )
          iyArr1.push(
            (
              res.data.data.self[i].list[0].netCFFromOperatingActivities /
              100000000
            ).toFixed(2)
          )
          jyArr1.push(
            (res.data.data.self[i].list[0].netCFActivities / 100000000).toFixed(
              2
            )
          )
        }
        this.growChart(xArr, ayArr1, ayArr2)
        this.netProfitChart(xArr, byArr1, byArr2)

        this.grossMarginChart(xArr, cyArr1, cyArr2)
        this.roeChart(xArr, dyArr1, dyArr2)
        this.opmAnpmChart(xArr, eyArr1, eyArr2)

        this.alrChart(xArr, fyArr1, fyArr2)
        this.cirChart(xArr, gyArr1, gyArr2)

        this.tatChart(xArr, hyArr1, hyArr2)

        this.ocfChart(xArr, iyArr1, iyArr2)
        this.ncfChart(xArr, jyArr1, jyArr2)
      })
    },
    // 选择时期
    chooseAreaDate (obj, index) {
      this.reportDate = obj.date
      this.areaNum = this.areaAllData[index].num
      this.pieChart()
    },
    // 指标 成长能力 营收同比
    growChart (xArr, yArr1, yArr2) {
      const chart = this.Echarts.init(document.getElementById('growChart'))
      const option = {
        title: {
          text: '营收同比（%）',
          left: 'center',
          top: 0,
          textStyle: {
            fontSize: 16,
            color: '#333'
          }
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '1%',
          right: '5%',
          bottom: '5%',
          top: '20%',
          containLabel: true
        },
        legend: {
          data: [this.companyInfo.companyName, '行业均值'],
          left: '0%',
          top: '10%',
          textStyle: {
            color: '#666666',
            fontSize: 14
          },
          itemWidth: 8,
          itemHeight: 8,
          itemGap: 25
        },
        xAxis: {
          type: 'category',
          data: xArr,
          axisLine: {
            lineStyle: {
              color: '#cdd5e2'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#666666'
            }
          }
        },
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: false,
              lineStyle: {
                color: '#cdd5e2'
              }
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: '#666666'
              }
            }
          }
        ],
        series: [
          {
            name: this.companyInfo.companyName,
            type: 'bar',
            barWidth: '20px',
            itemStyle: {
              normal: {
                color: new this.Echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#15659E'
                  },
                  {
                    offset: 1,
                    color: '#fff'
                  }
                ])
              }
            },
            data: yArr1
          },
          {
            name: '行业均值',
            type: 'bar',
            barWidth: '20px',
            itemStyle: {
              normal: {
                color: new this.Echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#FF9A00'
                  },
                  {
                    offset: 1,
                    color: '#fff'
                  }
                ])
              }
            },
            data: yArr2
          }
        ]
      }
      chart.setOption(option)
    },
    // 指标 成长能力 净利润同比
    netProfitChart (xArr, yArr1, yArr2) {
      const chart = this.Echarts.init(
        document.getElementById('netProfitChart')
      )
      const option = {
        title: {
          text: '净利润同比（%）',
          left: 'center',
          top: 0,
          textStyle: {
            fontSize: 16,
            color: '#333'
          }
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '1%',
          right: '5%',
          bottom: '5%',
          top: '20%',
          containLabel: true
        },
        legend: {
          data: [this.companyInfo.companyName, '行业均值'],
          left: '0%',
          top: '10%',
          textStyle: {
            color: '#666666',
            fontSize: 14
          },
          itemWidth: 8,
          itemHeight: 8,
          itemGap: 25
        },
        xAxis: {
          type: 'category',
          data: xArr,
          axisLine: {
            lineStyle: {
              color: '#cdd5e2'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#666666'
            }
          }
        },
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: false,
              lineStyle: {
                color: '#cdd5e2'
              }
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: '#666666'
              }
            }
          }
        ],
        series: [
          {
            name: this.companyInfo.companyName,
            type: 'bar',
            barWidth: '20px',
            itemStyle: {
              normal: {
                color: new this.Echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#15659E'
                  },
                  {
                    offset: 1,
                    color: '#fff'
                  }
                ])
              }
            },
            data: yArr1
          },
          {
            name: '行业均值',
            type: 'bar',
            barWidth: '20px',
            itemStyle: {
              normal: {
                color: new this.Echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#FF9A00'
                  },
                  {
                    offset: 1,
                    color: '#fff'
                  }
                ])
              }
            },
            data: yArr2
          }
        ]
      }
      chart.setOption(option)
    },
    // 指标 盈利能力 销售毛利率
    grossMarginChart (xArr, yArr1, yArr2) {
      const chart = this.Echarts.init(
        document.getElementById('grossMarginChart')
      )
      const option = {
        title: {
          text: '销售毛利率（%）',
          left: 'center',
          top: 0,
          textStyle: {
            fontSize: 16,
            color: '#333'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: [this.companyInfo.companyName, '行业均值'],
          left: '0%',
          top: '10%',
          textStyle: {
            color: '#666666',
            fontSize: 14
          }
        },
        grid: {
          left: '1%',
          right: '5%',
          bottom: '5%',
          top: '20%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: xArr
          }
        ],
        yAxis: [
          {
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            type: 'value'
          }
        ],
        series: [
          {
            name: this.companyInfo.companyName,
            type: 'line',
            stack: 'Total',
            symbolSize: 8,
            itemStyle: {
              normal: {
                color: '#15659E'
              }
            },
            areaStyle: {
              normal: {
                color: new this.Echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#15659E'
                  },
                  {
                    offset: 1,
                    color: '#fff'
                  }
                ])
              }
            },
            emphasis: {
              focus: 'series'
            },
            data: yArr1
          },
          {
            name: '行业均值',
            type: 'line',
            symbolSize: 8,
            itemStyle: {
              normal: {
                color: '#FF9A00'
              }
            },
            areaStyle: {
              normal: {
                color: new this.Echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#FF9A00'
                  },
                  {
                    offset: 1,
                    color: '#fff'
                  }
                ])
              }
            },
            emphasis: {
              focus: 'series'
            },
            data: yArr2
          }
        ]
      }
      chart.setOption(option)
    },
    // 指标 盈利能力 净资产收益率
    roeChart (xArr, yArr1, yArr2) {
      const chart = this.Echarts.init(document.getElementById('roeChart'))
      const option = {
        title: {
          text: '净资产收益率（%）',
          left: 'center',
          top: 0,
          textStyle: {
            fontSize: 16,
            color: '#333'
          }
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '1%',
          right: '5%',
          bottom: '5%',
          top: '20%',
          containLabel: true
        },
        legend: {
          data: [this.companyInfo.companyName, '行业均值'],
          left: '0%',
          top: '10%',
          textStyle: {
            color: '#666666',
            fontSize: 14
          },
          itemWidth: 8,
          itemHeight: 8,
          itemGap: 25
        },
        xAxis: {
          type: 'category',
          data: xArr,
          axisLine: {
            lineStyle: {
              color: '#cdd5e2'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#666666'
            }
          }
        },
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: false,
              lineStyle: {
                color: '#cdd5e2'
              }
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: '#666666'
              }
            }
          }
        ],
        series: [
          {
            name: this.companyInfo.companyName,
            type: 'bar',
            barWidth: '20px',
            itemStyle: {
              normal: {
                color: new this.Echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#15659E'
                  },
                  {
                    offset: 1,
                    color: '#fff'
                  }
                ])
              }
            },
            data: yArr1
          },
          {
            name: '行业均值',
            type: 'bar',
            barWidth: '20px',
            itemStyle: {
              normal: {
                color: new this.Echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#FF9A00'
                  },
                  {
                    offset: 1,
                    color: '#fff'
                  }
                ])
              }
            },
            data: yArr2
          }
        ]
      }
      chart.setOption(option)
    },
    // 指标 盈利能力 经营利润率和净利润率
    opmAnpmChart (xArr, yArr1, yArr2) {
      const chart = this.Echarts.init(document.getElementById('opmAnpmChart'))
      const option = {
        title: {
          text: '经营利润率和净利润率（%）',
          left: 'center',
          top: 0,
          textStyle: {
            fontSize: 16,
            color: '#333'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: ['经营利润率', '净利润率'],
          left: '0%',
          top: '10%',
          textStyle: {
            color: '#666666',
            fontSize: 14
          }
        },
        grid: {
          left: '1%',
          right: '5%',
          bottom: '5%',
          top: '20%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: xArr
          }
        ],
        yAxis: [
          {
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            type: 'value'
          }
        ],
        series: [
          {
            name: '经营利润率',
            type: 'line',
            symbolSize: 8,
            itemStyle: {
              normal: {
                color: '#15659E'
              }
            },
            areaStyle: {
              normal: {
                color: new this.Echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#15659E'
                  },
                  {
                    offset: 1,
                    color: '#fff'
                  }
                ])
              }
            },
            emphasis: {
              focus: 'series'
            },
            data: yArr1
          },
          {
            name: '净利润率',
            type: 'line',
            stack: 'Total',
            symbolSize: 8,
            itemStyle: {
              normal: {
                color: '#FF9A00'
              }
            },
            areaStyle: {
              normal: {
                color: new this.Echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#FF9A00'
                  },
                  {
                    offset: 1,
                    color: '#fff'
                  }
                ])
              }
            },
            emphasis: {
              focus: 'series'
            },
            data: yArr2
          }
        ]
      }
      chart.setOption(option)
    },
    // 指标 偿债能力 资产负债率
    alrChart (xArr, yArr1, yArr2) {
      const chart = this.Echarts.init(document.getElementById('alrChart'))
      const option = {
        title: {
          text: '资产负债率（%）',
          left: 'center',
          top: 0,
          textStyle: {
            fontSize: 16,
            color: '#333'
          }
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '1%',
          right: '5%',
          bottom: '5%',
          top: '20%',
          containLabel: true
        },
        legend: {
          data: [this.companyInfo.companyName, '行业均值'],
          left: '0%',
          top: '10%',
          textStyle: {
            color: '#666666',
            fontSize: 14
          },
          itemWidth: 8,
          itemHeight: 8,
          itemGap: 25
        },
        xAxis: {
          type: 'category',
          data: xArr,
          axisLine: {
            lineStyle: {
              color: '#cdd5e2'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#666666'
            }
          }
        },
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: false,
              lineStyle: {
                color: '#cdd5e2'
              }
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: '#666666'
              }
            }
          }
        ],
        series: [
          {
            name: this.companyInfo.companyName,
            type: 'bar',
            barWidth: '20px',
            itemStyle: {
              normal: {
                color: new this.Echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#15659E'
                  },
                  {
                    offset: 1,
                    color: '#fff'
                  }
                ])
              }
            },
            data: yArr1
          },
          {
            name: '行业均值',
            type: 'bar',
            barWidth: '20px',
            itemStyle: {
              normal: {
                color: new this.Echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#FF9A00'
                  },
                  {
                    offset: 1,
                    color: '#fff'
                  }
                ])
              }
            },
            data: yArr2
          }
        ]
      }
      chart.setOption(option)
    },
    // 指标 偿债能力 流动利率
    cirChart (xArr, yArr1, yArr2) {
      const chart = this.Echarts.init(document.getElementById('cirChart'))
      const option = {
        title: {
          text: '流动利率（%）',
          left: 'center',
          top: 0,
          textStyle: {
            fontSize: 16,
            color: '#333'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: [this.companyInfo.companyName, '行业均值'],
          left: '0%',
          top: '10%',
          textStyle: {
            color: '#666666',
            fontSize: 14
          }
        },
        grid: {
          left: '1%',
          right: '5%',
          bottom: '5%',
          top: '20%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: xArr
          }
        ],
        yAxis: [
          {
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            type: 'value'
          }
        ],
        series: [
          {
            name: this.companyInfo.companyName,
            type: 'line',
            symbolSize: 8,
            itemStyle: {
              normal: {
                color: '#15659E'
              }
            },
            areaStyle: {
              normal: {
                color: new this.Echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#15659E'
                  },
                  {
                    offset: 1,
                    color: '#fff'
                  }
                ])
              }
            },
            emphasis: {
              focus: 'series'
            },
            data: yArr1
          },
          {
            name: '行业均值',
            type: 'line',
            stack: 'Total',
            symbolSize: 8,
            itemStyle: {
              normal: {
                color: '#FF9A00'
              }
            },
            areaStyle: {
              normal: {
                color: new this.Echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#FF9A00'
                  },
                  {
                    offset: 1,
                    color: '#fff'
                  }
                ])
              }
            },
            emphasis: {
              focus: 'series'
            },
            data: yArr2
          }
        ]
      }
      chart.setOption(option)
    },
    // 指标 营运能力 总资产周转率
    tatChart (xArr, yArr1, yArr2) {
      const chart = this.Echarts.init(document.getElementById('tatChart'))
      const option = {
        title: {
          text: '总资产周转率（%）',
          left: 'center',
          top: 0,
          textStyle: {
            fontSize: 16,
            color: '#333'
          }
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '1%',
          right: '5%',
          bottom: '5%',
          top: '20%',
          containLabel: true
        },
        legend: {
          data: [this.companyInfo.companyName, '行业均值'],
          left: '0%',
          top: '10%',
          textStyle: {
            color: '#666666',
            fontSize: 14
          },
          itemWidth: 8,
          itemHeight: 8,
          itemGap: 25
        },
        xAxis: {
          type: 'category',
          data: xArr,
          axisLine: {
            lineStyle: {
              color: '#cdd5e2'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#666666'
            }
          }
        },
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: false,
              lineStyle: {
                color: '#cdd5e2'
              }
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: '#666666'
              }
            }
          }
        ],
        series: [
          {
            name: this.companyInfo.companyName,
            type: 'bar',
            barWidth: '20px',
            itemStyle: {
              normal: {
                color: new this.Echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#15659E'
                  },
                  {
                    offset: 1,
                    color: '#fff'
                  }
                ])
              }
            },
            data: yArr1
          },
          {
            name: '行业均值',
            type: 'bar',
            barWidth: '20px',
            itemStyle: {
              normal: {
                color: new this.Echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#FF9A00'
                  },
                  {
                    offset: 1,
                    color: '#fff'
                  }
                ])
              }
            },
            data: yArr2
          }
        ]
      }
      chart.setOption(option)
    },
    // 指标 现金流能力 营业性现金流
    ocfChart (xArr, yArr1, yArr2) {
      const chart = this.Echarts.init(document.getElementById('ocfChart'))
      const option = {
        title: {
          text: '营业性现金流（亿元）',
          left: 'center',
          top: 0,
          textStyle: {
            fontSize: 16,
            color: '#333'
          }
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '1%',
          right: '5%',
          bottom: '5%',
          top: '20%',
          containLabel: true
        },
        legend: {
          data: [this.companyInfo.companyName, '行业均值'],
          left: '0%',
          top: '10%',
          textStyle: {
            color: '#666666',
            fontSize: 14
          },
          itemWidth: 8,
          itemHeight: 8,
          itemGap: 25
        },
        xAxis: {
          type: 'category',
          data: xArr,
          axisLine: {
            lineStyle: {
              color: '#cdd5e2'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#666666'
            }
          }
        },
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: false,
              lineStyle: {
                color: '#cdd5e2'
              }
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: '#666666'
              }
            }
          }
        ],
        series: [
          {
            name: this.companyInfo.companyName,
            type: 'bar',
            barWidth: '20px',
            itemStyle: {
              normal: {
                color: new this.Echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#15659E'
                  },
                  {
                    offset: 1,
                    color: '#fff'
                  }
                ])
              }
            },
            data: yArr1
          },
          {
            name: '行业均值',
            type: 'bar',
            barWidth: '20px',
            itemStyle: {
              normal: {
                color: new this.Echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#FF9A00'
                  },
                  {
                    offset: 1,
                    color: '#fff'
                  }
                ])
              }
            },
            data: yArr2
          }
        ]
      }
      chart.setOption(option)
    },
    // 指标 现金流能力 净现金流
    ncfChart (xArr, yArr1, yArr2) {
      const chart = this.Echarts.init(document.getElementById('ncfChart'))
      const option = {
        title: {
          text: '净现金流（亿元）',
          left: 'center',
          top: 0,
          textStyle: {
            fontSize: 16,
            color: '#333'
          }
        },
        tooltip: {
          trigger: 'item',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          left: '1%',
          right: '5%',
          bottom: '5%',
          top: '20%',
          containLabel: true
        },
        legend: {
          data: [this.companyInfo.companyName, '行业均值'],
          left: '0%',
          top: '10%',
          textStyle: {
            color: '#666666',
            fontSize: 14
          },
          itemWidth: 8,
          itemHeight: 8,
          itemGap: 25
        },
        xAxis: {
          type: 'category',
          data: xArr,
          axisLine: {
            lineStyle: {
              color: '#cdd5e2'
            }
          },
          axisLabel: {
            textStyle: {
              color: '#666666'
            }
          }
        },
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: false,
              lineStyle: {
                color: '#cdd5e2'
              }
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              textStyle: {
                color: '#666666'
              }
            }

          }
        ],
        series: [
          {
            name: this.companyInfo.companyName,
            type: 'bar',
            barWidth: '20px',
            itemStyle: {
              normal: {
                color: new this.Echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#15659E'
                  },
                  {
                    offset: 1,
                    color: '#fff'
                  }
                ])
              }
            },
            data: yArr1
          },
          {
            name: '行业均值',
            type: 'bar',
            barWidth: '20px',
            itemStyle: {
              normal: {
                color: new this.Echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#FF9A00'
                  },
                  {
                    offset: 1,
                    color: '#fff'
                  }
                ])
              }
            },
            data: yArr2
          }
        ]
      }
      chart.setOption(option)
    },
    // 个股概况
    pieChart () {
      const data = []
      this.areaNum.forEach((i) => {
        data.push({ name: i.area, value: i.mainBusinessIncome })
      })
      const chart = this.Echarts.init(document.getElementById('areaBusiness'))
      const option = {
        color: [
          '#5B8FF9',
          '#5AD8A6',
          '#5D7092',
          '#F6BD16',
          '#E8684A',
          '#6DC8EC',
          '#9270CA',
          '#FF9D4D',
          '#269A99',
          '#9C5471'
        ],
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {d}%'
        },
        toolbox: {
          show: true
        },
        legend: {
          orient: 'vertical',
          right: '20%',
          align: 'left',
          top: '20%',
          icon: 'circle',
          itemWidth: 12,
          itemHeight: 12,
          textStyle: {
            color: '#8C8C8C'
          }
        },
        series: [
          {
            name: ' ',
            type: 'pie',
            radius: ['0', '70%'],
            center: ['30%', '45%'],
            label: {
              normal: {
                position: 'inside',
                align: 'right',
                formatter: '{d}%'
              }
            },
            data: data
          }
        ]
      }
      chart.setOption(option)
    },
    // 第三页 基本信息
    getBaseInfo () {
      this.shSecuHolder()
      // this.equityPenetrate();
      this.directInvest()
      this.changeInfo()
      this.getShareholderInfoByCompanyCode()
      this.actualControl()
    },
    // 公告披露股东
    shSecuHolder () {
      this.shareholderDate = []
      this.disclosureShareholders = []
      this.Axios.post(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/shSecuHolder/pageList/${this.companyCode}`
      ).then((res) => {
        for (const i in res.data.data) {
          this.shareholderDate.push(i)
          this.allShareholders.push(res.data.data[i])
        }
        this.chooseDate(0)
      })
    },
    handleExecutiveChange (val) {
      this.executivePage = val
    },
    handleCoreChange (val) {
      this.corePage = val
    },
    // 对外投资
    equityPenetrate () {
      this.Axios.post(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/equityPenetrate/page?currPage=${this.investmentPage}&size=10`,
        {
          companyCode: this.companyCode
        }
      ).then((res) => {
        this.investment = res.data.data.list
        this.investmentTotal = res.data.data.total
      })
    },
    handleInvestChange (val) {
      this.investmentPage = val
      this.equityPenetrate()
    },
    // 直接对外投资
    directInvest () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/invest/page/${this.companyCode}?currPage=${this.directInvestmentPage}&size=10`
      ).then((res) => {
        this.directInvestment = res.data.data.list
        this.directInvestmentTotal = res.data.data.total
      })
    },
    handleDirectInvestChange (val) {
      this.directInvestmentPage = val
      this.directInvest()
    },
    // 变更记录
    changeInfo () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/changeInfo/changeInfo/${this.companyCode}?currPage=${this.recordPage}&size=10`
      ).then((res) => {
        this.record = res.data.data.list
        this.recordTotal = res.data.data.total
      })
    },
    handleChangeChange (val) {
      this.recordPage = val
      this.changeInfo()
    },
    // 股东信息
    getShareholderInfoByCompanyCode () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/shareholderEpPub/getShareholderInfoByCompanyCode/${this.companyCode}?currPage=${this.shareholderPage}&size=10`
      ).then((res) => {
        this.shareholder = res.data.data.list
        this.shareholderTotal = res.data.data.total
      })
    },
    handleHolderChange (val) {
      this.shareholderPage = val
      this.getShareholderInfoByCompanyCode()
    },
    // 疑似控制人
    actualControl () {
      this.Axios.post(
        'https://zcjrys.chinaindustria.com/api/jrys/companypic/actualControl/page?currPage=1&size=100',
        {
          companyCode: this.companyCode
        }
      ).then((res) => {
        this.controller = res.data.data.list
      })
    },
    // 第四页 重点关注
    getAttention () {
      this.queryCourtAnnounce()
      this.queryCourtSession()
      this.queryCourtRegister()
      this.queryFaithExecutee()
      this.queryPunishmentBsPub()
      this.queryLawsuit()
      this.queryExecutee()
    },
    // 法院公告
    queryCourtAnnounce () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/courtAnnounce/queryCourtAnnounce/${this.companyCode}?currPage=${this.courtNoticePage}&size=10`
      ).then((res) => {
        this.courtNotice = res.data.data.list
        this.courtNoticeTotal = res.data.data.total
      })
    },
    handleCourtChange (val) {
      this.courtNoticePage = val
      this.queryCourtAnnounce()
    },
    // 开庭公告
    queryCourtSession () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/courtSession/queryCourtSession/${this.companyCode}?currPage=${this.holdcourtNoticePage}&size=10`
      ).then((res) => {
        this.holdCourtNotice = res.data.data.list
        this.holdCourtNoticeTotal = res.data.data.total
      })
    },
    handleSessionChange (val) {
      this.holdcourtNoticePage = val
      this.queryCourtSession()
    },
    // 庭审信息
    queryCourtRegister () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/courtRegister/queryCourtRegister/${this.companyCode}?currPage=${this.courtInformationPage}&size=10`
      ).then((res) => {
        this.courtInformation = res.data.data.list
        this.courtInformationTotal = res.data.data.total
      })
    },
    handleInformationChange (val) {
      this.courtInformationPage = val
      this.queryCourtRegister()
    },
    // 失信被执行人
    queryFaithExecutee () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/faithExecutee/queryFaithExecutee/${this.companyCode}?currPage=${this.executedPage}&size=10`
      ).then((res) => {
        this.executed = res.data.data.list
        this.executedTotal = res.data.data.total
      })
    },
    handleExecutedChange (val) {
      this.executedPage = val
      this.queryFaithExecutee()
    },
    // 行政处罚
    queryPunishmentBsPub () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/punishmentBsPub/queryPunishmentBsPub/${this.companyCode}?currPage=${this.sanctionPage}&size=10`
      ).then((res) => {
        this.sanction = res.data.data.list
        this.sanctionTotal = res.data.data.total
      })
    },
    handleSanctionChange (val) {
      this.sanctionPage = val
      this.queryPunishmentBsPub()
    },
    // 法律诉讼
    queryLawsuit () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/lawsuit/queryLawsuit/${this.companyCode}?currPage=${this.litigationPage}&size=10`
      ).then((res) => {
        this.litigation = res.data.data.list
        this.litigationTotal = res.data.data.total
      })
    },
    handleLawsuitChange (val) {
      this.litigationPage = val
      this.queryLawsuit()
    },
    // 被执行人信息
    queryExecutee () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/executee/queryExecutee/${this.companyCode}?currPage=${this.executeePage}&size=10`
      ).then((res) => {
        this.executee = res.data.data.list
        this.executeeTotal = res.data.data.total
      })
    },
    handleExecuteeChange (val) {
      this.executeePage = val
      this.queryExecutee()
    },

    // 第五页 知识产权
    getKnowledge () {
      this.queryPatents()
      this.queryTrademarks()
      this.queryCopyrightWork()
      this.queryCertificate()
      this.queryIcp()
      this.queryCopyrightReg()
    },
    // 专利
    queryPatents () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/patents/queryPatents/${this.companyCode}?currPage=${this.patentPage}&size=10`
      ).then((res) => {
        this.patent = res.data.data.list
        this.patentTotal = res.data.data.total
      })
    },
    handlePatentChange (val) {
      this.patentPage = val
      this.queryPatents()
    },
    // 商标
    queryTrademarks () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/trademarks/queryTrademarks/${this.companyCode}?currPage=${this.trademarksPage}&size=10`
      ).then((res) => {
        this.trademark = res.data.data.list
        this.trademarkTotal = res.data.data.total
      })
    },
    handleTrademarksChange (val) {
      this.trademarksPage = val
      this.queryTrademarks()
    },
    // 作品著作权
    queryCopyrightWork () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/copyrightWorks/queryCopyrightWork/${this.companyCode}?currPage=${this.copyrightPage}&size=10`
      ).then((res) => {
        this.copyright = res.data.data.list
        this.copyrightTotal = res.data.data.total
      })
    },
    handleCopyrightChange (val) {
      this.copyrightPage = val
      this.queryCopyrightWork()
    },
    // 资质证书
    queryCertificate () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/certificates/queryCertificate/${this.companyCode}?currPage=${this.certificatePage}&size=10`
      ).then((res) => {
        this.certificate = res.data.data.list
        this.certificateTotal = res.data.data.total
      })
    },
    handleCertificateChange (val) {
      this.certificatePage = val
      this.queryCertificate()
    },
    // 网站
    queryIcp () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/icp/queryIcp/${this.companyCode}?currPage=${this.websitePage}&size=10`
      ).then((res) => {
        this.website = res.data.data.list
        this.websiteTotal = res.data.data.total
      })
    },
    handleWebsiteChange (val) {
      this.websitePage = val
      this.queryIcp()
    },
    // 软件著作权
    queryCopyrightReg () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/copyrightReg/queryCopyrightReg/${this.companyCode}?currPage=${this.softwarePage}&size=10`
      ).then((res) => {
        this.software = res.data.data.list
        this.softwareTotal = res.data.data.total
      })
    },
    handleSoftwareChange (val) {
      this.softwarePage = val
      this.queryCopyrightReg()
    },

    // 第六页 经营情况
    getOperation () {
      this.queryFinance()
      this.supplyChainRelationship()
      this.queryBuildingProject()
      this.queryMainInvestProject()
      this.queryLicencePub()
      this.queryProductInfo()
      this.queryCompetingProductInfo()
    },
    // 融资记录
    queryFinance () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/companyFinance/queryFinance/${this.companyCode}?currPage=${this.financingPage}&size=10`
      ).then((res) => {
        this.financingTotal = res.data.data.total
        this.financing = res.data.data.list
      })
    },
    handleFinancingChange (val) {
      this.financingPage = val
      this.queryFinance()
    },
    // 供应链
    supplyChainRelationship () {
      this.Axios.post(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/supplyChainRelationship/page?currPage=${this.supplyChainPage}&size=10`,
        { companyCode: this.companyCode }
      ).then((res) => {
        this.supplyChainTotal = res.data.data.total
        this.supplyChain = res.data.data.list
      })
    },
    handleSupplyChainChange (val) {
      this.supplyChainPage = val
      this.supplyChainRelationship()
    },
    // 重大在建项目
    queryBuildingProject () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/bulidingProject/queryBulidingProject/${this.companyCode}?currPage=${this.buildProjectPage}&size=10`
      ).then((res) => {
        this.buildProjectTotal = res.data.data.total
        this.buildProject = res.data.data.list
      })
    },
    handleBuildProjectChange (val) {
      this.buildProjectPage = val
      this.queryBuildingProject()
    },
    // 重大投资项目
    queryMainInvestProject () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/mainInvestProject/queryMainInvestProject/${this.companyCode}?currPage=${this.investProjectPage}&size=10`
      ).then((res) => {
        this.investProjectTotal = res.data.data.total
        this.investProject = res.data.data.list
      })
    },
    handleInvestProjectChange (val) {
      this.investProjectPage = val
      this.queryMainInvestProject()
    },
    // 行政许可
    queryLicencePub () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/licencePub/queryLicencePub/${this.companyCode}?currPage=${this.licencePage}&size=10`
      ).then((res) => {
        this.licence = res.data.data.list
        this.licenceTotal = res.data.data.total
      })
    },
    handleLicenceChange (val) {
      this.licencePage = val
      this.queryLicencePub()
    },
    // 产品信息
    queryProductInfo () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/productsInfo/queryProductInfo/${this.companyCode}?currPage=${this.productPage}&size=10`
      ).then((res) => {
        this.product = res.data.data.list
        this.productTotal = res.data.data.total
      })
    },
    handleProductChange (val) {
      this.productPage = val
      this.queryProductInfo()
    },
    // 竞品信息
    queryCompetingProductInfo () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/competingProductsInfo/queryCompetingProductInfo/${this.companyCode}?currPage=${this.competitorPage}&size=10`
      ).then((res) => {
        this.competitor = res.data.data.list
        this.competitorTotal = res.data.data.total
      })
    },
    handleCompetitorChange (val) {
      this.competitorPage = val
      this.queryCompetingProductInfo()
    },
    // 第七页 数据解读
    getDataAnalysis () {
      this.queryCourtLawSuit()
      this.queryCourtLawSuitTable()
    },
    checkList () {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/data/interpretation/check/${this.companyCode}`
      ).then((res) => {
        this.leftList = res.data.data
        this.dataChoose(0, 'actualControl')
      })
    },
    // 实际控制人
    actualChart (arr) {
      const name = this.companyInfo.companyName
      const chart = this.Echarts.init(document.getElementById('actualChart'))
      const data = {
        name:
          name.length > 6
            ? name.slice(0, name.length / 2) +
              '\n' +
              name.slice(name.length / 2)
            : name,
        symbol: 'circle',
        symbolSize: 120,
        itemStyle: {
          backgroundColor: '#ff6600'
        },
        label: {
          normal: {
            color: 'white',
            align: 'center',
            position: ['50%', '50%'],
            fontSize: 16,
            backgroundColor: {
              color: '#ff6600'
            }
          }
        },
        children: arr.children
      }
      const option = {
        series: [
          {
            type: 'tree',
            roam: true,
            top: '1%',
            left: '30%',
            bottom: '1%',
            right: '30%',
            symbol: 'circle',
            itemStyle: {
              color: '#417dff',
              borderColor: '#417dff'
            },
            label: {
              position: 'top',
              verticalAlign: 'middle',
              align: 'left',
              fontSize: 16
            },
            lineStyle: {
              color: '#417dff'
            },
            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left',
                fontSize: 16
              }
            },
            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750,
            data: [data]
          }
        ]
      }
      chart.setOption(option)
    },
    queryActual () {
      this.Axios.post(
        'https://zcjrys.chinaindustria.com/api/jrys/companypic/actualControl/list',
        {
          companyCode: this.companyCode
        }
      ).then((res) => {
        const arr = res.data.data
        this.actualChart(arr)
      })
    },
    // 对外下属投资方 图
    investChart (arr1, arr2, arr3) {
      const chart = this.Echarts.init(document.getElementById('investChart'))
      const name = this.companyInfo.companyName

      const arr = [...arr1, ...arr2, ...arr3]
      const categories = [
        {
          name: '企业',
          itemStyle: {
            normal: {
              color: '#417DFF'
            }
          }
        },
        {
          name: '自然人',
          itemStyle: {
            normal: {
              color: '#FF9900'
            }
          }
        },
        {
          name: '政府企事业单位',
          itemStyle: {
            normal: {
              color: '#24B5C3'
            }
          }
        }
      ]
      const data = {
        name:
          name.length > 6
            ? name.slice(0, name.length / 2) +
              '\n' +
              name.slice(name.length / 2)
            : name,
        value: 1,
        symbol: 'circle',
        symbolSize: 120,
        label: {
          color: 'white',
          fontSize: 16
        },
        itemStyle: {
          normal: {
            color: '#ff6600',
            borderColor: '#ff6600'
          }
        },
        children: []
      }

      arr.forEach((i, index) => {
        data.children.push({
          name: i.name,
          value: 12,
          label: {
            normal: {
              color: '#333',
              position: 'right'
            }
          }
        })
      })
      if (data.children.length > 15) {
        data.children = data.children.slice(0, 15)
        data.children.push({ name: '... ...' })
      }
      const option = {
        legend: [
          {
            selectedMode: false,
            data: categories.forEach((x) => x.name),
            icon: 'circle'
          }
        ],
        series: [
          {
            type: 'tree',
            roam: true,
            top: '1%',
            left: '30%',
            bottom: '1%',
            right: '30%',
            symbol: 'circle',
            itemStyle: {
              color: '#417dff',
              borderColor: '#417dff'
            },
            lineStyle: {
              color: '#417dff'
            },
            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left',
                fontSize: 16
              }
            },
            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750,
            data: [data]
          }
        ]
      }
      chart.setOption(option)
    },
    queryInvest () {
      this.Axios.post(
        'https://zcjrys.chinaindustria.com/api/jrys/companypic/equityPenetrate/list',
        {
          companyCode: this.companyCode
        }
      ).then((res) => {
        const arr1 = res.data.data
        const arr2 = []
        const arr3 = []
        this.investChart(arr1, arr2, arr3)
      })
    },
    // 对外下属投资方 表
    queryInvestTable () {
      this.Axios.post(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/equityPenetrate/page?currPage=${this.investTablePage}&size=10`,
        { companyCode: this.companyCode }
      ).then((res) => {
        this.investTable = res.data.data.list
        this.investTableTotal = res.data.data.total
      })
    },
    handleInvestTableChange (val) {
      this.investTablePage = val
      this.queryInvestTable()
    },
    // 供应链年份 分供应商和销售客户
    getChainYears () {
      this.Axios.post(
        'https://zcjrys.chinaindustria.com/api/jrys/companypic/supplyChainRelationship/yearSelect',
        {
          companyCode: this.companyCode
        }
      ).then((res) => {
        this.saleYears = res.data.data.saleList
        this.supplyYears = res.data.data.supplyList
        this.chainYears = this.supplyYears
      })
    },
    // 供应链关系方图
    chainChart (arr1, arr2) {
      if (arr1.length > 15) {
        arr1 = arr1.slice(0, 15)
        arr1.push({ name: '... ...' })
      }
      if (arr2.length > 15) {
        arr2 = arr2.slice(0, 15)
        arr2.push({ name: '... ...' })
      }
      const chart = this.Echarts.init(document.getElementById('chainChart'))
      const name = this.companyInfo.companyName
      const categories = [
        {
          name: '供应商',
          itemStyle: {
            normal: {
              color: '#417DFF'
            }
          }
        },
        {
          name: '客户方',
          itemStyle: {
            normal: {
              color: '#FF9900'
            }
          }
        }
      ]
      const data1 = {
        name:
          name.length > 6
            ? name.slice(0, name.length / 2) +
              '\n' +
              name.slice(name.length / 2)
            : name,
        symbol: 'circle',
        symbolSize: 120,
        label: {
          normal: {
            color: 'white',
            offset: [0, 0],
            position: 'inside',
            fontSize: 16,
            backgroundColor: {
              color: '#ff6600'
            }
          }
        },
        children: arr1
      }
      const data2 = {
        name:
          name.length > 6
            ? name.slice(0, name.length / 2) +
              '\n' +
              name.slice(name.length / 2)
            : name,
        symbol: 'circle',
        symbolSize: 120,
        label: {
          normal: {
            color: 'white',
            offset: [0, 0],
            position: 'inside',
            fontSize: 16,
            backgroundColor: {
              color: '#417dff'
            }
          }
        },
        children: arr2
      }
      const option = {
        legend: [
          {
            data: categories.forEach((x) => x.name),
            icon: 'circle'
          }
        ],
        series: [
          {
            name: '供应商',
            width: '30%',
            orient: 'RL',
            type: 'tree',
            roam: false,
            top: '1%',
            left: '20%',
            right: '50%',
            bottom: '1%',
            symbol: 'circle',
            z: 2,
            itemStyle: {
              color: '#417dff',
              borderColor: '#417dff'
            },
            lineStyle: {
              color: '#417dff'
            },
            leaves: {
              label: {
                position: 'left',
                verticalAlign: 'middle',
                align: 'right',
                fontSize: 14
              }
            },
            expandAndCollapse: false,
            animationDuration: 550,
            animationDurationUpdate: 750,
            data: [data1]
          },
          {
            name: '客户方',
            width: '30%',
            type: 'tree',
            roam: false,
            top: '1%',
            bottom: '1%',
            right: '50%',
            left: '50%',
            z: 1,
            symbol: 'circle',
            itemStyle: {
              color: '#FF9900',
              borderColor: '#FF9900'
            },
            lineStyle: {
              color: '#FF9900'
            },
            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left',
                fontSize: 14
              }
            },
            expandAndCollapse: false,
            animationDuration: 550,
            animationDurationUpdate: 750,
            data: [data2]
          }
        ]
      }

      chart.setOption(option)
    },
    // 供应链关系图的数据
    queryChain () {
      this.Axios.post(
        'https://zcjrys.chinaindustria.com/api/jrys/companypic/supplyChainRelationship/list',
        {
          companyCode: this.companyCode
        }
      ).then((res) => {
        const arr1 = res.data.data.supplyList
        const arr2 = res.data.data.saleList
        this.chainChart(arr1, arr2)
      })
    },
    // 供应链关系 销售客户
    queryCustomer () {
      this.Axios.post(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/supplyChainRelationship/page?currPage=${this.customerPage}&size=10`,
        {
          companyCode: this.companyCode,
          keyword: this.keyword,
          year: this.chainYear,
          scRelationship: this.relationType
        }
      ).then((res) => {
        this.customer = res.data.data.list
        this.customerTotal = res.data.data.total
      })
    },
    handleCustomerChange (val) {
      this.customerPage = val
      this.queryCustomer()
    },
    // 分支机构 图数据
    queryBranch () {
      this.Axios.post(
        'https://zcjrys.chinaindustria.com/api/jrys/companypic/branch/list',
        {
          companyCode: this.companyCode
        }
      ).then((res) => {
        this.branchAnalysisVos = res.data.data.analysisVos
        this.allBranchData = res.data.data.province
        res.data.data.province.forEach((i) => {
          this.branchMapData.push({ name: i.province, value: i.count })
        })
        this.mapChart(
          'https://zcjrys.chinaindustria.com/map/mapjson/100000.json',
          'china'
        )
        // this.mapChart("http://localhost:8888/static/mapjson/100000.json", "china");
      })
    },
    // 分支机构 地图
    mapChart (mapCode, name) {
      const myChart = this.Echarts.init(document.getElementById('mapChart'))
      const points = []
      const links = {
        // 坐标点和线条
        type: 'lines',
        color: ['#ff9900'],
        data: []
      }
      this.branchMapData.forEach((i) => {
        points.push({ name: i.name, value: this.provincePoint[i.name] })
        links.data.push({
          coords: [
            this.provincePoint[i.name],
            this.provincePoint[this.companyInfo.province]
          ]
        })
      })
      this.Axios.get(mapCode).then((res) => {
        this.Echarts.registerMap(name, res.data)
        const option = {
          grid: {
            top: 50,
            bottom: 0,
            left: 0,
            right: 0
          },
          visualMap: {
            min: 0,
            max: 10,
            left: 'right',
            top: 'bottom',
            text: ['多', '少'],
            calculable: true,
            seriesIndex: [0],
            inRange: {
              color: ['#E2EFFD', '#0061C6']
            },
            show: false
          },
          geo: [
            {
              map: name,
              layoutCenter: name === 'china' ? ['48%', '70%'] : ['48%', '50%'], // 距离左右，上下距离的百分比
              layoutSize: name === 'china' ? '120%' : '100%' // map百分比大小
            }
          ],
          series: [
            {
              layoutCenter: name === 'china' ? ['48%', '70%'] : ['48%', '50%'], // 距离左右，上下距离的百分比
              layoutSize: name === 'china' ? '120%' : '100%', // map百分比大小
              type: 'map',
              selectedMode: 'single',
              mapType: name,
              label: {
                normal: {
                  color: '#666',
                  show: true
                },
                emphasis: {
                  show: true,
                  color: 'white'
                }
              },
              itemStyle: {
                normal: {
                  borderColor: 'white',
                  borderWidth: 2
                },
                emphasis: {
                  areaColor: '#FF9A00'
                }
              },
              select: {
                itemStyle: {
                  areaColor: '#FF9A00',
                  borderColor: 'white',
                  borderWidth: 2
                }
              },
              data: this.branchMapData
            },
            {
              color: ['#ff9900'],
              type: 'effectScatter',
              coordinateSystem: 'geo',
              showEffectOn: 'render',
              rippleEffect: {
                period: 3,
                scale: 4,
                brushType: 'fill'
              },
              symbolSize: 5,
              itemStyle: {
                normal: {
                  color: '#ff9900',
                  shadowBlur: 7,
                  shadowColor: '#ff9900'
                }
              },
              data: points
            },
            {
              type: 'scatter',
              coordinateSystem: 'geo',
              symbol:
                'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAAEhyb7BAAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAEqADAAQAAAABAAAAEgAAAAC5YZBvAAABsUlEQVQ4EZ1Tyy5EQRA9dUPEIyZibPkBIbEhho3HAhs2SIhEJD7Dh8xjQUjEHonEyrCQsBDzAdZmMyKDjXaqr+7pOzMx6KRvV51zqrqr+jbAYQ4wqKuYffTjA2Xa9wo0DjFZGIUjywlKXuMYzxJ4VickvNobJocJp/DZPauGsk5hCZPHsiXyyFog/Fh1HpkQE1PAKD6xTnCR5x8JyCrtcwhORUFziF5UUQkEzryRXWRs2bKJF4cm1gh7Cd85rsHO1zVu4DfCijJ4x1MoaBCxgKKC2rFQKAS2WdUSwQXOLk8KHmifca9jbe0dnTFP1hsdSMctyOKa3GQ9z7wprdyKlEzcSawus0cDatrqWNVsjCe+aee5Fqyx/SVGik6GrjjBr1ZWf6mzldjt1lTHBBds4YxOazdVxWDTRMbw+eT4JxnM+1jaiinnscDQO7miPxVg/zGLERs3jXb0cZ/bP2fQGMZqjsQxzQl6+BIeWdLQj0mFP24Kw7KKV6dL9qiC7pZJNFI3Um0wkonEPuEarUePMGdnfel12rZaFC2DNwZtYQdHInxYyTGuLm8tQgEb1HaG9Bfjj4W+cJTemAAAAABJRU5ErkJggg==',
              symbolSize: 20,
              data: [
                {
                  name: this.companyInfo.province,
                  value: this.provincePoint[this.companyInfo.province]
                }
              ]
            },
            links
          ],
          animationDurationUpdate: 1000,
          animationEasingUpdate: 'quinticInOut'
        }
        myChart.setOption(option, true)
        setTimeout(() => {
          this.mapRelationChart(this.allBranchData)
        }, 500)
      })
      myChart.off('click')
      myChart.on('click', (params) => {
        // let name = params.name; //地区name
        if (params.name === '台湾' || params.name === '南海诸岛') {
          this.$message.warning('暂无相关区域数据')
        } else {
          const temp = []
          for (let i = 0; i < this.allBranchData.length; i++) {
            if (params.name.indexOf(this.allBranchData[i].province) !== -1) {
              this.allBranchData[i].name.forEach((j) => {
                temp.push({ name: j })
              })
            }
          }
          if (temp.length === 0) {
            document.getElementById('mapRelationChart').style.visibility =
              'hidden'
          } else {
            document.getElementById('mapRelationChart').style.visibility =
              'visible'
            this.mapRelationChart(temp)
          }
        }
        // let mapCode = provinces[name]; //地区的json数据
        // this.mapChart(mapCode, name);
      })
    },
    // 分支机构 右侧关系图
    mapRelationChart (arr) {
      if (arr.length > 15) {
        arr = arr.slice(0, 15)
        arr.push({ name: '... ...' })
      }
      const chart = this.Echarts.init(
        document.getElementById('mapRelationChart')
      )
      const name = this.companyInfo.companyName
      const data = {
        name:
          name.length > 6
            ? name.slice(0, name.length / 2) +
              '\n\n' +
              name.slice(name.length / 2)
            : name,
        symbol: 'circle',
        symbolSize: 120,
        itemStyle: {
          color: '#417dff',
          borderColor: '#417dff'
        },
        label: {
          normal: {
            color: 'white',
            offset: [0, 0],
            position: 'inside',
            fontSize: 14
          }
        },
        children: arr
      }
      const option = {
        series: [
          {
            name: '子公司',
            type: 'tree',
            roam: false,
            top: '10%',
            bottom: '10%',
            left: '10%',
            right: '60%',
            containLabel: true,
            z: 1,
            symbol: 'circle',
            itemStyle: {
              color: '#FF9900',
              borderColor: '#FF9900'
            },
            lineStyle: {
              color: '#FF9900'
            },
            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left',
                fontSize: 12
              }
            },
            expandAndCollapse: false,
            animationDuration: 550,
            animationDurationUpdate: 750,
            data: [data]
          }
        ]
      }
      chart.setOption(option)
    },
    // 分支机构 表
    queryBranchTable () {
      this.Axios.post(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/branch/page?size=10&currPage=${this.branchPage}`,
        { companyCode: this.companyCode }
      ).then((res) => {
        this.branchTable = res.data.data.list
        this.branchTableTotal = res.data.data.total
      })
    },
    handleBranchChange (val) {
      this.branchPage = val
      this.queryBranchTable()
    },
    // 三合一接口 图
    queryCourtLawSuit () {
      this.Axios.post(
        'https://zcjrys.chinaindustria.com/api/jrys/companypic/courtLawSuit/count',
        {
          companyCode: this.companyCode
        }
      ).then((res) => {
        this.courtLawSuit = res.data.data
      })
    },
    // 三合一接口 表
    queryCourtLawSuitTable () {
      this.Axios.post(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/courtLawSuit/page?size=10&currPage=${this.lawSuitPage}`,
        { companyCode: this.companyCode }
      ).then((res) => {
        this.courtLawSuitTable = res.data.data.list
        this.lawSuitTableTotal = res.data.data.total
        if (this.lawSuitPage === 1) {
          this.courtLawSuitTableTop10 = res.data.data.list
        }
      })
    },
    // 诉讼对象 翻页
    handleLawSuitChange (val) {
      this.lawSuitPage = val
      this.queryCourtLawSuitTable()
    },
    // 诉讼对象 关系图
    lawsuitChart () {
      const chart = this.Echarts.init(document.getElementById('lawsuitChart'))
      const name = this.companyInfo.companyName
      let temp = {
        name:
          name.length > 6
            ? name.slice(0, name.length / 2) +
              '\n' +
              name.slice(name.length / 2)
            : name,
        value: 0,
        children: []
      }
      const a = this.courtLawSuit.analysisVos.plaintiffList
      const b = this.courtLawSuit.analysisVos.defendantList
      if (a.length + b.length > 20) {
        var aPercent = (a.length / (a.length + b.length)) * 20
        var bPercent = (b.length / (a.length + b.length)) * 20
      }
      a.length &&
        a.forEach((i) => {
          temp.children.push({ name: i.name, type: 'yg', children: [] })
        })
      if (a.length + b.length > 20) {
        temp.children = temp.children.slice(0, aPercent)
      }
      b.length &&
        b.forEach((i) => {
          temp.children.push({ name: i.name, type: 'bg', children: [] })
        })
      if (a.length + b.length > 20) {
        temp.children = temp.children.slice(0, aPercent + bPercent)
      }
      if (a.length + b.length > 20) {
        temp.children.push({ name: '... ...', type: 'bg', children: [] })
      }
      const handle = function handleData (data, index, color = '#417DFF') {
        // index标识第几层
        return data.forEach((item, index2) => {
          // 设置节点大小
          if (index === 0) {
            item.label = {
              position: 'inside',
              fontSize: 14,
              rotate: 0
            }
          } else {
            item.label = {
              fontSize: 14,
              color: '#999'
            }
          }
          // 设置label大小
          switch (index) {
            case 0:
              item.symbolSize = 120
              break
            default:
              item.symbolSize = 12
              break
          }
          // 设置线条颜色
          item.lineStyle = {
            color: 'rgba(65, 125, 255, 0.2)'
          }
          item.itemStyle = {
            borderColor: item.type === 'yg' ? '#15659E' : '#FF9900',
            color: item.type === 'yg' ? '#15659E' : '#FF9900'
          }
          item.children = handle(item.children, index + 1, color)
          return item
        })
      }
      const arr = []
      arr.push(temp)
      temp = handle(arr, 0)
      const option = {
        type: 'tree',
        series: [
          {
            type: 'tree',
            hoverAnimation: true, // hover样式
            data: temp,
            layout: 'radial',
            symbol: 'circle',
            animationDurationUpdate: 750,
            expandAndCollapse: true, // 子树折叠和展开的交互，默认打开
            roam: true, // 是否开启鼠标缩放和平移漫游。scale/move/true
            itemStyle: {
              borderWidth: 1
            },
            label: {
              // 标签样式
              color: '#fff',
              fontSize: 14,
              fontFamily: 'SourceHanSansCN',
              position: 'top'
              // rotate: 0,
            },
            lineStyle: {
              width: 1,
              curveness: 0
            }
          }
        ]
      }
      if (temp[0].children.length === 0) return
      chart.setOption(option)
    },
    // 诉讼对象 饼图
    lawsuitRoundChart () {
      const chart = this.Echarts.init(
        document.getElementById('lawsuitRoundChart')
      )
      const option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: 10,
          icon: 'circle',
          left: 'left'
        },
        series: [
          {
            type: 'pie',
            color: ['#15659E', '#ff9900'],
            radius: ['30%', '35%'],
            label: {
              show: true,
              formatter: '{b}\n' + '{d}%' + '（{c}）'
            },

            data: [
              {
                value: this.courtLawSuit.lawsuit.plaintiffs,
                name: '原告/上诉方'
              },
              {
                value: this.courtLawSuit.lawsuit.defendants,
                name: '被告/当事人'
              }
            ]
          }
        ]
      }
      chart.setOption(option)
    },
    // 纠纷对象前十统计 图
    disputeChart () {
      const xArr = []
      const yArr = []
      this.courtLawSuit.dispute.forEach((i) => {
        xArr.push(i.name)
        yArr.push(i.count)
      })
      const chart = this.Echarts.init(document.getElementById('disputeChart'))
      const bgData = []
      for (const i in yArr) {
        bgData.push(yArr[0] + yArr[yArr.length - 1])
      }
      const option = {
        grid: {
          left: '20%',
          right: '20%',
          bottom: '20%',
          top: '20%',
          containLabel: true
        },
        xAxis: {
          show: false,
          type: 'value'
        },
        yAxis: [
          {
            type: 'category',
            axisLabel: {
              show: true,
              margin: 15,
              textStyle: {
                color: '#333'
              }
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            inverse: true, // 倒
            data: xArr
          },
          {
            type: 'category',
            axisTick: 'none',
            axisLine: 'none',
            show: true,
            axisLabel: {
              textStyle: {
                color: '#417EFF',
                fontSize: '14'
              }
            },
            inverse: true, // 倒
            data: yArr
          }
        ],
        series: [
          {
            name: '次数',
            type: 'bar',
            zlevel: 1,
            itemStyle: {
              normal: {
                color: new this.Echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: '#37A8FC'
                  },
                  {
                    offset: 1,
                    color: '#021E84'
                  }
                ])
              }
            },
            barWidth: 15,
            data: yArr
          },
          {
            name: '背景',
            type: 'bar',
            barWidth: 15,
            barGap: '-100%',
            data: bgData,
            itemStyle: {
              normal: {
                color: '#EBF6FF'
              }
            }
          }
        ]
      }
      chart.setOption(option)
    },
    // 案由前十统计 图
    briefChart () {
      const xArr = []
      const yArr = []
      this.courtLawSuit.caseReason.forEach((i) => {
        xArr.push(i.name)
        yArr.push(i.count)
      })
      const chart = this.Echarts.init(document.getElementById('briefChart'))
      const bgData = []
      for (const i in yArr) {
        bgData.push(yArr[0] + yArr[yArr.length - 1])
      }
      const option = {
        grid: {
          left: '20%',
          right: '20%',
          bottom: '20%',
          top: '20%',
          containLabel: true
        },
        xAxis: {
          show: false,
          type: 'value'
        },
        yAxis: [
          {
            type: 'category',
            axisLabel: {
              show: true,
              margin: 15,
              textStyle: {
                color: '#333'
              }
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            inverse: true, // 倒
            data: xArr
          },
          {
            type: 'category',
            axisTick: 'none',
            axisLine: 'none',
            show: true,
            axisLabel: {
              textStyle: {
                color: '#417EFF',
                fontSize: '14'
              }
            },
            inverse: true, // 倒
            data: yArr
          }
        ],
        series: [
          {
            name: '次数',
            type: 'bar',
            zlevel: 1,
            itemStyle: {
              normal: {
                color: new this.Echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: '#37A8FC'
                  },
                  {
                    offset: 1,
                    color: '#021E84'
                  }
                ])
              }
            },
            barWidth: 15,
            data: yArr
          },
          {
            name: '背景',
            type: 'bar',
            barWidth: 15,
            barGap: '-100%',
            data: bgData,
            itemStyle: {
              normal: {
                color: '#EBF6FF'
              }
            }
          }
        ]
      }
      chart.setOption(option)
    },
    // 获取关系图数据
    getRelationData () {
      this.Axios.post(
        `https://zcjrys.chinaindustria.com/api/jrys/companypic/atlas/${this.companyInfo.companyCode}`,
        {}
      ).then((res) => {
        this.initRelationData = res.data.data
        this.initRelationData.nodes.forEach((i) => {
          i.symbolSize = i.category === 2 ? 40 : 50
          // i.x=Math.random(-150,150)
          // i.y=Math.random(-100,100)
        })
        this.allList = []
        this.initRelationData.nodes.forEach((i) => {
          this.allList.push(i.name)
        })
        this.initRelationData.links.forEach((i) => {
          i.percent = (i.percent * 100).toFixed(2)
        })
        this.filterList = this.allList
        this.relationData = JSON.parse(JSON.stringify(this.initRelationData))
        this.relationMapChart()
      })
    },
    // 打开全屏
    openfullScreen () {
      this.fullScreen = true
      this.bigRelationLoading = true
      this.$nextTick(() => {
        this.bigRelationMapChart()
      })
    },
    // 关闭全屏
    closeFullScreen () {
      this.fullScreen = false
      bigRelationChart && bigRelationChart.dispose()
    },
    // 全屏关系图
    bigRelationMapChart (company) {
      bigRelationChart && bigRelationChart.dispose()
      bigRelationChart = this.Echarts.init(
        document.getElementById('bigRelationMapChart')
      )
      const color0 = '#ff6600'
      const color1 = '#45ADEA'
      const color2 = '#ff6060'

      this.relationData.nodes.forEach((node) => {
        if (node.category === 0) {
          node.itemStyle = {
            color: color0,
            opacity: node.weak ? 0.1 : 1
          }
        } else if (node.category === 1) {
          node.itemStyle = {
            color: color1,
            opacity: node.weak ? 0.1 : 1
          }
        } else if (node.category === 2) {
          node.itemStyle = {
            color: color2,
            opacity: node.weak ? 0.1 : 1
          }
        }
      })
      const allLine = []

      this.relationData.links.forEach((link) => {
        allLine.push([link.source, link.target])
        link.label = {
          align: 'center',
          fontSize: 12,
          opacity: 0.6
        }
        switch (link.type) {
          case 'INVEST': // 参股
            link.lineStyle = {
              color: '#4CBEFF',
              opacity: 0.6
            }
            break
          case 'OWN': // 法人
            link.lineStyle = {
              color: '#FE6600',
              opacity: 0.6
            }
            break
          case 'SERVE': // 董监高
            link.lineStyle = {
              color: '#FF6060',
              opacity: 0.6
            }
            break
          case 'BRANCH': // 分支机构
            link.lineStyle = {
              color: '#417DFF',
              opacity: 0.6
            }
            break
          default:
            break
        }
      })

      const categories = [
        {
          name: '当前企业',
          itemStyle: {
            color: color0
          }
        },
        {
          name: '公司',
          itemStyle: {
            color: color1
          }
        },
        {
          name: '人员',
          itemStyle: {
            color: color2
          }
        }
      ]
      const option = {
        title: {
          text: ''
        },
        legend: [
          {
            show: false,
            data: categories.forEach((x) => x.name)
          }
        ],
        tooltip: {
          textStyle: {
            fontSize: 18
          },
          formatter: (param) => {
            if (param.dataType === 'edge') {
              switch (param.data.type) {
                case 'INVEST':
                  return (
                    param.data.source +
                    '<span style="color:#4CBEFF">' +
                    param.data.name +
                    '</span>' +
                    param.data.target
                  )
                case 'OWN':
                  return (
                    param.data.source +
                    '<span style="color:#FE6600">担任</span>' +
                    param.data.target +
                    '<span style="color:#FE6600">' +
                    param.data.name +
                    '</span>'
                  )
                case 'SERVE':
                  return (
                    param.data.source +
                    '<span style="color:#FF6060">担任</span>' +
                    param.data.target +
                    '<span style="color:#FF6060">' +
                    param.data.name +
                    '</span>'
                  )
                case 'BRANCH':
                  return (
                    param.data.source +
                    '<span style="color:#417DFF">' +
                    param.data.name +
                    '</span>' +
                    param.data.target
                  )
                default:
                  break
              }
            } else {
              return param.name
            }
          }
        },
        series: [
          {
            name: 'graph',
            type: 'graph',
            layout: 'force',
            draggable: false,
            roam: true,
            scaleLimit: {
              min: 1.2,
              max: 5
            },
            nodeScaleRatio: 0.6,
            focusNodeAdjacency: false,
            autoCurveness: [0, -0.2, 0.2],
            categories: categories,
            edgeSymbol: ['', 'arrow'],
            symbolSize: 30,
            edgeLabel: {
              normal: {
                show: true,
                textStyle: {
                  fontSize: 18,
                  opacity: 0.5
                },
                formatter (x) {
                  return x.data.name
                }
              }
            },
            force: {
              // 力引导图基本配置
              repulsion: 1000, // 节点之间的斥力因子。支持数组表达斥力范围，值越大斥力越大。
              gravity: 0.8, // 节点受到的向中心的引力因子。该值越大节点越往中心点靠拢。
              edgeLength: 100, // 边的两个节点之间的距离，这个距离也会受 repulsion影响 。值越大则长度越长
              layoutAnimation: false // 因为力引导布局会在多次迭代后才会稳定，这个参数决定是否显示布局的迭代动画
              // 在浏览器端节点数据较多（>100）的时候不建议关闭，布局过程会造成浏览器假死。
            },
            label: {
              show: true,
              color: '#fff',
              fontSize: 12,
              formatter: (param) => {
                if (param.name.length > 10) {
                  return (
                    param.name.slice(0, 5) +
                    '\n' +
                    param.name.slice(5, 10) +
                    '..'
                  )
                } else {
                  return param.name
                }
              }
            },
            emphasis: {
              focus: 'adjacency',
              lineStyle: {
                opacity: 1
              },
              itemStyle: {
                opacity: 1
              }
            },
            blur: {
              itemStyle: {
                opacity: 0.5
              },
              label: {
                show: true,
                opacity: 0.9
              }
            },
            data: this.relationData.nodes,
            links: this.relationData.links
          }
        ]
      }
      bigRelationChart.setOption(option, true)

      this.bigRelationLoading = false

      bigRelationChart.on('click', (param) => {
        if (param.dataType !== 'node') return
        this.filterCompany = param.name
        bigRelationChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          name: param.name
        })
      })
      bigRelationChart.on('mouseout', (_) => {
        if (!this.filterCompany) return
        bigRelationChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          name: this.filterCompany
        })
      })
      bigRelationChart.on('mouseover', (_) => {

      })
      document.oncontextmenu = function () {
        return false
      }
      // 双击取消高亮
      bigRelationChart.getZr().on('dblclick', () => {
        this.filterCompany = ''
        bigRelationChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0
        })
      })
      if (company) {
        relationChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: this.relationData.nodes.findIndex(
            (i) => i.name === company
          )
        })
      }
    },
    // 关系图谱
    relationMapChart (company) {
      relationChart && relationChart.dispose()
      relationChart = this.Echarts.init(
        document.getElementById('relationMapChart')
      )
      const color0 = '#ff6600'
      const color1 = '#45ADEA'
      const color2 = '#ff6060'

      this.relationData.nodes.forEach((node) => {
        if (node.category === 0) {
          node.itemStyle = {
            color: color0,
            opacity: node.weak ? 0.1 : 1
          }
        } else if (node.category === 1) {
          node.itemStyle = {
            color: color1,
            opacity: node.weak ? 0.1 : 1
          }
        } else if (node.category === 2) {
          node.itemStyle = {
            color: color2,
            opacity: node.weak ? 0.1 : 1
          }
        }
      })
      const allLine = []

      this.relationData.links.forEach((link) => {
        allLine.push([link.source, link.target])
        link.label = {
          align: 'center',
          fontSize: 12,
          opacity: 0.6
        }
        switch (link.type) {
          case 'INVEST': // 参股
            link.lineStyle = {
              color: '#4CBEFF',
              opacity: 0.6
            }
            break
          case 'OWN': // 法人
            link.lineStyle = {
              color: '#FE6600',
              opacity: 0.6
            }
            break
          case 'SERVE': // 董监高
            link.lineStyle = {
              color: '#FF6060',
              opacity: 0.6
            }
            break
          case 'BRANCH': // 分支机构
            link.lineStyle = {
              color: '#417DFF',
              opacity: 0.6
            }
            break
          default:
            break
        }
      })

      const categories = [
        {
          name: '当前企业',
          itemStyle: {
            color: color0
          }
        },
        {
          name: '公司',
          itemStyle: {
            color: color1
          }
        },
        {
          name: '人员',
          itemStyle: {
            color: color2
          }
        }
      ]
      const option = {
        title: {
          text: ''
        },
        legend: [
          {
            show: false,
            data: categories.forEach((x) => x.name)
          }
        ],
        tooltip: {
          formatter: (param) => {
            if (param.dataType === 'edge') {
              switch (param.data.type) {
                case 'INVEST':
                  return (
                    param.data.source +
                    '<span style="color:#4CBEFF">' +
                    param.data.name +
                    '</span>' +
                    param.data.target
                  )
                case 'OWN':
                  return (
                    param.data.source +
                    '<span style="color:#FE6600">担任</span>' +
                    param.data.target +
                    '<span style="color:#FE6600">' +
                    param.data.name +
                    '</span>'
                  )
                case 'SERVE':
                  return (
                    param.data.source +
                    '<span style="color:#FF6060">担任</span>' +
                    param.data.target +
                    '<span style="color:#FF6060">' +
                    param.data.name +
                    '</span>'
                  )
                case 'BRANCH':
                  return (
                    param.data.source +
                    '<span style="color:#417DFF">' +
                    param.data.name +
                    '</span>' +
                    param.data.target
                  )
                default:
                  break
              }
            } else {
              return param.name
            }
          }
        },
        series: [
          {
            name: 'graph',
            type: 'graph',
            layout: 'force',
            draggable: false,
            roam: true,
            scaleLimit: {
              min: 0.7,
              max: 1.8
            },
            nodeScaleRatio: 0.8,
            focusNodeAdjacency: false,
            autoCurveness: [0, -0.2, 0.2],
            categories: categories,
            edgeSymbol: ['', 'arrow'],
            symbolSize: 30,
            edgeLabel: {
              normal: {
                show: true,
                textStyle: {
                  fontSize: 8
                },
                formatter (x) {
                  return x.data.name
                }
              }
            },
            force: {
              // 力引导图基本配置
              repulsion: 1000, // 节点之间的斥力因子。支持数组表达斥力范围，值越大斥力越大。
              gravity: 0.6, // 节点受到的向中心的引力因子。该值越大节点越往中心点靠拢。
              edgeLength: 100, // 边的两个节点之间的距离，这个距离也会受 repulsion影响 。值越大则长度越长
              layoutAnimation: false // 因为力引导布局会在多次迭代后才会稳定，这个参数决定是否显示布局的迭代动画
              // 在浏览器端节点数据较多（>100）的时候不建议关闭，布局过程会造成浏览器假死。
            },
            label: {
              show: true,
              color: '#fff',
              fontSize: 10,
              formatter: (param) => {
                if (param.name.length > 6) {
                  return (
                    param.name.slice(0, 3) +
                    '\n' +
                    param.name.slice(3, 6) +
                    '..'
                  )
                } else {
                  return param.name
                }
              }
            },
            emphasis: {
              focus: 'adjacency',
              lineStyle: {
                opacity: 1
              },
              itemStyle: {
                opacity: 1
              }
            },
            blur: {
              itemStyle: {
                opacity: 0.5
              },
              label: {
                show: true,
                opacity: 0.9
              }
            },
            links: this.relationData.links,
            data: this.relationData.nodes
          }
        ]
      }
      relationChart.setOption(option, false)
      relationChart.getDom().style.paddingRight = '320px'
      relationChart.resize()
      // let time=null
      // relationChart.on('graphRoam', (params) => {
      //   clearTimeout(time)
      //   let opt=relationChart.getOption()
      //   if(opt.series[0].zoom<0.7){
      //     opt.series[0].label.fontSize=(12*opt.series[0].zoom).toFixed(0)-0
      //     opt.series[0].edgeLabel.show=false
      //   }else if(opt.series[0].zoom<1){
      //     opt.series[0].label.fontSize=10
      //     opt.series[0].edgeLabel.show=true
      //   }else{
      //     opt.series[0].label.fontSize=12
      //     opt.series[0].edgeLabel.show=true
      //   }
      //   time=setTimeout(() => { //缩放会重新绘制-暂时不用
      //     relationChart.setOption(opt)
      //   }, 1000);
      // });
      this.relationLoading = false
      relationChart.on('click', (param) => {
        if (param.dataType !== 'node') return
        this.filterCompany = param.name
        // this.relationData.links.forEach((i,index)=>{
        //   if(i.source==param.name){
        //     temp.push(i.target)
        //   }else if(i.target==param.name){
        //     temp.push(i.source)
        //   }
        // })
        relationChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          name: param.name
        })
      })
      relationChart.on('mouseout', (_) => {
        if (!this.filterCompany) return
        relationChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          name: this.filterCompany
        })
      })
      relationChart.on('mouseover', (_) => {

      })
      document.oncontextmenu = function () {
        return false
      }
      // 双击取消高亮
      relationChart.getZr().on('dblclick', (param) => {
        // this.relationData=JSON.parse(JSON.stringify(this.initRelationData))
        // this.invest=''
        // this.post=''
        // this.holdShares=0
        // this.relationMapChart()
        this.filterCompany = ''
        relationChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0
        })
      })
      if (company) {
        relationChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: this.relationData.nodes.findIndex(
            (i) => i.name === company
          )
        })
      }
    },
    // 重置
    reset () {
      this.filterCompany = ''
      relationChart.dispatchAction({
        type: 'downplay',
        seriesIndex: 0
      })
      bigRelationChart.dispatchAction({
        type: 'downplay',
        seriesIndex: 0
      })
    },
    // 处理关系图数据 invest-投资关系 post-任职情况
    handleRelationData (invest, post) {
      const temp = []
      const tempLink = []
      this.relationData.links.forEach((i) => {
        if (i.target === this.companyInfo.companyName && i.name === invest) {
          temp.push(i.source)
        }
      })
      this.relationData.links.forEach((i) => {
        if (
          temp.indexOf(i.source) === -1 ||
          (temp.indexOf(i.source) !== -1 && i.name !== post)
        ) {
          i.name = ''
          i.target = ''
          i.source = ''
        } else {
          tempLink.push(i.source)
          tempLink.push(i.target)
        }
      })
      this.relationData.nodes.forEach((i) => {
        if ([...new Set(tempLink)].indexOf(i.name) !== -1) {
          i.weak = false
        } else {
          i.weak = true
        }
      })
      this.relationMapChart()
    },
    // 不止董事 有多种关系所以单独处理
    handleRelationData1 (invest) {
      const temp = []
      const tempLink = []
      this.relationData.links.forEach((i) => {
        if (i.target === this.companyInfo.companyName && i.name === invest) {
          temp.push(i.source)
        }
      })
      this.relationData.links.forEach((i) => {
        if (
          temp.indexOf(i.source) === -1 ||
          (temp.indexOf(i.source) !== -1 && i.name === '参股')
        ) {
          i.name = ''
          i.target = ''
          i.source = ''
        } else {
          tempLink.push(i.source)
          tempLink.push(i.target)
        }
      })
      this.relationData.nodes.forEach((i) => {
        if ([...new Set(tempLink)].indexOf(i.name) !== -1) {
          i.weak = false
        } else {
          i.weak = true
        }
      })
      this.relationMapChart()
    },
    // 持股变化
    sliderChange (val) {
      this.relationData = JSON.parse(JSON.stringify(this.initRelationData))
      const tempLink = []
      this.relationData.links.forEach((i) => {
        if (i.percent < val) {
          i.name = ''
          i.source = ''
          i.target = ''
        } else {
          tempLink.push(i.source)
          tempLink.push(i.target)
        }
      })
      this.relationData.nodes.forEach((i) => {
        if ([...new Set(tempLink)].indexOf(i.name) !== -1) {
          i.weak = false
        } else {
          i.weak = true
        }
      })
      this.relationMapChart()
    },
    // 输入模糊查询
    inputChange (val) {
      if (val.trim() === '') {
        return (this.filterList = this.allList)
      }
      const arr = []
      this.allList.forEach((i) => {
        if (i.indexOf(val) !== -1) {
          arr.push(i)
        }
      })
      this.filterList = arr
    },
    // 聚焦公司
    focusCompany () {
      relationChart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: this.relationData.nodes.findIndex(
          (i) => i.name === this.filterCompany
        )
      })
      bigRelationChart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: this.relationData.nodes.findIndex(
          (i) => i.name === this.filterCompany
        )
      })
    },

    //   高管详情
    openExecutiveDetail (obj) {
      this.executiveDetailDialog = true
      this.executiveDetail = obj
    },
    // 选择披露日期
    chooseDate (index) {
      this.currentDate = index
      this.disclosureShareholders = this.allShareholders[index]
    },
    // 展示详情
    showDetail (obj) {
      console.log(obj)
    },
    // 跳转网址
    toWebsite (website) {
      window.open(website)
    },
    cellStyle ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 4) {
        return 'color:#FF3300'
      }
    }
  }
}
</script>
