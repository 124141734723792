<template>
  <div class="page-header flex-row">
    <span class="line"></span>
    <span class="title">{{ title }}</span>
    <span class="describle">{{ describle }}</span>
  </div>
</template>

<script>
export default {
  name: 'PageHeader',
  props: {
    title: {
      require: true,
      type: String
    },

    describle: {
      require: true,
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
.page-header {
  align-items: center;

  .line {
    width: 5px;
    height: 20px;
    background: #3370FF;
    border-radius: 3px;
  }

  .title {
    font-size: 20px;
    color: #333333;
    letter-spacing: 0;
    font-weight: 600;
    margin-left: 10px;
  }

  .describle {
    font-size: 16px;
    color: #999999;
    letter-spacing: 0;
    margin-left: 10px;
    font-weight: 400;
  }
}
</style>
