<template>
  <div class="talk">
    <div class="header">
      <HeaderView />
    </div>
    <div class="container flex-row">
      <div class="asider">
        <div class="return flex-row" @click="$router.push({ name: 'index' })">
          <img
            src="@/assets/image/return.png"
            alt=""
            style="margin-right: 15px"
          />
          产业智脑
        </div>
        <div class="handles flex-row">
            <div class="new flex-row" @click="createNewChat('')">
              <img
                src="@/assets/image/new.png"
                alt=""
                style="margin-right: 12px"
              />
              新建对话
            </div>
          <div class="upload flex-row" @click="showModal = true">
            <img
              src="@/assets/image/upload.png"
              alt=""
              style="margin-right: 12px"
            />
            上传文档
          </div>
        </div>

        <div class="menus">
          <el-menu
            :default-openeds="['1', '2']"
            @select="handleSelect"
            :default-active="activeKey"
          >
            <el-submenu index="1">
              <template slot="title">
                <img src="@/assets/image/chat.png" alt="" />
                对话
              </template>
              <el-menu-item
                ref="talk"
                :index="menu.id"
                v-for="(menu, index) in chatMenus"
                :key="menu.id"
              >
                <template>
                  {{
                    menu.name.length > 10
                      ? menu.name.slice(0, 10) + "..."
                      : menu.name
                  }}
                </template>
                <span class="edits flex-row">
                  <img
                    src="@/assets/image/edit.png"
                    alt=""
                    @click.stop.prevent="handleEditMenuName(index)"
                  />
                  <el-popover
                    placement="top"
                    width="160"
                    v-model="tipVisibles[index]"
                  >
                    <p>确定删除吗？</p>
                    <div style="text-align: right; margin: 0">
                      <el-button
                        size="mini"
                        type="text"
                        @click="$set(tipVisibles, index, false)"
                        >取消</el-button
                      >
                      <el-button
                        type="primary"
                        size="mini"
                        @click="handleDelMenuName(index)"
                        >确定</el-button
                      >
                    </div>
                    <img
                      @click.stop
                      slot="reference"
                      style="margin-left: 10px; margin-top: -5px"
                      src="@/assets/image/del.png"
                      alt=""
                    />
                  </el-popover>
                </span>
              </el-menu-item>
            </el-submenu>
            <el-submenu index="2">
              <template slot="title">
                <img src="@/assets/image/doc.png" alt="" />
                文档
              </template>
              <el-menu-item
                ref="docRef"
                :index="menu.id"
                v-for="menu in docsMenus"
                :key="menu.id"
                >{{
                  menu.name.length > 14
                    ? menu.name.slice(0, 14) + "..."
                    : menu.name
                }}</el-menu-item
              >
            </el-submenu>
          </el-menu>
        </div>
      </div>
      <div class="chat">
        <div class="chat-introduce flex-row">
          <img
            src="@/assets/image/system.png"
            alt=""
            style="margin-right: 18px"
          />
          <p>
            你好，我是{{ chain }}产业专家，我能帮你快速获取和理解{{
              chain
            }}领域的专业知识和建议。
          </p>
        </div>
        <div v-if="chatMenus.length" class="chat-container flex-col">
          <div class="chat-list" @click="showKeywords = false">
            <div class="tip" v-if="curMenuChunk === '1'">
              <img src="@/assets/image/system.png" alt="" />

              <div class="prompt" v-if="!curCompany">
                <p style="color: #333">试着对我说</p>
                <p
                  @click="ask(about)"
                  style="cursor: pointer"
                  v-for="about in askAbout"
                  :key="about.id"
                >
                  {{ about.question }}
                </p>
                <p style="color: #999">
                  提示：产业专家数字人将基于您添加的资源和提问进行思考对话~
                </p>
              </div>

              <div class="prompt" v-else>
                <p style="color: #333">
                  向我询问有关{{ curCompany }}公司的问题
                </p>
                <p
                  @click="talk(about)"
                  style="cursor: pointer"
                  v-for="(about, index) in talkList.slice(0, 3)"
                  :key="index"
                  v-html="about"
                ></p>
                <p style="color: #999">
                  提示：产业专家数字人将基于您添加的资源和提问进行思考对话~
                </p>
              </div>
            </div>
            <div v-for="(record, index) in chatRecords" :key="index">
              <div
                class="admin"
                v-if="
                  record.messageType === 'QUESTION' || record.role === 'user'
                "
              >
                <div class="admin-top flex-row">
                  <div class="ask">{{ record.content }}</div>
                  <img src="@/assets/image/avatar.png" alt="" />
                </div>
              </div>
              <div
                class="robot flex-row"
                v-if="
                  record.messageType === 'ANSWER' || record.role === 'assistant'
                "
              >
                <div class="robot-top flex-row">
                  <img src="@/assets/image/system.png" alt="" />
                  <div class="ques">
                    <div class="ques-content" v-html="record.content"></div>
                    <div
                      v-if="record.messageType === 'ANSWER'||record.role==='assistant'"
                      class="ques-btns flex-row markdown-body"
                    >
                      <span
                        @click="copy(record.content)"
                        class="flex-row"
                        style="align-items: center"
                        ><img
                          src="@/assets/image/copy.png"
                          alt=""
                          style="margin-right: 4px"
                        />复制</span
                      >
                      <!-- <span
                        :style="
                          record.collectValue ? 'opacity:1' : 'opacity:0.5'
                        "
                        @click="handle(record, 'collect')"
                        class="flex-row"
                        style="align-items: center; margin-right: 22px"
                        ><img
                          :style="
                            record.collectValue ? 'opacity:1' : 'opacity:0.5'
                          "
                          src="@/assets/image/collect.png"
                          alt=""
                          style="margin-right: 4px"
                        />{{ record.collectValue ? "已收藏" : "收藏" }}</span
                      > -->
                      <img
                        v-if="
                          !record.feedback ||
                          (record.feedback &&
                            record.feedback.rating !== 'dislike')
                        "
                        :style="
                          record.feedback && record.feedback.rating === 'like'
                            ? 'opacity:1'
                            : 'opacity:0.5'
                        "
                        @click="handle(record, 'like')"
                        style="margin-left: 22px"
                        src="@/assets/image/dianzan.png"
                        alt=""
                      />
                      <img
                        v-if="
                          !record.feedback ||
                          (record.feedback && record.feedback.rating !== 'like')
                        "
                        :style="
                          record.feedback &&
                          record.feedback.rating === 'dislike'
                            ? 'opacity:1'
                            : 'opacity:0.5'
                        "
                        @click="handle(record, 'dislike')"
                        style="margin-left: 22px"
                        src="@/assets/image/cai.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="send-message">
            <div class="search-direction" v-show="showKeywords">
              <p @click="help('产业链图谱')">产业链图谱</p>
              <p @click="help('知识图谱')">知识图谱</p>
              <!-- <p @click="help('产业研报')">搜索研报</p> -->
              <p @click="help('上市公司')">上市公司</p>
              <p @click="help('翻译')">翻译</p>
              <!-- <p @click="help('收藏夹')">收藏夹</p> -->
              <p @click="help('写作')">写作</p>
              <p @click="help('会议记录')">智能会议记录</p>
            </div>
            <div class="send flex-row">
              <el-input
                ref="textarea"
                type="textarea"
                resize="none"
                rows="3"
                @keyup.enter.native="send"
                @click.native="showKeywords = false"
                v-model="message"
                @input="valueChange"
                :placeholder="
                  curMenuChunk === '1'
                    ? '输入您的问题，输入/获得提示'
                    : '输入您的问题'
                "
              />
              <img
                height="24"
                v-if="!speaking"
                style="
                  position: absolute;
                  right: 65px;
                  bottom: 5px;
                  cursor: pointer;
                "
                @click="handleVoiceStart"
                src="@/assets/image/mic.png"
                alt=""
              />
              <span
                v-else
                class="flex-row"
                style="
                  align-items: center;
                  position: absolute;
                  right: 70px;
                  bottom: 5px;
                "
              >
                <img
                  height="10"
                  src="@/assets/image/loading.gif"
                  alt=""
                />&nbsp;
                <img
                  height="18"
                  style="cursor: pointer"
                  @click="handleVoiceEnd"
                  src="@/assets/image/mic-close.png"
                  alt=""
                />
              </span>
              <div class="send-btn flex-row" @click="send">
                <img src="@/assets/image/send.png" alt="" />
              </div>
            </div>
            <p>该产品由M-Land支持，内容仅供参考。</p>
          </div>
        </div>
        <div
          class="chat-container flex-col"
          style="justify-content: center; align-items: center"
          v-else
        >
          <div class="create" @click="createNewChat('')">开始对话</div>
        </div>
      </div>
      <div class="extend" v-if="tabs.length">
        <el-tabs
          style="margin-top: 10px; height: 87vh"
          v-model="curTabValue"
          type="border-card"
          :closable="true"
          :addable="false"
          @edit="handleTabsEdit"
        >
          <el-tab-pane
            v-if="tabs.indexOf('产业链图谱') > -1"
            label="产业链图谱"
            name="产业链图谱"
          >
            <div class="chart-container">
              <div id="chart" style="width: 100%; height: 500px"></div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            v-if="tabs.indexOf('知识图谱') > -1"
            label="知识图谱"
            name="知识图谱"
          >
            <div class="chart-container">
              <div id="knowledgeChart" style="width: 100%; height: 500px"></div>
            </div>
          </el-tab-pane>

          <el-tab-pane
            v-if="tabs.indexOf('产业研报') > -1"
            label="产业研报"
            name="产业研报"
          >
            <el-input
              placeholder="请输入内容"
              @keyup.enter.native="getReportList"
              v-model="reportValue"
              style="width: 300px"
            >
              <el-button
                @click="getReportList"
                slot="append"
                icon="el-icon-search"
              ></el-button>
            </el-input>
            <div class="extend-content">
              <div class="total">
                共找到{{ total }}篇报告，点击标题可预览内容详情。
              </div>
              <el-table
                border
                :header-cell-style="{
                  'text-align': 'center',
                  background: '#FAFAFA',
                }"
                :data="tableData"
                style="width: 100%; border-radius: 4px"
              >
                <el-table-column
                  align="left"
                  prop="title"
                  label="标题"
                  width="360"
                >
                  <template slot-scope="scope">
                    <div
                      @click="getReport(scope.row)"
                      style="color: #3370ff; cursor: pointer"
                    >
                      {{ scope.row.title }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="publishTime" label="时间">
                  <template slot-scope="scope">
                    {{ scope.row.publishTime.slice(0, 10) }}
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="source" label="来源">
                </el-table-column>
                <el-table-column align="center" label="操作" width="120">
                  <template slot-scope="scope">
                    <el-button type="text" size="small"> 目录 </el-button>
                    <el-button
                      @click.native.prevent="abstract(scope.row.title)"
                      type="text"
                      size="small"
                    >
                      摘要
                    </el-button>
                    <el-button
                      type="text"
                      size="small"
                      @click="conversation(scope.row)"
                    >
                      对话
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="pdf-dialog" v-show="prePdf">
              <p class="flex-row">
                {{ prePdfTitle
                }}<el-button
                  type="primary"
                  size="mini"
                  @click="start(true)"
                  v-if="curMenuChunk == '1'"
                  >对话</el-button
                ><span
                  @click="prePdf = ''"
                  style="
                    cursor: pointer;
                    color: #2d5ed6;
                    font-weight: normal;
                    font-size: 16px;
                  "
                  >返回</span
                >
              </p>
              <iframe
                style="padding: 0 10px"
                width="100%"
                height="100%"
                :src="prePdf"
                frameborder="0"
              ></iframe>
            </div>
          </el-tab-pane>

          <el-tab-pane
            v-if="tabs.indexOf('会议记录') > -1"
            label="会议记录"
            name="会议记录"
          >
          <div v-if="!historyDetail.taskName" class="extend-content">
            <el-input
              placeholder="搜索会议主题"
              @keyup.enter.native="meetHistory"
              v-model="meetValue"
              style="width: 300px"
            >
              <el-button
                @click="meetHistory"
                slot="append"
                icon="el-icon-search"
              ></el-button>
            </el-input>
            <el-button type="primary" style="float:right" @click="uploadDialog = true">上传音视频</el-button>
            <br />
            <br />
            <el-dialog
              title="上传本地音视频文件"
              :visible.sync="uploadDialog"
              width="40%"
            >
              <el-upload
              style="margin:0 auto"
                drag
                ref="uploadRef"
                action="https://sxmland.oss-cn-beijing.aliyuncs.com"
                :data="data"
                :on-before-upload="onBeforeUpload"
                :on-error="onUploadError"
                :on-finish="onUploadFinish"
                accept=".mp3,.mp4,.m4a"
                :max="1"
                :show-file-list="false"
              >
                  <i class="el-icon-upload"></i><br/><br/>
                <p style="font-size: 16px">
                  点击或者拖动本地音视频到该区域来上传
                </p>
                <p style="margin: 8px 0 0 0;color:#999">
                  单个文件最长2小时，单次最多可上传50个。
                </p>
                <p style="margin: 8px 0 0 0;color:#999">
                  视频支持：mp4/wmv/m4v/flv/rmvb/dat/mov/mkv/webm，单个最大4G；
                </p>
                <p style="margin: 8px 0 0 0;color:#999">
                  音频支持：mp3/wav/m4a/wma/aac/ogg/amr/flac，单个最大500M。
                </p>
              </el-upload>
              <span slot="footer" style="margin:0 auto">
                <el-button type="primary"
                  @click="dialogVisible = false"
                  >开始转写</el-button
                >
              </span>
            </el-dialog>
              <el-table
                border
                :header-cell-style="{
                  'text-align': 'center',
                  background: '#FAFAFA',
                }"
                :data="historyData"
                style="width: 100%; border-radius: 4px"
              >
                <el-table-column
                  align="left"
                  prop="taskName"
                  label="会议主题"
                >
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.taskName }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="times"
                  label="上传时间"
                >
                  <template slot-scope="scope">
                    {{ scope.row.times.slice(0, 10) }}
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="taskStatus" label="状态">
                </el-table-column>
                <el-table-column align="center" label="操作" width="120">
                  <template slot-scope="scope">
                    <el-button
                      @click.native.prevent="viewHistory(scope.row)"
                      type="text"
                      size="small"
                    >
                      查看
                    </el-button>
                    <el-button
                      type="text"
                      size="small"
                      @click="exportHistory(scope.row)"
                    >
                      导出
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
          </div>

            <div v-else class="extend-content">
              <div class="editorContent">
              <div class="file-tool-tips">
              <div class="file-title" @click="historyDetail={}">
                <i class="el-icon-arrow-left"></i> {{ '演示会议记录' }}
              </div>
              <div class="tools" v-if="!isSearch">
                <div class="tool_item" @click="isSearch = true">
                  <i class="el-icon-search"></i>
                </div>
                <div
                  class="tool_item"
                  @mousemove="isReplace = true"
                  @mouseleave="isReplace = false"
                >
                <i class="el-icon-aim"></i>
                  <div class="find-replace" v-if="isReplace">
                    <div class="template">
                      <p>查找与替换</p>
                      <div class="seek-replace-item">
                        <span>查找</span>
                        <input type="text" />
                      </div>
                      <div class="seek-replace-item">
                        <span>替换为</span>
                        <input type="text" />
                      </div>
                      <div class="btns">
                        <button>全部替换</button>
                        <button>替换</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tool_item"
                  @mousemove="isPick = true"
                  @mouseleave="isPick = false"
                >
                <i class="el-icon-copy-document"></i>
                  <div class="pick-container" v-if="isPick">
                    <p>批量摘取</p>
                    <div style="display: flex; align-items: center">
                      <i class="el-icon-c-scale-to-original"
                        style="width: 20px; height: 20px; margin: 5px;vertical-align: middle;"
                      ></i>
                      摘取全文
                    </div>
                    <div style="display: flex; align-items: center">
                      <i class="el-icon-c-scale-to-original"
                        style="width: 20px; height: 20px; margin: 5px;vertical-align: middle;"
                      ></i>
                      摘取标记内容
                      <span :style="{ background: getRandomColor() }"></span>
                      <span :style="{ background: getRandomColor() }"></span>
                      <span :style="{ background: getRandomColor() }"></span>
                    </div>
                  </div>
                </div>
                <div
                  class="tool_item"
                  @mousemove="isFilter = true"
                  @mouseleave="isFilter = false"
                >
                  <i class="el-icon-s-open"></i>
                  <div class="filter-container" v-if="isFilter">
                    <p>筛选</p>
                    <div class="mark">
                      <input type="checkbox" name="" id="" />
                      <div style="margin-right: 10px">只看标记内容</div>
                      <span :style="{ background: getRandomColor() }"></span>
                      <span :style="{ background: getRandomColor() }"></span>
                      <span :style="{ background: getRandomColor() }"></span>
                    </div>
                    <div class="tree">
                      <el-tree
                        block-line
                        :data="tree_data"
                        :default-expanded-keys="defaultExpandedKeys"
                        checkable
                        expand-on-click
                        selectable
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="search-container" v-if="isSearch">
              <div class="search-input">
                <span class="iconbox">
                  <i style="width: 20px; height: 20px" class="el-icon-search"></i>
                </span>
                <input type="text" placeholder="输入内容回车进行搜索" />
                <span class="cancel" @click="isSearch = false">取消</span>
              </div>
              <div class="search-history">
                <p>猜你想搜</p>
                <span>转写</span>
              </div>
              </div>
            </div>
              <div class="editorContentBottom">
              <div class="keywords" style="margin-bottom: 28px">
                <div style="font-weight: bold">关键词</div>
                <div class="tags">
                  <span v-for="(item, idx) in transData.KeywordLabResult" :key="idx">{{
                    item
                  }}</span>
                </div>
              </div>
              <div class="summary" style="margin-bottom: 28px">
                <div style="font-weight: bold; margin-bottom: 12px">全文概要</div>
                <div class="multiEllipsis-text">
                  本文介绍了数字显示技术在显示效果、功耗和成本等方面的优势，
                  并详细讨论了数字显示技术在眼镜、工程样板间和军品领域的应用。
                  文章还提到了数字显示技术的未来发展方向，包括更高的刷新率和沉浸式体验。
                  同时，文章也提到了数字显示技术与传统显示技术的比较和挑战。
                  对话还涉及了摄影行业的发展和技术挑战，以及半导体工艺和设备、液晶面板驱动电路和芯片产业的发展情况。
                  此外，还讨论了创新思维、投资失败经历和VR产业的发展。
                  最后，对话提到了液晶屏的价格和性能对产品的影响以及用户对产品使用体验和价值的看法。
                  总体来说，这段对话涵盖了数字显示技术、半导体产业、VR产业和液晶屏相关的发展和挑战。
                </div>
              </div>
              <div class="tabs">
                <el-tabs v-model="curActive">
                  <el-tab-pane name="1" label="章节速览">
                    <el-timeline>
                      <el-timeline-item
                        v-for="(item, idx) in timesContent"
                        :key="idx"
                        color="#4841e6"
                        type="success"
                        line-type="dashed"
                      >
                          <div
                            style="display: flex; justify-content: space-between"
                          >
                            <span> {{ item.title }}</span>
                            <span>{{ item.time }}</span>
                          </div>
                          <div class="time-content">
                            {{ item.content }}
                          </div>
                      </el-timeline-item>
                    </el-timeline>
                  </el-tab-pane>
                  <el-tab-pane name="2" label="发言总结">
                    <div
                      class="summarizes"
                      v-for="(item, idx) in abstractData.RoleSummaryLabResults"
                      :key="idx"
                    >
                      <div class="summarizes-person">
                        <div
                          class="summarizes-avatar"
                          :style="{ background: getRandomColor() }"
                        ></div>
                        <div class="summarizes-text">{{ item.SpeakerName }}</div>
                      </div>
                      <div class="summarizes-content">{{ item.Summary }}</div>
                    </div>
                  </el-tab-pane>
                  <el-tab-pane name="3" label="要点回顾">
                    <div
                      class="keyPoints-item"
                      v-for="(item, idx) in abstractData.QaExtractorResults
  "
                      :key="idx"
                    >
                      <div class="important">
                        <span>重点</span>
                      </div>
                      <div class="content">
                        <div class="content-title">{{ item.question }}</div>
                        <div class="content-content">{{ item.answer }}</div>
                        <div class="content-name">{{ item.name }}</div>
                      </div>
                    </div>
                  </el-tab-pane>
                </el-tabs>
              </div>
              <el-divider
                title-placement="center"
                style="font-size: 12px; color: rgba(39, 38, 77, 0.25)"
              >
                智能内容由 AI
                模型生成，我们不对内容准确性和完整性做任何保证，亦不代表我们的观点或态度
              </el-divider>
              <div class="fate">
                <div style="font-weight: bold">原文</div>

                <template v-for="item in doc_content">
                  <div class="helper" v-if="item.content" :key="item.pi">
                    <div class="help-time">
                      <span>
                        {{ item.person }} &emsp;&emsp;&emsp; {{ item.time }}</span
                      >
                    </div>
                    <div class="help-content">
                      {{ item.content }}
                    </div>
                  </div>
                </template>
              </div>
              </div>
              <div class="audioPlayer">
                <audio src="/static/LED交流.m4a" controls></audio>
              </div>
            </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            v-if="tabs.indexOf('翻译') > -1"
            label="翻译"
            name="翻译"
          >
            <div>
              翻译成：
              <el-select
                style="width: 150px"
                size="mini"
                v-model="lang"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in langOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option> </el-select
              >&emsp;
              <el-button
                :loading="translating"
                @click="translate"
                size="mini"
                type="primary"
                >翻译</el-button
              >
              <br /><br />
              <el-input
                type="textarea"
                :rows="8"
                resize="none"
                placeholder="请将需要翻译的文字粘贴到这里"
                v-model="translateValue"
              >
              </el-input>

              <div class="translated" slot="reference">
                {{ translatedValue }}
              </div>
              <el-button
                style="float: right"
                :disabled="!translatedValue ? true : false"
                size="mini"
                @click="copy(translatedValue)"
                >复制</el-button
              >
            </div>
          </el-tab-pane>

          <el-tab-pane
            v-if="tabs.indexOf('收藏夹') > -1"
            label="收藏夹"
            name="收藏夹"
          >
            <div class="extend-content">
              <div class="total">
                <div class="sub-header">
                  <el-checkbox
                    :indeterminate="isIndeterminate"
                    v-model="checkAll"
                    @change="handleCheckAllChange"
                    >全选当前页</el-checkbox
                  >
                  <span
                    @click="cancelOperation"
                    class="extend-btn"
                    :style="
                      checkedTopics.length == 0
                        ? 'cursor:not-allowed;opacity:0.5'
                        : ''
                    "
                    >取消收藏</span
                  ><span
                    @click="insert"
                    class="extend-btn"
                    :style="
                      checkedTopics.length == 0
                        ? 'cursor:not-allowed;opacity:0.5'
                        : ''
                    "
                    >插入对话框</span
                  >
                </div>

                <div style="margin: 15px 0"></div>
                <el-checkbox-group
                  v-model="checkedTopics"
                  @change="handleCheckedTopicsChange"
                >
                  <el-checkbox v-for="i in topics" :label="i" :key="i.id"
                    >{{ i.text }}<br />
                    <div class="question flex-row">
                      {{ i.parent.text }}<span>{{ i.date }}</span>
                    </div>
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            v-if="tabs.indexOf('上市公司') > -1"
            label="上市公司"
            name="上市公司"
          >
            <div>
              <el-input
                placeholder="请输入公司名"
                @keyup.enter.native="getACompanyList"
                v-model="companyValue"
                style="width: 300px"
              >
                <el-button
                  @click="getACompanyList"
                  slot="append"
                  icon="el-icon-search"
                ></el-button>
              </el-input>
              <div
                class="flex-col"
                style="align-items: center; margin-top: 50px"
                v-if="aCompanyList.length === 0"
              >
                <img src="@/assets/image/nodata.png" alt="" /><br />
                <p>暂无数据</p>
              </div>
              <div
                class="company"
                v-for="(i, index) in aCompanyList"
                :key="index"
              >
                <p class="company-companyName">
                  <span
                    @click="companyCode = i.companyCode"
                    v-html="i.companyName"
                  ></span
                  >&emsp;<span
                    @click="talkCompany(i.companyName)"
                    style="color: #3370ff"
                    >对话</span
                  >
                </p>
                <p style="text-align: left; margin-top: 5px">
                  <span class="company-state">{{ i.regStatus }}</span
                  >&emsp;<span class="company-tag">{{
                    i.otherTags.split(",")[0]
                  }}</span>
                </p>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane
            v-if="tabs.indexOf('公司列表') > -1"
            label="公司列表"
            name="公司列表"
          >
            <div>
              <div class="subtitle">{{ curSubtitle }}</div>
              <div
                class="flex-col"
                style="align-items: center; margin-top: 50px"
                v-if="companyList.length === 0"
              >
                <img
                  v-if="companyList.length === 0"
                  src="@/assets/image/nodata.png"
                  alt=""
                /><br />
                <p>暂无数据</p>
              </div>
              <div
                class="company"
                v-for="(i, index) in companyList"
                :key="index"
              >
                <p class="company-companyName">
                  <span @click="companyCode = i.companyCode">{{
                    i.companyName
                  }}</span>
                  &emsp;<span
                    @click="talkCompany(i.companyName)"
                    style="color: #3370ff"
                    >对话</span
                  >
                </p>
                <p style="text-align: left; margin-top: 5px">
                  <span class="company-state">{{ i.regStatus }}</span
                  ><span class="company-type">{{
                    i.marketing.split(",")[0]
                  }}</span
                  ><span class="company-tag">{{
                    i.otherTags.split(",")[0]
                  }}</span>
                </p>
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane
            v-if="tabs.indexOf('写作') > -1"
            label="写作"
            name="写作"
          >
            <div class="article-direction" style="width: 735px">
              <div style="margin-bottom: 10px">格式：</div>
              <div>
                <span
                  @click="article.format = i"
                  v-for="(i, index) in formats"
                  :key="index"
                  :style="
                    article.format == i ? 'color:white;background:#3370FF' : ''
                  "
                  >{{ i }}</span
                >
              </div>
              <div style="margin-bottom: 10px">语气：</div>
              <div>
                <span
                  @click="article.tone = i"
                  v-for="(i, index) in tones"
                  :key="index"
                  :style="
                    article.tone == i ? 'color:white;background:#3370FF' : ''
                  "
                  >{{ i }}</span
                >
              </div>
              <div style="margin-bottom: 10px">长度：</div>
              <div>
                <span
                  @click="article.length = i"
                  v-for="(i, index) in lengths"
                  :key="index"
                  :style="
                    article.length == i ? 'color:white;background:#3370FF' : ''
                  "
                  >{{ i }}</span
                >
              </div>
              <el-input
                type="textarea"
                :rows="5"
                placeholder="输入或粘贴您要撰写的主题和背景材料"
                v-model="article.content"
              >
              </el-input>
            </div>
            <el-button
              size="mini"
              @click="getDraft"
              style="float: right"
              :loading="drafting"
              :disabled="!article.content ? true : false"
              type="primary"
              >生成草稿</el-button
            >
            <br />
            <div v-if="draft">
              <p style="font-weight: bold">预览</p>
              <div class="article-draft">{{ draft }}</div>
              <el-button
                style="float: right"
                :disabled="!draft ? true : false"
                size="mini"
                @click="copy(draft)"
                >复制</el-button
              >
            </div>
          </el-tab-pane>

          <el-tab-pane
            v-if="tabs.indexOf('研究报告') > -1"
            label="研究报告"
            name="研究报告"
          >
            <div style="height: calc(100vh - 200px); position: relative">
              <iframe
                width="100%"
                height="100%"
                :src="pdf"
                frameborder="0"
              ></iframe>
            </div>
          </el-tab-pane>

          <el-tab-pane
            v-if="tabs.indexOf('百问百答') > -1"
            label="百问百答"
            name="百问百答"
          >
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span>{{ node }}行业常见问题</span>
                <el-button
                  @click="fresh"
                  style="float: right; padding: 3px 0"
                  type="text"
                  >换一批</el-button
                >
              </div>
              <div
                v-for="o in questionList"
                :key="o"
                class="text item"
                @click="ask(o)"
              >
                {{ o.question }}
              </div>
            </el-card>
          </el-tab-pane>
        </el-tabs>
        <el-pagination
          v-if="
            (curTabValue == '产业研报' && !pdf&&!prePdf) ||
            curTabValue == '收藏夹' ||
            curTabValue == '公司列表' ||
            curTabValue == '上市公司' ||
            curTabValue == '会议记录'&&!meetHistory
          "
          style="position: fixed; right: 20px; bottom: 20px"
          @current-change="changePage"
          background
          :page-size="5"
          layout="total,prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </div>
      <div v-else class="extend">
        <div class="title flex-row">
          <div>
            <img
              src="@/assets/image/extend.png"
              alt=""
              style="margin-right: 12px; vertical-align: middle"
            />扩展资料展示区
          </div>
        </div>

        <div class="extend-content">
          <p class="exmple">
            这里是扩展资料展示区，可展示对话中关联的图片、图表、文档等内容。
          </p>
        </div>
      </div>
    </div>
    <div class="company-dialog flex-row" v-if="companyCode">
      <company-info :companyCode="companyCode" @sendMsg="receiveMsg" />
    </div>

    <UploadDoc
      ref='uploadDoc'
      v-model="showModal"
      v-if="showModal"
      @refreshData="refreshData"
      @closeModel="closeModel"
      @createNewDocChat="createNewDocChat"
    />
  </div>
</template>

<script>
import { fetchEventSource } from '@microsoft/fetch-event-source'
import { timesContent } from '@/constants'
import CompanyInfo from '../components/CompanyInfo.vue'
import '@/assets/css/talk-view.css'
import HeaderView from '@/components/HeaderView.vue'
import UploadDoc from '@/components/UploadDoc.vue'
import { policy, getChatList, getNewName, createNewChat, editChat, deleteChat, getConversationHistory, createFileTrans } from '@/apis'
import { lStorage, sStorage, getFileSha1 } from '@/utils'
import { marked } from 'marked'
export default {
  name: 'TalkView',
  components: {
    HeaderView,
    CompanyInfo,
    UploadDoc
  },
  data () {
    return {
      abstractData: require('@/assets/exp.json'),
      timesContent: timesContent,
      transData: require('@/assets/exp2.json'),
      height: 0,
      activeKey: '1',
      message: '',
      options: {
        sessionId: '',
        hisSession: '',
        type: '2'
      },
      chain: '',
      keyword: '',
      tipVisibles: [],
      curTabValue: '',
      tabs: [],
      showKeywords: false,
      showModal: false,
      formats: [
        '报告',
        '大纲',
        '文章',
        '邮件',
        '评论',
        '短信',
        '续写',
        '改写',
        '总结',
        '生成标题',
        '统计数据'
      ],
      tones: ['正式的', '专业的', '热情的', '幽默的', '随意的'],
      lengths: ['短', '中', '长'],
      article: {
        format: '',
        tone: '',
        length: '',
        content: '主题：\n背景资料：'
      },
      draft: '',
      drafting: false,
      askAbout: [],
      title: '',
      chatMenus: [],
      docsMenus: [],
      curMenuChunk: '',
      curMenuIndex: '',
      chatRecords: [],
      type: '',
      reportValue: '',
      langOptions: [
        {
          value: '中文（简体）',
          label: '中文（简体）'
        },
        {
          value: '中文（繁体）',
          label: '中文（繁体）'
        },
        {
          value: '英语',
          label: '英语'
        },
        {
          value: '法语',
          label: '法语'
        }
      ],
      lang: '中文（简体）',
      translateValue: '',
      translatedValue: '',
      translating: false,
      copyVisible: false,
      chart: null,
      chartData: { name: '', level: 1, children: [] },
      total: 0,
      chainList: require('@/assets/chain.json'),
      tableData: [],
      checkAll: false,
      checkedTopics: [],
      isIndeterminate: false,
      topics: [],
      curSubtitle: '',
      currentPage: 1,
      companyType: '',
      companyList: [],
      companyCode: 0,
      prePdf: '', // 预览
      prePdfTitle: '',
      pdf: '',
      pdfTitle: '',
      sseId: '',
      timer: null,
      dot: 0,
      node: '',
      allQuestionList: [],
      questionList: [],
      speaking: false,
      beforeTime: new Date(),
      talkList: [],
      companyValue: '',
      curCompany: '',
      aCompanyList: [],
      meetValue: '',
      historyData: [{ taskName: '演示会议记录', times: '2023-02-10', taskStatus: '上传完成' }],
      historyDetail: {},
      curActive: '1',
      isSearch: false,
      isReplace: false,
      isPick: false,
      isFilter: false,
      tree_data: [
        {
          key: '1',
          label: '显示发言人',
          children: [
            { key: '2', label: '发言人一' },
            { key: '3', label: '发言人二' }
          ]
        }
      ],
      defaultExpandedKeys: 1,
      uploadDialog: false,
      data: {
        policy: '',
        signature: '',
        key: '',
        ossaccessKeyId: '',
        dir: '',
        host: ''
      },
      policyData: {},
      fileSuffix: ''
    }
  },
  computed: {
    doc_content () {
      const goal = []
      this.transData.DocResult.pg.forEach((v) => {
        goal.push({
          pi: v.pi,
          ui: v.ui,
          content: v.sc.map((item) => item.tc).join('')
        })
      })
      return goal
    }
  },
  methods: {
    /**
 * 获取随机背景颜色
 */
    getRandomColor () {
      const letters = '0123456789ABCDEF'
      let color = '#'
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
      return color
    },
    // 获取专家对话列表
    getChatList () {
      getChatList('CHAT').then((res) => {
        if (res.data.success) {
          this.chatMenus = res.data.data.list
          if (this.chatMenus.length) {
            setTimeout(() => {
              document.querySelector('.el-menu-item').click()
            }, 100)
          }
        }
        // if (
        //   res.data.data.length &&
        //   res.data.data.find((i) => {
        //     return i.inputs.hangye === this.chain
        //   })
        // ) {
        //   const temp = []
        //   res.data.data.forEach((i) => {
        //     if (i.inputs.hangye === this.chain) {
        //       temp.push(i)
        //     }
        //   })
        //   this.chatMenus = temp
        //   if (this.chatMenus.length) {
        //     setTimeout(() => {
        //       document.querySelector('.el-menu-item').click()
        //     }, 100)
        //   }
        // }
      })
    },
    // 获取对话详情
    getChatDetail (sessionId) {
      this.chatRecords = []
      // this.$refs.textarea.focus()
      if (sessionId === '0') {
        this.options.sessionId = ''
      } else {
        getConversationHistory(sessionId).then((res) => {
          this.options.sessionId = res.data.data.list.length ? res.data.data.list[0].sessionId : sessionId
          res.data.data.list.forEach((i) => {
            this.chatRecords.push({
              messageType: 'QUESTION',
              content: i.question,
              sessionId: i.sessionId,
              id: i.id
            })
            this.chatRecords.push({
              messageType: 'ANSWER',
              content: marked(i.answer),
              sessionId: i.sessionId,
              id: i.id,
              feedback: i.feedback
            })
          })
          // this.chatRecords = res.data.data
          this.height++
        })
      }
    },
    // 获取固定问话
    getAllQuestion () {
      this.Axios.get(
        `https://chainai.mtheworld.com/question_answer/list?chainType=${this.chain}`
      ).then((res) => {
        this.allQuestionList = res.data.data
        this.askAbout = this.allQuestionList.slice(0, 3)
      })
    },
    // // 获取文档对话详情
    // async getPagesDoc (sessionId) {
    //   const res = await pagesDoc({
    //     sessionId: sessionId
    //   })
    //   this.chatRecords = res.data.data.record
    //   this.height++
    // },
    handleTabsEdit (targetName, action) {
      if (action === 'remove') {
        this.tabs.forEach((tab, index) => {
          if (tab === targetName) {
            this.tabs.splice(index, 1)
            const nextTab = this.tabs[index + 1] || this.tabs[index - 1]
            if (nextTab) {
              this.curTabValue = nextTab
            }
          }
        })
      }
    },
    handleSelect (menuIndex, indexPath) {
      this.dot = 0
      clearTimeout(this.timer)
      this.timer = null
      this.curMenuChunk = indexPath ? indexPath[0] : '2'
      this.curMenuIndex = menuIndex
      // 对话部分逻辑
      if (this.curMenuChunk === '1') {
        console.log(this.chatMenus)
        const curChat = this.chatMenus.find(i => { return i.id === menuIndex })
        this.options.sessionId = curChat.id
        this.options.hisSession = curChat.hisSession || ''
        this.tabs = []
        this.title = curChat.name
        const chat = curChat.inputs
        if (menuIndex === '0') {
          this.curCompany = this.chatMenus[0].gongsi
          this.curCompany = this.curCompany.replaceAll('<span>', '')
          this.curCompany = this.curCompany.replaceAll('</span>', '')
          if (this.curCompany) {
            this.talkList = [
              `${this.curCompany}有哪些竞争优势?`,
              `${this.curCompany}的竞争对手有哪些?`,
              `${this.curCompany}公司所处的行业有哪些法规和政策限制?`,
              `${this.curCompany}的远期市盈率是多少?`,
              `${this.curCompany}的历史业绩如何?`,
              `${this.curCompany}的管理团队有哪些经验丰富的人员?`,
              `提供${this.curCompany}的历史净债务比率`,
              `${this.curCompany}的资产负债表是否稳健?`,
              `${this.curCompany}计划如何扩大市场份额和提高盈利能力?`
            ]
            this.talkList.sort(() => Math.random() - 0.5)
          } else {
            this.curCompany = ''
          }
        } else if (chat && chat.gongsi) {
          this.curCompany =
            this.curCompany === chat.gongsi ? this.curCompany : chat.gongsi
          this.talkList = [
            `${this.curCompany}有哪些竞争优势?`,
            `${this.curCompany}的竞争对手有哪些?`,
            `${this.curCompany}公司所处的行业有哪些法规和政策限制?`,
            `${this.curCompany}的远期市盈率是多少?`,
            `${this.curCompany}的历史业绩如何?`,
            `${this.curCompany}的管理团队有哪些经验丰富的人员?`,
            `提供${this.curCompany}的历史净债务比率`,
            `${this.curCompany}的资产负债表是否稳健?`,
            `${this.curCompany}计划如何扩大市场份额和提高盈利能力?`
          ]
          this.talkList.sort(() => Math.random() - 0.5)
        } else {
          this.curCompany = ''
        }
        this.getChatDetail(curChat.id)
        // 文档部分逻辑
      } else {
        const curFile = this.docsMenus.find(i => { return i.id === menuIndex })
        this.options.sessionId = curFile.id
        this.options.hisSession = curFile.hisSession || ''
        this.help('研究报告')
        this.title = curFile.name
        this.curTabValue = '研究报告'
        this.pdf = curFile.fileUrl
        this.getChatDetail(curFile.id)
      }
    },
    // 回答思考动画
    loading () {
      this.timer = setTimeout(() => {
        if (this.dot >= 3) {
          this.chatRecords[this.chatRecords.length - 1].content = ''
          this.dot = 0
        } else {
          this.dot++
          this.chatRecords[this.chatRecords.length - 1].content += ' .'
        }
        this.loading()
      }, 500)
    },
    // 新建普通对话
    async createNewChat (company) {
      const rr = await getNewName()
      if (this.chatMenus.length && this.chatMenus[0].id === '0') {
        this.chatMenus.shift()
      }
      this.curCompany = company
      this.curCompany = this.curCompany.replaceAll('<span>', '')
      this.curCompany = this.curCompany.replaceAll('</span>', '')
      createNewChat(company || rr.data.data, 'CHAT').then(res => {
        if (res.data.success) {
          this.getChatList()
          // const newchat = {
          //   id: '0', // 临时用为了判断index
          //   name: '新的对话',
          //   gongsi: this.curCompany
          // }
          // this.title = ''
          // this.chatMenus.unshift(newchat)
          setTimeout(() => {
            document.querySelector('.el-menu-item').click()
          }, 300)
        }
      })
    },
    // 新建文档对话
    createNewDocChat (title) {
      createNewChat(title, 'DOC').then(res => {
        if (res.data.success) {
          this.$refs.uploadDoc.saveFileRecord(res.data.data)
          this.getFileList()
        }
      })
    },
    valueChange (val) {
      if (val.indexOf('/') > -1 && this.curMenuChunk === '1') {
        this.showKeywords = true
        this.message =
          this.message.slice(0, val.indexOf('/')) +
          this.message.slice(val.indexOf('/') + 1)
      }
    },
    // 获取内容
    getInfo (name) {
      this.message = name + '的概念'
      this.send()
    },
    // 提示
    help (operation) {
      if (this.tabs.indexOf(operation) === -1) {
        this.tabs.push(operation)
      }
      this.$nextTick(() => {
        this.curTabValue = operation
        switch (operation) {
          case '产业链图谱':
            this.chainData()
            break
          case '知识图谱':
            this.knowledgeData()
            break
          case '收藏夹':
            this.openCollect()
            break
          case '上市公司':
            this.getACompanyList()
            break
          case '会议记录':
            this.meetHistory()
            break
          default:
            break
        }
      })

      this.height++
      this.showKeywords = false
      this.$refs.textarea.focus()
    },
    drawChart (type = 'chain') {
      this.chart =
        type === 'chain'
          ? this.Echarts.getInstanceByDom(document.querySelector('#chart'))
          : this.Echarts.getInstanceByDom(
            document.querySelector('#knowledgeChart')
          )
      if (this.chart == null) {
        this.chart =
          type === 'chain'
            ? this.Echarts.init(document.querySelector('#chart'))
            : this.Echarts.init(document.querySelector('#knowledgeChart'))
      }
      const option = {
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove',
          formatter: '{b}'
        },
        series: [
          {
            type: 'tree',
            data: [this.chartData],
            itemStyle: {
              // 设置边框的属性
              color: 'white',
              borderWidth: 0
            },
            label: {
              verticalAlign: 'middle',
              align: 'left',
              fontSize: 12,
              color: '#333',
              backgroundColor: 'transparent',
              lineHeight: 24,
              offset: [-40, 0],
              formatter: function (params) {
                if (params.data.level === 1) {
                  return '{a|' + params.name + '}'
                } else if (params.data.level === 2) {
                  return '{b|' + params.name + '}'
                }
              },
              rich: {
                verticalAlign: 'middle',
                offset: [0, 0],
                a: {
                  padding: [12, 18],
                  borderRadius: 4,
                  color: '#fff',
                  fontSize: 18,
                  offset: [0, 0],
                  fontWeight: 600,
                  backgroundColor: '#3370FF'
                },
                b: {
                  padding: [8, 12],
                  offset: [0, 0],
                  fontSize: 14,
                  borderRadius: 4,
                  color: '#fff',
                  fontWeight: 600,
                  backgroundColor: '#39444C'
                }
              }
            },
            lineStyle: {
              // 连线颜色
              color: '#ccc',
              width: 1
            },
            selectedMode: 'single',
            select: {
              label: {
                show: true,
                color: '#ff6600',
                borderWidth: 0.5,
                padding: [0, 5]
                // borderColor: "#ff6600",
              }
            },
            emphasis: {},
            leaves: {
              label: { show: true }
            },
            top: '0%',
            left: '10%',
            bottom: '0%',
            right: '15%',
            containLabel: true,
            symbolSize: [40, 20],
            edgeShape: 'polyline',
            expandAndCollapse: false,
            animationDuration: 100,
            animationDelay: function (idx) {
              // 越往后的数据时长越大
              return idx * 100
            },
            symbol: 'rect',
            initialTreeDepth: 4
          }
        ]
      }
      this.chart.clear()
      this.chart.off('click')
      let height = 0
      this.chartData.children.forEach((i) => {
        height += i.children.length + 1
      })

      this.chart.getDom().style.width =
        600 + this.getDepth(this.chartData) * 60 + 'px'
      this.chart.getDom().style.height = height * 80 + 'px'
      this.chart.resize()
      option && this.chart.setOption(option)

      this.chart.on('click', (params) => {
        this.currentPage = 1
        if (params.data.level !== 1 && this.curTabValue === '产业链图谱') {
          this.chatRecords.push({
            messageType: 'ANSWER',
            content: `点击下面选项了解${params.data.name}节点的相关资讯：<br/><span onclick="getInfo('${params.data.name}')">1.概念</span><br/><span onclick="getCompany('${params.data.name}',1)">2.A股公司</span><br/><span onclick="getCompany('${params.data.name}',2)">3.三板公司</span><br/><span onclick="getCompany('${params.data.name}',3)">4.四板公司</span><br/><span onclick="getCompany('${params.data.name}',4)">5.非挂牌上市公司</span><br/><span onclick="getCompany('${params.data.name}',5)">6.已私募融资公司</span><br/><span onclick="industryAsk()">7.百问百答</span>`
          })
          this.height++
        }
        if (
          params.data.level !== 1 &&
          params.data.level !== 2 &&
          this.curTabValue === '知识图谱'
        ) {
          this.message = params.data.msg
          this.send()
        }
      })
    },
    // 表格文本摘要
    abstract (val) {
      this.message = val + '的摘要'
      this.send()
    },
    // 表格直接对话
    conversation (obj) {
      this.help('研究报告')
      this.docsMenus.forEach((i, index) => {
        if (i.session_name === obj.title) {
          return document
            .querySelectorAll('.el-menu-item')
            [this.chatMenus.length + index].click()
        }
      })
      if (this.curMenuIndex.length > 10 || this.curMenuIndex === '0') {
        this.Axios.get(
          `https://zcjrys.chinaindustria.com/api/jrys/project/tsyb/info/${obj.reportNumber}`
        ).then((res) => {
          this.pdf = res.data.data.originalUrl
          this.pdfTitle = obj.title
          this.start()
        })
      }
    },
    // 讨论公司
    talkCompany (company) {
      this.createNewChat(company)
      // this.curCompany = company
      // this.talkList = [`${company}有哪些竞争优势?`, `${company}的竞争对手有哪些?`, `${company}公司所处的行业有哪些法规和政策限制?`, `${company}的远期市盈率是多少?`, `${company}的历史业绩如何?`, `${company}的管理团队有哪些经验丰富的人员?`, `提供${company}的历史净债务比率`, `${company}的资产负债表是否稳健?`, `${company}计划如何扩大市场份额和提高盈利能力?`]
      // this.talkList.sort(() => Math.random() - 0.5)
    },
    // 开始文档对话
    start (pre = false) {
      if (pre) {
        this.pdf = this.prePdf
        this.pdfTitle = this.prePdfTitle
      }
      this.help('研究报告')
      this.docsMenus.forEach((i, index) => {
        if (i.session_name === this.pdfTitle) {
          return document
            .querySelectorAll('.el-menu-item')
            [this.chatMenus.length + index].click()
        }
      })
      if (this.curMenuIndex.length > 10 || this.curMenuIndex === '0') {
        const loading = this.$loading({
          lock: true,
          text: '正在解读文档 ... ...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.Axios.post(
          'https://chainai.mtheworld.com/file/chatURLUpload',
          // 'http://10.66.8.6:3002/file/chatURLUpload',
          {
            title: this.pdfTitle,
            authorization: sStorage.get('loginToken').token,
            chain_type: this.chain,
            url: this.pdf
          }).then((res1) => {
          this.curTabValue = '研究报告'
          loading.close()
          this.getFileList()
          setTimeout(() => {
            document
              .querySelectorAll('.el-menu-item')
              [document.querySelectorAll('.el-menu-item').length - 1].click()
          }, 500)
        })
      }
    },
    openCollect () {
      this.currentPage = 1
      this.getCollectList()
    },
    // 收藏列表
    getCollectList () {
      this.Axios.post(
        'https://chainai.mtheworld.com/user_operation/queryPage',
        {
          pageNum: this.currentPage,
          pageSize: 4,
          type: 'collect'
        }
      ).then((res) => {
        this.topics = res.data.data.records
        this.total = res.data.data.total
        this.$nextTick(() => {
          document.querySelector('.el-checkbox').style.width = 'auto'
        })
      })
    },
    // 翻译功能
    translate () {
      if (!this.translateValue.trim()) {
        return this.$message.warning('请输入要翻译的内容')
      }
      this.translating = true
      const that = this
      this.Axios.post(
        'https://chainai.mtheworld.com/chat_message/send',
        {
          prompt: `把${this.translateValue}这句话翻译成${this.lang}，只输出翻译后的结果`,
          options: { hisSession: '', sessionId: '' },
          systemMessage: ''
        },
        {
          headers: {
            Accept: 'text/event-stream',
            'Content-Type': 'application/json',
            jwt: '07274c4f-e6ee-432f-a9eb-693ed0f70a70'
          },
          onDownloadProgress ({ event }) {
            const { responseText } = event.target
            const lastIndex = responseText.lastIndexOf('\n')
            let chunk = responseText
            if (lastIndex !== -1) chunk = responseText.substring(lastIndex)
            const finalResponse = JSON.parse(chunk)
            that.translatedValue = finalResponse.text
            that.$forceUpdate()
          }
        }
      ).then((res) => {
        that.translating = false
      })
    },
    answer (val) {
      this.chatRecords.push({
        messageType: 'ANSWER',
        content: val
      })
    },
    // 生成草稿
    getDraft () {
      if (!this.article.content.trim()) {
        return this.$message.warning('请输入内容')
      }
      this.drafting = true
      const that = this
      this.Axios.post(
        'https://chainai.mtheworld.com/chat_message/send',
        {
          prompt: `根据${this.article.content}生成一篇${this.article.format},要求长度为${this.article.length}，语气为${this.article.tone}`,
          options: { hisSession: '', sessionId: '' },
          systemMessage: ''
        },
        {
          headers: {
            Accept: 'text/event-stream',
            'Content-Type': 'application/json',
            jwt: '07274c4f-e6ee-432f-a9eb-693ed0f70a70'
          },
          onDownloadProgress ({ event }) {
            const { responseText } = event.target
            const lastIndex = responseText.lastIndexOf('\n')
            let chunk = responseText
            if (lastIndex !== -1) chunk = responseText.substring(lastIndex)
            const finalResponse = JSON.parse(chunk)
            that.draft = finalResponse.text
            that.$forceUpdate()
          }
        }
      ).then((res) => {
        that.drafting = false
      })
    },
    // 研报列表
    getReportList () {
      this.Axios.post(
        `https://zcjrys.chinaindustria.com/api/jrys/project/tsyb/page?currPage=${this.currentPage}&size=5`,
        {
          chainNumber: this.chainList.find((i) => {
            return i.name === this.chain
          }).number,
          keyWord: this.reportValue
        }
      ).then((res) => {
        this.tableData = res.data.data.list
        this.total = res.data.data.total
        this.message = ''
      })
    },
    // 研报详情
    getReport (obj) {
      this.Axios.get(
        `https://zcjrys.chinaindustria.com/api/jrys/project/tsyb/info/${obj.reportNumber}`
      ).then((res) => {
        this.prePdf = res.data.data.originalUrl
        this.prePdfTitle = obj.title
        // this.curTabValue = '研究报告'
        // this.help('研究报告')
        this.$notify({
          title: '提示',
          type: 'warning',
          message: '当前报告为预览状态'
        })
      })
    },
    ask (val) {
      this.loading()
      this.chatRecords.push({
        messageType: 'QUESTION',
        content: val.question
      })
      this.chatRecords.push({
        messageType: 'ANSWER',
        content: ''
      })
      this.height++
      const ans = this.allQuestionList.find((i) => {
        return i.id === val.id
      }).answer
      setTimeout(() => {
        this.dot = 0
        clearTimeout(this.timer)
        this.timer = null
        this.chatRecords[this.chatRecords.length - 1].content = ans
        this.height++
      }, 1000)
    },
    talk (val) {
      val.replaceAll('<span>', '')
      val.replaceAll('</span>', '')
      this.message = val
      this.send()
    },
    send () {
      const that = this
      this.message.replaceAll('<span>', '')
      this.message.replaceAll('</span>', '')
      if (!this.message.trim()) return
      // 文档
      // if (this.curMenuChunk === '2') {
      //   this.getDocsChatRecords()
      // } else {
      // 对话
      if (!this.message) return this.$message.error('请输入您的提问')
      if (this.message.indexOf('研报') > -1) {
        this.chatRecords.push({
          messageType: 'QUESTION',
          content: this.message
        })
        this.message = ''
        return this.help('产业研报')
      } else if (this.message.indexOf('产业链图谱') > -1) {
        this.chatRecords.push({
          messageType: 'QUESTION',
          content: this.message
        })
        this.message = ''
        return this.help('产业链图谱')
      } else if (this.message.indexOf('知识图谱') > -1) {
        this.chatRecords.push({
          messageType: 'QUESTION',
          content: this.message
        })
        this.message = ''
        return this.help('知识图谱')
      } else if (this.message.indexOf('收藏夹') > -1) {
        this.chatRecords.push({
          messageType: 'QUESTION',
          content: this.message
        })
        this.message = ''
        return this.help('收藏夹')
      }
      this.chatRecords.push({
        messageType: 'QUESTION',
        content: this.message
      })
      this.chatRecords.push({ messageType: 'ANSWER', content: '' })
      const reqParams = {
        question: this.message,
        session: this.options.sessionId,
        historySession: this.options.hisSession
      }
      if (this.curMenuChunk === '2') {
        reqParams.docName = this.title
      }
      this.height++
      this.loading()
      this.message = ''
      let res = ''
      const controller = new AbortController()
      const eventSource = fetchEventSource(
        // 'https://chainai.mtheworld.com/aliMessage/streamChat',
        this.curMenuChunk === '2' ? 'https://meet.mtheworld.com/api/brain/ai/doc-chat' : 'https://meet.mtheworld.com/api/brain/ai/qwen-chat',
        {
          method: 'POST',
          headers: {
            // Accept: '*/*',
            'Content-Type': 'application/json',
            authorization: sStorage.get('loginToken').token
            // 'X-DashScope-SSE': 'enable'
            // Authorization: 'sk-fdb8371b22af48aaa47637221d8a0df2'
            // Authorization: 'Bearer ' + lStorage.get('token').qHPtfayCyRt4gG3T
          },
          body: JSON.stringify(reqParams),
          onopen () {
            console.log('open')
          },
          onmessage (event) {
            const result = JSON.parse(event.data)
            console.log(result)
            that.dot = 0
            clearTimeout(that.timer)
            that.timer = null
            if (!event.data) { return }
            if (
              that.chatRecords[that.chatRecords.length - 1].content.slice(
                0,
                1
              ) === '.'
            ) {
              that.chatRecords[that.chatRecords.length - 1].content =
                  that.chatRecords[that.chatRecords.length - 1].content.slice(
                    1
                  )
            }
            res = event.data ? result.data.text : ''

            // const a = marked('```\n' + res + '\n```')
            const a = marked(res)
            that.chatRecords[that.chatRecords.length - 1].content = a
            that.height++
          },
          onclose () {
            console.log('close')
            if (that.curMenuIndex === '0') {
              that.getChatList()
            }
            controller.abort()
            eventSource.close()
          },
          onerror (error) {
            console.log(error)
            throw error
            // controller.abort()
            // eventSource.close()
          }
        }
      )
    },
    // 文档提问
    getDocsChatRecords () {
      if (!this.message) return this.$message.error('请输入您的提问')
      this.sseId = ''
      this.chatRecords.push({ role: 'user', content: this.message })
      this.chatRecords.push({ role: 'assistant', content: '' })
      this.openwss()
      setTimeout(() => {
        const data = {
          user_id: sStorage.get('userId'),
          message: this.message,
          session_id: this.curMenuIndex - 0,
          sse_emitter_id: this.sseId,
          session_type: 0
        }
        this.Axios.post(
          'https://chainai.mtheworld.com/file/streamChatWithFile',
          // 'http://10.66.8.6/file/streamChatWithFile',
          data
        )
        this.message = ''
        this.height++
        this.dot = 0
        if (this.chatRecords[this.chatRecords.length - 1].content === '') {
          this.loading()
        }
        // this.Axios.post('/chainai/file/chatWithFile', { message: this.message, user_id: sStorage.get('userId'), session_id: this.curMenuIndex, session_type: 0 }).then(async res => {
        //   this.chatRecords.push({ role: 'assistant', content: res.data.data.message })
        //   this.message = ''
        // })
      }, 500)
    },
    // 创建wss链接
    openwss () {
      // chatgpt接收
      const source = new EventSource(
        'https://chainai.mtheworld.com/chatFile/getSseEmitter'
        // 'http://10.66.8.6/chatFile/getSseEmitter'
      )
      this.chatRecords[this.chatRecords.length - 1].content = ''
      source.onmessage = (event) => {
        if (this.sseId) {
          this.dot = 0
          clearTimeout(this.timer)
          this.timer = null
          this.height++
          if (
            this.chatRecords[this.chatRecords.length - 1].content.slice(0, 1) ==
            '.'
          ) {
            this.chatRecords[this.chatRecords.length - 1].content =
              this.chatRecords[this.chatRecords.length - 1].content.slice(1)
          }
          this.chatRecords[this.chatRecords.length - 1].content += event.data
        } else {
          this.sseId = event.data.slice(1, 19)
        }
      }
      source.onopen = (event) => {
        console.log(event)
      }
    },
    // 复制对话
    copy (content) {
      const input = document.createElement('input') // 不会保留文本格式
      input.value = content
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      this.$message.success('复制成功')
      input.remove()
    },
    // 对话直接收藏、取消收藏
    handle (content, type) {
      switch (type) {
        // case 'collect':
        //   if (content.collectValue) {
        //     this.checkedTopics.push({ id: content.collectValue })
        //     this.cancelOperation()
        //     content.collectValue = false
        //   } else {
        //     this.operation(content, 'collect')
        //   }
        //   break
        case 'like':
          if (content.feedback && content.feedback.rating === 'like') {
            this.cancelOperation(content)
          } else {
            this.operation(content, 'like')
          }
          break
        case 'dislike':
          if (content.feedback && content.feedback.rating === 'dislike') {
            this.cancelOperation(content)
          } else {
            this.operation(content, 'dislike')
          }
          break
        default:
          break
      }
    },
    // 收藏对话 点赞 点踩
    operation (content, type) {
      this.Axios.post(
        `https://model1.chinaindustria.com/api/messages/${content.id}/feedbacks`,
        {
          rating: type
          // this.Axios.post('/chainai/user_operation/modify', {
          //   refType: 'ANSWER',
          //   refId: content.messageId,
          //   type: type
        }
      ).then((res) => {
        if (res.status === 200) {
          switch (type) {
            // case 'collect':
            //   content.collectValue = res.data.data
            //   break
            case 'like':
              if (content.feedback && content.feedback.rating === 'dislike') {
                return this.$message.warning('已点踩')
              } else {
                content.feedback = { rating: 'like' }
              }
              break
            case 'dislike':
              if (content.feedback && content.feedback.rating === 'like') {
                return this.$message.warning('已点赞')
              } else {
                content.feedback = { rating: 'dislike' }
              }
              break
            default:
              break
          }
          this.$message.success('操作成功')
          this.$forceUpdate()
        } else {
          this.$message.error('操作失败')
        }
      })
    },
    // 取消收藏 点赞 点餐
    cancelOperation (content) {
      this.Axios.post(
        `https://model1.chinaindustria.com/api/messages/${content.id}/feedbacks`,
        {
          rating: null
        }
      ).then((res) => {
        if (res.status === 200) {
          content.feedback.rating = null
          this.$message.success('操作成功')
        }
      })

      // const ids = []
      // this.checkedTopics.forEach((i) => {
      //   ids.push(i.id)
      // })
      // this.Axios.post('/chainai/user_operation/delete', ids).then((res) => {
      //   if (res.data.code === 200) {
      //     this.$message.success('操作成功')
      //     this.checkedTopics = []
      //     this.getCollectList()
      //     this.$forceUpdate()
      //   } else {
      //     this.$message.error('操作失败')
      //   }
      // })
    },
    // 插入对话
    insert () {
      this.checkedTopics.forEach((i) => {
        this.message += i.text
      })
    },
    // 产业链图谱
    chainData () {
      this.Axios.post(
        'https://zcjrys.chinaindustria.com/api/jrys/common/chain/gpt/tree',
        { chainName: this.chain }
      ).then((res) => {
        this.chartData = { name: this.chain, level: 1, children: [] }
        this.formatData(res.data.data.children)
        this.chartData.children = res.data.data.children
        this.drawChart()
        this.height++
        this.answer('好的，我正在按照您的要求绘画，请稍等...')
        setTimeout(() => {
          this.height++
          this.answer(
            `${this.chain}产业链图谱已经生成，您可以点击每个节点向我提问。请注意，这只是一个通用的知识框架，${this.chain}产业非常复杂且不断发展，还有许多细分领域和相关内容可以加入到知识图谱中。您可以根据自己的需求和兴趣进一步扩展和完善这个知识图谱。`
          )
        }, 2000)
      })
    },
    // 知识图谱
    knowledgeData () {
      delete this.options.type
      this.chartData = {
        name: `${this.chain}产业学习框架`,
        level: 1,
        children: [
          {
            name: '产业知识',
            level: 2,
            children: [
              {
                name: '基础概念',
                msg: `介绍一下${this.chain}产业的基础概念`,
                chatgpt: `按照如下大纲介绍一下${this.chain}产业的基础概念：产业定义、产业分类、产业链条、产业生命周期`,
                children: [
                  {
                    name: '产业定义',
                    msg: `介绍一下${this.chain}的产业定义`,
                    chatgpt: `介绍一下${this.chain}的产业定义`
                  },
                  {
                    name: '产业分类',
                    msg: `介绍一下${this.chain}的产业分类`,
                    chatgpt: `介绍一下${this.chain}的产业分类`
                  },
                  {
                    name: '产业链条',
                    msg: `介绍一下${this.chain}的产业链条`,
                    chatgpt: `介绍一下${this.chain}的产业链条`
                  },
                  {
                    name: '产业生命周期',
                    msg: `介绍一下${this.chain}的产业生命周期`,
                    chatgpt: `介绍一下${this.chain}的产业生命周期`
                  }
                ]
              },
              {
                name: '主要产业领域',
                msg: `介绍一下${this.chain}产业的主要产业领域`,
                chatgpt: `介绍一下${this.chain}产业的主要产业领域只列出标题不要详细内容`
              },
              {
                name: '产业政策与法规',
                msg: `介绍一下${this.chain}产业的产业政策与法规`,
                chatgpt: `${this.chain}产业的产业政策与法规有哪些？只提供标题不要详细内容`
              },
              {
                name: '产业标准和指标',
                msg: `介绍一下${this.chain}产业的产业标准和指标`,
                chatgpt: `列出${this.chain}产业的产业标准和指标，只要标题不要详细内容`
              }
            ]
          },
          {
            name: '产业研究',
            level: 2,
            children: [
              {
                name: '产业分析方法',
                msg: `${this.chain}产业的产业分析方法有哪些？`,
                chatgpt: `${this.chain}{产业节点名称}产业的产业分析方法有哪些？`,
                children: [
                  {
                    name: 'PESTEL分析',
                    msg: `用PESTEL分析模型分析一下${this.chain}产业`,
                    chatgpt: `用PESTEL分析模型分析中国国内${this.chain}产业，分析结果中请包括一些数据、政策、报告、文件等进行详细说明`
                  },
                  {
                    name: '价值链分析',
                    msg: `用价值链分析法分析一下${this.chain}产业`,
                    chatgpt: `用价值链分析法分析一下${this.chain}产业`
                  },
                  {
                    name: '技术创新与研发投入分析',
                    msg: `用技术创新与研发投入分析法分析一下${this.chain}产业`,
                    chatgpt: `用技术创新与研发投入分析法分析一下${this.chain}产业`
                  },
                  {
                    name: '成本结构与效率分析',
                    msg: `用成本结构与效率分析法分析一下${this.chain}产业`,
                    chatgpt: `用成本结构与效率分析法分析一下${this.chain}产业`
                  },
                  {
                    name: 'Porter五力分析',
                    msg: `用Porter五力分析模型分析一下${this.chain}产业`,
                    chatgpt: `用Porter五力分析模型分析一下${this.chain}产业`
                  }
                ]
              },
              {
                name: '市场需求与趋势',
                msg: `介绍一下${this.chain}产业的市场需求与趋势`,
                chatgpt: `按照如下大纲介绍一下${this.chain}产业的市场需求与趋势`,
                children: [
                  {
                    name: '市场规模预测',
                    msg: `介绍一下${this.chain}的市场规模预测`,
                    chatgpt: `介绍一下${this.chain}的市场规模预测`
                  },
                  {
                    name: '消费者行为分析',
                    msg: `介绍一下${this.chain}的消费者行为分析`,
                    chatgpt: `介绍一下${this.chain}的消费者行为分析`
                  },
                  {
                    name: '技术发展趋势',
                    msg: `介绍一下${this.chain}的技术发展趋势`,
                    chatgpt: `介绍一下${this.chain}的技术发展趋势`
                  },
                  {
                    name: '竞争格局变化',
                    msg: `介绍一下${this.chain}的竞争格局变化`,
                    chatgpt: `介绍一下${this.chain}的竞争格局变化`
                  }
                ]
              },
              {
                name: '企业竞争力评估',
                msg: `介绍一下${this.chain}产业的企业竞争力评估`,
                chatgpt: `按照如下大纲介绍一下${this.chain}产业的企业竞争力评估`,
                children: [
                  {
                    name: '产品和服务比较',
                    msg: `介绍一下${this.chain}的产品和服务比较`,
                    chatgpt: `介绍一下${this.chain}的产品和服务比较`
                  },
                  {
                    name: '财务指标分析',
                    msg: `介绍一下${this.chain}的财务指标分析`,
                    chatgpt: `介绍一下${this.chain}的财务指标分析`
                  },
                  {
                    name: '创新能力评估',
                    msg: `介绍一下${this.chain}的创新能力评估`,
                    chatgpt: `介绍一下${this.chain}的创新能力评估`
                  },
                  {
                    name: '供应链管理水平',
                    msg: `介绍一下${this.chain}的供应链管理水平`,
                    chatgpt: `介绍一下${this.chain}的供应链管理水平`
                  }
                ]
              },
              {
                name: '产业风险识别与应对',
                msg: `介绍一下${this.chain}产业的产业风险识别与应对`,
                chatgpt: `按照如下大纲介绍一下${this.chain}产业的产业风险识别与应对`,
                children: [
                  {
                    name: '政策法规变动风险',
                    msg: `介绍一下${this.chain}的政策法规变动风险`,
                    chatgpt: `介绍一下${this.chain}的政策法规变动风险`
                  },
                  {
                    name: '经济波动风险',
                    msg: `介绍一下${this.chain}的经济波动风险`,
                    chatgpt: `介绍一下${this.chain}的经济波动风险`
                  },
                  {
                    name: '技术革新风险',
                    msg: `介绍一下${this.chain}的技术革新风险`,
                    chatgpt: `介绍一下${this.chain}的技术革新风险`
                  },
                  {
                    name: '行业竞争风险',
                    msg: `介绍一下${this.chain}的行业竞争风险`,
                    chatgpt: `介绍一下${this.chain}的行业竞争风险`
                  }
                ]
              }
            ]
          }
        ]
      }
      // this.Axios.post('/chainai/atlas/send', {
      //   prompt: `请给我${this.chain}的产业知识图谱的节点标题`
      // }).then((res) => {
      //   res.data.data.forEach((i) => {
      //     this.chartData.children.push({ name: i })
      //   })
      // })
      this.height++
      this.answer('好的，我正在按照您的要求绘画，请稍等...')
      setTimeout(() => {
        this.height++
        this.answer(
          `${this.chain}知识图谱已经生成，您可以点击每个节点向我提问。请注意，这只是一个通用的知识框架，${this.chain}产业非常复杂且不断发展，还有许多细分领域和相关内容可以加入到知识图谱中。您可以根据自己的需求和兴趣进一步扩展和完善这个知识图谱。`
        )
      }, 2000)
      this.$nextTick(() => {
        this.drawChart('knowledge')
      })
    },
    // 数据处理
    formatData (obj) {
      if (Array.isArray(obj)) {
        obj.forEach((item) => {
          this.formatData(item)
        })
      } else if (typeof obj === 'object') {
        if (obj.data) {
          obj.name = obj.data.nodeTitle
          this.formatData(obj.children)
        } else {
          Object.values(obj).forEach((value) => {
            this.formatData(value)
          })
        }
      }
    },
    // 查询children层级
    getDepth (obj) {
      let depth = 0
      if (typeof obj === 'object') {
        for (const key in obj) {
          const childDepth = this.getDepth(obj[key])
          if (childDepth > depth) {
            depth = childDepth
          }
        }
        depth++
      }
      return depth
    },
    // 上市公司列表
    getACompanyList () {
      this.curTabValue = '上市公司'
      this.Axios.post(
        `https://zcjrys.chinaindustria.com/api/jrys/index/search/list-company?currPage=${this.currentPage}&size=5`,
        { chain: this.chain, name: this.companyValue }
      ).then((res) => {
        this.aCompanyList = res.data.data.list
        this.total = res.data.data.total
      })
    },
    // type 1，2，3，4，5
    getCompany (name, type) {
      this.help('公司列表')
      this.curTabValue = '公司列表'
      this.companyType = type
      this.node = name
      switch (type) {
        case 1:
          this.curSubtitle = `${name}的A股公司`
          break
        case 2:
          this.curSubtitle = `${name}的三板公司`
          break
        case 3:
          this.curSubtitle = `${name}的四板公司`
          break
        case 4:
          this.curSubtitle = `${name}的非挂牌上市公司`
          break
        case 5:
          this.curSubtitle = `${name}的已私募融资公司`
          break
        default:
          break
      }
      const params = {
        market: type,
        tag: name
      }
      this.Axios.post(
        `https://zcjrys.chinaindustria.com/api/jrys/regional/chain/industry/page?currPage=${this.currentPage}&pageSize=5`,
        params
      ).then((res) => {
        this.companyList = res.data.data.list
        this.total = res.data.data.total
      })
    },
    // 会议记录列表
    async meetHistory () {
      // const res = await transPages()
      // this.historyData = res.data.list
      // this.total = res.data.total
    },
    async onBeforeUpload ({ file }) {
      console.log(file)
      this.fileSuffix = file.file.name.substring(
        file.file.name.lastIndexOf('.')
      )
      const fileSha1 = await getFileSha1(file.file)
      return new Promise((resolve, reject) => {
        policy({
          folder: '66',
          fileName: file.file.name,
          fileSize: file.file.size,
          fileSuffix: this.fileSuffix,
          fileSha1
        })
          .then((response) => {
            this.data.policy = response.data.policy
            this.data.signature = response.data.signature
            this.data.ossaccessKeyId = response.data.accessid
            this.data.id = response.data.id
            this.data.key = response.data.dir + response.data.name
            this.data.dir = response.data.dir
            this.data.host = response.data.host
            this.policyData = response.data
            console.log(1111, this.data)
            resolve(true)
          })
          .catch((err) => {
            console.log('出错了...', err)
            reject(new Error(false))
          })
      })
    },
    onUploadError ({ file, event }) {
      this.$message.error('当前任务失败，请稍后重试')
      console.error(file, event)
    },

    async onUploadFinish ({ file, event }) {
      console.log(file, event)
      await createFileTrans({
        audioLanguage: 'cn',
        audioOssPath: this.policyData.dir + this.policyData.name,
        audioOutputEnabled: false,
        audioOutputOssBucket: '',
        audioOutputOssPath: '',
        audioRoleNum: '2',
        audioSegmentsEnabled: false,
        externalUrl: `${this.policyData.host}/${this.policyData.dir + this.policyData.name}`,
        taskName: '试试别的音频'
      })
      this.uploadDialog = false
      this.meetHistory()
    },
    viewHistory (history) {
      this.historyDetail = history
    },
    exportHistory () {

    },
    industryAsk () {
      this.questionList = []
      for (let i = 0; i < 6; i++) {
        this.questionList.push(this.allQuestionList[i])
      }
      this.help('百问百答')
    },
    changePage (val) {
      this.currentPage = val
      switch (this.curTabValue) {
        case '收藏夹':
          this.getCollectList()
          break
        case '公司列表':
          this.getCompany(this.chain, this.companyType)
          break
        case '上市公司':
          this.getACompanyList()
          break
        case '产业研报':
          // 研报列表
          this.getReportList()
          break
        case '会议列表':
          // 会议列表
          this.meetHistory()
          break
        default:
          break
      }
    },
    receiveMsg (msg) {
      this.companyCode = msg
    },
    handleCheckAllChange (val) {
      this.checkedTopics = []
      if (val) {
        this.topics.forEach((i) => {
          this.checkedTopics.push(i)
        })
      }
      this.isIndeterminate = false
    },
    handleCheckedTopicsChange (value) {
      const checkedCount = value.length
      this.checkAll = checkedCount === this.topics.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.topics.length
    },
    handleEditMenuName (index) {
      this.$prompt('请输入对话名', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(({ value }) => {
          const data = { title: value, type: 'CHAT', id: this.chatMenus[index].id }
          editChat(data).then((res) => {
            this.chatMenus[index].name = value
            this.title = value
            this.$message({
              type: 'success',
              message: '修改成功 '
            })
          })
            .catch(() => {
              this.$message({
                type: 'error',
                message: '修改失败 '
              })
            })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleDelMenuName (index) {
      if (this.chatMenus[index].id !== '0') {
        deleteChat(this.chatMenus[index].id).then(
          // this.Axios.post('/chainai/chat_room/delete', [this.chatMenus[index].id]).then(
          (res) => {
            if (res.data.success) {
              this.$message.success('删除成功')
              this.chatMenus.splice(index, 1)
              this.tipVisibles.splice(index, 1)
              if (this.chatMenus.length > 0) {
                document.querySelector('.el-menu-item').click()
              }
            } else {
              this.$message.error('删除失败')
            }
          }
        )
      } else {
        this.$message.success('删除成功')
        this.chatMenus.splice(index, 1)
        this.tipVisibles.splice(index, 1)
        if (this.chatMenus.length > 0) {
          document.querySelector('.el-menu-item').click()
        }
      }
    },
    refreshData () {
      this.getFileList()
    },
    closeModel () {
      this.showModal = false
      setTimeout(() => {
        document
          .querySelectorAll('.el-menu-item')
          [document.querySelectorAll('.el-menu-item').length - 1].click()
      }, 500)
    },
    // 获取文件列表
    async getFileList () {
      const res = await getChatList('DOC')
      this.docsMenus = res.data.data.list
    },
    // 百问百答换一批
    fresh () {
      if (new Date().getTime() - this.beforeTime.getTime() > 5000) {
        this.beforeTime = new Date()
        const num = Math.floor(
          Math.random(0, 1) * (this.allQuestionList.length - 6)
        )
        this.questionList = this.allQuestionList.slice(num, num + 6)
      } else {
        return this.$message.warning('操作频繁')
      }
    },
    // 开始识别
    handleVoiceStart () {
      const readyState = this.iatRecorder.webSocket
        ? this.iatRecorder.webSocket.readyState
        : 3
      if (readyState !== 3) {
        this.$message.warning('上一段进程未结束，请稍后再使用该功能！')
      } else {
        // this.myvoice = this.comments
        this.speaking = true
        this.iatRecorder.start()
        const voice = setInterval(() => {
          if (this.iatRecorder.closeVoiceFlag === 1) {
            this.$message.warning('未检测到语音！')
            this.speaking = false
            this.iatRecorder.closeVoiceFlag = 0
            clearInterval(voice)
          }
          if (this.iatRecorder.voiceDialog === 0) {
            this.speaking = false
            clearInterval(voice)
          }
        }, 40)
      }
    },
    // 结束识别
    handleVoiceEnd () {
      // this.$message.warning('结束录音')
      this.iatRecorder.stop()
      this.iatRecorder.onTextChange = (text) => {
        this.message += text
      }
      this.speaking = false
    }
  },
  created () {
    this.chain = this.$route.query.name
    window.getInfo = this.getInfo
    window.getCompany = this.getCompany
    window.industryAsk = this.industryAsk
    this.getChatList()
    this.getFileList()
    this.getAllQuestion()
  },
  watch: {
    height (n, o) {
      if (n !== o) {
        this.$nextTick(() => {
          const ele = document.querySelector('.chat-list')
          ele.scrollTop = ele.scrollHeight
        })
      }
    }
  }
}
</script>
