<template>
  <div class="header">
    <div class="logo">
      <img src="@/assets/image/logo.png" alt="">
    </div>
    <div class="avatar flex-row">
      <img src="@/assets/image/avatar.png" alt="" style="margin-right: 10px;">
      <span>{{ account }}</span>
    </div>
    <el-popover
  placement="bottom"
  v-model="visible">
  <p>确定退出吗？</p>
  <div style="text-align: right; margin: 0">
    <el-button size="mini" type="text" @click="visible = false">取消</el-button>
    <el-button type="primary" size="mini" @click="logout">确定</el-button>
  </div>
    <div slot="reference" class="setting" style="margin-left: 56px; cursor: pointer;">
      <img src="@/assets/image/setting.png" alt="">
    </div>
  </el-popover>
  </div>
</template>

<script>
import { sStorage } from '@/utils'
export default {
  name: 'HeaderView',
  data () {
    return {
      account: '',
      visible: false
    }
  },
  mounted () {
    this.account = sStorage.get('loginToken').account
  },
  methods: {
    logout () {
      this.$router.push({ name: 'login' })
      sStorage.clear()
    }
  }

}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  padding-left: 70px;
  background: #fff;
  padding-right: 20px;
  flex: 1;
  height: 100%;
  .logo {
    margin-right: auto;
  }

  .avatar {
    cursor: pointer;
    align-items: center;
    font-size: 18px;
    color: #505F71;
    letter-spacing: 0;
    font-weight: 400;
  }
}
</style>
