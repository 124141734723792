export * from './chat'

export const timesContent = [
  {
    time: '00:00',
    title: '数字显示技术的优势及应用',
    content:
      '本文介绍了数字显示技术在显示效果、功耗和成本等方面的优势，并详细讨论了数字显示技术在眼镜、工程样板间和军品领域的应用。文章还提到了数字显示技术的未来发展方向，包括更高的刷新率和沉浸式体验。同时，文章也提到了数字显示技术与传统显示技术的比较和挑战。'
  },
  {
    time: '18:19',
    title: '摄影行业的发展与技术挑战',
    content:
      '对话中提到了摄影行业的发展和技术挑战，包括颜色处理、光学材料和掩模板等方面的问题。同时还讨论了韩国人在摄影技术上的研究和投资，以及中国的OLED显示器和半导体行业的发展情况。对话还涉及了创业经历和投资决策等方面的内容。'
  },
  {
    time: '35:09',
    title: '半导体工艺和设备的发展',
    content:
      '本文讨论了半导体工艺和设备的发展，包括芯片背板设计、模拟驱动发烫问题、京东方的OLED VGO技术、大疆无人机的需求等。还讨论了不同尺寸芯片的市场情况和成本问题，以及中国在半导体产业的发展现状和挑战。'
  },
  {
    time: '52:18',
    title: '创新思维与投资失败',
    content:
      '对话中涉及到创新思维的讨论以及投资失败的经历。其中提到了从另一种思维方式来思考创新的重要性，以及在玻璃上进行创新的困难和挑战。还讨论了OLED和LED技术在大屏幕显示领域的应用问题，以及驱动问题和面板设计的挑战。最后提到了山东工厂通过基金电路设计解决了一些技术问题，并提出了新的设计思路。'
  },
  {
    time: '01:01:58',
    title: '中国国内技术领先的电竞人员融资情况',
    content:
      '对话片段中提到了中国国内技术领先的电竞人员融资情况，讨论了集成电路设计公司的发展和挑战，以及液晶面板驱动电路的市场情况。对话还涉及了投资决策和市场竞争等方面的内容。总体来说，对话片段涵盖了中国电竞行业和相关产业的融资与发展情况。'
  },
  {
    time: '01:24:14',
    title: '中国的芯片产业面临挑战与发展',
    content:
      '中国的芯片产业目前面临销量下降和资金问题，但内部仍有争论和分歧。同时，也有机构对其持谨慎态度。该产业的发展受到技术壁垒和市场竞争的限制，在微型液晶和军工领域有一些订单，但整体产业规模较小。在未来，随着技术的进步和产业的支撑，中国的芯片产业有望迎来新的发展机遇。'
  },
  {
    time: '01:35:12',
    title: 'VR产业发展及市场竞争分析',
    content:
      '本文对VR产业的发展进行了分析，指出了VR市场的潜力和挑战。讨论了VR产品的技术瓶颈和市场局限性，以及VR公司的发展方向。同时，还探讨了政府合作和定制化产品的推广策略，以及供应链的重要性。最后，强调了工号价格优势和性价比对于VR产业的竞争力的重要性。'
  },
  {
    time: '01:46:19',
    title: '液晶屏与处理器性能的关系',
    content:
      '对话片段中讨论了液晶屏的价格和性能对产品的影响，以及处理器性能对游戏开发的重要性。同时提到了市场上游的重要性和产业链的发展。最后，讨论了用户对产品的使用体验和价值的看法。'
  }
]

export const summarizes = [
  {
    person: '发言人2',
    content:
      '他主要就显示器驱动电路的价格、技术和市场等方面进行了讨论。他提到了驱动电路的价格较高，但随着技术的进步和市场需求的增加，价格有望下降。他还讨论了不同尺寸和技术的驱动电路的优缺点，并提到了中国在这一领域的发展和国际竞争。此外，他还涉及到了液晶面板的市场格局、技术创新和政府支持等方面的内容。在回答提问时，他还提到了他们在产品设计、规划和成本控制方面的努力和挑战。他在创业初期的经历包括从事家电制造、进入外企工作以及创办公司等。他还与一些知名企业合作，如IBM和Intel。'
  },
  {
    person: '发言人4',
    content:
      '主要介绍了公司的产品和市场情况。他指出，该问题只涉及工程量的问题，而不是原理性科学问题。他还提到了公司的背景、产品特点、成本优势和良率，以及与其他公司的合作和竞争关系。他还讨论了数字驱动和传统驱动的优劣，液晶和LED等技术的发展历程，以及产品价格和市场推广的策略。他强调了好的产品才能吸引客户，而不是仅仅追求低价格或者抢购。'
  },
  {
    person: '发言人3',
    content:
      '他主要讨论了投资、企业家生涯、市场营销等方面的话题。他提到了自己的毕业情况、工作经历以及对投资项目的看法。同时，他也谈到了市场竞争、团队建设以及公司的发展前景。在讨论中，他提到了具体的投资案例和市场数据。总体而言，他对投资和企业发展有着自己的见解，并希望能找到合适的投资机构和融资方式来支持自己的项目。.3寸到1.6寸的微型显示器，通过提高功耗、亮度和寿命等性能指标来满足用户需求。他还提到了竞争对手的优势和挑战，强调了公司在产品质量、成本和寿命等方面的优势。此外，他还讨论了不同驱动方式和工艺条件对显示效果的影响，以及国内外市场的发展趋势。'
  },
  {
    person: '发言人1',
    content:
      '他提到了公司的情况和市场动态，包括主要业务、合作机构、面临的挑战和预期销量等。他还谈到了硬件和应用的互动、机构投资者的认可和合作可能性。总的来说，他认为公司在市场上有发展潜力，但需要更多努力和创新。'
  },
  {
    person: '发言人6',
    content:
      '他主要讨论了显示技术的话题，包括他们公司在福州开发的1991年的显示器，厦门的合作经历，液晶和OLED屏幕的优缺点，以及一些公司在这个行业的投资和研发情况。此外，他还提到了面板尺寸和驱动技术的问题。'
  },
  {
    person: '发言人5',
    content:
      '他提到了总冠军、品牌、投资公司、政府采购、头盔推广和使用场景、政府和企业合作以及需求和配置等话题。他在对话中表达了对行业内情况和观点的看法。'
  }
]

export const keyPoints = [
  {
    name: '发言人4等',
    title: '您们现在的LED的功耗是多少？您们现在LED的工艺是什么？',
    content:
      '我们现在的良率已经是六七成，非常高了。我们用了一个更便宜的工艺去做，因为我们的数字系统算法以后，它实际上从算法的层面，从那个策略的层面就已经把这个成本降下来了。'
  },
  {
    name: '发言人4等',
    title:
      '您们是否可以给我报销？请问您确定您的LED亮度很低吗？现在LED亮度可以降低到多少？您们现在LED的产能如何？',
    content:
      '我是专家，是直接报销，所以对，直接LED。我们后来画了一个工艺节点，稍微提高了工艺节点，就把功耗降到了现在市面上的水平。这是一个问题。我们现在的产能已经很高了，应该能长期产生。'
  },
  {
    name: '发言人 2 等',
    title: '数字刷新率和电路消耗的关系是什么？',
    content:
      '数字刷新率越高，电路消耗越低，传统的刷新率低于数字刷新率的方式更麻烦，耗消耗更大。'
  },
  {
    name: '发言人 4',
    title: '电视机的刷新率是多少？',
    content:
      '电视机的刷新率基本上都在120以上，现在还是行业里面这个基础还是在90左右。'
  },
  {
    name: '发言人 4',
    title: '白光可以直接用于照明吗？',
    content:
      '现在还在想办法，有没有办法直接去做用这个掩模板的方式，直接去做一个其实非常难，因为这个单点的像素只有几个微米，几个微米的话，你这个板的这个厚度就不能太厚，可能也是几微米。'
  },
  {
    name: '发言人 6',
    title: '华星光的历史和背景是什么？',
    content:
      '华星光是华东政府和美国EMO电视品牌共同出资成立的公司，曾经是全球最大的显示器品牌之一，现在在全球占有率已经降至6成。'
  },
  {
    name: '发言人 6',
    title: '显示器品牌LC是什么？',
    content:
      'LC品牌原来是美国的emo电视品牌，后来被AOC收购并卖给了中国电子，最终在全球占有率降至6成。'
  },
  {
    name: '发言人 2',
    title: '天使是做什么的？',
    content: '天使是一家上市公司，主要从事大陆it业务，在台湾也有相关业务。'
  },
  {
    name: '发言人 2',
    title: '07到哪一年是做投早期的那是鼎盛？',
    content:
      '08年11年OK在预备他们作证。11年之后11年以后，一二年以后我去了去花了比较多一点时间在加拿大待了女孩子。'
  },
  {
    name: '发言人 2',
    title: '你是国内的人员吗？',
    content:
      '当初是国内的人员都希望是人民币基金。但是他华盛顿和这个镀金山两边公司到了以后，美国政治斗争环境的人坚决不同意做人民基金。'
  },
  {
    name: '发言人 2',
    title: 'NRA能进到你这来吗？',
    content: 'NEI能进到你这来，按照这个价格，那我也给他一点，让他送我2.5。'
  },
  {
    name: '发言人 2',
    title: '前面出了40块钱吗？',
    content:
      '出了2500万，公司里面我送2000万，一直保证三年，每年有三个月在有的用一下。'
  },
  {
    name: '发言人 6',
    title: '安总回来快捷之后也做了吗？',
    content: '没有冠军我就退出了。'
  },
  {
    name: '发言人 6',
    title: '苹果为什么找索尼合作？苹果为什么不选择数字驱动？',
    content:
      '索尼的模拟驱动发烫，做不了，不良率高。苹果希望解决这个问题，最后找到了索尼。苹果使用的是物理驱动，但是它的产品并不需要那么烫。因此，它的良率只有10%。'
  },
  {
    name: '发言人 4',
    title: '苹果找京东方的目的是什么？',
    content:
      '为了解决翻过来的问题，现在相当于韩国三星2G也要加进来。苹果希望京东方提供更好的技术。'
  },
  {
    name: '发言人 4',
    title: '索尼的工艺是否最先进？',
    content:
      '索尼的工艺不是全球最先进的，国内的技术反而更好。苹果在这个方面有所改进，但并不是全球最先进的。'
  },
  {
    name: '发言人 6',
    title: '京东方为什么不用OLED屏幕？',
    content:
      '京东方的OLED屏幕做不出来，它的量产线在云南这个八寸也有，12寸也有。如果所以meta它是世界最大的，它有它这个后面要做超过1000万台机就是。'
  },
  {
    name: '发言人 4',
    title: '苹果为什么找半导体工厂合作？',
    content:
      '苹果使用的是半导体工厂的工艺，它的电路做完以后，把经营位置给我们，我们把它放到产品里面去。'
  },
  {
    name: '发言人 4',
    title: '为什么半导体硅是1.3寸？半导体硅的最大尺寸是多少？',
    content:
      '因为半导体硅是光刻机曝光做出来的，现在行业里曝光机的尺寸一般来说大概就26乘32 33毫米这种板块。最大尺寸是1.3寸，需要重新搞个缝纫机，重新搞一套这个刻蚀机制的东西。'
  },
  {
    name: '发言人 4',
    title: '1.3寸的市场量是多少？',
    content: '1.3寸的市场量是比较小的，但是在技术方面有很大的潜力。'
  },
  {
    name: '发言人 4',
    title: '未来的装修公司数量会有多少？1.3寸的产品成本如何计算？',
    content:
      '如果未来的装修公司能蹦起来，可能会很多。1.3寸的产品成本可以接受，大概就是30块钱一个。'
  },
  {
    name: '发言人 4',
    title: '液晶的层数和成本有什么关系？',
    content:
      '层数越多，成本越贵。未来的模式可能更是做ID模式，可能是自己去做对吧？一般来说就是一个决定。'
  },
  {
    name: '发言人 6',
    title: '你们公司有没有专门做COC这一块的驱动是专门线路设计的？',
    content: '这就是我们的核心价值。'
  },
  {
    name: '发言人 4',
    title: '现在的成本比以前降低了吗？',
    content: '一个八寸的可能成本也就100美金，不到12寸的也就100美金。'
  },
  {
    name: '发言人 4',
    title: '这个行业的割裂现象有多严重？',
    content:
      '就是说传统的做那个面板的全新的剪刀模式还是什么，还是做面板的起的，所以他是做一半怎么做面板的知道吗？对，但怎么做下面的那个。'
  },
  {
    name: '发言人 4',
    title: '为什么你会找到这个方向？',
    content:
      '就是说传统的做那个面板的全新的剪刀模式还是什么，还是做面板的起的，所以他是做一半怎么做面板的知道吗？对，但怎么做下面的那个。'
  },
  {
    name: '发言人 6 等',
    title: '他有没有可能在玻璃上做透明的TFT？',
    content:
      '他跨领域了，就是他这个适用范围已经超出适用范围了，对吧？这个玻璃什么的，反正他只能比如说做那个手机基金的话，做手表已经是极限了。'
  },
  {
    name: '发言人 2',
    title:
      '苹果迷你LED成本怎么降？迷你LED成本如何降低？超大屏幕LED如何降低成本？',
    content:
      '成本无法降低，每平方米的密度越高，分区越多，密度就越需要高档，需要算一下价钱，密度和算价钱是关键。京东方投资做了迷你LED，用激光面打到前板上，密度越高，分区越多，密度就越需要高档，需要算一下价钱。无法降低成本，需要使用激光面打到前板上，密度越高，分区越多，密度就越需要高档，需要算一下价钱。'
  },
  {
    name: '发言人 2',
    title: '超过100寸的LED怎么办？',
    content:
      '使用基金电路设计的方式，重新涉及到这明年月底的。先在窄板上面把它先把它封装好，磨牙切割分选出来，你再一贴KS4。这个方法可以提高对比度，因为涉及到一个关键的问题是官创业板的问题，解决了他原来是官方的问题危险。'
  },
  {
    name: '发言人 2',
    title: '你是说什么时候？',
    content: '现在的心情。'
  },
  {
    name: '发言人 4 等',
    title: '张玉军是谁？张玉军他是什么时候的？张玉军他的身份是什么？',
    content:
      '张玉军是国际电竞人员之一，也是博客力的三个电台之一。他是去年开始做的。张玉军是从国际角度考虑问题的人。'
  },
  {
    name: '发言人 2',
    title: '现在液晶面板的驱动电路价值是多少？',
    content: '已经超过了白菜的价格。'
  },
  {
    name: '发言人 2',
    title: '他觉得他自己的是什么？',
    content: '他自己觉得自己是老板。'
  },
  {
    name: '发言人 2',
    title: '为什么建议不要走？',
    content:
      '他们去年已经亏损了，即使是融资，也可能不能保证2022年还能赚16个亿。'
  },
  {
    name: '发言人 2',
    title: '为什么挂牌这么困难？',
    content: '他只要一挂牌，就低于他的一个投资的一个马上挂牌。'
  },
  {
    name: '发言人 2',
    title: '袁总觉得眼光怎么样？',
    content: '我觉得不错，未来可以考虑玩满军贡献。'
  },
  {
    name: '发言人 2',
    title: '什么是企业定增？',
    content: '就是在市场低迷时进行投资，等待市场回暖后卖出。'
  },
  {
    name: '发言人 2',
    title: '先给军工再供应海外的会有问题吗？',
    content: '不会，应该可以在技术上隔离。'
  },
  {
    name: '发言人 3',
    title: '中国的智能化管理城市需要多少钱？',
    content:
      '一两条腿，滁州市长要去一趟，联系上市长后，可以看看福州市的钱情况，但很难找到12年就坐飞机的人。'
  },
  {
    name: '发言人 2',
    title: '为什么在中国很难找到早期项目？',
    content:
      '因为中国做VCE的本身，它的一定是要非常有产业空间投资金结合他这个团队在前面的几年还没有形成，还有一部分作为一些本身都是那些爆发户，三下5除了赚了很多钱，但那是早些年那些人他们有钱以后，他也不懂看这个事。'
  },
  {
    name: '发言人 3',
    title: '滁州市场怎么样？',
    content:
      '可以去一趟，跟市长联系上之后，看看福州市的钱情况，但很难找到12年就坐飞机的人。'
  },
  {
    name: '发言人 1',
    title: '这个项目的印象如何？',
    content:
      '很好，但是并不是每家公司都能存活到市场上。对于某一家particular公司是否能够存活到市场上，这些VC或者PEVC是有一些印象的。'
  },
  {
    name: '发言人 1',
    title:
      '中国女性用户对这个产品的喜好程度如何？美国和日本的市场份额在下降吗？',
    content:
      '中国的话基本上就是中国是在女性的，目前来其他包括棋爱奇艺喜欢的挺少。不断下降，但内部争论相当大，因为内部花了很大的代价把他们过来，把他们的人数也小于100个、400个，甚至于更多。'
  },
  {
    name: '发言人 1',
    title:
      '融资时，PC问题在switch上有没有爆款型应用？融资后机构对switch的销量预期有什么变化？',
    content:
      '在switch上有爆款型应用可以促进销量。机构对switch的销量预期存在变化，需要更加强有力的推广。'
  },
  {
    name: '发言人 3',
    title: '对方是否已经卖出了产品？',
    content: '是的，产品已经卖出。'
  },
  {
    name: '发言人 4',
    title: '何俊和夏老师是如何组合的？',
    content: '组合在一起后发现技术有待提高。'
  },
  {
    name: '发言人 4',
    title: '公司在行业中的地位如何？',
    content: '我们是行业里的用户，但不会购买芯片公司的产品。'
  },
  {
    name: '发言人 4',
    title: '为什么我们的观点不再适用？',
    content: '因为风口来的时候，如果你再不卖，可能就卖不掉了。'
  },
  {
    name: '发言人 6 等',
    title: '市场中有哪些企业已经取得成功？',
    content:
      '国外市场中，大家都在看皮口，字节跳动是一个搜索引流，但是社交它也不是一个游戏公司。皮口在政策上有些不利，之前还有扶持开发，但现在已经减少了扶持金额。此外，还有美国的XX这个产品也很受欢迎。'
  },
  {
    name: '发言人 4',
    title: '现在的LED技术有多优秀？',
    content: 'LED技术非常优秀，能给用户带来不同的体验。'
  },
  {
    name: '发言人 2 等',
    title: 'LED技术的发展方向是什么？',
    content: '替代899，能给用户带来不同的体验。'
  },
  {
    name: '发言人 4',
    title: '什么是轻的概念？',
    content: '指能量密度高、重量轻的产品。'
  },
  {
    name: '发言人 6',
    title: '液晶加厚的产品有哪些特点？',
    content: '液晶加厚的产品能让用户感受到清晰的世界。'
  },
  {
    name: '发言人 4',
    title: '中国公司如何批量降低LED产品的成本？',
    content: '需要找到具备消费电子产品批量生产能力的公司。'
  },
  {
    name: '发言人 4',
    title: '这个园区是愿意做这个东西，对吧？政府定制的园区是什么？',
    content:
      '可以定制一个东西出来，进去以后就是路演，就是投资，就是给机构定了一个政府定制一个园区，跟园区。他们觉得发给政府一套东西。是定制一个政府定制的园区，可以满足企业和政府的需求'
  },
  {
    name: '发言人 2',
    title: '为什么量大？',
    content:
      '因为中国元素区间接近3000个，每个委办局都有人带着这个去参观，所以量大。'
  },
  {
    name: '发言人 5',
    title: '为什么这个推广形式很好？',
    content: '因为政府和企业都在参与，有很好的推广效果。'
  },
  {
    name: '发言人 5',
    title: '为什么政府出面推广这个产品？',
    content: '因为政府有资源，可以为这个产品提供支持。'
  },
  {
    name: '发言人 4',
    title: '机构投资是否有风险？',
    content:
      '投资这种类型的产品存在一定风险，因为公司的供应链很容易被复制。同时，投资人也需要考虑产品的推广效果，以及未来的发展前景。'
  },
  {
    name: '发言人 4',
    title: '如何推广这个产品？',
    content:
      '政府出面可以帮助推广这个产品，比如在招商局等部门发布宣传资料。同时，可以考虑定制化产品，如根据企业的需求定制，也可以为不同区县定制头盔等产品。'
  },
  {
    name: '发言人 4',
    title: '中国资产对上游的保护措施是什么？',
    content: '中芯国际会保证上游供应链的安全，但具体不会透露更多细节。'
  },
  {
    name: '发言人 4',
    title: '大红两片品质的差异在哪里？手机产业链上的瓶颈是什么？',
    content:
      '在于处理性能和其他方面的体系化问题，而不是单独的屏幕本身。主要是处理器的性能和芯片驱动的问题，导致无法跑出好的内容。'
  },
  {
    name: '发言人 4',
    title: '手机产业链的瓶颈能否在两三年内得到解决？',
    content:
      '根据快速算法的发展趋势，处理器性能每两三年就能翻一倍，市场也会随之改变。'
  },
  {
    name: '发言人 4',
    title: '如何推广好的游戏？',
    content:
      '好的游戏需要迭代和按节奏的方式，经过七八年的迭代，玩法都已经被摸索完了，但问题在于市场并不是唯一的问题。'
  },
  {
    name: '发言人 6',
    title: '未来体验差很远，是否符合？',
    content: '两个屏幕后面是液晶寸寸，前面一个0.7寸合在一起，不符合'
  },
  {
    name: '发言人 4',
    title: '你现在如果破产了，你的整个市场？',
    content: '价格不是关键，卖200有200的市场，卖100有100有市场'
  }
]

export const records = [
  {
    type: 1,
    time: '00:00',
    title: '数字显示技术的优势及应用',
    content:
      '本文介绍了数字显示技术的优势和应用。数字显示技术在功耗、成本和良率等方面具有明显优势，能够提供更低的功耗、更低的价格和更高的良率。同时，数字显示技术还能够实现高刷新率，使显示内容更加流畅舒适。数字显示技术已经在各个领域得到广泛应用，如智能家居、汽车显示、虚拟现实等。'
  },
  {
    type: 2,
    time: '00:00',
    person: '发言人1',
    content: '其实是另外一个，大家可以带着或者通过这个。可以在上面。'
  },
  {
    type: 2,
    time: '00:10',
    person: '发言人 2',
    content: '对。'
  },
  {
    type: 2,
    time: '00:11',
    person: '发言人 1',
    content: '然后我们里面的这些主持人等等，其实后面都积累了。他可以说是回答企业或者这种各种问题。然后你大家可以上招商，这个上游下一步他们具体的情况怎么样？产能现在的情况。'
  },
  {
    type: 2,
    time: '00:29',
    person: '发言人 3',
    content: '好，你来读一句。'
  },
  {
    type: 2,
    time: '00:32',
    person: '发言人 4',
    content: '好，袁董事长。'
  },
  {
    type: 2,
    time: '00:33',
    person: '发言人 3',
    content: '对'
  },
  {
    type: 2,
    time: '00:34',
    person: '发言人 3',
    content: '对'
  }
]
