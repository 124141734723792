import { instance as _axios } from '@/utils'

/**
 * 上传文件
 * @param {*} data
 * @returns
 */
export const uploadFiles = (data) => {
  return _axios({
    url: 'https://chainai.mtheworld.com/file/chatPdfUpload',
    method: 'post',
    data
  })
}

/**
 * 文档详情
 * @param {*} params
 * @returns
 */
export const pagesDoc = (params) => {
  return _axios({
    url: 'https://chainai.mtheworld.com/chat/getSessionChatRecord',
    method: 'get',
    params
  })
}

/**
 * 文件分页//暂时不用
 * @param {*} params
 * @returns
 */
// export const getFileList = (data) => {
//   return _axios({
//     url: 'https://meet.mtheworld.com/api/brain/ai/train/file/page',
//     method: 'post',
//     data
//   })
// }

/**
 * 获取文件上传签名
 * @param {*} data
 * @returns
 */
export const policy = (data) => {
  return _axios({
    url: 'https://meet.mtheworld.com/api/brain/ai/train/file/get-policy',
    method: 'post',
    data
  })
}
/**
 * 保存文档信息
 * @returns
 */
export const saveFileRecord = (data) => {
  return _axios({
    url: 'https://meet.mtheworld.com/api/brain/ai/train/file/save-file-record',
    method: 'post',
    data
  })
}

/**
 * 分页查询
 * @returns
 */
export const transPages = () => {
  return _axios({
    url: '/ml/meet/tingwu/tran-page',
    method: 'post',
    data: {
      pageNo: 1,
      pageSize: 100
    }
  })
}
/**
 * 创建文件转写任务
 * @param {*} data
 * @returns
 */
export const createFileTrans = (data) => {
  return _axios({
    url: '/ml/meet/tingwu/create-file-trans',
    method: 'post',
    data
  })
}
/**
 * 聊天列表分页查询
 * @returns
 */
export const getChatList = (type) => {
  return _axios({
    url: 'https://meet.mtheworld.com/api/brain/ai/session/page',
    method: 'post',
    data: {
      keyword: '',
      type: type,
      pageNo: 1,
      pageSize: 50
    }
  })
}
/**
 * 对话标题
 * @returns
 */
export const getNewName = (title, type) => {
  return _axios({
    url: 'https://meet.mtheworld.com/api/brain/ai/session/get-title',
    method: 'get'
  })
}
/**
 * 新建对话
 * @returns
 */
export const createNewChat = (title, type) => {
  return _axios({
    url: `https://meet.mtheworld.com/api/brain/ai/session/save?type=${type}&title=${title}`,
    method: 'post',
    data: {
    }
  })
}
/**
 * 修改对话名称
 * @returns
 */
export const editChat = (data) => {
  return _axios({
    url: 'https://meet.mtheworld.com/api/brain/ai/session/update',
    method: 'post',
    data
  })
}
/**
 * 删除对话
 * @returns
 */
export const deleteChat = (id) => {
  return _axios({
    url: `https://meet.mtheworld.com/api/brain/ai/session/delete?id=${id}`,
    method: 'post',
    id
  })
}
/**
 * 获取历史对话
 * @returns
 */
export const getConversationHistory = (id) => {
  return _axios({
    url: 'https://meet.mtheworld.com/api/brain/ai/chat/page',
    method: 'post',
    data: {
      keyword: '',
      pageNo: 1,
      pageSize: 100,
      sessionId: id
    }
  })
}
