<template>
  <div class="layout">
    <el-container>
      <el-header style="height: 80px; padding: 0">
        <HeaderView />
      </el-header>
      <el-container>
        <el-aside width="310px">
          <el-menu
            :default-openeds="['1','2']"
            @select="handleSelect"
            :default-active="activeKey"
          >
            <el-submenu index="1">
              <template slot="title">
                <img src="@/assets/image/hot.png" alt="" />
                热门专家
              </template>
              <el-menu-item index="新能源汽车">新能源汽车专家</el-menu-item>
              <!-- <el-menu-item index="通信及设备">通信及设备专家</el-menu-item>
              <el-menu-item index="电子元器件">电子元器件专家</el-menu-item>
              <el-menu-item index="新材料">新材料专家</el-menu-item>
              <el-menu-item index="高端装备">高端装备专家</el-menu-item> -->
            </el-submenu>
            <el-submenu index="2">
              <template slot="title">
                <img src="@/assets/image/my.png" alt="" />
                我的专家
              </template>
              <el-menu-item v-for="i in proList" :index="i.chainType" :key="i.id">{{ i.chainType }}</el-menu-item>
            </el-submenu>
          </el-menu>
        </el-aside>
        <el-main>
          <div class="electron">
            <PageHeader
              title="产业智脑"
              describle="当前共有107位产业专家为您提供服务"
            />
            <div class="chain flex-row">
              <div class="content-img">
                <p>
                  通过与产业专家数字人实时对话，快速获取和理解各产业领域内的专业知识，高效解决问题和编写文稿。
                </p>
                <div
                  v-html="svg"
                  @mouseover="mouseOver"
                  @click.stop="jumpShortIndustry"
                ></div>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import HeaderView from '@/components/HeaderView.vue'
import '@/assets/css/electron-view.css'
import { sStorage } from '@/utils'
export default {
  name: 'LayoutView',
  components: {
    PageHeader,
    HeaderView
  },
  data () {
    return {
      activeKey: '',
      svg: '',
      proList: []
    }
  },
  mounted () {
    this.getUserInfo()
    this.getSvg()
  },
  methods: {
    // 获取我的专家列表
    getProList () {
      this.Axios.post('https://chainai.mtheworld.com/chat_room/page', {
        pageSize: 5,
        pageNum: 1,
        type: '2'
      }).then((res) => {
        this.proList = res.data.data.records
      }).catch(err => {
        console.log(err)
      })
    },
    getUserInfo () {
      this.Axios.get('https://zcjrys.chinaindustria.com/api/jrys/uc/user/info').then(res => {
        sStorage.set('userId', JSON.parse(res.data.data.phone))
        this.getProList()
      })
    },
    handleSelect (menuName) {
      this.$router.push({
        name: 'talk',
        query: { name: menuName }
      })
    },
    getSvg () {
      this.Axios.get(
        'https://zcjrys.chinaindustria.com/api/jrys/yscompany/chain/chainOverView'
      ).then((res) => {
        this.svg = res.data.data
        this.$nextTick(() => {
          const svg = document.querySelector('svg')
          svg.setAttribute('width', '1530')
          svg.setAttribute('height', '850')
        })
      })
    },
    // 快速行业选择
    jumpShortIndustry (e) {
      if (e.target.classList.value.indexOf(' buy') !== -1) {
        if (e.target.id !== '新能源汽车') return this.$message.warning('该专家正在训练中')
        this.$router.push({
          name: 'talk',
          query: { name: e.target.id }
        })
      }
    },
    mouseOver (e) {
      if (e.target.classList.value.indexOf(' buy') !== -1) {
        e.target.setAttributeNS(null, 'style', 'cursor:pointer')
      } else {
        e.target.setAttributeNS(null, 'style', 'cursor:normal')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.el-aside {
  // height: calc(100vh - 80px);
  background: #172148;

  .el-menu {
    background: #172148;
    border: none;

    ::v-deep {
      ul {
        background: #172148 !important;
      }
    }

    .el-submenu {
      ::v-deep {
        .el-submenu__title {
          font-size: 20px;
          color: #ffffff;
          letter-spacing: 0;
          font-weight: 600;
          display: flex;
          align-items: center;
          img {
            margin-right: 5px;
          }
          &:hover {
            background: #172148;
          }
        }
      }
    }

    .el-menu-item {
      background: #172148;
      font-size: 16px;
      color: #ffffff;
      padding-left: 50px !important;
      letter-spacing: 0;
      line-height: 52px;
      font-weight: 400;

      &.is-active {
        background: #2d5ed6;
      }

      &:hover {
        background: #2d5ed6;
      }
    }
  }
}

.el-main {
  background: #f3f6fa;
}
</style>
