import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'
import LayoutView from '../views/LayoutView.vue'
import TalkView from '../views/TalkView.vue'
import { sStorage } from '@/utils'
Vue.use(VueRouter)

const routes = [
  {
    name: 'index',
    path: '/index',
    component: LayoutView
  },
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/talk',
    name: 'talk',
    component: TalkView
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && !sStorage.get('loginToken')) {
    next('/')
  } else {
    // 检查页面权限
    next()
  }
})

export default router
