<template>
  <div class="upload" v-if="value">
    <div class="dialog" v-loading="loading">
      <div class="upload-header">
        <img src="@/assets/image/dialog-close.png" alt="" style="opacity: 0;">
        <span>上传文档</span>
        <img src="@/assets/image/dialog-close.png" alt="" @click="$emit('input', false)" style="cursor: pointer;">
      </div>
      <div class="line"></div>
      <div class="dialog-container">
        <p>{{ activeKey === 'Text' ? '请直接输入需要训练的文本，产业专家数字人将从中学习。' : '产业专家数字人将从您的文档资源中主动学习，并解决您的各种问题，帮助你找到任何问题的答案。' }}</p>
        <div class="upload-btns flex-col" style="margin-top: 32px;" v-if="!activeKey">
          <div class="upload-item " style="margin-bottom: 20px;" @click="()=>{activeKey = 'PDF', fileList.pop()}">
            <img src="@/assets/image/pdf.png" alt="">
            <span>添加 PDF/DOC 文档</span>
            <img src="@/assets/image/arrow.png" alt="">
          </div>
          <div class="upload-item" style="margin-bottom: 20px;" @click="()=>{activeKey = 'QA', fileList.pop()}">
            <img src="@/assets/image/qa.png" alt="">
            <span>添加 Q&A 问答系列</span>
            <img src="@/assets/image/arrow.png" alt="">
          </div>
          <div class="upload-item" @click="activeKey = 'Text'">
            <img src="@/assets/image/text.png" alt="">
            <span>输入文本</span>
            <img src="@/assets/image/arrow.png" alt="">
          </div>
        </div>

        <div class="drag-container">
          <!-- multiple -->
          <el-upload class="upload-demo" ref="uploadRef" :data="dataObj" :on-success="handleSuccess" :on-error="handleError" :on-change="handleChange" action="https://zcw-zt.oss-cn-beijing.aliyuncs.com/"
            :auto-upload="false" :on-remove="handleRemove" :before-upload="onBeforeUpload" v-if="activeKey != 'Text' && activeKey != ''" drag
             :file-list="fileList" :show-file-list="true" :accept="activeKey ==='PDF' ? '.pdf,.doc,.docx,.rtf' : '.xls,.xlsx,.csv'">
            <img style="margin-top: 20px;" src="@/assets/image/upload-big.png" alt="">
            <div class="el-upload__text drag">点击或将文件拖拽到这里上传</div>
            <div class="el-upload__text support">{{ activeKey === 'PDF' ? '支持上传PDF、Doc、Docx格式文件' :
              '请上传csv格式文件，第一列为问题，第二列为回答。'
            }}</div>
            <div class="el-upload__tip" slot="tip" v-if="activeKey === 'QA'" style="font-size: 14px;
color: #3370FF; cursor: pointer;" @click="downloadTemplate">点击下载csv模板</div>
          </el-upload>

          <div class="question-container" v-if="activeKey === 'Text'">
            <input class="text" type="text " v-model="textForm.question" placeholder="请输入标题">
            <textarea class="textarea" type="textarea" v-model="textForm.answer" placeholder='请输入内容'></textarea>
          </div>

          <div v-if="activeKey" class="btns flex-row">
            <div class="btn" style="margin-right: 40px;" @click="activeKey = ''">返回</div>
            <div class="btn" style="background: #3370FF;color: #FFFFFF;"  @click="confirmUpload">{{ activeKey==='Text' ? '确定' : '上传' }}</div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { saveDoc, policy, saveFileRecord, uploadFiles } from '@/apis'
import { sStorage, getFileSha1 } from '@/utils'

export default {
  name: 'UploadDoc',
  props: {
    value: {
      required: true,
      type: Boolean
    },
    currentSessionId: {
      type: String
    }
  },
  data () {
    return {
      loading: false,
      fileList: [],
      activeKey: '',
      textForm: {
        answer: '',
        question: ''
      },
      dataObj: {
        policy: '',
        signature: '',
        key: '',
        ossaccessKeyId: '',
        dir: '',
        host: ''
      }
    }
  },

  mounted () {
    // 看看文件有没有上传成功
    // this.Axios.post('http://192.168.120.7:3002/attach/page', { pageSize: 10, pageNum: 1, chainType: this.$route.query.name }).then(res => {
    //   console.log(res)
    // })
  },

  methods: {
    downloadTemplate () {
      const a = document.createElement('a')
      a.setAttribute('href', './static/template.csv')
      a.setAttribute('download', '模板')
      a.setAttribute('target', '_blank')
      const clickEvent = document.createEvent('MouseEvents')
      clickEvent.initEvent('click', true, true)
      a.dispatchEvent(clickEvent)
    },
    handleChange (file) {
      console.log(file)
      if (file.status === 'ready') {
        this.uploadFile = { key: file.name }
        this.fileList.pop()
        this.fileList.push(file)
        const fileSuffix = file.name.substring(
          file.name.lastIndexOf('.')
        )
        return new Promise((resolve, reject) => {
          policy({
            folder: '66',
            fileName: file.name,
            fileSize: file.size,
            fileSuffix: fileSuffix,
            fileSha1: ''
          })
            .then((response) => {
              this.dataObj.policy = response.data.data.policy
              this.dataObj.signature = response.data.data.signature
              this.dataObj.ossaccessKeyId = response.data.data.accessid
              this.dataObj.id = response.data.data.id
              this.dataObj.name = response.data.data.name
              this.dataObj.key = response.data.data.dir + response.data.data.name
              this.dataObj.dir = response.data.data.dir
              this.dataObj.host = response.data.data.host
              resolve(true)
            })
            .catch((err) => {
              console.log('出错了...', err)
              reject(new Error(false))
            })
        })
      }
    },
    handleRemove () {
      this.fileList.pop()
    },
    handleSuccess (res, file) {
      this.$message.success('文件上传成功')
      this.$emit('createNewDocChat', file.name)
      this.fileList.pop()
    },
    handleError () {
      this.$message.error('系统繁忙，请稍后重试')
    },
    async onBeforeUpload ({ file }) {
      console.log(file)
    },
    saveFileRecord (currentSessionId) {
      const fileRecord = {
        fileId: this.dataObj.id,
        fileUrl: this.dataObj.host + '/' + this.dataObj.key,
        sessionId: currentSessionId
      }
      saveFileRecord(fileRecord)
    },
    async confirmUpload () {
      // 文本
      if (this.activeKey === 'Text') {
        if (!this.textForm.answer || !this.textForm.question) return this.$message.error('请填写标题和内容')
        await saveDoc(this.textForm)
        this.$message.success('操作成功')
        this.activeKey = ''
        return
      }

      // 上传文件
      if (this.fileList.length < 1) return this.$message.error('请选择要上传的文件')
      this.$refs.uploadRef.submit()
      // this.loading = true
      // const fd = new FormData()
      // const file = this.fileList[0].raw
      // fd.append('chainType', this.$route.query.name)
      // fd.append('file', file)
      // fd.append('userId', sStorage.get('userId'))
      // await uploadFiles(fd)
      // this.activeKey = ''
      // this.$emit('refreshData')
      // this.$emit('closeModel')
      // this.loading = false
      // this.$message.success('文件上传成功')
    }
  }
}
</script>

<style lang="scss" scoped>
.upload {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba($color: #000000, $alpha: .3);

  .dialog {
    width: 600px;
    min-height: 440px;
    background: #FFFFFF;
    border: 1px solid rgba(221, 221, 221, 1);
    box-shadow: 0px 0px 10px 0px rgba(51, 112, 255, 0.2);
    border-radius: 4px;
    padding: 20px;

    .upload-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 20px;
        color: #333333;
        letter-spacing: 0;
        font-weight: 600;
      }
    }

    .line {
      margin-top: 16px;
      width: 560px;
      height: 1px;
      background-color: #DDD;
    }

    .dialog-container {
      width: 500px;
      margin: 0 auto;

      P {
        margin-top: 24px;
        font-size: 16px;
        color: #333333;
        letter-spacing: 0;
        font-weight: 400;
      }

      .upload-btns {
        .upload-item {
          width: 500px;
          height: 60px;
          display: flex;
          align-items: center;
          background: #FFFFFF;
          border: 1px solid rgba(221, 221, 221, 1);
          border-radius: 4px;
          padding-left: 21px;
          cursor: pointer;

          span {
            margin-left: 22px;
            margin-right: auto;
            font-size: 16px;
            color: #333333;
            letter-spacing: 0;
            font-weight: 400;
          }
        }
      }

      .drag-container {
        ::v-deep {
          .upload-demo {
            margin-top: 30px;
            text-align: center;

            .drag {
              margin-top: 12px;
              font-size: 16px;
              color: #333333;
              letter-spacing: 0;
              text-align: center;
              font-weight: 400;
            }

            .support {
              margin-top: 12px;
              font-size: 14px;
              color: #999999;
              letter-spacing: 0;
              text-align: center;
              font-weight: 400;
            }
          }
        }

        .question-container {
          .text {
            margin-top: 25px;
            padding-left: 15px;
            display: block;
            background: #FFFFFF;
            border: 1px solid rgba(221, 221, 221, 1);
            border-radius: 4px;
            width: 500px;
            height: 50px;
            outline: none;
            font-size: 16px;

            &::placeholder {
              font-size: 16px;
              color: #999999;
              letter-spacing: 0;
              font-weight: 400;
            }
          }

          .textarea {
            margin-top: 20px;
            padding: 10px 15px;
            display: block;
            background: #FFFFFF;
            border: 1px solid rgba(221, 221, 221, 1);
            border-radius: 4px;
            width: 500px;
            height: 190px;
            outline: none;
            font-size: 16px;
            resize: none;

            &::placeholder {
              font-size: 16px;
              color: #999999;
              letter-spacing: 0;
              font-weight: 400;
            }
          }
        }

        .btns {
          align-items: center;
          justify-content: center;
          margin-top: 20px;

          .btn {
            cursor: pointer;
            width: 140px;
            height: 40px;
            line-height: 38px;
            text-align: center;
            background: #FFFFFF;
            border: 1px solid rgba(221, 221, 221, 1);
            border-radius: 4px;
            font-size: 16px;
            color: #333333;
            letter-spacing: 0;
            font-weight: 400;
          }
        }
      }
    }
  }
}
</style>
