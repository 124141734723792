export const chat_records = [
  {
    type: 1,
    title: '数字显示技术的优势及应用',
    time: '00:00',
    content:
      '本文介绍了数字显示技术的优势和应用。数字显示技术在功耗、成本和良率等方面具有明显优势，能够提供更低的功耗、更低的价格和更高的良率。同时，数字显示技术还能够实现高刷新率，使显示内容更加流畅舒适。数字显示技术已经在各个领域得到广泛应用，如智能家居、汽车显示、虚拟现实等。'
  },

  {
    type: 2,
    person: '发言人1',
    time: '00:00:00',
    content: '其实是另外一个，大家可以带着或者通过这个。可以在上面。'
  },

  { type: 2, person: '发言人2', time: '00:00:10', content: '对。' },

  {
    type: 2,
    person: '发言人1',
    time: '00:00:11',
    content:
      '然后我们里面的这些主持人等等，其实后面都积累了。他可以说是回答企业或者这种各种问题。然后你大家可以上招商，这个上游下一步他们具体的情况怎么样？产能现在的情况。'
  },

  { type: 2, person: '发言人3', time: '00:00:29', content: '好，你来读一句。' },

  { type: 2, person: '发言人4', time: '00:00:32', content: '好，袁董事长。' },

  { type: 2, person: '发言人3', time: '00:00:33', content: '对。' },

  { type: 2, person: '发言人4', time: '00:00:34', content: '幸会幸会潘哥。' },

  { type: 2, person: '发言人6', time: '00:00:36', content: '杨哥都介绍了。' },

  {
    type: 2,
    person: '发言人5',
    time: '00:00:37',
    content: '谢谢袁总。应该对总冠军比较熟，觉得人是。'
  },

  { type: 2, person: '发言人2', time: '00:00:43', content: '他。' },

  {
    type: 2,
    person: '发言人6',
    time: '00:00:44',
    content: '协议是我，那我建的1991年在福清福州。'
  },

  {
    type: 2,
    person: '发言人3',
    time: '00:00:52',
    content:
      '要不要往下做一点，然后手臂可以往中间走。我们那边还没毕业，好像感觉。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:01:01',
    content: '大学就毕业了，直接跑到学校去。'
  },

  { type: 2, person: '发言人3', time: '00:01:04', content: '是大学毕业是吧？' },

  { type: 2, person: '发言人6', time: '00:01:06', content: '对。' },

  {
    type: 2,
    person: '发言人3',
    time: '00:01:08',
    content: '卡尼吉梅隆毕业之后，然后直接到工厂最地板牛板。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:01:14',
    content:
      '91年那个年代基本上是在沿海这一带，都是因为89以后，八九年以后，对，很多外企都撤了。对。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:01:25',
    content:
      '我们就是我就八九年3月到厦门，结果664。对，就不敢进来。后来习大大91年的时候说他调到福州了，叫我们过去的。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:01:39',
    content:
      '反正广东福建那一带，后来主要是从港澳台是进来做加工。我们91年我跟李东升做家电创业的时候，也是先从美国的从美国的这个做worker.'
  },

  { type: 2, person: '发言人6', time: '00:02:01', content: '是。' },

  {
    type: 2,
    person: '发言人2',
    time: '00:02:01',
    content:
      '做代工开始。然后再把提观音，再把彩电慢慢的跟下面人是是不带公开的。对对对，我们在十年改变到1999年的年底，我们才开始建自己的工程。那工程就是在贵州，是我们成为全国第一大产业龙头企业者，才开始建自己的工厂。是要不要今天做不起来，因为没有钱，你都是60的，也是为也是为胡总的181。'
  },

  {
    type: 2,
    person: '发言人3',
    time: '00:02:45',
    content: '企业家的一生不可能没有不弯腰的时候。'
  },

  { type: 2, person: '发言人2', time: '00:02:49', content: '会有到处弯腰。' },

  {
    type: 2,
    person: '发言人3',
    time: '00:02:50',
    content: '每个人都得弯腰的时候。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:02:52',
    content:
      '对，93年我们为了40万块钱，这个借40万块钱，我找了9.8块，他一个人。'
  },

  { type: 2, person: '发言人3', time: '00:03:05', content: '太难了。' },

  {
    type: 2,
    person: '发言人2',
    time: '00:03:06',
    content:
      '中国没有什么大学考的9293年这个。绘制的就是叫房地产重灾区海南的海口加广西的人，三大住宅区总机。张瑞敏等别搞得挺好，要去筛查。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '00:03:31',
    content: '对，聊一下这个。严总是专家。'
  },

  { type: 2, person: '发言人2', time: '00:03:37', content: '我是投资人。' },

  {
    type: 2,
    person: '发言人5',
    time: '00:03:40',
    content: '显示你们都是这个行业内的可能举报的人可能。'
  },

  {
    type: 2,
    person: '发言人3',
    time: '00:03:47',
    content: '觉得对，要怎么要介绍？你把袁总看的资料表里吗？'
  },

  { type: 2, person: '发言人2', time: '00:03:52', content: '我看。' },

  {
    type: 2,
    person: '发言人3',
    time: '00:03:53',
    content:
      '你有什么问题还要讲一下吗？这个人季季博就是发明人说的了，那个是不是。'
  },

  { type: 2, person: '发言人5', time: '00:04:01', content: '大陆的。' },

  { type: 2, person: '发言人4', time: '00:04:02', content: '你不是小孩。' },

  { type: 2, person: '发言人5', time: '00:04:03', content: '对。' },

  {
    type: 2,
    person: '发言人4',
    time: '00:04:04',
    content: '你是在南京，公司在南京，我在哪？'
  },

  { type: 2, person: '发言人3', time: '00:04:08', content: '上海也有芯片的。' },

  {
    type: 2,
    person: '发言人4',
    time: '00:04:10',
    content:
      '就是注册在北京，然后我们有个子公司在上海，相当于说一个两个。南京是我们的一条产线，然后上海也是我们的一个研发的一个基金。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:04:24',
    content: '现在做的最大的事情。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:04:26',
    content:
      '我们能做到1.3寸、1.3、1.32，1.3里面是比较大的1.3的样子，然后专门给比亚迪那个空间计算在眼镜的。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:04:47',
    content: '做小的可能会应用，别人过去飞机就基本上不用军工。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:04:57',
    content: '对，但那个很小，跟之前一样。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:04:59',
    content: '对对对，最早的时候飞机对做了。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:05:03',
    content:
      '一个像素不高像素不高，那我们现在需要你2K2K到2.5K，然后那个苹果他希望是希望直接拉到4K的。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:05:18',
    content: '4K的话，那那你这个那就是。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:05:21',
    content:
      '两种发展路径。一种是说的小的，他肯定是未来的发展，是希望大家把它做到眼镜里面，就是一个非常小的一个显示器，越小越好在希望的或者是整个的价格，这是一个发展方向。第二个发展方向，就是我们现在做的是1.3寸大一些的这两个位置是能显示沉浸式，对，就市场就更大一些。我戴眼镜，我可以看到更多的这个内容。现在的更更先进的做法就是我把它做成你妈了。就是我可以看到虚拟的实现。我也可以通过外面的三个传感器看到外面。对，通过一个电切换，或者在未来以后可能也不用切换了，就是你随时随地你想看外面，那么这种就需要一个大一些的信息。因为它的货币时报表需要看的大一些，所以就有两种。小的发展中心，大家更倾向于这个micro d就是现在比较比较热的这个。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:06:21',
    content:
      'micro ad其实了很多年了，其实他从结它它在结构上是多大，跟现在的有机你能享受。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:06:30',
    content: '这个专业的专业了。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:06:32',
    content: '对，太多东西太多人不懂。'
  },

  { type: 2, person: '发言人6', time: '00:06:37', content: '就被被套进去了。' },

  {
    type: 2,
    person: '发言人2',
    time: '00:06:39',
    content: '贷款已经不可能实现这个事。'
  },

  { type: 2, person: '发言人4', time: '00:06:41', content: '今天早上你还。' },

  {
    type: 2,
    person: '发言人3',
    time: '00:06:44',
    content: '救了个国准备MMLD的，请潘总给大家。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:06:50',
    content:
      '问一个问题。他就是在他从这个产品本身的结构就决定了它很难实现的。如果是没有规定你的话，因为他原来他基本上都是用TFT的那种方式来做，对吧？'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:07:08',
    content: '那现在对，但是Michael大家就是说要亮，越亮越好。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:07:13',
    content: '其实你这个头像设计是不需要。'
  },

  { type: 2, person: '发言人6', time: '00:07:15', content: '那么好的质量。' },

  {
    type: 2,
    person: '发言人4',
    time: '00:07:17',
    content:
      '特别是我做沉浸式的，我的对亮度要求其实没有那么高。就现在的技术已经有了。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:07:22',
    content: '对，现在本身现在的染色颜料有提示，坐在一起2.9米还是挺高的。'
  },

  { type: 2, person: '发言人6', time: '00:07:31', content: '但相反的有。' },

  {
    type: 2,
    person: '发言人2',
    time: '00:07:32',
    content: '个600亿才有，其实能看起来。'
  },

  { type: 2, person: '发言人4', time: '00:07:35', content: '现在。' },

  {
    type: 2,
    person: '发言人2',
    time: '00:07:35',
    content: '这个差不多也450亿.'
  },

  { type: 2, person: '发言人6', time: '00:07:39', content: '对。' },

  {
    type: 2,
    person: '发言人4',
    time: '00:07:41',
    content: '我是专家，是直接报销，所以对，直接LED。对，所以说LED.'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:07:48',
    content: '他是说我要2万、3万、4万.'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:07:51',
    content: '就是一个西服的凉山，就是在户外所以。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:07:55',
    content:
      '我们在19年的时候，本来就专注于危显示里面大面板的这个方面研究做一点删除。因为这个路线跟小的还不太一样，就是我落实到真正的其实技术具体上面来说的话，用的这个技术方法就不太一样。所以后来我们就研究了我们自己叫做常规点。就是基于数字原理，基于0101的这种驱动的原理，我不再去产生一个具体的一个BID流程了。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:08:31',
    content:
      '我是通过麦克模式切换，然后通过光的这个闪烁的方式。当我的频率到了一定的程度，比如我现在是超过了3000的，你们到超过这个程度以后，人眼就不再会看到这个闪烁了。那我这样就展开一下，这样的更加均匀，然后会带来两个非常大的好处。第一个好处是低功耗，昨天我们会上面也开了，反正看到我们的结果，我们都还不愿意跟我们讲这个通话。是，我记得是。你的。'
  },

  { type: 2, person: '发言人3', time: '00:09:07', content: '笑容我们。' },

  {
    type: 2,
    person: '发言人6',
    time: '00:09:08',
    content:
      '都不敢相信，仿真出来这个数字你确定吗？我是昨天问了好几次，你确定这么低吗？'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:09:17',
    content:
      '我们后来画一个工艺节点，就是说稍微提高了工艺节点，就把这个功耗降到现在的这个就是现在市面上这个功耗是非常大的。'
  },

  { type: 2, person: '发言人3', time: '00:09:26', content: '就是你外件。' },

  {
    type: 2,
    person: '发言人4',
    time: '00:09:28',
    content: '鄙人用工号是一个核心。'
  },

  { type: 2, person: '发言人2', time: '00:09:30', content: '很重要的一点。' },

  {
    type: 2,
    person: '发言人4',
    time: '00:09:31',
    content:
      '因为显示效果最后大家做的都是差不多的对，就是你各家做做出来以后就是显示多久，这是第一个优势。我们现在的文化公开的数据的话，我们就比这个竞争的要低一半。然后最近的技术的话会在基础上继续进行优化，这是第一个大优势。第二个大优势是成本，就是我们不放风，我并没有用一个更加贵的一个工艺，我反而我用了一个更便宜的一个东西去做，因为用了我们的数字系统算法以后，它实际上从算法的层面，从那个策略的层面就已经把这个成本降下来了。所以他这些看我们长报的数据，基本上我们这个良率已经是非常高了。我们现在的中医院的良率基本上。'
  },

  { type: 2, person: '发言人2', time: '00:10:18', content: '已经是六七成。' },

  {
    type: 2,
    person: '发言人4',
    time: '00:10:20',
    content:
      '什么概念呢？就是现在像索尼这样的，大概是1.3的价格，大概百分之二三十。这个是对于你们预估的价格很高。'
  },

  { type: 2, person: '发言人2', time: '00:10:29', content: '这个是一个。' },

  {
    type: 2,
    person: '发言人4',
    time: '00:10:30',
    content:
      '最大的问题。一个瓶要贵三百多美金，对吧？对，那么如果我们也得三百多斤，那我们笑死了，我们这个成本只有它的一个零头。我们几乎这个背板就背板的良率已经是一个很高的水平了。然后再加上国内的整个产线，我们是综合出来的话，应该能长期产生。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:10:54',
    content:
      '像你现在你现在归期不是说是不是记得是两日，非要做完两个测试以后，然后再开始再上三个。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:11:06',
    content: '现在也不是就是一就是一次做做完以后。'
  },

  { type: 2, person: '发言人2', time: '00:11:10', content: '再来那个。' },

  {
    type: 2,
    person: '发言人4',
    time: '00:11:11',
    content:
      '对，因为我多了一面程度，我是一个规格全部送进去的，所以我真不是说这个时候你不要量很大，等我睁度全部完成以后，封装成完成以后，我再在法官。是是是，那你要保证整个工艺进程。对对对，现在我做处理，你的已经做这个产品了，我们经过两年。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:11:35',
    content:
      '这个良率不是我们量产的良率，就是我最开心的是它是一片，我们是在开发就有这么高的良率，所以就是什么都都没做，我们就是在开发的过程，每一片都是这么高的对。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:11:50',
    content:
      '六七层。对，因为我知道他那个京东方案那边，京东方案提前，他们那边搞个夏天。对，合肥。'
  },

  { type: 2, person: '发言人6', time: '00:12:02', content: '的这是。' },

  {
    type: 2,
    person: '发言人2',
    time: '00:12:04',
    content:
      '就是两个人都不是，一直是四五个人是他大概率的小红心，所以价格不贵。华星武汉差不多是这两天没。对，两月份。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:12:21',
    content: '其实是，包括他的卖出来的可以卖的，就是他认为合格不罚款。他这个。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:12:31',
    content:
      '他那边也是买了回来测他，所以他们有很多坏点，然后拿到专门的一个改造厂家的修复，那修复就很难。你们这个修复好，那修复好了，这顶单第一批去办。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:12:49',
    content: '是要左边的在什所以。'
  },

  { type: 2, person: '发言人2', time: '00:12:52', content: '我觉得我们。' },

  {
    type: 2,
    person: '发言人4',
    time: '00:12:53',
    content: '目前这个技术路线已经基本上这个里面。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:12:58',
    content:
      '验证的时候，比如说对这个行。如果是你功耗表现，它原来有一个现象，就是说也是我是应该放在家里来吃，就是说进入的图像就静态图像。宏观那个宏观那个也是一种宏观那个内圈比较因为以产品为代，他这个东西很有意思。商品这个跟工号还是然后。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:13:32',
    content:
      '数字有个好处，就是说它不是全开的，它是一个麦昆。麦昆就是我开了一段时间放一段时间。我们在观感时间的时候，我们就可以做一些修复合作，使得它有一个寿命，有一个法律的提升。给他开一点。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:13:47',
    content: '关于开，你现在开关这个每秒。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:13:50',
    content:
      '大概很多，内部的话切换大概在三千多，然后折算到我们，因为有25个厂的折算。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:13:56',
    content: '这个厂大概是121。'
  },

  { type: 2, person: '发言人4', time: '00:14:00', content: '我如果想。' },

  {
    type: 2,
    person: '发言人2',
    time: '00:14:01',
    content: '好看比较接近，他们经常可能你看东西高刷刷。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:14:08',
    content: '对，一人员12 3000赫兹才是健康的，没有安全安安全。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:14:15',
    content:
      '对你要看不到那个闪烁的这个感觉。对，所以这个也是数字的一个潜在的优势，就是消费者可能不是太清楚，但是事实上他就是有时候。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:14:25',
    content: '你还要感觉看着舒服，他可能不会眼睛很新。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:14:29',
    content:
      '如果你刷新率太低的话，你这边看的话你会流眼泪，或者是反正就是太舒服这个样子类的。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:14:35',
    content: '实现高刷新高，关键是有多少成本的事情。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:14:39',
    content:
      '数字的话我们是天然的就说我做好它已经是我还没有做优化。当然我们可以做优化，对，但他不再有做优化的情况下，我就已经达到了已经能满足的这个要求了。数字要做的就是一个打法矫正可能更麻烦一些。'
  },

  {
    type: 1,
    title: '中国IT行业发展及投资情况',
    time: '14:54',
    content:
      '这段对话片段涉及中国IT行业的发展历程和投资情况。谈论了一些知名公司的兴衰，如AOC、天使、NEA等。还提到了半导体技术的挑战和OLED屏幕的研究。总体来说，中国IT行业在过去几十年间取得了长足的进展，但仍面临一些挑战和变革。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:14:54',
    content:
      '像现在里面，如果是在工程样板间，比如说我们空军的头盔。类别的那个级别。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:15:04',
    content: '现在是可以做的，我们正在进行研究所。'
  },

  { type: 2, person: '发言人2', time: '00:15:07', content: '对他他对。' },

  {
    type: 2,
    person: '发言人4',
    time: '00:15:08',
    content:
      '这个可靠性是啊他可能就是一个是寿命，它需要高低温的不停的这个策略。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:15:15',
    content: '高低温不对于做军品的利益，他也不要担心我极限。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:15:20',
    content:
      '所以这个就是我们这种驱动方式本身它好就好在它对电路来说的这个耗消耗是很低的。'
  },

  { type: 2, person: '发言人2', time: '00:15:26', content: '这个。' },

  {
    type: 2,
    person: '发言人4',
    time: '00:15:27',
    content: '主要是在材料，在制度上面。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:15:31',
    content: '我说西，我是你本来演员的，他本来也不是很好点，是传统。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:15:36',
    content:
      '的这个思路就像试驾这种，他们虽然换不了C的公司，但他思维还是停留在CFD上。面对他为什么做的没有我们好呢？就是现在大家还是用天天上这个危险期。对，那因为我我自己就是做芯片出身的，所以我一开始做这个行业的时候就跑上来就是用这个半导体的词户部管，上面有一个管，下面一个管，然后户户起来做。这个功耗就是天然就比那种传统的对对，所以我们那个时候还没下优化，我就是就很简单的先搭个原理决定样机，搭出来一看已经有这个问题了，所以这两天我们再优化一下，对比一下。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:16:16',
    content:
      '所以这个是模拟问题。那你下面做光的这一层的，现在材料主要还是那些，基本上都不错。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:16:26',
    content:
      '到今年为止，基本上国产每一层的材料基本上都是可以报备了。很多很多只是一些专利的问题，到时候再看怎么去解决。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:16:34',
    content: '对对对，但是中国国际，所以但是。'
  },

  { type: 2, person: '发言人4', time: '00:16:38', content: '国际都有。' },

  {
    type: 2,
    person: '发言人6',
    time: '00:16:39',
    content:
      '也还好，就是meat他们也说了，到时候要帮他们生产的时候，他会指定的这个材料我要给对你是美国的还是用日本的？'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:16:53',
    content:
      '我们你上次去跟张总去见过，他他那个就是我们看的他原来想考虑这个没有比。他的那个南关的那个，他调出来了。效果这个的话就设置设一个宽度非常高，就是你把你把红灯往上一照的。社区就非常他做的测试是几乎比国内的那些提供的那个叫什么？那个叫。这个高频他关键他还是在他本身那个宣传器，他自己个人保密没人知道。因为那个是关键的非餐期，就是那个1%的那那一项，他从来都是自己一个人偷偷摸摸早上去实验室去调好，让其他人员在上班的时候发现上班就是上班，那你们是找到他给的，其他的肯定进去。那你看到那确实是有这种能测试出来的。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:18:13',
    content: '这个是未来的一个发展方向。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:18:15',
    content: '未来对，因为他那个是最终做下来是成本很低的。是的。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:18:22',
    content:
      '你现在这个颜色的话，现在普遍来说的话，我们这个摄影这个行业可能现在还在八九十，跟电视机还不好比。电视机基本上都在120以上的。对的，在硅上面做的，现在还是行业里面这个基础还是在90左右，包括我们也是差不多，这还有一个发展。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:18:40',
    content: '那你现在用的是用的是白光来红蓝绿蓝，过滤局有关面。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:18:48',
    content:
      '因为他的点也很小。现在韩国人也在想办法，有没有办法直接去做用这个掩模板的方式，直接去做一个其实非常难，因为这个单点的像素只有几个微米，几个微米的话，你这个板的这个厚度就不能太厚，可能也是几微米。那就是对你这么一张光照几个微米的时候。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:19:07',
    content: '你的位置就是理论值最小1到5微米就下不去了。'
  },

  { type: 2, person: '发言人2', time: '00:19:12', content: '对。' },

  {
    type: 2,
    person: '发言人6',
    time: '00:19:12',
    content: '是吧？就是我已经因为你要我的孔5微米，它的厚度有5微米。'
  },

  { type: 2, person: '发言人2', time: '00:19:17', content: '对。' },

  {
    type: 2,
    person: '发言人6',
    time: '00:19:18',
    content: '因为我也没在想那个电柱没办法控制的。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:19:21',
    content:
      '还有一个很严重的是那个堵孔。所以说如果这个就算做出来以后，你那这就意味着这个master寿命也是用两次你就要洗了，所以这个里面成本就很高。所以现在这条路线，现在韩国人投了很多钱在做，好像成效不是太明显。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:19:36',
    content: '原来绩效一直他你坚持走他那个。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:19:41',
    content: '你妈对打打印，我是一个起诉证明。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:19:46',
    content:
      '他打印的话，实际上我是一开始我是因为他的严格，实际是很坚持。我还在做总裁的时候，那时候招商的他其实一点也不想赢，当时当当时主要是做这个采购的。组合技术。但是我觉得就是说他后来他慢慢我离开的时候应该是那时候基本上是给原来几家的违约金这一块最早我们的排放红旗的红旗如果不是财务出问题，他只是在跟我们提，就是现在东莞就已经做做了这个业绩和某种工程当中，把所有的土地跟市里面全都谈。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:20:34',
    content:
      '我们两家是模组，是我们又不是以他是液晶面板，它是它不是一个是。他出来采购什么构建，然后我们就摆下来了。摆下来过了两年跟算为看家族什么三家之前对吧？他们两家后来不干了，我看人又改了改了以后我是07年离开的，后来是李鸿章要到11个市委做书记。那你干脆不要放进贵州鸿道、深圳，现在是政府投。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:21:14',
    content: '了140华星光是吧？'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:21:16',
    content:
      '那没关系，那就是华裔光这个器，那那是我已经离开的人。2007年左右开始，你的比较早再干了。17。我走的时候TC的时候做700左右，我去的时候家里我做了300三四百万8的。从几个人开始做起。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '00:21:47',
    content: '冠捷后来是先卖给中国电子是吧？'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:21:51',
    content:
      '一起卖的就是我05年一半50%给京东方，50%给京，后来新东方没钱了，他把他的股份给卖了，卖了就给一个日本一些给中国电，然后之后去前年，中国电子把它从香港全部下市，变成百分百。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:22:15',
    content: '都有京东方。现在没有了。'
  },

  { type: 2, person: '发言人6', time: '00:22:17', content: '对。' },

  {
    type: 2,
    person: '发言人2',
    time: '00:22:17',
    content: '就要找，因为他们是。'
  },

  { type: 2, person: '发言人6', time: '00:22:19', content: '没钱才安排起来。' },

  {
    type: 2,
    person: '发言人2',
    time: '00:22:21',
    content:
      '就是京东开始最近这十年才有钱。是的，十年之前是穷的叮当响。对，虽然说是北京的。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:22:31',
    content:
      '是的，所以他就是合肥之后他开始赚钱，但是这两年又不行了。它现在的架构率已经到6成了，很糟糕。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '00:22:46',
    content: '所以AOC那个品牌。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:22:48',
    content:
      '在在哪里？LC品牌原来是美国的，我是美国人，叫emo，是将军电视。然后到了台湾设电视厂，然后美国告台湾倾销去破产。破产了以后，正好习大大说他需要一个现代工厂，我们就把AOC过去买下来，把台湾工程师放到。语言中是啊八九年。'
  },

  { type: 2, person: '发言人2', time: '00:23:19', content: '的时候。' },

  {
    type: 2,
    person: '发言人6',
    time: '00:23:24',
    content: '对，现在好多电脑还是用的游戏。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:23:28',
    content: '对，像你看到很多显示器材，这个是LC.'
  },

  {
    type: 2,
    person: '发言人5',
    time: '00:23:32',
    content: '高峰的时候，你做到一个世界。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:23:35',
    content: '就是显电脑显示器，我们做到世界最大，就04年的时候。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:23:41',
    content: '但是对，但是全球最大。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:23:43',
    content:
      '所有的个人电脑几乎都是我们公司。你不要control compact，HP IBM Simons贴牌了，对着他走动。IOCCAOC edition.'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:24:00',
    content:
      '我跟他说的那你应该可以，微视人也不对，台湾微视人也做了一期这个行业的，都没有什么优势。也是做it的。'
  },

  { type: 2, person: '发言人6', time: '00:24:14', content: '台湾做it太多了。' },

  {
    type: 2,
    person: '发言人2',
    time: '00:24:15',
    content:
      '很多人是不是。你也打吗？你也答他是他在大陆it t是比较知名一点，是在里面，就是他，你说这个。那就专注在中国的大西北，我这个学生扶贫就可以大陆的做it的对他他早期的我们去台湾那时候会主动先去台湾给他视频见过面。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:24:53',
    content:
      '我们中间在在美国会有一家公司叫。叫天使。他是实际上是在美国的时，这是上位的人是吧？柜台交易的。但是我做的，如果他是做了大头，当然那个老板他就拿了，我们跟我们就有三个上市公司交换的。他变成3亿港币以后，他就到澳洲去赌博了。小说吗？但我们也拿不到他那个，财报的广告，是吧？'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:25:34',
    content:
      '在上海设立一个子公司的，当时做了一个。喝了没办法，李宗盛气的不得了。因为去美国自谈一个星期，有的人都做了一个干部的。当然不会说那人把这个东西卖了以后就一直在那几年。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:25:55',
    content:
      '后来是我们跑到美国联邦这个对方案，他跟联邦警察达成这个协议，就说他把他作为股权。因为当初我们要全部加起来股权来来给他的子女才能做决策。相当一部分散户就是台湾一些土耳其的，在哪儿？让我们跑到台上给你做身造型的。一般这些弄来以后，反正我们大家一起来，那妹妹自己不老百姓。'
  },

  { type: 2, person: '发言人5', time: '00:26:30', content: '家03年就去世。' },

  { type: 2, person: '发言人2', time: '00:26:33', content: '因为是这样。对。' },

  {
    type: 2,
    person: '发言人2',
    time: '00:26:40',
    content: 'It于台湾早年还是要做的。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '00:26:44',
    content: '后来员工其实我出来就到美国的这个NEA。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:26:49',
    content:
      'NEA是合伙人，就做那种早期组织下做的最大的。他在中国区的时候，我帮他做。看了在国内看了300个企业，把它大大小小都看了。就包括当时你像展讯中芯国际，他们嘉兴国际，像杭州的那个，杭州那个叫。杭州有一些专门做这个叫做移动网上支付的那条，其实启动的很少。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '00:27:39',
    content: '但是你咱们雷尔国际。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:27:42',
    content: '后来他他卖的是卖给那个聪明的运通，卖了很大的一个价钱。'
  },

  {
    type: 2,
    person: '发言人3',
    time: '00:27:50',
    content: '那您07到哪一年是做这个投早期的那是鼎盛。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:27:55',
    content:
      '我是08年11年OK在预备他们作证。11年之后11年以后，一二年以后我去了去花了比较多一点时间在加拿大待了女孩子。对对对，是的。'
  },

  {
    type: 2,
    person: '发言人3',
    time: '00:28:13',
    content: '因为07年山第一次进入中国。'
  },

  { type: 2, person: '发言人2', time: '00:28:17', content: '对。' },

  {
    type: 2,
    person: '发言人3',
    time: '00:28:18',
    content: '10001000份儿记得很清楚，但他的业绩并不好，但中国的VCD.'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:28:25',
    content:
      '甚至1.1个对，那个时候在中国实际上是真的坐飞机的，就是很多国内做卫生他没有这个概念。我只是到了NNA我才发现他们公司？他们是很有耐心，我投下去就是12点.'
  },

  { type: 2, person: '发言人3', time: '00:28:48', content: '还就是。' },

  {
    type: 2,
    person: '发言人2',
    time: '00:28:49',
    content:
      '他的基金就是说低于12点你不要做VIP非常清晰。08年的金融风暴以后，你看上面零他们还投了13亿美金。他的日常的盘子是145，就在在这些果粒。'
  },

  { type: 2, person: '发言人3', time: '00:29:13', content: '大概有多少？' },

  {
    type: 2,
    person: '发言人2',
    time: '00:29:16',
    content:
      '在国内当初是国内的人员都希望是人民币基金。但是他华盛顿和这个镀金山两边公司到了以后，美国政治斗争环境的人坚决不同意做人民基金。那今天第三个就是了，老是诚信，始终是把钱打在香港你这个项目看好了，香港再把钱进来。搞得非常，你比如我们去美国参加三次年会，我就知道我是好像对于东部的，你是西部的。因为每一个项目大家发生这样的，下次你再几个项目我去接接出去，不管你读的好不好。'
  },

  { type: 2, person: '发言人4', time: '00:30:02', content: '这就不是我。' },

  {
    type: 2,
    person: '发言人2',
    time: '00:30:03',
    content: '就离开他们，就是因为我给他们推荐了那个。做那个豆浆机。'
  },

  { type: 2, person: '发言人3', time: '00:30:11', content: '在那边去看。' },

  {
    type: 2,
    person: '发言人2',
    time: '00:30:12',
    content:
      '甘肃九阳公司才多少钱，当时估值是大概是5亿都不到人民币，那是他们应该说另一个。但是他那个那个总站的批发，我们一般上面做一些培训，我给你做了几场培训，他说那你这个，那我的条件就是说NEI能能进到你这来，按照这个价格，那我也给他一点，让他送我2.5。但后来后来也自己解决了这个项目，他说加快我们不同。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:30:50',
    content:
      '后来是国内的另外一场。退出来就是40，40叫我奶奶来讲，就是中等教育类似的叫投资金性。两个字的事也做，工作有的时候做的蛮大的。也可以。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:31:19',
    content:
      '前面出了40块钱吗？对，因为AA没进去，那那王董事长就说，那我就干脆这样。你就放到我这个母公司里面，对吧？你出2500万，我们公司里面我送2000万，一直保证三年，每年有三个月在有的用一下。我不是我觉得那你那你不能背着人家去做这样的事对吧？可能来说你这个人人品。干干一段时间确实觉得没什么意思。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:31:57',
    content:
      '很多项目我们在中国这个角度，我这边客户就拿过来两个人坐的。马部长是对全国的所有的高科技区开发区那些人都可以说他们要推荐的学校，那我来去给他做一些反馈。因为人家在中国都是些小年轻硕士、博士，但是从来没打过工的他怎么去判断这个乞丐都是我带着他们出去的。发现没有？第二位是少看了很多雷，13年，我觉得没什么意思，一年你这一年150万，他每个人老老实实把我所有税费扣完，然后再加上回加拿大来回一年回个四五万。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:32:49',
    content: '给了儿子借钱干嘛。'
  },

  { type: 2, person: '发言人2', time: '00:32:53', content: '对吧？没有。' },

  {
    type: 2,
    person: '发言人5',
    time: '00:32:57',
    content: '安总回来快捷之后也做了吗？'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:33:01',
    content: '没有冠军我就退出了。我就要。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '00:33:04',
    content: '退出之后，你就自己做自己。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:33:06',
    content:
      '就自己投。对，自己投，投了很多前面就失败。因为我一开始我就跟王东升反对，用这个金属掩模板，是三星想出来的，我就。反正那种做法，大妈做不大小，也做不小，就是就夹在中间。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:33:28',
    content: '英语娃娃肯定大招不大。'
  },

  { type: 2, person: '发言人4', time: '00:33:31', content: '小说不小。' },

  {
    type: 2,
    person: '发言人2',
    time: '00:33:32',
    content: '小说不大小。范围的厚度决定。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:33:35',
    content:
      '对，那个孔不能跟他讲，理论值只能到5微米。对对对，实际上其实到20微米就不得了，这个很难做小，那做大那个就会。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:33:47',
    content: '垂下去了，对他没法拉平。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:33:52',
    content: '再来规是最糟糕的半导体，所以OLED需要迁移率高电流。'
  },

  { type: 2, person: '发言人4', time: '00:33:59', content: '对。' },

  {
    type: 2,
    person: '发言人6',
    time: '00:34:00',
    content:
      '所以这个折腾怎么可能有这么，所以这个LG PS我们说是少年，他不是花点，就是因为它的电流都是均匀的对，所以就要有很多很多补偿，所以一个像素你要用七个管子，神经病。所以那时候我们在western house，我看了今年我们旁边新的集团的一个library读书管理50年代所有半导体的数据，他们做了很多实验，最好的半导体是Cameron slm。后来我做出来了，给王东升，他去国家去举报这个。这不是我一个失败的。所以到今天为止，大家还是在用这个三星的这是一个错误的。所以你看折叠屏，我们15年前就在家，可选择到现在过两天。'
  },

  { type: 2, person: '发言人2', time: '00:35:07', content: '对，就是因为。' },

  {
    type: 1,
    title: '半导体工艺及技术发展',
    time: '35:09',
    content:
      '本对话片段讨论了半导体工艺及技术的发展趋势，包括数字驱动、晶圆尺寸、曝光机规格等方面。对话中提到了索尼在模拟驱动发烫问题上的困扰，以及苹果自主设计背板的背后故事。还涉及到京东方、大疆、Meta等公司在半导体领域的合作与探索。同时讨论了半导体产业的市场前景和装备需求。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '00:35:14',
    content: '现在我那天还听了一下，就是我们现在跟索尼现在。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:35:23',
    content:
      '我们大方向来讲，就是你像苹果他的vision pro它需要它第一代vision pro它需要100万台，索尼弄来弄去最后只能给他10万。10分之1而已，索尼也是有同样的问题，它就是模拟驱动发烫，做做不了。苹果本来三年前就要做了，结果索尼做不出来，4K太多了，烫的要命。所以苹果亲自操刀设计芯片背板做出来，结果索尼的不良率还是90%做不出来。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:36:03',
    content:
      '所以现在的结果是苹果自己设计的背板，以前是苹果丢给手机，希望索尼能够搞一个显示器，员工他直接买了就可以了，后来发现不行，他说很多缺陷，那苹果他就找了一堆的人，他就说那flash不行的话，那么他他这个？比如现在这个微零pro使用这个模式，就是并不是苹果自己设计它，只不过它设计，它能跟它的处理器对，很多东西可以自己无法自己去做的，但是这个工艺还是要做的。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:36:36',
    content: '所以你觉得你去做的。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:36:39',
    content:
      '但事实上索尼的工艺不是全球最。就现在为止国内的技术，你像我们是京东，包括我们做出来的东西反而不是应该好。因为他这个投资是去十年前的投资。对，蛮老的。我们现在设备都很先进的对，现在的这个工艺技术。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:36:55',
    content: '也那你们现在买那个设备。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:36:58',
    content:
      '是买的是主设备是韩国的，因为是增补设备，就是韩国的几家大的在卖。那么我们我们买的时候大概是2020年的样子，这个时候的设备已经比他五六年前，包括比之前的设备高。我认为他做的2米高也跟这个有点关系的。相当于我们我们在他的基础上又提了很多改进的方案。因为我们这个团队相对来说还是比较成熟的，就是原来各个厂的一些专家认证这样子。然后我们把他请过来，然后重新的把它的设备重新再去优化一下。所以在原来基础上能达到现在这样，所以我们做的都属于统一。好，但是苹果因为它是属于国际公司，它跟日本还是属于走的比较近，所以希望这个火力的支柱，现在相当于韩国三星2G也要加进来。他等于也是在跟苹果去谈，苹果除了手机你还在找还在找更好的技术。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:37:49',
    content: '原来苹果找的是三星，就要早点给他提供，翻过来不用了。对。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:37:54',
    content:
      '鸡汤饭也做不出来。那他就是脸书，它就是依赖京东方，结果京东方弄了半天，12寸量产线也投了。对，到现在为止还是用液晶的，是fast RCD，它的OLED VGO的是做不出来。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '00:38:13',
    content: '目前应该没有用。对，就是因为京东方。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:38:17',
    content:
      '的OLED做不出来。它的量产线在云南这个八寸也有，12寸也有。如果所以meta它是世界最大的，它有它这个后面要做超过1000万台机就是。对。'
  },

  { type: 2, person: '发言人5', time: '00:38:39', content: '他说华华也是。' },

  { type: 2, person: '发言人2', time: '00:38:41', content: '去年他。' },

  {
    type: 2,
    person: '发言人6',
    time: '00:38:45',
    content:
      '中国现在唯一能量产的就是合肥市。合肥市雅那个12寸，它的产能是应该要300万，一的产能是300。但是大疆是跟他买，去年只做出来30万。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:39:02',
    content: '陈老板像他那个驱动好像也不是物理这个方式的。'
  },

  { type: 2, person: '发言人6', time: '00:39:07', content: '他还是。' },

  {
    type: 2,
    person: '发言人2',
    time: '00:39:08',
    content: '原来那个模拟的对。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:39:10',
    content:
      '所以他也不要烫，所以他的良率只有10%过两位的。所以这个大疆的无人机它戴上眼镜，就好像你真的在上面，所以他今年要求他们必须给我100万元。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:39:26',
    content: '那你现在你现在柜机上面做西红柿也是。第二协作。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:39:35',
    content: '对，就是用半导体工厂的还是半导体工厂的工艺。'
  },

  { type: 2, person: '发言人2', time: '00:39:39', content: '主要还是。' },

  {
    type: 2,
    person: '发言人4',
    time: '00:39:39',
    content:
      '对方工厂过来，这个中心国际这种他做完这个电路做完以后，他把那个经营位置给我们，对，我们就拿到我们厂里面去，把这个放到产品里面去。所以他就将上面长了LND的材料，对吧？'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:39:55',
    content:
      '就是这个数字驱动我们全世界目前只有我们的有，其他都还是全只有两分钟。'
  },

  {
    type: 2,
    person: '发言人3',
    time: '00:40:06',
    content: '这个大概存在事儿，就为什么？'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:40:07',
    content:
      '那你对，然后艾特也来我们带来是八月份来过一公司都过来。然后后来也讲适当他们也是跟我们不谋而合的，他们也在找，因为他们也在想要减少他的功耗，也降低他的工耗，对吧？要降低这个成本，那么有什么途径可以去做的？所以他们自己这个模式我们来教交流。就是说不是他看到我没有数字驱动，他才决定我们，而是他们自己也在找这个方案，正好找到一家我们也在这个方案，很开心的，所以就特地从美国飞过来跟我们交流，所以是希望你可以但是雷他们自己，他又不是芯片公司，它跟品牌不一样，它是互联网公司，他们自己没法去组织团队去审查，谁来不了。所以他也很尴尬，他说他他的这个博士挺有水平的。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:40:58',
    content:
      '然后那这个博士也讲，他说本来说他们不像我们是一个专业的，就是一个设计公司。我觉得这个业务的踏踏实实也是要东西去去去找个团队过来，对吧？因为现在还是挺复杂的对。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:41:11',
    content: '对他原来是苹果的、微软的、三星的、LG的这些博士凑起来了。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:41:20',
    content:
      '三公35，你知道给科瑞，科瑞就到处找，在广东这边找了一大片，找不到他的那个答案，满足他去，他对别人说是对的。他们在展会上讲，没有他来的这些人，康明斯管质量的专门定的看的是啊在美国看的。是的，质量还搞技术的都是也都是那些大企业发来的，组组一个团队到这个。'
  },

  { type: 2, person: '发言人3', time: '00:41:57', content: '基因。' },

  { type: 2, person: '发言人2', time: '00:41:59', content: '对。' },

  {
    type: 2,
    person: '发言人4',
    time: '00:42:02',
    content: '所以现在就是跟他聊了以后，现在我们跟他那个互动还是蛮密切的。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:42:11',
    content: '有时候你现在你的设备是针对1.3岁的。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:42:18',
    content:
      '我们这个设备本身它的它是含八寸的，好吧，按背板设计成1.3乘以1.3乘0.0 0点。但是我们这个方法在在你那个方法。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:42:28',
    content: '你跟别的不一样的。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:42:29',
    content: '也是叫叫厂长对叠。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:42:32',
    content: '长队叠。那是因为你工艺不一样。对。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:42:37',
    content: '设备还有工艺都一样的，功能都一样，就是电路不一样。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:42:40',
    content: '电路为电路我们的。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:42:42',
    content:
      '方法不一样，就是我驱动的这个算法不一样，电路不一样，但后端工艺跟那个后面都没有。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:42:49',
    content: '都一样的。那也可以做的比较大对对，就是对硅基要求比较对。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:42:57',
    content:
      '因为半导体硅为什么是1.3呢？几个选择。因为半导体它都是光刻机曝光做出来的。那么现在行业里面曝光机的尺寸一般来说大概就26乘32 33毫米这种板块。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:43:10',
    content:
      '对，这个是光刻机的规格。你报单次包装只能报是吧？对，那么它就是一个单次包装的。那么这个里面你做成最大的这个就1.34的，要再到大众上去了。如果再大的话也不是不可行，技术不是不可行，我得重新搞个缝纫机，重新搞一套这个刻蚀机制的东西也能做。但是现在你行业没法去推，因为我下个月。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:43:33',
    content: '还没来，你没法做做更大的曝光。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:43:36',
    content: '就算他不会理我们的事情，对他不会。对。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:43:39',
    content:
      '现在还没到这一步，因为你这个曝光时间也要求不高，就是一百多万。对，要求这个也不是对美国要限制。对对对对你你最小的100，你最小也100万。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:43:53',
    content: '对，其实也不用再打了，像现在这个。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:43:57',
    content: '业绩一直，其实一点都挺好的。'
  },

  { type: 2, person: '发言人4', time: '00:44:01', content: '就是这么个尺寸。' },

  {
    type: 2,
    person: '发言人6',
    time: '00:44:02',
    content: '我们有两个刚好对着眼睛一样的。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:44:06',
    content: '那你平均这个市场的话在一百多多平，我就差不多两天这个的。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:44:15',
    content:
      '所以那个时候刚刚才的时候量，其实我们我们做1.3的苹果还没解，对吧？那我定的规则还是蛮超前的。当时因为这个大概其实就是研究的差不多。因为为什么我们会做的苹果也会做1.3，抖音会做1.3？因为大家这几天研究起来，对差不多。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:44:31',
    content: '原来的就是传统这个光刻机构对它这个规则，它效率是一定。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:44:39',
    content:
      '最高的成本。然后成本我们算过，可以接受。因为再早些年，这种说法就是规定的成本太贵，可能根本就其实量产也会也太贵，但是市场量产又是不贵。我们举个例子，比如说12寸的12寸的这个晶圆，可能我们按照成本价来。数量大以后的话，那有点不好，肯定也就两千美金样子了。两千美金我们能做七八十个，做70的话，你2000除以70大概也就30块钱，30美金就200块钱一个。所以未来的装修公司不会太多，只要我这个产业能蹦起来。对对对。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:45:14',
    content: '最后可能比液晶还对比液晶还便宜。只要良率到60以上.'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:45:19',
    content:
      '70 80 80对，别人可能发生，他是这样发生的价格，你如果是做到成品率一次一个一次合理，如果明确的70的主要对。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:45:35',
    content:
      '其实他是这么计算的。就是说我主要是开发这个城市的，就是液晶因为TFC它可能只有67层，因为液晶是平面的对吧？我我我没太多的内存数的，我希望是大概有30层。对，30层。那么每到可能成熟的这个价格可能会比以前可能会贵一些。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:45:52',
    content: '一成一。现在一层就是平均下来了。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:45:56',
    content: '要30到，要看供应的，有些30，有些可能20就够了.'
  },

  { type: 2, person: '发言人2', time: '00:46:00', content: '有些40就是公共。' },

  {
    type: 2,
    person: '发言人4',
    time: '00:46:02',
    content:
      '节点不一样，他还是自动的。那么未来的再往下走的这个模式，可能更是做ID模式，可能是自己去做对吧？'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:46:11',
    content: '你现在飞机这边做了三四次。'
  },

  { type: 2, person: '发言人4', time: '00:46:14', content: '我们现在是有33.' },

  {
    type: 2,
    person: '发言人2',
    time: '00:46:16',
    content:
      '你是密度比较高了，就是你你要针对你那你一组的话你去动也不一定是要只驱动。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:46:24',
    content: '他是这样，他一般来说就是一个决定。'
  },

  { type: 2, person: '发言人2', time: '00:46:26', content: '像什么。' },

  {
    type: 2,
    person: '发言人4',
    time: '00:46:27',
    content:
      '对举个例子，比如说点18的照就普通的8.1毫米，基本上就28层，基本上就是整整个那个的。它六寸金属六分钟加隔离层，这个就是二层的，一下还是只能是那个那个管子，就是那个摩斯登。我基本上就将近30个样子，我们做了优化可以30分钟销售。所以像30层每层如果是20美金的话就600美金。对，那么再加上微和是100美金，就是700美金。七八寸就是你已经把这个程序减到最低还最低。刚才讲700美金，如果我一个八千是30天，70除以30的话也就。'
  },

  {
    type: 1,
    title: 'COC驱动设计的核心价值',
    time: '47:03',
    content:
      '公司是否有专门做COC驱动设计的团队；成本降低的方案；面板和驱动的关系；LED和OLED的应用范围；超大屏幕的问题；以及设计和规划在降低成本中的重要性。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:47:07',
    content: '你们公司有没有专门做这一块COC这一块的驱动是专门线路设计的对吧？'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:47:14',
    content: '这就是我们的核心价值。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:47:18',
    content:
      '他们那里现在算下来已经做的是最极限的，是不是要做三次？我们原来有一家企业，就是上海最早他的微软在做就跟格瑞合作的事。他原来是用的是32寸。这个成本是比较高的那我说你这个是可以调来把陈数减少，因为一一存下来，反正你做饭子，你知道做一次是多少钱。后来他们是在线路上面确实跟科瑞讨论了很长时间，短了半个小时。这又做了一个新的一个工艺，在归纳化改了18条，而且这种很少。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:48:10',
    content: '二层的话已经减少了四成了。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:48:14',
    content: '他全部重新做培训作业业务。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:48:17',
    content:
      '这个倒是有可能，但这个需要我们有很大的人去跟发展商的平台。比如说你原来对对对，因为他原来从广州专业，然后提供给那个但是标准化的东西。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:48:28',
    content: '所以这个是内部设计的。我们设计你。'
  },

  { type: 2, person: '发言人4', time: '00:48:32', content: '在家里。' },

  {
    type: 2,
    person: '发言人2',
    time: '00:48:33',
    content: '里面应该是因为对，你把那个产地发了，你们本身可以改价这个价。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:48:40',
    content: '但其实你跟他杀价更快，比比小陈。'
  },

  { type: 2, person: '发言人2', time: '00:48:44', content: '还要快。' },

  {
    type: 2,
    person: '发言人6',
    time: '00:48:47',
    content:
      '它它的成本你八寸它的成本其实只要400块，400块这是一条，但是他要卖给你800块.'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:48:55',
    content: '因为以前两条，是啊，那这样你是在。'
  },

  { type: 2, person: '发言人6', time: '00:48:59', content: '国内还是在。' },

  {
    type: 2,
    person: '发言人4',
    time: '00:49:00',
    content: '国国外国外国际国际国际国际。但现在我们美国的。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:49:07',
    content: '连中英国际班他就不接受了。'
  },

  { type: 2, person: '发言人4', time: '00:49:10', content: '所以说我是。' },

  {
    type: 2,
    person: '发言人2',
    time: '00:49:11',
    content: '你看这个走台湾的是。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:49:14',
    content: '我们现在八岁的不好找，所以我们。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:49:17',
    content:
      '台湾已经好了。对对对，台湾只有做公共器械的发出来。对对对，韩国的，韩国那台湾已经没有了，台湾是发生了这种攻击事情。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:49:35',
    content: '还有16点，后面就是12月份.'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:49:40',
    content: '12岁的时候成本低，到9月底，而且你这0.18对他12岁来讲，对吧？'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:49:49',
    content: '对我就他他一样的。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:49:50',
    content:
      '对，买更贵的一样卖的。你一读一读出来的。同样那个时间他说了很多，只说了差不多三四遍。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:50:01',
    content:
      '是，所以在十月份可能会多一些。我们可能8寸跟12寸可能都会都会现在算下来差不多。就现在按照经济上算下来，可能12月份的乘80的可能差的没有，不是跨越式的差，不是像那个面积的是吧？'
  },

  { type: 2, person: '发言人6', time: '00:50:18', content: '这个面板不一样。' },

  {
    type: 2,
    person: '发言人2',
    time: '00:50:19',
    content: '对，跟面板不一样。对对对。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:50:21',
    content:
      '没有两倍。对，差不多。反正就是说一个前面算一个31个40，就这差别。这个。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:50:29',
    content: '因为他关照时刻。他们家的工具都少不了，对。'
  },

  { type: 2, person: '发言人6', time: '00:50:38', content: '好处是很多的。' },

  { type: 2, person: '发言人4', time: '00:50:40', content: '这个差对。' },

  { type: 2, person: '发言人6', time: '00:50:41', content: '成本还差多少。' },

  {
    type: 2,
    person: '发言人2',
    time: '00:50:43',
    content:
      '但是的话工具建议现在我们在台湾有加工，现在他一颗心变成。就买这个。一分五人民币。他说去东西这个小东。'
  },

  { type: 2, person: '发言人3', time: '00:51:10', content: '我想看一下。' },

  {
    type: 2,
    person: '发言人2',
    time: '00:51:13',
    content:
      '半导体就是你想你你这堆积的，就实际上它的材料都是好了，捡来的地下要挖一下。对呀对。'
  },

  {
    type: 2,
    person: '发言人3',
    time: '00:51:29',
    content: '还是这样。上次分解就硅片。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:51:32',
    content:
      '的成本是很低的，一个八寸的可能成本也就100美金，不到12寸的也就100美金。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:51:38',
    content: '现在大米应该也做推进。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:51:45',
    content:
      '所以这个行业，我们现在找到一个点，就是说很关键的点，就是现在这个行业是割裂的。就是说传统的做那个面板的全新的剪刀模式还是什么，还是做面板的起的，所以他是做一半怎么做面板的知道吗？对，但怎么做下面的那个。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:52:03',
    content: '他是不清楚的，没有。对。'
  },

  { type: 2, person: '发言人6', time: '00:52:08', content: '就是专家对。' },

  {
    type: 2,
    person: '发言人3',
    time: '00:52:10',
    content: '而且这个是我认竞争优势对吧？这个就没得没办法很难改变的时候。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:52:18',
    content: '这个就是他大企业说接下来。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:52:21',
    content:
      '我们会说上市规模，其实他很难就是说从另外一个思维方式来思考，这个创新是最开始的。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:52:32',
    content:
      '内部我是专门找到这个，我原来已经走了一个微信，原来在合肥朱克泰，那时候我们就是在讨论这个，就是大概七八年前他就坚决用TFT来做，但是在玻璃上做成本更低，对吧？所以我们五六个朋友就投他了，投了以后找了半天做不出来，我就发现这几个问题就到处找。朋友丢到直播，我一来看，这个好。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:53:08',
    content:
      '他没有时间是用我玻璃，因为他习惯于我的玻璃，这个多便宜，我这些大规模化现在都在玻璃上面。一开始合作没有了，一开始这个产品现在玻璃上做做。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:53:22',
    content:
      '对，而且他他是说你看以后可以透明的，他就想说坐在玻璃上要，然后透明的坐在上面。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:53:29',
    content: '但是玻璃的，所以是。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:53:31',
    content: '是啊是，所以他他。'
  },

  { type: 2, person: '发言人4', time: '00:53:34', content: '对这个有。' },

  { type: 2, person: '发言人6', time: '00:53:35', content: '很多的很多问题。' },

  {
    type: 2,
    person: '发言人4',
    time: '00:53:37',
    content:
      '他跨领域了，就是他这个适用范围已经超出适用范围了，对吧？这个玻璃什么的，反正他只能比如说做那个手机基金的话，做手表已经是极限了。对你手表上时间，你再招你，你不适合的人在硬做还是硬来了。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:53:51',
    content:
      '所以我才去投那个。我另外投那个公司在抓的是每月在柯达那里就是时刻OLED。因为在玻璃上你必须要LED要时刻，所以我还专门投入那家公司，搞了半天弄不出来。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:54:06',
    content: '苹果不是有一段时间他做的那个平板有mini，对。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:54:14',
    content: '这个在那个LED也是成本降不下。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:54:19',
    content:
      '他没法降低减费。就是你只要是这一切效率都来自于规划和设计。是如果你每次规划这个路线和设计的路线技术路线，如果不是本人，它能产生一个会产生一个很大的成本下降的话，或者可靠性意识和问题这些不合的。一开始这个节奏。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:54:43',
    content:
      '就决定对你说那个那个对吧？我也是跟京东方说，他那个大屏幕的话就是在5厘米的对，那你你那个成本怎么降？你不可能降到1万块以下的对。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:54:57',
    content:
      '就每平方米我没法这样。那现在像现在它迷你的，你说你都是白光，看起来每一颗都很便宜。单位就是你密度越高，你分区越多，这个岗位就越需要这个高档的就必须要你必须要把算一下价钱，算了几个材料，10%，那你卖这个。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:55:22',
    content: '我这是我第二个失败的投资。'
  },

  { type: 2, person: '发言人2', time: '00:55:25', content: '我跟。' },

  {
    type: 2,
    person: '发言人6',
    time: '00:55:26',
    content: '王东升说，你大屏幕我们要用那个激光max然后在硅谷有两个印度人。'
  },

  { type: 2, person: '发言人2', time: '00:55:35', content: '他投资做了。' },

  {
    type: 2,
    person: '发言人6',
    time: '00:55:37',
    content:
      '用激光面打到前板上，前面是要发出红绿蓝，这个方式打上去我就这么薄，这个可以无限的降成本，但是自动发出对我其实没有任何的。所以一个OLED正常的，一个是大屏幕的阴影调那个差不多走过。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:56:03',
    content:
      '房你也可以实现的。如果是你只是从消费来讲，你原来用的LED的做背光源对吧？你只说我希望他这个随便一点。'
  },

  { type: 2, person: '发言人6', time: '00:56:18', content: '你就是产是最多。' },

  { type: 2, person: '发言人2', time: '00:56:21', content: '也得做到。' },

  {
    type: 2,
    person: '发言人6',
    time: '00:56:22',
    content: '三个区区的四百区。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:56:25',
    content:
      '以及足够了，每天要做五千去。他那个板子必须跟面板是一样的，你也没法去做一条了。'
  },

  { type: 2, person: '发言人4', time: '00:56:38', content: '是这个。' },

  {
    type: 2,
    person: '发言人2',
    time: '00:56:39',
    content: '加工程度，这个一下就是。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:56:41',
    content: '对他们认为，但是没有你。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:56:46',
    content: '还有个你还有驱动问题是。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:56:49',
    content: '是你面积越大越能驱动。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:56:52',
    content:
      '别的就。那你苹果十家是你看他没没卖没卖多少，他现在就决定全部都是给我保险，他没法再。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:57:04',
    content: '所以超大屏幕现在就变得没方案了，大家只能死死硬磕这个迷你LED.'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:57:12',
    content:
      '只能这样的，但是又不一样。你想。98岁的，如果是你不用你那价格是便宜，右边也得贵了几倍是吧？对普通的消费者来。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '00:57:29',
    content: '那就是我们10平方米的，就是220寸的这种。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:57:34',
    content:
      '我是告诉你一个数据，现在这个主题是一个。我来再问他他说看看吧，它是我们真正进出用一年级这种对比。总量没法改进，水平太贵了。你说你这个显示效果，那现在你给谁面板的对。'
  },

  { type: 2, person: '发言人6', time: '00:58:06', content: '又是数字性好。' },

  {
    type: 2,
    person: '发言人2',
    time: '00:58:09',
    content:
      '本来就不差。就他的价格你怎么去卖的，反正一看才几千块钱和这个几万块钱。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:58:19',
    content: '对吧？如果差差个10%.'
  },

  { type: 2, person: '发言人2', time: '00:58:21', content: '咬牙贵了五六倍。' },

  { type: 2, person: '发言人3', time: '00:58:23', content: '那就是超过100块.' },

  {
    type: 2,
    person: '发言人6',
    time: '00:58:26',
    content: '的怎么办？这是给你这是一个市场，超过100寸你怎么办？'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:58:31',
    content:
      '超过100寸现在不是但是我们山东工厂就是用集成电路设计的方式，重新涉及到这明年月底的。就用四克为宜，原来上面一部分驱动了解上移到上个阶层。'
  },

  { type: 2, person: '发言人4', time: '00:58:55', content: '他叫然后对。' },

  {
    type: 2,
    person: '发言人2',
    time: '00:58:59',
    content:
      '但是这个是原来前面十年一直没解决。就是说你继续玩2K3K4K做的时候，比如说115，最后的108岁的就没有单颗的这个灯珠LED没有提供，也容易在生产过程中碰到了。但是我们山东工厂就是针对这个问题，也是用基金电路设计的，谢谢。好了，设计出来就是先在那个窄板上面把它先把它先把它封装好，磨牙切割分选出来，你再一贴KS4。那个对比度就比比单科要好很多。因为你涉及到一个你关创业板的问题，解决了他原来是官方的问题危险。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '00:59:50',
    content:
      '还有就是说COD或者IMD就是集成式的，还有个DS mind方式，就是我们那个故事。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '00:59:57',
    content:
      '现在在美国因为我们是用半的这个思维方式来思考做这个事情。是而原来中国传统做AED的几乎所有大佬从来没有解除办法。所以他们也包括服装的，他们一直在原来的这个基础上，反正是垂直结构，从那个灯开始，交通灯开始，然后再到平面贴。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:00:23',
    content:
      '即使到平面贴，他们从来没想过就说我用另外一层的逻辑把它上移到跟登在一起。那你原来要6成到8成做到你说的100 100岁以上，要做到你要做到1000 2000以上，对吧？110 100岁以上。他们搞了十年是吧？始终搞不出来原因在哪里。就是他没有把那个八层中间的一部分逻辑上移到这个珍珠上面，你用双面加上，对对对吧？'
  },

  { type: 2, person: '发言人4', time: '01:01:01', content: '因为两层。' },

  {
    type: 2,
    person: '发言人2',
    time: '01:01:04',
    content:
      '减少两层，那你每层1500块钱，一个图发给那个他。你你你你整你整体的你把你本身对于这个板材来讲，它减少沉积以后，它它的子宫越高，你可以说贴这一个词，这个还是要就是我开始说的一个他必须要具有先天要具备这个资金产品的。往这个方向下，京东方提出没有问题。对，他绝对不会想说，那你你如果是用用玻璃来做，基本上你用柜子做，基本上它全都。他认可我前段时间是也是在推动这边有一个做5G芯片的，就是高通地区高功率高通出来的。'
  },

  {
    type: 1,
    title: '中国集成电路产业发展及投资状况',
    time: '01:01：58',
    content:
      '本文介绍了中国集成电路产业的发展状况，以及投资界对该行业的关注和投资情况。文章讨论了集成电路设计公司的融资问题，以及液晶面板驱动电路的市场需求和竞争情况。同时，还提到了京东方和华为等企业在该行业的发展和地位。文章强调了建立市场地位和技术优势的重要性，以及投资者对该行业的投资意愿。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:02:04',
    content:
      '上是我们国家大陆第一批是伯克利世界移动通讯领域的电竞人员，三个电台之一的doctor，他是他的大拇指，他说他第一代的我是谁？其他都是台湾的，就包括像现在中心的那个台湾那个顾问叫啥来着？原来从宠物排积淀。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '01:02:37',
    content: '出来的那个那个什么有什么梦？你是说什么时候？现在的心情。'
  },

  { type: 2, person: '发言人4', time: '01:02:45', content: '其实。' },

  {
    type: 2,
    person: '发言人2',
    time: '01:02:46',
    content: '这部分还是重点技术的人。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '01:02:50',
    content: '现在是现在现在现在这叫什么梦？'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:02:54',
    content:
      '张玉军他是不是是张玉军他们后来的后来的这个叫一下子去杀人，他就是这个logo在学校。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:03:07',
    content: '也是从国际的角度。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:03:09',
    content: '所以我记得是很多是。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:03:18',
    content:
      '那他那像他们做这一块儿的，他就是很专业。但是你如果是你跟其他的那些所融资的，你跟他们去谈的话。很难很很难，反正好非常沟通。然后他说原来你帮我去找一下金校，我就找了。原来是我在的人，他是负责这个叫提前一个GC的设计公司就在上面。他他们设计一下，他是说你其实这个公司你得超50万的那尾碳领域，微碳领域这个。'
  },

  { type: 2, person: '发言人3', time: '01:04:10', content: '你手机有他项的。' },

  {
    type: 2,
    person: '发言人2',
    time: '01:04:13',
    content:
      '如果说这个负责人他用的是设计彩电的电路的思维方式来领导这个就。对吧？已经上了，已经上5000万了，就像我一个一台给他们给他。完了以后还有，那后来我就。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:04:50',
    content: '不是这些和作品这个方向。'
  },

  { type: 2, person: '发言人6', time: '01:04:55', content: '他是围绕。' },

  {
    type: 2,
    person: '发言人2',
    time: '01:04:56',
    content:
      '他们自己本身需要做的这个集成电路，需要用的集电路。同时他老板也都是希望他这个集电路线的公司还能够是对外卖OK。其实他已经没有这个可能了。因为当时我们我说一个北京的吉川北方，他把你所有的驱动的培训都做都已经做烂了。你每年烧5000万，然后你再做一个失败的事情。对，而且你没法对外融资。人家说你是T恤是大股东，大股东的T恤是做几年的？'
  },

  {
    type: 2,
    person: '发言人6',
    time: '01:05:36',
    content:
      '那现在还是同样的事情在发生，我们去年TP没做出来，就是我们本来负责做T票的人跑出去创业，要做液晶屏的驱动性。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:05:50',
    content: '对，我说你神经病，你俩都。'
  },

  { type: 2, person: '发言人3', time: '01:05:54', content: '没有说服他。' },

  {
    type: 2,
    person: '发言人6',
    time: '01:05:57',
    content: '因为他就是发财梦，他认为他可以做老板。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:06:01',
    content: '现在液晶面板的驱动电路价值是比那。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:06:05',
    content:
      '白菜的价格要再加点。老早早就跟他说，对，他原来就是做这个行业的，我这项目的捞出来就是说你我们做的未来的东西，但是搞了一年来算数字驱动它不是太好理解。他是按原来那个思路搞了一年，到时候培训了一年，还是原来那个结果。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:06:24',
    content: '因为从模拟业务做出来的，他们觉得他自己的。'
  },

  { type: 2, person: '发言人4', time: '01:06:28', content: '经验就。' },

  {
    type: 2,
    person: '发言人2',
    time: '01:06:29',
    content: '数字定位设计了，他很难听到那个。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:06:36',
    content:
      '就能想到现在的这个，包括是他已经到我公司来了，对吧？他搞了半天，他觉得我们看不懂或者怎么样。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '01:06:46',
    content: '我们白白烧了一年的钱。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:06:49',
    content: '对，没有我讲个故事，去年有去。'
  },

  { type: 2, person: '发言人3', time: '01:06:52', content: '没有团队。' },

  {
    type: 2,
    person: '发言人2',
    time: '01:06:54',
    content: '去年最红火的时候，集团北方投钱，估值270亿.'
  },

  { type: 2, person: '发言人4', time: '01:07:00', content: '还要融它。' },

  {
    type: 2,
    person: '发言人2',
    time: '01:07:01',
    content:
      '原来预计融60亿，就大量的投资基金去抢进去。270亿有270、180，然后集中就是消费在武汉那个厂投，往彩头，你看这么好的钱，那行，我们拿五个亿，他是抢来五个亿的份额，想走的多了。流行也就是他要追溯这个，他就把那这个发过去。他因为去年都封闭，我说下个星期3月6号出差，一直到7月底才回来，就回不来。那时候这个东西在博弈，发给我一看，那我觉得你看一下这个集团本案279。如果是270，即使机械挂牌说给我十个亿。我都劝你不要去，为啥？'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:07:57',
    content:
      '我说他前三年，这是去年是2012对吧？我到他2020年的时候他是亏损的，2021年的时候赚了16对吧？2022年预计钻石，因为你想他2022年做所谓赚了这个16点，其实主要是来源于2021年下半年全球缺缺数据创导体，这是第一第二全就缺这个面板。因为大家都在家里上班，他是提前把疫情的全球的这个显示器，这都在家里只添加一个。我儿在英国上班，他说他们公司给他配了五块屏在在宿舍里的，因为他们做做金融。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:08:43',
    content:
      '对对对对。对，好，那我就可以想象得到你这个突然增加的量，你自然就是它价格就卖的高了。我说问题是过了，你2002年他还能赚16个亿。你赚不到吗？我说这个是我我个人建议你绝对不要走，他们就犹豫了。对，结果后来投进去的就是融资。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:09:13',
    content: '融了我融了87个亿。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:09:17',
    content:
      '他超额了融了87个亿。好，然后就排队。今年年初批了，到现在你挂牌为什么？因为他只要挂牌就低于他的一个投资的一个马上挂牌。那你说不定这股东怎么交。'
  },

  { type: 2, person: '发言人3', time: '01:09:34', content: '在哪儿挂牌。' },

  {
    type: 2,
    person: '发言人2',
    time: '01:09:36',
    content:
      '在A在哪里？就在上海。上海上海这个合浆板他已经过了，那个人到现在为止几个月都没回来。是他只要一挂牌，你想你270，你想等于100整个公司的。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '01:09:54',
    content:
      '今年京东方定增也是，有一个基金就来问我，我说你别相信这个是疫情特别情况，人家在家办公，所以他赚钱了。'
  },

  { type: 2, person: '发言人2', time: '01:10:07', content: '对，结果对对。' },

  { type: 2, person: '发言人6', time: '01:10:10', content: '去年就下了。' },

  {
    type: 2,
    person: '发言人2',
    time: '01:10:11',
    content:
      '你想它在疫情期间液晶面板滑行关闭，它盈利比前年要好净增15%百。我们对利润。'
  },

  { type: 2, person: '发言人3', time: '01:10:24', content: '就是这样的。' },

  {
    type: 2,
    person: '发言人4',
    time: '01:10:26',
    content: '就是他把后面五年的全部的生意，他把后面五年的全部。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '01:10:31',
    content: '一堆人抢那个京东方的定增，对。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:10:35',
    content:
      '他都抢了。我刚才说的就是唯一的这个企业，全国有这个参考。京东方市长打电话说，今天定金，你得把你的除21，那市场给他打电话了，市委书记现在是谁？打马东林有三个是一对对。我要说其实这个是书记讲，记得反正是国有的，我也是有的，反正公交的钱对吧？'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:11:10',
    content: '多少人？那就除了你。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:11:14',
    content: '除了签字可以怎么。'
  },

  { type: 2, person: '发言人1', time: '01:11:16', content: '听着那么熟悉。' },

  { type: 2, person: '发言人3', time: '01:11:19', content: '对吧？' },

  {
    type: 2,
    person: '发言人2',
    time: '01:11:20',
    content: '对，这都是我亲自经历对他们的。因为他那个基金公司。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '01:11:25',
    content: '我们的时候是的好几家来问我，他们都要抢，是京东方竞争必须抢到。'
  },

  {
    type: 2,
    person: '发言人3',
    time: '01:11:34',
    content: '那是最大的。然后又有一个班。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:11:37',
    content:
      '后来谁的税务什么的，因为我原来在这旁边，实验税高文宝以上陈元顺的。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '01:11:45',
    content: '新东方的第一天我。'
  },

  { type: 2, person: '发言人2', time: '01:11:48', content: '就认识了。' },

  {
    type: 2,
    person: '发言人6',
    time: '01:11:49',
    content: '1993年对对，我93年就跟他们合作了，然后94年我把。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:11:58',
    content:
      '冠捷建在他的厂房里。它的资本运作还算是蛮对的，它是湖北精明的因素。'
  },

  {
    type: 2,
    person: '发言人3',
    time: '01:12:13',
    content: '袁总觉得您资料也看了，人也见了，觉得眼光怎么样？也希望未来我。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:12:20',
    content:
      '觉得确实不错。今后我倒是觉得如果是量不大的时候，可以考虑玩满军贡献。因为那是个建立和门槛的一个很关键的事。第一个就是说国内目前还没有挤没有挤下来盯上这一块。另外一个就是就真正做的像。你们是要介绍的，就是说一个是包括像现在的这种工号，合格率。对的。因为你你功耗档案的话，你去做高定位极限测试肯定也一样的目标，是高密度目标，对不对？这个因为建立一个门槛，不是说代表不去做那种民用的，还是不知道肯定要有。对，2两个2个只有瞄准这一块。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:13:22',
    content:
      '因为建立了门槛以后，其实从市场阶段，市场占有的这个份额，它其实变化很大。人家只要一盯着你一个相同的产品，我这一次降价就把你的这个对吧？但你建立这个市场的地位，他在我就在你华为那个华为如果你能够把所有的这个数量也超过苹果，但苹果的低。你未必现在就明白他暂时利润就是由市场地位带来的，不是他的市场份额下，对吧？那我觉得应该是要利用这种技术领先和这种结构上，就是你的设计的结构的独特性建立起这么因为在中国你做的产品，今年现在全世界国家美国人和欧洲人学会降价。我我这个我不太快，全国会跟中国打不了。因为中国卖的东西多，所以按照美国人说法，只要你们中国人一做，就把这个价格做了，好吧？'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:14:35',
    content:
      '他是这样的，所以我觉得要建立一个。既有敏感，你会保证那一块逐渐建立起这个市场地位。你这个材料上是你要有的对吧？对你要有个融资和退出通道，那这个地位就比那个分量很强。'
  },

  {
    type: 2,
    person: '发言人3',
    time: '01:15:02',
    content:
      '这个是那他这个会不会比如说他搞先给军工，然后再供应海外的，像甘蔗，这个你们可能会有问题。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:15:13',
    content: '不是我觉得这个时候这个应该是可以，应该会在技术上隔离。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:15:20',
    content:
      '是就是要看怎么去可能从产品本质上我们可以达到去募集或者是达到的问题。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:15:28',
    content: '他这样的就是说我如果是做海外的那一块，我就完全把它完全清楚的。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:15:34',
    content:
      '对他比较清楚。因为其实那天他也反复跟我们强调，包括国内的字节跳动上次来了也是反复的强调。我们在开会，法务部门打个电话说，宁波好像是射菌的，立立即立即不能打电话。对，那些技术大佬就开始傻了。'
  },

  {
    type: 2,
    person: '发言人3',
    time: '01:15:50',
    content: '非得是我们交流。因为在那个。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:15:53',
    content:
      '管理结构，制定结构上是可以做出来，就是说我们公司是完全可以持有，尤其是现在，最简单就是美国制裁浪潮能量合作。福建全球第二大了，他制裁的是他的母公司那种集团。但实际上那种集团下面还有几有有三家上市公司，其中做服务器的角色是他的下级。美国一样的，美国那些卖卖存储芯片的一样的是开心的很卖的，就是你自己的是母公司，模式部的孩子，我是北京的。对，现在你看资产量涨停一年涨了一年多了。我上次见到那什么，我不是打算问他的，他是跟我们没有任何影响。因为我们我们实际在上市公司做这个服务器销量全球的主机，我买我我买一个，但是我买这个都是，但是我从你。这是可以的，和管理结构展示。'
  },

  { type: 2, person: '发言人4', time: '01:17:04', content: '可以。' },

  {
    type: 2,
    person: '发言人2',
    time: '01:17:05',
    content:
      '比如说我设计一个全职的直播室，我就是我随便只做名品，没有什么。对，你华为把把荣耀卖掉以后，不是按照这样的人买美国，是的，就很开心，对吧？那基本上是认为，而且北京现在的医院全都是国明知这个。卖那么多花那么大的价钱，保费的问题。对，我错了，你以为他是真的是。对不对？'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:17:43',
    content:
      '是你现在全国的省会一级的和二级人员，二线这个投资最主要的是。这属于。那就是。你这个省会城市对吧？好，未来投资是他们的，那就卖个100亿的也给你这。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:18:19',
    content:
      '建筑上因为本质上来讲，古镇本来就要智能化管理城市，你买你买谁家的那现在在在这两年华为份额政府的。对吧？你想他把荣耀卖出去，我还是要支持这个流程。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:18:48',
    content: '是的，是这个市政府过来的。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:18:50',
    content:
      '因为中国本身的经济它是依靠政府来去，他不排水。很大一部分市场它是在这个驱动以后，看到了这个趋势以后，市场才会加入进来。这就是为什么在中国你很难找到12年就坐飞机。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:19:16',
    content:
      '你很难找到。因为中国做VCE的本身，它的一定是要非常有产业空间投资金结合他这个团队在前面的几年还没有形成，还有一部分作为一些本身都是那些爆发户，三下5除了赚了很多钱，但那是早些年那些人他们有钱以后，他也不懂看这个事。所以你只是觉得说你看谁谁谁做金融那一年下来都是回报，他们愿意做这个短期的。所以在中国你很难找到早期项目的。其实中国很多种少有几号是就是法律。一两两条腿少。'
  },

  { type: 2, person: '发言人6', time: '01:20:06', content: '一条腿。' },

  {
    type: 1,
    title: '滁州市场投资潜力及融资需求分析',
    time: '01:20：07',
    content:
      '根据对话片段分析，滁州市场具有一定的投资潜力，但融资需求较大。市场化投资和国际融资是目前的主要方式，同时对于项目的爆款型应用和强有力的机构支持也有需求。讨论中还涉及到芯片公司的发展和产业扩大的挑战。总的来说，滁州市场的投资和融资环境值得进一步关注。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:20:10',
    content: '那现在这个滁州现在。'
  },

  {
    type: 2,
    person: '发言人3',
    time: '01:20:13',
    content:
      '滁州现在我觉得要去一趟，跟市长联系上之后，你们觉得要从。因为主要是据我们了解，安徽政府，你们是六安对吧？也可以看看他们的钱怎么样。如果是福州，如果有钱当然好了。但是现在我们的初步的反馈是很难投六七个亿以上。那这样子的话我们就找国际融资，因为他们设备是韩国和日本，那国际融资一定要老大，因为这个概念原来途中也不一定有。但是你说朱总，你说就几个亿算什么？'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:21:02',
    content: '最重要的关键这个市场对吧？对我觉得还是是市场化有投资再加两个。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:21:08',
    content: '因为的东西要卖不出去对吧？'
  },

  {
    type: 2,
    person: '发言人3',
    time: '01:21:11',
    content:
      '东西卖的出去，我觉得这个牌子只要能做出来，肯定是中间性，买票都买不完。在你不做军工的前提下。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:21:20',
    content: '他的你做了，他这里面那个对，这个除了袁总最红的就你了。'
  },

  {
    type: 2,
    person: '发言人3',
    time: '01:21:26',
    content: '没有，对他属于我觉得子琪你从投资的角度，刚刚咱俩聊的这个挺好。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:21:31',
    content: '他对他对这个投资公司法这帮人，他他他喜欢他他也都认识他。'
  },

  {
    type: 2,
    person: '发言人3',
    time: '01:21:36',
    content: '第一任是子琪，见了300家.'
  },

  {
    type: 2,
    person: '发言人1',
    time: '01:21:41',
    content:
      '我们我们这个帮实际上不一样的。在这边包括上海、北京生只有三百多辆。'
  },

  { type: 2, person: '发言人3', time: '01:21:54', content: '还融到了这都是。' },

  {
    type: 2,
    person: '发言人1',
    time: '01:21:56',
    content:
      '是当然里面有很小一部分可能是政府政府，但是也是由市场。他们在这个过程当中，就是因为我自己本身我也参与了一百多场，就是大家的一个想法大概是这样，大家看好这个R也认为以后这些，所以这些大家都会有很多的。但是对于某一家就particular这一家公司是不是能够存活到那个市场。大家其实这些VC或者说这些PEVC信，他是有些就哪怕是已经可能跟到后面，大家可以出一些TS，有一部分最终还是被。'
  },

  {
    type: 2,
    person: '发言人1',
    time: '01:22:55',
    content:
      '大家对于这个东西的一个印象，就是他们有一些专门的对在。他的一些算法在里面，他们就是组织了这些供应链。因为几几乎没有一家是自己生产的，全部都是各个供应链的代工的。这样的组织前，然后这个当中可能还发生了一些一开始的pancake，他们还没有完全的生产出来，有好几家，他们都有遇到。其实因为代工的那家厂，尽管是不同的牌子，然后就是这个package也遇到一些。但是就是从从这个角度来讲，他们就觉得这个东西的不假的，或者这个门槛是不是今天比如说万家这个手机厂商，他今天主要想说我要做这个演引创也好，谁家也好，搞一点算法来，然后把供应链一组组织好，大家就能做了，是不是？就是说这个你们没有了。所以说大家的心里和这些机构看这些项目是都有这样的一些信息。然后另外一个就是说对于这个量，那这个量就也是个事实，因为你看这个banca他不能说是公开的，就是说。'
  },

  { type: 2, person: '发言人2', time: '01:24:13', content: '哎。' },

  {
    type: 2,
    person: '发言人1',
    time: '01:24:16',
    content:
      '中国的话基本上就是中国是在女性的，目前来其他包括棋爱奇艺喜欢的挺少。然后从今年美国跟日本拿下来，他们的年初的这个计划不断的在下降，对销量的这个数不断的在下降。明年，咱们现在还没有一个非常明确的一个说法，就内部的争论其实相当大。当然是因为自己花了很大的代价把他们过来，把他们的人数也小于100个、400个，甚至于更多。所以对他们其实这个团队整个的KPI，也造成了他们可能内部也有进行分析。'
  },

  {
    type: 2,
    person: '发言人1',
    time: '01:25:04',
    content:
      '然后大家可能就是我们在融资的时候，就是好多的这些PC，他们也玩玩这个东西。其实他们也一直问这个问题，说你到底有什么爆款型的应用，现在可以在你这个上面玩。因为这个硬件跟这个应用或者说这个软件，它应该是完全是上市，大家互相相处。你如果在switch上你有一个的什么赛尔达之类的游戏，大家都去买了。但是在这个眼镜上面目前。所以大家对于他这个销量的预期总是有打工的问。所以就是我我们跟那些机构聊下来，他们尽管还是看好这个，但是他们对于他的一些也有谨慎的人我觉得也是一个没办法忽视的一个事实。'
  },

  {
    type: 2,
    person: '发言人1',
    time: '01:25:59',
    content:
      '像今年尽管这个也一般。已经过了。他们其实也有一些闹了小几千了，但是可能也是资金上暂时没有，还不太行。'
  },

  {
    type: 2,
    person: '发言人1',
    time: '01:26:16',
    content:
      '这个就是可能这个行业里面还是之前我们人人火的那那段时候男人给他快乐起来，但是现在看看着好像这个鱼、用具这。所以说我们也可能对这些机构来说，可能会要一些更加强有力的一些。就是说看到说这个东西是挺有希望的对，但是我觉得咱们这个东西因为是做的是太这个当中的这个硬件，然后瞄准的也是每个项全世界第。如果说跟这个安全之间，或者确实互相现在建立了一些联系，那这个可能就是或者说是一些货有的这个就让我有一些辛苦的一个人生。'
  },

  {
    type: 2,
    person: '发言人1',
    time: '01:27:14',
    content:
      '然后另外一个就是可能比方说我们因为我知道这个之前也已经能。因为也有一些机构投资者在在咱们那，比方说他们之前投资的时候，对于咱们这个估值的一些认可，或者说是大家提交的一些情，其实也是可以分享。因为这个大家都是行业里面的对对，他们投资了，他们认可这个故事。那他们那这些东西可以和咱们市场化的一些机构分享。当然这个东西因为是个动态的，大家肯定也有新的一些进展，把这些东西影响。因为我们这个对可能我们之前投过一家就是类似的，他是做LS唱这个。可能几个方向，他都做了，都做，然后那个。'
  },

  { type: 2, person: '发言人5', time: '01:28:26', content: '后来后来后来。' },

  {
    type: 2,
    person: '发言人4',
    time: '01:28:27',
    content: '是回购了是吧？然后过了那但是也不能退出了。'
  },

  {
    type: 2,
    person: '发言人1',
    time: '01:28:33',
    content:
      '对，他们那个时候咱们是21年的时候，那时候是4.8。然后今年还是去年的那下半年上半年是我想问。搞了小小几千万，但是其实不止你每次，那他囤货也就不了。'
  },

  {
    type: 2,
    person: '发言人3',
    time: '01:28:56',
    content: '他他什么状态了？产品已经卖了。'
  },

  {
    type: 2,
    person: '发言人1',
    time: '01:28:58',
    content:
      '他他有一些这个卖，但是他的其实收入不达预期。当时我们那个我们是卖给一个起付什么的，当时我们大家都一了一圈，我们其实卖的时候还有点回报上，还有点舍不得。但是大家反正大家一一群我们大家都讨论，但是就当时。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:29:17',
    content: '就无异议。对对对。'
  },

  { type: 2, person: '发言人1', time: '01:29:21', content: '后来。' },

  { type: 2, person: '发言人3', time: '01:29:22', content: '你们。' },

  {
    type: 2,
    person: '发言人1',
    time: '01:29:23',
    content: '想他不接他不接方便。'
  },

  { type: 2, person: '发言人2', time: '01:29:28', content: '所以我就想想。' },

  {
    type: 2,
    person: '发言人4',
    time: '01:29:31',
    content:
      '第一个新手是做液晶的出身的，他他那个创始人开始说，他们早些年也拿过国家项目，我们当时走的路线就是LED。因为我当时觉得液晶这个东西不适合这个微商液晶因为这个技术太老了，液晶这个技术太老了，就是说晶晶相当不看好这个东西。如果你到了危险的时候，元宇宙了，你还有搞的液晶的，是你说把它玩下去能玩到什么个程度？'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:30:02',
    content:
      '很难，这是第一个。因为他为什么会有订单？因为他们是做一些军工的，还有一些叫研科研机构的，那个叫做固定光调制器。这个以前我都研究过，这个不要问太小众了，有订单，但是说也成熟。但是你要做出大的产业是很难的。你想做成一个千亿公司，甚至想做成更大的产业，知道都很难就能做的小生意，十度也是可以的。那么夏老师在学校里面资源还是比较不错的，有也有些学生跟我一样，就能做出一些东西来。何军是原来学计算机数据的，就是导演。但是他比较会讲，他就嘴巴比较厉害，而且他喜欢这个比较喜欢这个，天生就比较。'
  },

  { type: 2, person: '发言人3', time: '01:30:44', content: '喜欢这个做滴滴。' },

  {
    type: 2,
    person: '发言人4',
    time: '01:30:46',
    content:
      '所以他们两个组合还行，就何俊那边在外面加货，然后也相信会就帮他把这个队伍去搞。但是这个团队作业晶的这个驱动芯片的底子相对来说，然后我们也跟他发过新闻，共同发布新闻，然后一起去开发的东西，做额外的，但是现在买回来以后才发现技术还是差的。有一些代数的，所以就是我们在想你用过才知道，因为你你们只能看一些新闻对吧？那些东西我说我们是用户单位最有发言权的。你过来我们就直接测试表演你的接口的性能、速度，就是能行吗？我觉得就行。我们云光这个公司有蛮有劲的。'
  },

  { type: 2, person: '发言人2', time: '01:31:29', content: '我们上下都有。' },

  {
    type: 2,
    person: '发言人4',
    time: '01:31:31',
    content:
      '对行业里面来说，我们实际上也是这些芯片公司的用户。所以基本上大家投的那些芯片公司我们都不去买。对，我们跟他也竞争，但是我们我们俩公司，南京公司是停产了，所以我们会买一些芯片，顺便给我我都要我们购买的都可以。然后上海公司芯片公司，所以我们的芯片也会卖给行业里面原来的企业的。然后我们对于平常也很熟，所以我们对于厂对，是这这个都都很熟，都有很多的。'
  },

  {
    type: 2,
    person: '发言人1',
    time: '01:32:01',
    content: '是的，对，我们那个时候投的时候，真的。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:32:04',
    content:
      '那个时候可能早退，他可能素质低，然后你们还能赚去赚点钱，你再往后走。如果他想扩动再大的产业，这个就需要产业来支撑这个事情。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:32:14',
    content: '那我们就我当时的一个观点。就风口来的时候，你如果再不卖。'
  },

  { type: 2, person: '发言人2', time: '01:32:24', content: '也是鼓励下我们。' },

  { type: 2, person: '发言人4', time: '01:32:25', content: '年终买的时候。' },

  {
    type: 2,
    person: '发言人5',
    time: '01:32:26',
    content: '你卖这本来没人接盘的，对吧？对对对。'
  },

  {
    type: 2,
    person: '发言人1',
    time: '01:32:31',
    content: '当时好几家什么起。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:32:33',
    content: '付什么的都在，还要额外的。'
  },

  { type: 2, person: '发言人2', time: '01:32:37', content: '对吧？缺点就。' },

  {
    type: 2,
    person: '发言人4',
    time: '01:32:43',
    content:
      '跑不了了。他的技术方向上来说比较窄，然后他这个不行，就是说要去myr.'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:32:53',
    content: 'ID那个更加是这个我都。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:32:55',
    content:
      '你你要知道我们员工当时在彭总货物之前，我是是跟行业的最大的区别的。我们卖给GPD，还有好多好多一些机构院所，卖了几千万的那。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:33:07',
    content:
      '这个时候他记住了，他应该了解lippo，LED他要直接去做的话，所以他不懂。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:33:13',
    content:
      '那我们也推出来了。弄了以后，我原来想做主的，后来觉得这个行业我现在全新做的。那个我的认为对你来说我们是最早做的。你想对。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:33:25',
    content:
      '经理销售的，你想首先是芯片，你做的最小那个像素这个尺寸你已经没法去弄，因为外包一定只能用倒装，倒装那两个汉盘占的物理尺寸那么大，你要做的很小。你把三个七天要排了，整整齐齐。所以说去年转移，但是理论上是可以这样，但是实践做的时候就很贵。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:33:58',
    content: '就是他在实战当中遇到的挑战，实际上是好多挑战没有答案。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:34:07',
    content:
      '没有也没有来说就很难。那你说什么叫做巨量反应？你只抓十颗芯片转移到这上面来，如果中间有一颗两颗它是斜的，你怎么调？对吧？是的，然后你粘好以后过或者一次过好以后，如果是有一些偏离的，那个是当时开始一次性我。'
  },

  { type: 2, person: '发言人3', time: '01:34:34', content: '就不开了。' },

  {
    type: 2,
    person: '发言人2',
    time: '01:34:39',
    content:
      '所以我们从头到尾那一段时间，不是希望迈克IDC的话，mini一个中心的四合一强的比较多。是因为那个节点路跟半导体没有关联的，你要把它纯机械式的把micro做做起来。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '01:34:57',
    content:
      '不过国内HR的整个市场也是走歪了，原主人99%都去做AR。对，那你看世界上的销售量。'
  },

  { type: 2, person: '发言人2', time: '01:35:08', content: '对。' },

  {
    type: 1,
    title: 'VR产业发展现状与挑战',
    time: '01:35：08',
    content:
      '该对话片段讨论了VR产业的现状和发展挑战。对话提到了VR市场的观点、投资难点、内容爆款、技术优势和产业链问题。讨论还涉及到了爱奇艺、字节跳动、苹果以及国内外VR公司的情况。最后，对话指出了VR产品的优势和不足，以及中国公司在批量生产和降低成本方面的挑战。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '01:35:08',
    content:
      '还要加一点点利润，上次是没有了。对，然后唯一的VR原来是爱奇艺脑筋有问题，它的核心竞争力是电影。我那时候跟熊总说，你为什么不做3D电影？好莱坞多少3D电影？说这个公司。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:35:27',
    content: '是不是不给他，不给他我们这边消失对吧？'
  },

  {
    type: 2,
    person: '发言人6',
    time: '01:35:31',
    content: '他就一定要自己研发游戏，研发游戏跟赌博不一样。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:35:37',
    content: '爱就是爱奇艺不是个游戏公司。'
  },

  { type: 2, person: '发言人1', time: '01:35:41', content: '我们这个吃的。' },

  { type: 2, person: '发言人2', time: '01:35:42', content: '是。' },

  {
    type: 2,
    person: '发言人4',
    time: '01:35:43',
    content: '就是我就这个这个事情就留给专人去做爱奇艺。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:35:48',
    content: '相关处理。最近我们提过这一轮。'
  },

  { type: 2, person: '发言人3', time: '01:35:52', content: '接触了多少机构。' },

  {
    type: 2,
    person: '发言人4',
    time: '01:35:56',
    content: '我们也没有集中说做完，陆陆续续的都有一些。但是。'
  },

  { type: 2, person: '发言人2', time: '01:36:05', content: '我们也。' },

  {
    type: 2,
    person: '发言人6',
    time: '01:36:07',
    content: '不懂，所以都是来了讲讲完了就没戏了，我们也不知道该怎么追。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:36:15',
    content:
      '就现在我就发现我的这个感受跟你刚说的感受是一样的。就是现在的机构一个大的观点，就觉得这个市场我们看这个产品活动比较看好，但是哪一家出来我不知道。他的层面是停留在VI层面，就是那个系统层面的。这个我也包含出来因为一小公司很难起来的一个，我还打个什么VIP出来。然后我今天做起来了，明天包括一做，你只要一做，这个品牌就被干掉了。因为你的供应链都是可以被研究透的，你有啥供应链，那么他们去做啥供应，他的供应量对，一法律。所以做做品牌商似乎是没前途的那大玩家现在没几个，可能就是pico，对爱奇，然后可能还有华为，就接触这些不多，所以投资公司很难下手。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:36:58',
    content:
      '对，你你你不可能在爱奇艺或者投那个那个PPOPP跳动已经卖掉了是吧？你没机会了，爱奇艺那你觉得有二线的，你又不大又不大会去做做二线的，要做做头部的那头部已经卖掉了，你就没有点不下手了。这是一个，大家就希望是不是有爆款的内容出来，报考内容是有的。不知道大家有没有玩，美国好多爆款内容出来了，房间节奏一个对吧？然后还有XX的这个一个半东西，最近又出来了，又有几个了对吧？其实美国是一个很很好玩的玩具，我也是个资本玩家，反正回去一直在玩。那就是我不知道国内是。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '01:37:38',
    content:
      '怎么玩这个东西的。国外现在大家都在看皮口，字节跳动它是一个搜索引流，但是社交它也不是一个游戏公司。'
  },

  { type: 2, person: '发言人2', time: '01:37:49', content: '对。' },

  {
    type: 2,
    person: '发言人4',
    time: '01:37:50',
    content:
      '而且他的政策其实不好，他给游戏者扶持，以前还有扶持开发一个300万，现在今年就50万了，再下去都没了。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:38:00',
    content: '好，那现在这个因为本来用东西在用，就是OLED没有的是吧？'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:38:07',
    content: '我看的东西也没有，大家你现在有没有控制。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:38:10',
    content: '下去就是了，里面东西的我老觉得我太糊涂了，我不喜欢这种。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:38:15',
    content:
      '对，你说的太对了。对，问题就是在这边。就现在大家讨论的这个VR全是买眼睛的这个VR这个眼睛的VR它是天花板，就已经在这边了，大家就不用看了。是我我们现在员工干的事情，不是现在这个事情。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:38:29',
    content:
      '怪怪我的意思就是从效果上你要这样去能让我看一下你现在你现在你你现在的1.3升那个像素之间的，现在接入。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:38:41',
    content:
      'P7大概是2，就是PPI。3000 2700 2800这个品种你看到基本上就没有那个。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:38:49',
    content: '因为他们拿过来的什么小块了，看已经看不到。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:38:55',
    content: '那个小块的。但是技术已经非常好了。'
  },

  { type: 2, person: '发言人6', time: '01:39:01', content: '要拿这么大一块。' },

  {
    type: 2,
    person: '发言人4',
    time: '01:39:02',
    content: '就是华人机跟液晶的启动是所以。'
  },

  { type: 2, person: '发言人6', time: '01:39:05', content: '看看不出。' },

  {
    type: 2,
    person: '发言人4',
    time: '01:39:06',
    content: '所以这块这个就是苹果的。'
  },

  { type: 2, person: '发言人6', time: '01:39:09', content: '那个也是。' },

  {
    type: 2,
    person: '发言人4',
    time: '01:39:10',
    content: 'pro明年你说的一个出来，大家就知道。'
  },

  { type: 2, person: '发言人6', time: '01:39:15', content: '这个就是液体的。' },

  {
    type: 2,
    person: '发言人4',
    time: '01:39:17',
    content: '所以因为现在没有人干出这个就哪里。'
  },

  { type: 2, person: '发言人2', time: '01:39:22', content: '对对对。' },

  {
    type: 2,
    person: '发言人4',
    time: '01:39:23',
    content:
      '我们因为产线也在下线，所以我们现在是有合作的几家，但是目前为止还没有推这个产品出来。我们也是才出来，需要把我们的产品用到这个上面去。苹果他已经做了这个事情了，所以最现实的就是等苹果出来，大家看到苹果的东西。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '01:39:40',
    content: '就知道这么本来今年我们是很希望苹果六月就出来了。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:39:44',
    content: '检查没通过来沟通。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '01:39:47',
    content: '大家就可以感受到什么才叫LED。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:39:50',
    content: '但华兴现在做的AIDC的手机上。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:39:54',
    content:
      '华西做的就玻璃做的。所以但是用硅做成平台，所以它在玻璃上面还是用传统的做业务做好。'
  },

  { type: 2, person: '发言人4', time: '01:40:08', content: '这样的就是他们。' },

  {
    type: 2,
    person: '发言人2',
    time: '01:40:10',
    content:
      '这边不一样，他们这个是飞机上先把这个驱动这个西红柿把它做好，在上面直接来做。'
  },

  { type: 2, person: '发言人5', time: '01:40:18', content: '你就是要轻很多。' },

  { type: 2, person: '发言人2', time: '01:40:20', content: '清晰度很多。' },

  { type: 2, person: '发言人5', time: '01:40:22', content: '这也是很清晰的。' },

  { type: 2, person: '发言人2', time: '01:40:24', content: '很多能。' },

  { type: 2, person: '发言人5', time: '01:40:25', content: '望远镜。' },

  {
    type: 2,
    person: '发言人4',
    time: '01:40:26',
    content:
      '望远镜就是他上了一个level的。就是我我们怎么理解？就原来你可以可能看的是液晶或者干涩品种之类的。现在我们给你的突然很优秀的人出来，你进园去这个感觉就完全不就是说这个东西它跟现在P图我们讲的是2两2两种产品，或者是一个升级类的产品。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:40:45',
    content: '所以说我觉得可以放一个。'
  },

  { type: 2, person: '发言人2', time: '01:40:49', content: '样子放到我们。' },

  {
    type: 2,
    person: '发言人5',
    time: '01:40:51',
    content:
      '就这样，就反正你我看上去，为什么你说你是贵一点，像我们这个人贵一点，贵一点。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '01:40:57',
    content: '的这东西跟你跟我不就是。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:41:00',
    content: '我们我们不是我去。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:41:03',
    content:
      '就是你我就说你不培养机，你下面对吧，你找人家到下游给你做一个东西。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '01:41:09',
    content:
      '现在的压力机，对，苹果专门为这个开发了RQ芯片，不然的话对它是整个是系统工程。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:41:20',
    content:
      '就是很难做的好啊，就是可以看到显示很优秀。但是你对一般用户来说的话，你只是放个东西来说，是他是感觉不是那么强烈的，一定要做出一个东西。头戴好，就很轻，然后手臂动不动他也可以跟着你动。这个企业是比较对他比较小。我们只是一家供应链，就我们只是一家上游这些供应链，但是我们看好这个方向，我们他不做手机，他只做手机。'
  },

  { type: 2, person: '发言人2', time: '01:41:45', content: '对。' },

  {
    type: 2,
    person: '发言人4',
    time: '01:41:46',
    content:
      '所以你要不做一个手机，我手机可能做不出出来，可可能可能做的不太好，很粗糙。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:41:51',
    content: '反而你会觉得这个。'
  },

  { type: 2, person: '发言人6', time: '01:41:54', content: '东西很多。' },

  {
    type: 2,
    person: '发言人1',
    time: '01:41:55',
    content: '非常的清楚，所以他们是对。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:41:59',
    content:
      '你可以把瓜子拿过来看，就是瓜子当中当中是会稽痘类的，旁边是对规定的。'
  },

  { type: 2, person: '发言人6', time: '01:42:05', content: '它是液晶加厚的。' },

  { type: 2, person: '发言人2', time: '01:42:08', content: '给看过的对。' },

  {
    type: 2,
    person: '发言人4',
    time: '01:42:09',
    content:
      '就是未来的东西，就是我们这种品未来的东西就是要替代899，因为899外面还是太贵了，因为它它是液晶加上那个我的。如果你有89，你可以对他自己体验过的。'
  },

  {
    type: 2,
    person: '发言人1',
    time: '01:42:23',
    content: '然后他他的这真是不一样，就是说做出来。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:42:26',
    content:
      '你带上去就感觉是非常清晰的一个世界，对吧？很很好的一个东西。然后我看他是十几万多。能忠诚一点没有，他只是功能机。对对对，他这个东西不是线上卖的，就是给政府做飞飞行员的培训员做一个测试。'
  },

  { type: 2, person: '发言人2', time: '01:42:46', content: '做起来来对对。' },

  {
    type: 2,
    person: '发言人1',
    time: '01:42:48',
    content: '来跟大家做做他们专业的用来拍摄一些电影。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:42:50',
    content:
      '对对对，这个东西一定要让中国公司来把它批量，把它成本降下来。对，大众公司他他不具备国内的这个消费电。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:43:02',
    content: '所以他就是跟你这个路线作用。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:43:05',
    content:
      '就是这个可以搞这个，但是别的他说什么？因为索尼它是一种0.7寸的屏，索尼有没有1.33的那那0.7寸你不是按照当时开吗？买一套那个。所以你把你原上的问题都解决了，就不需要他曾有。'
  },

  { type: 2, person: '发言人2', time: '01:43:30', content: '这一段。' },

  { type: 2, person: '发言人5', time: '01:43:31', content: '还不成在一起了。' },

  {
    type: 2,
    person: '发言人4',
    time: '01:43:34',
    content:
      '所以我们现在的这个公司的价值还是被市场发掘出来，这是我现在看的问题。我们聊了个机构，最后跟我讲的是两件事，其实是可以的。所以这个混合的应用就不对。'
  },

  {
    type: 1,
    title: '游戏市场及产业链分析',
    time: '01:43：48',
    content:
      '本文主要探讨了游戏市场以及游戏产业链的相关问题。其中，游戏市场的规模及发展趋势、游戏在各个领域的应用、不同游戏类型的特点等内容都进行了分析和讨论。此外，还对游戏开发的相关技术、设备以及市场推广等方面进行了探索和梳理，为游戏产业的发展提供了一些思考和建议。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '01:43:48',
    content:
      '就是大家都在弄游戏，但游戏只是这你就看电脑游戏只占电脑的2%的市场，其他的是工作、设计、工业，这些才是。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:44:01',
    content:
      '大市场。什么都没吃饭。我给你算过的一个政府全国年上万个连续镇，一个人搞这个十套。好，因为就是你你想对。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:44:17',
    content:
      '你们这个园区是愿意做这个东西，对吧？对，我们是可以定制一个东西出来。进去以后就是路演，就是投资，就是给机构定了一个政府定制一个园区，跟园区。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:44:28',
    content: '没完没了的人去参观对吧？每个人带一套。'
  },

  { type: 2, person: '发言人5', time: '01:44:33', content: '他们觉得。' },

  {
    type: 2,
    person: '发言人2',
    time: '01:44:34',
    content: '发给政府一套东西。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:44:38',
    content:
      '他们大概是送了三个还是？送3到5号的这个系数，书记、县长，那肯定不够。他这边委办局那帮人都带了什么医院去奥运了。弄完了之后。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:44:51',
    content: '理论上来讲中国元素区间。'
  },

  { type: 2, person: '发言人5', time: '01:44:55', content: '接近3000个.' },

  {
    type: 2,
    person: '发言人2',
    time: '01:44:57',
    content: '是就3000个。你一台一个地方搞100台是.'
  },

  { type: 2, person: '发言人4', time: '01:45:02', content: '那就是30万.' },

  {
    type: 2,
    person: '发言人5',
    time: '01:45:04',
    content: '因为量大，为什么呢？'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:45:05',
    content: '因为他们技术我们产生的这个知道的绝大多数人的。'
  },

  { type: 2, person: '发言人4', time: '01:45:10', content: '大部分人都。' },

  {
    type: 2,
    person: '发言人2',
    time: '01:45:11',
    content: '知道元宇宙的宇是什么？是元宇宙。然后每个月50万家之后。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:45:18',
    content:
      '你看为什么呢？你一个叫学校，一个医院，一个什么，他们每个委办去去，你看那个三观都不下来，是其实你不点一点不下来是太不贵。'
  },

  { type: 2, person: '发言人2', time: '01:45:30', content: '这个东西。' },

  {
    type: 2,
    person: '发言人5',
    time: '01:45:31',
    content:
      '比如说你采购来说，你买个十套买了，因为我送就送了，比如说三套五套了。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:45:37',
    content:
      '那这个课题就变得简单，我们找一个客户专门做这个压根解决的做产品给他定制一个，就是先考虑这个场景对吧？因为我做通用的这个难度的那我就做这个场景的这个，我觉得这个就可以做。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:45:53',
    content:
      '其中有一个目标是中国的2800个区县，每一个上面都有一套的头盔。原来的头盔问题是打游戏这个场景他也不会打游戏的对吧？他他就说他每天在用，他为什么每天要在用呢？就是这个包括为什么就不像现在马云也会失去没有他在他们上海的时候开这个会就闪送到我家里，散坐在家里，他带着他肯定看个三分钟五分钟，就看那个场地过去看一下。'
  },

  { type: 2, person: '发言人4', time: '01:46:25', content: '没人看过你这里。' },

  {
    type: 2,
    person: '发言人5',
    time: '01:46:27',
    content: '对不对？当然这是一个非常好的推广。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:46:30',
    content: '这个是很不好，或者这个推广形式很好。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:46:34',
    content:
      '对吧？这个就非常好。为什么张总也来跟我合作？因为如果有政府，全中国又有政府又有企业做。'
  },

  { type: 2, person: '发言人4', time: '01:46:43', content: '你知道吧？' },

  {
    type: 2,
    person: '发言人5',
    time: '01:46:44',
    content:
      '那你光光有就是光有工具，没有，光有工具没人在，对吧？因为上午这个法则讲的好太好的，新疆这个心得太远了，我们这边飞过去对吧？你看你那一个头盔。对吧？那个全都是政府出面，是政府为什么？因为现在他们招了你的那个包都是一些资料。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:47:12',
    content: '那个区的印刷品，你不用。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:47:13',
    content: '每个人头盔就好了的。是的，全国的招商局的所有驾校。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:47:25',
    content: '百万大军，一百多万大军。'
  },

  { type: 2, person: '发言人2', time: '01:47:29', content: '你知道吧？' },

  {
    type: 2,
    person: '发言人4',
    time: '01:47:30',
    content: '发现一个市场，发现这个是个好市场。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:47:37',
    content:
      '弄一个10万的话，干嘛都讲不清楚的，你就带上去让他看。第二个，他们努力在哪里？不但看在里面，那边是数据线的现场分析室。他开始交流，他有这个搜索在。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:47:55',
    content:
      '这个项目是值得去做的。因为我们做过，我们不是跟佳宁，我不是还有几个软件开发的，我们给南京江宁就做过一个展厅，企业展厅弄得蛮漂亮的，里面都是企业的这个队伍，是一脚就知道。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:48:08',
    content:
      '然后里面有配音，这个你们可以深度合作一下。现在反正他们现在用的那个样机是对口的。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:48:16',
    content: '这种是入门级的，反正先解决有无的问题。'
  },

  {
    type: 2,
    person: '发言人1',
    time: '01:48:22',
    content: '机构的钱，因为机构也为了要推广他们的那个。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:48:26',
    content: '他送了他违规。但是他那个眼镜就是天花板就在这边了。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:48:31',
    content: '就是你再怎么讨论。'
  },

  { type: 2, person: '发言人4', time: '01:48:34', content: '说话不清楚。' },

  { type: 2, person: '发言人2', time: '01:48:35', content: '又晕了就晕了。' },

  { type: 2, person: '发言人4', time: '01:48:38', content: '肯定他没钱不好。' },

  {
    type: 2,
    person: '发言人2',
    time: '01:48:39',
    content: '对吧？因为他眼睛要不停的聚焦，不停的聚焦。'
  },

  {
    type: 2,
    person: '发言人3',
    time: '01:48:42',
    content: '而且很多领导都是远视，什么近视。'
  },

  { type: 2, person: '发言人6', time: '01:48:46', content: '你看看。' },

  {
    type: 2,
    person: '发言人4',
    time: '01:48:48',
    content: '所以看不清，你这个产品就没有办法推广。所以现在比亚。'
  },

  { type: 2, person: '发言人5', time: '01:48:53', content: '不是说没需求。' },

  {
    type: 2,
    person: '发言人4',
    time: '01:48:58',
    content:
      '不行，我IDIYID的我们也很熟，他这个团队怎么弄的呢？原来是大鹏叫大鹏，各项的一个几个人？出来现在再回去了，回去了，他拿回去了，看完了。'
  },

  { type: 2, person: '发言人1', time: '01:49:14', content: '他们当一个废物。' },

  {
    type: 2,
    person: '发言人4',
    time: '01:49:16',
    content:
      '就说就是这个问题，就是说你这个供应链是很好被别人弄的了。比如说我这个创业公司对吧？我我我也公司也是一群人，但是你找哪个骨干出去把你供应链复制一下。华为营销短短的半年之内搞了个东西，比那个机构可以，机构的比较不错的。他哪来的水平？他不可能从零起起建的对，倒进去不是他老公家那边的。所以这样的公司投资是很很危险的。因为他他们今天能到外地搞点钱，明天也有好多地方再搞点钱，你说你说这个就很难。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:49:52',
    content:
      '但是我们的上游供应链就不一样，就是你不管哪一家，我只要这个期间本身我是有足够多的。所以我为什么强调我们工号价格有优势？我只要抓住这两点，你不管是长远怎么变，我的品总是有竞争力。所以我们做上游就是做做性价比。这就这件这件事我只要我的工号，我的这个成本在行业内是有足够竞争力的。你不管行业下面怎么变。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:50:17',
    content:
      '你现在是你现在的这个对身体这块是上面跑的这个社区店铺都是你自己设计。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:50:25',
    content: '而且我们是围了一下，目前为止看起来是又做芯片。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:50:29',
    content: '中芯国际要保证国际部里边的第二家。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:50:33',
    content:
      '不是我们给中国资产，就是it资产。好了，我也他不能拿你们的烟烟去去去的，是吧？这个他不可能的，肯定不会干的，他不能为这个。事那这个也就是所以说。'
  },

  { type: 2, person: '发言人2', time: '01:50:57', content: '我把燕军。' },

  {
    type: 2,
    person: '发言人5',
    time: '01:50:58',
    content: '换成你的东西配置，你的东西可能就贵。'
  },

  { type: 2, person: '发言人3', time: '01:51:03', content: '一倍了是吧？' },

  {
    type: 2,
    person: '发言人4',
    time: '01:51:06',
    content:
      '对我感觉我说我们家了，我们讲他现在用的液晶肯定是50美刀的那就是1 300块钱，差不多是这个样子的，或者三四百这个样子的。我们现在这个平时售价大概1000块钱，1000块钱，150美金。索尼是350美金，我们一直在那一天，每天意味着他就贵两千。他现在的成本是两千多，可能他就要卖到四五千，但是我觉得四五千是有市场的。你这个东西好用的，你好用这个综合合规还是他是互联网的思维，他希望把每个人都买6000，那你可能是比较贵，这只有下20到34000。因为他想卖到上亿台的可能是蛮难的对吧？但是我四五千的东西，我卖个几十万台、百万台是可以的是吧？然后我给行业用去做。我一开始先不说这个，只要视觉效果，因为这个云阳你这个是东西是不一样的，反正就是其他东西都一样，就是显示的质量特别好好。'
  },

  {
    type: 2,
    person: '发言人1',
    time: '01:52:00',
    content: '我看过那个瓜子，它还能自动的。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:52:05',
    content: '对它对焦，现在这个都可以做的。PO他不是。'
  },

  {
    type: 2,
    person: '发言人1',
    time: '01:52:09',
    content: '就已经开始这个工作。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:52:11',
    content:
      '里面搞个电机，外面有红外传播。显示方式不一样的对吧？所以你手机的操作一些东西你家里面都能用对吧？那么你唯一不一样的就一个品牌光学对吧？光学公司多，他那个你我至少所属的20家公司都有，就我们接触的人我估计选择100家都有。对，就是中国的一百多家公司都能干压力，他们绝对不是门槛。'
  },

  { type: 2, person: '发言人2', time: '01:52:40', content: '这个整体。' },

  {
    type: 2,
    person: '发言人4',
    time: '01:52:41',
    content:
      '大红两片这个品质。我自己读过博士高研究生，我自己做什么做demo我们都是专业公司的。但屏它是个体系，它需要芯片驱动，芯片需要生产厂厂商就需要结合，对吧？你这是个体系，那这今天的这个大产业，除了你这个大产业，你需要有这帮人做起来才能保证。所以平时这个突破点上最关键的因素，其他人多少平，你刚才说还有东西说没有，现在他为什么这样做？我告诉你回答你为什么因为处理性能不够，因为我们自己遇到的瓶颈就是你想开发个好的东西，你发现根本跑不起来。比如说你现在的是手机上的那个那个产业链，用的那个处理器可能还不如手机处理处处理东西。所以你美术师能画的出很漂亮的场景，你在里面我有时间可能一看都是很简单的很糙的动画，对吧？'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:53:32',
    content:
      '我觉得这个根本不好玩，这个确实是这样。但是问题是处理器它大概我估计每过应该是每过两三年就能翻一倍。你看今天快速的算法的这个东西，它处理现在已经翻一倍了，已经能比三年之前那个产品翻一倍了。那你往后面再三年，就用了三年的还能翻一倍，那个时候你就觉得这个就不是问题了，我的这个内容会越来越好的，只要我这个处理性能后，这个市场还好。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:53:57',
    content:
      '具有头部的开发的环节的开发率。现在问题是不是没有重量级的内容，是因为没有头部的这个内容公司去开发这个东西。你比如说随便哪个团队，你还有个团队马上帮你就搞一个。'
  },

  {
    type: 2,
    person: '发言人1',
    time: '01:54:11',
    content:
      '这个当时也跟他们聊过，对，他说要不要把这个原神移到那个那个上面对。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:54:16',
    content:
      '那么他们肯定算一下多少终端，多少东西，我投这个是不是划算。如果能投个几个亿的，他们搞原生就搞了投了两个亿的对吧？你说再搞5000万，我把它变现的东西搞个团队研究一把，那肯定用户本身一大堆。所以说不是说没市场，员工只是一个爱很多人，你比如说别人的做美妆吧？我们都调研过几个这些公司，我觉得这个公司最后都会上来干这个事情，这个不需要了。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:54:40',
    content:
      '大家知道不是现在的小团队吭哧吭哧的搞几个人的话，好不容易搞个游戏出来上线的那种像背后的推广方法，他到小团队来搞游戏，比如说一个团队补业务是吧，你说这怎么可能搞得出好好的东西，不可能说不好玩游戏的。就这就这种能迭代的方式，能迭代方案，经过这个七八年能迭代的玩法都迭代完了，现在迭代出来就是光按节奏的那拍戏对吧？这个还可以，还有一个大枪的离人的视角拍戏对吧？他没有这种打怪升级的手游戏都没有，MYPG都没有。所以他不他问题不在这里，问题在于你现在。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:55:28',
    content:
      '大家的信心，外面砸你这个产品就行了。你按照现在的投入体量，根本没有办法跟这个手机相比。手机这个行业经过了十七八年，20年的行业，这么多钱往里面砸，钱砸的时候你不要想短短的想靠几个公司，VC公司或者我想去砸几个亿出来。你这个体量，你行业大大产业媒体去做，没有人起来干的，我干不动。所以说我们判断逻辑就是一定是上游层面下的先出一个很牛的器件出来，然后再有系统公司出来，拿着牛牛牛的事件去做产品。就是说999为什么这么贵呢？他没评价，他没有别的供应商给他供作品，他只能自己动脑筋，索尼买个0.7寸的，自己买个液晶的，拼起来想办法去搞这个。'
  },

  { type: 2, person: '发言人3', time: '01:56:14', content: '事情的对吧？' },

  {
    type: 2,
    person: '发言人4',
    time: '01:56:15',
    content:
      '这是你看你这个企业就很好啊，人家就不干这个事。那我们现在站出来，我说你完全不用干了，我现在给你一点，再再给一点，对吧？我把好的企业直接扔到你手上，这是一块直接点进去，你先干个几班的，这个同学先。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:56:30',
    content: '干干干一些出来。对。'
  },

  { type: 2, person: '发言人2', time: '01:56:37', content: '该去做。' },

  {
    type: 2,
    person: '发言人1',
    time: '01:56:39',
    content: '但是我看到他的未来。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:56:43',
    content: '我们因为看过这个东西，我觉得未来。'
  },

  {
    type: 2,
    person: '发言人3',
    time: '01:56:45',
    content: '体验是很差很远的对，那你要换上整个大屏的。'
  },

  {
    type: 2,
    person: '发言人2',
    time: '01:56:50',
    content: '你看的时候他那个0.7寸的对对。'
  },

  {
    type: 2,
    person: '发言人1',
    time: '01:56:54',
    content:
      '然后我那时候不知道他是当中哪一块是边上的，不是50的，但我不知道这个事。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:56:59',
    content: '但是我当它是符合的吗？'
  },

  { type: 2, person: '发言人2', time: '01:57:01', content: '符合。' },

  {
    type: 2,
    person: '发言人6',
    time: '01:57:01',
    content: '但是两个屏幕后面是液晶寸寸，前面一个0.7寸合在一起。'
  },

  { type: 2, person: '发言人2', time: '01:57:10', content: '合在没有。' },

  {
    type: 2,
    person: '发言人4',
    time: '01:57:11',
    content: '那个还是有道理，你马上就觉得这个东西跨时代的这个是跨时代的直播。'
  },

  {
    type: 2,
    person: '发言人5',
    time: '01:57:17',
    content: '那你现在如果说成都也好，南京也好，你破产了之后，你的整个这个。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:57:22',
    content:
      '价格也可利润的。我估计在100美金，这个是多少？现在大概在150、200，但是我觉得一百也好，两百也好，都不是关键，就是都有市场。我卖两百有两百的市场，卖100有100有市场。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '01:57:37',
    content:
      '2016年的时候，手机的液晶屏才十块美金，OLED屏要100美金，大家全部去拿ORE10倍，十倍现在手机都收了。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:57:51',
    content:
      '对，所以我现在觉得价格不是，所以我互联网公司它是批复思路是反的。它就是要你看贝塔也是要便宜对吧？它是希望大家都用上去，所以要便宜才能普及更高，他才能走他的应用起来。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:58:05',
    content:
      '但是我们的思路不是，我们我们是苹果思路。你先有好的东西，哪怕2000到3000到2万到3万到你都有客户。你那个200 200出的东西你就不是客户，人家是勉强硬。其实我是因为便宜，或者我是因为各种机缘抢购才用一用，大家都不行，那我就不会持续的去买，对吧？他他他是你像我们办公室批复的也是一堆会是一堆平时开发员工也不会去玩。只是我说我面对他们，你不去开发，你再去开发，你以前的功夫没人去，我还是在那玩手机，你说这个没有意义。讲白了就是我pico如果我是送我都不用。用户说过，假设他有五千多的子弹送下去，他就是逻辑想多了。'
  },

  {
    type: 2,
    person: '发言人1',
    time: '01:58:47',
    content: '去年不是这样说的，他对他开始他送，然后他是他打卡189天.'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:58:52',
    content:
      '你会发现网上你的平台的这个1800的提取出来，是因为大家打完卡就把机器二号手掉了。对，所以这个东西对用户来说没价值。有价值的是那个180年坚持下来那个运动的那那段时间对投资本身没价值，对吧？所以它是没价值的。'
  },

  {
    type: 2,
    person: '发言人1',
    time: '01:59:11',
    content: 'RGB输入外面的那个东西，我始终觉得它有点变形。这个是跟。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:59:17',
    content:
      '这个是不是这是他们催缴的，所以说没什么，就是说这个技术肯定就是因为你摄像头跟人脸没对起来，现在看到人走到旁边就有点歪，拉伸，对吧？这个是吧？这个是。'
  },

  {
    type: 2,
    person: '发言人6',
    time: '01:59:37',
    content:
      '只有两边畸变，前面摄像头本身就机变了，然后光学镜片又没有跟摄像头搭配好，所以只有两个。'
  },

  {
    type: 2,
    person: '发言人4',
    time: '01:59:47',
    content:
      '他没有原理性科学原理性的问题，他只是工程量的问题。你什么情况要问。'
  },

  { type: 2, person: '发言人2', time: '01:59:53', content: '这也是你来自于。' },

  {
    type: 2,
    person: '发言人3',
    time: '02:00:03',
    content: '认收货，要不然的话，那就是很多人。'
  },

  { type: 2, person: '发言人4', time: '02:00:08', content: '我们大概。' }
]
