import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts'
// import axios from 'axios'
import { instance } from './utils'
import IatRecorder from './utils/IatRecorder'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

const iatRecorder = new IatRecorder('en_us', 'mandarin', '6bd5e2e0')
Vue.use(ElementUI)
Vue.prototype.accessToken = 'qHPtfayCyRt4gG3T'
Vue.prototype.iatRecorder = iatRecorder
Vue.prototype.Axios = instance
Vue.prototype.Echarts = echarts
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
