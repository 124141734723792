<template>
  <div class="login">
    <div class="container">
      <div class="header flex-row">
      </div>
      <div class="login-form-container">
        <div class="title">欢迎登陆</div>
        <div class="login-form flex-col">
          <div class="mobile" style="margin-top: 56px">
            <input
              type="text"
              maxlength="11"
              placeholder="请输入手机号码"
              v-model.trim="form.mobile"
            />
          </div>
          <div
            class="password flex-row"
            style="margin-top: 20px"
            v-if="form.mobile !== '13888880201'"
          >
            <input
              type="text"
              ref="code"
              placeholder="请输入验证码"
              v-model.trim="form.code"
              style="width: 266px"
            />
            <span @click="getCode" v-if="show" class="code">短信验证码</span>
            <span v-else class="code">{{ count }}秒重发</span>
          </div>
          <div class="mobile" style="margin-top: 20px" v-else>
            <input
             @keyup.enter="login"
              type="password"
              placeholder="请输入密码"
              v-model.trim="form.password"
            />
          </div>
          <div class="submit" @click="login">立即登陆</div>
        </div>
        <div class="qywx">企业微信登录</div>
        <div class="wx-icon" @click="qylogin">
          <img src="../assets/image/login-qywx.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/css/login-view.css'
import { sStorage, lStorage } from '@/utils'
import md5 from 'js-md5'
export default {
  name: 'LoginView',
  data () {
    return {
      show: true,
      timer: null,
      form: {
        mobile: '',
        code: '',
        password: ''
      },
      count: 60
    }
  },
  created () {
    if (!lStorage.get('token') || this.accessToken !== Object.keys(lStorage.get('token'))[0]) {
      this.getToken()
    }
    if (this.$route.query.code) {
      // 扫码登陆
      this.wxLogin(this.$route.query.code, this.$route.query.appid)
    }
  },
  methods: {
    wxLogin (code, appid) {
      this.Axios.post('https://zcjrys.chinaindustria.com/api/auth/union/login/v2', {
        code: code,
        appid: appid,
        endpoint: 'CHAIN_AI'
      }).then((res) => {
        sStorage.set('loginToken', res.data.data)
        this.$router.push({ name: 'index' })
      })
    },

    getCode () {
      if (!this.form.mobile) return this.$message.error('请输入手机号')
      this.Axios.post('https://zcjrys.chinaindustria.com/api/auth/union/send/sms', {
        endpoint: 'CHAIN_AI',
        account: this.form.mobile
      }).then((res) => {
        if (res.data.code === 200) {
          this.$refs.code.focus()
          const timeCount = 60
          if (!this.timer) {
            this.count = timeCount
            this.show = false
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= timeCount) {
                this.count--
              } else {
                this.show = true
                clearInterval(this.timer)
                this.timer = null
              }
            }, 1000)
          }
        } else {
          alert(res.data.msg)
        }
      })
    },
    login () {
      const { mobile, code, password } = this.form
      if (mobile === '13888880201') {
        if (!password) return this.$message.error('请检查登录信息是否完成')
      } else {
        if (!code) return this.$message.error('请检查登录信息是否完成')
      }
      const params = {}
      if (this.form.mobile !== '13888880201') {
        params.account = this.form.mobile
        params.code = this.form.code
        params.type = 'CHAIN_AI'
      } else {
        params.account = this.form.mobile
        params.password = md5(this.form.password)
        params.type = 'CHAIN_AI'
      }

      this.Axios.post('https://zcjrys.chinaindustria.com/api/auth/union/login/v2', { ...params }).then((res) => {
        sStorage.set('loginToken', res.data.data)
        if (lStorage.get('phone') !== this.form.mobile) {
          this.getToken()
        }
        this.$router.push({ name: 'index' })
      })
    },
    qylogin () {
      this.Axios.get('https://zcjrys.chinaindustria.com/api/auth/wechat/login/scanning/CHAIN_AI').then((res) => {
        const a = document.createElement('a')
        a.setAttribute('href', res.data.loginUrl)
        a.setAttribute('target', '_self')
        document.body.appendChild(a)
        a.click()
      })
    },
    // 获取model学习token
    getToken () {
      this.Axios.get('https://model1.chinaindustria.com/api/passport', {
        headers: { 'X-App-Code': this.accessToken }
      }).then((res) => {
        lStorage.set('token', { qHPtfayCyRt4gG3T: res.data.access_token })
        lStorage.set('phone', { phone: this.form.mobile })
      })
    }
  }
}
</script>
