import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router'
import { sStorage, lStorage } from '@/utils'
const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '' : 'https://chainai.mtheworld.com'
  // baseURL: 'http://61.169.69.123:63100'
  // baseURL: 'https://chainai.mtheworld.com/chat_room'
  // baseURL: 'http://10.66.8.6:3002'
})

instance.interceptors.request.use(function (config) {
  const token = sStorage.get('loginToken')
  if (token) {
    if (config.url.indexOf('model') > -1) {
      config.headers.authorization = 'Bearer ' + lStorage.get('token').qHPtfayCyRt4gG3T
    } else {
      config.headers.userId = sStorage.get('userId') - 0
      // config.headers.userId = 2688
      config.headers.authorization = token.token
    }
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

instance.interceptors.response.use(function (response) {
  return new Promise((resolve, reject) => {
    if ((response.status !== 200 && response.status !== 204) && response.data.code !== 200) { // ...文件上传成功没有返回code...
      Message.error(response.data.msg || response.data.message || response)
    } else {
      resolve(response)
    }
  })
}, function (error) {
  console.log(error)
  if (error.response && error.response.status === 401) {
    Message.error(error.response.data.msg || error.response.data.message || error.response)
    setTimeout(() => {
      sStorage.clear()
      router.push('/')
    }, 1000)
  } else {
    Message.error(error.response.data.msg || error.response.data.message || error.response)
  }
  return Promise.reject(error)
})

export {
  instance
}
