import { isNullOrUndef } from '@/utils'

export const storage = {
  // 存储
  set (key, value) {
    sessionStorage.setItem(key, JSON.stringify(value))
  },

  // 取出数据
  get (key) {
    try {
      const value = sessionStorage.getItem(key)
      if (value === null || value === undefined || value === '') return null
      return JSON.parse(sessionStorage.getItem(key))
    } catch (err) {
      return null
    }
  },

  // 删除数据
  clear (key) {
    sessionStorage.removeItem(key)
  }
}

class Storage {
  constructor (option) {
    this.storage = option.storage
    this.prefixKey = option.prefixKey
  }

  getKey (key) {
    return `${this.prefixKey}${key}`.toUpperCase()
  }

  set (key, value, expire) {
    const stringData = JSON.stringify({
      value,
      time: Date.now(),
      expire: !isNullOrUndef(expire) ? new Date().getTime() + expire * 1000 : null
    })
    this.storage.setItem(this.getKey(key), stringData)
  }

  get (key) {
    const { value } = this.getItem(key, {})
    return value
  }

  getItem (key, def = null) {
    const val = this.storage.getItem(this.getKey(key))
    if (!val) return def
    try {
      const data = JSON.parse(val)
      const { value, time, expire } = data
      if (isNullOrUndef(expire) || expire > new Date().getTime()) {
        return { value, time }
      }
      this.remove(key)
      return def
    } catch (error) {
      this.remove(key)
      return def
    }
  }

  remove (key) {
    this.storage.removeItem(this.getKey(key))
  }

  clear () {
    this.storage.clear()
  }
}

export function createStorage ({ prefixKey = '', storage = sessionStorage }) {
  return new Storage({ prefixKey, storage })
}
